export const statuses = {
  new: "new",
  prepare: "prepare",
  ready: "ready",
  picked: "picked",
  delivered: "delivered",
  undelivered: "undelivered",
  cancel: "cancel",
  refused: "refused",
  incomplete: "incomplete",
  damaged: "damaged",
  other: "other",
  approved: "approved",
  sale_order_ready: "sale_order_ready",
  waiting_approvement: "waiting_approvement",
  draft: "draft",
  completed: "completed",
  pending: "pending",
  accepted: "accepted",
  denied: "denied",
  unknown_price: "unknown_price",
  pricing: "pricing",
  active: "active",
  inactive: "inactive",
  unavailable: "unavailable",
  success: "success",
  fail: "fail",
  processing: "processing",
  available: "available",
  unknown_item: "unknown_item",
  expired: "expired",
  out_of_stock: "out_of_stock",
  disabled: "disabled",
  on_way: "on_way",
  at_vendor: "at_vendor",
  to_warehouse: "to_warehouse",
  waiting_sorting: "waiting_sorting",
  start_sorting: "start_sorting",
  end_sorting: "end_sorting",
  invoice: "invoice",
  price: "price",
};
