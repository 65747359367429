const IsEmpty = (field) => {
    if (
        field === null ||
        field === "null" ||
        field === undefined ||
        field === "undefined" ||
        field === ""
    ) {
        return true;
    } else {
        return false;
    }
};
export default IsEmpty;
