import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@emotion/react";
import {
  LinearProgress,
  PaginationItem,
  Pagination,
  Stack,
  Typography,
  Collapse,
  Tooltip,
} from "@mui/material";
import CustomTableCell from "./CustomTableCell";
import CreateRefundModal from "../modals/CreateRefundModal";
import EditNoteModal from "../modals/EditNoteModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getClientAutobiaWallet,
  getClientWallet,
  resendPaymentLink,
} from "../../../../app/features/wallet/wallet";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  notifyWithError,
  notifyWithSuccess,
} from "../../../../app/features/error/error";
import RefundsTable from "./RefundsTable";
import { FaCircleQuestion } from "react-icons/fa6";

const DataTable = ({
  items,
  columns,
  isTransactions,
  loading,
  fetchTransactions,
  fetchOrders,
  handleStateChange,
  tabStates,
  tabIndex,
  fetchSettlements,
  fetchDocuments,
}) => {
  //SECTION - general
  const theme = useTheme();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  //SECTION - useState
  const [openRefundModal, setOpenRefundModal] = useState({
    open: false,
    sessionId: null,
    transaction_id: null,
    client_name: null,
    invoice_total_amount: null,
    refunds: [],
    commission_rate: null,
    refund_rate: null,
  });
  const [openEditNote, setOpenEditNote] = useState({
    open: false,
    sessionId: null,
    transaction_id: null,
    client_name: null,
    invoice_total_amount: null,
    phone_number: null,
    note: null,
  });
  const [resendLinkLoading, setResendLinkLoading] = useState({});
  const [refundsOpen, setRefundsOpen] = useState(false);
  const [opennedTooltip, setOpennedTooltip] = useState(null);

  //SECTION - useSelector
  const pagination = useSelector((state) => state.walletReducer.pagination);

  //SECTION - useEffect
  useEffect(() => {
    if (Array.isArray(items) && tabIndex === 0) {
      const hasPendingPayment = items.some(
        (transaction) => transaction.status === "pending_payment"
      );
      if (hasPendingPayment) {
        const timer = setTimeout(() => {
          if (!openRefundModal.open) {
            fetchOrders();
            dispatch(getClientWallet());
            dispatch(getClientAutobiaWallet());
          }
        }, 20000);
        return () => clearTimeout(timer); // Clear the timeout if the component unmounts
      }
    }
  }, [items, dispatch, fetchOrders, openRefundModal.open, tabIndex]);

  //SECTION - functions
  const handleOpenRefundModal = (
    sessionId,
    transaction_id,
    clientName,
    invoiceTotalAmount,
    refunds,
    comissionRate,
    refundRate
  ) =>
    setOpenRefundModal({
      sessionId,
      open: true,
      transaction_id: transaction_id,
      client_name: clientName,
      invoice_total_amount: invoiceTotalAmount,
      refunds: refunds,
      commission_rate: comissionRate,
      refund_rate: refundRate,
    });
  const handleOpenEditNoteModal = (
    sessionId,
    transaction_id,
    client_name,
    invoice_total_amount,
    phone_number,
    note
  ) =>
    setOpenEditNote({
      open: true,
      sessionId,
      transaction_id,
      client_name,
      invoice_total_amount,
      phone_number,
      note,
    });

  const handleCloseRefundModal = () =>
    setOpenRefundModal({
      sessionId: null,
      transaction_id: null,
      open: false,
      client_name: null,
      invoice_total_amount: null,
      refunds: [],
      commission_rate: null,
      refund_rate: null,
    });
  const handleCloseEditNoteModal = () =>
    setOpenEditNote({
      open: false,
      sessionId: null,
      transaction_id: null,
      client_name: null,
      invoice_total_amount: null,
      note: null,
    });
  const handleSendPaymentLink = (id) => {
    // Set loading state for the specific transaction item to true for resendingPaymentLink
    setResendLinkLoading((prevState) => ({
      ...prevState,
      [id]: true,
    }));

    const data = {
      session_id: id,
    };

    dispatch(resendPaymentLink(data)).then((res) => {
      // Reset loading state for the specific transaction item to false after resending payment link
      if (res.type === "wallet/resendPaymentLink/fulfilled") {
        fetchOrders();
        setResendLinkLoading((prevState) => ({
          ...prevState,
          [id]: false,
        }));
        dispatch(
          notifyWithSuccess({
            message: t("wallet.messageSentSuccessfully"),
            type: "success",
          })
        );
      } else {
        dispatch(
          notifyWithError({
            message: t("wallet.errorSendingMessage"),
            type: "error",
          })
        );
      }
    });
  };
  return (
    <Stack sx={{ width: "100%" }}>
      <CreateRefundModal
        handleClose={handleCloseRefundModal}
        open={openRefundModal.open}
        sessionId={openRefundModal.sessionId}
        transaction_id={openRefundModal.transaction_id}
        client_name={openRefundModal.client_name}
        invoice_total_amount={openRefundModal.invoice_total_amount}
        refundsList={openRefundModal.refunds}
        commission_rate={openRefundModal.commission_rate}
        refund_rate={openRefundModal.refund_rate}
        fetchOrders={fetchOrders}
      />
      <EditNoteModal
        open={openEditNote.open}
        handleClose={handleCloseEditNoteModal}
        sessionId={openEditNote.sessionId}
        transaction_id={openEditNote.transaction_id}
        client_name={openEditNote.client_name}
        invoice_total_amount={openEditNote.invoice_total_amount}
        phone_number={openEditNote.phone_number}
        note={openEditNote.note}
        fetchOrders={fetchOrders}
      />
      {loading && (
        <Box width={"100%"}>
          <LinearProgress color="secondary" />
        </Box>
      )}
      <TableContainer
        sx={{
          maxHeight: 600,
          overflow: "auto",
          borderRadius: theme.borderRadius.r10,
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    color: theme.palette.gray.g600,
                  }}
                >
                  {[
                    "sales_amount",
                    "refunds_amount",
                    "sales_service_fees_amount",
                    "credit_note_service_fees_amount",
                    "vat_transaction_amount",
                    "purchases_amount",
                    "Net Amount",
                  ].includes(column.id) ? (
                    <>
                      <Stack
                        direction={
                          i18n.language === "ar" ? "row-reverse" : "row"
                        }
                        gap={"0.25rem"}
                        onClick={() => {
                          setOpennedTooltip(column.id);
                          setTimeout(() => setOpennedTooltip(null), 5000);
                        }}
                        onMouseEnter={() => {
                          setOpennedTooltip(column.id);
                        }}
                        onMouseLeave={() => {
                          setTimeout(() => setOpennedTooltip(null), 200);
                        }}
                      >
                        <Stack
                          direction={"row"}
                          gap={"0.5rem"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Typography
                            fontWeight={"bold"}
                            fontSize={"0.9rem"}
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {column.label}
                          </Typography>
                          <Tooltip
                            arrow
                            title={
                              <Typography>
                                {t(`wallet.settlementTooltips.${column.id}`)}
                              </Typography>
                            }
                            open={opennedTooltip === column.id}
                          >
                            <Stack
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <FaCircleQuestion />
                            </Stack>
                          </Tooltip>
                        </Stack>
                      </Stack>
                      {[
                        "sales_service_fees_amount",
                        "credit_note_service_fees_amount",
                      ].includes(column.id) && (
                        <Typography
                          variant="body2"
                          fontSize={"0.6rem"}
                          color={theme.palette.secondary.main}
                        >
                          {t("currency.VATInclusiveShort")}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography
                      fontWeight={"bold"}
                      fontSize={"0.9rem"}
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {column.label}
                    </Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((row, index) => (
              <>
                <TableRow
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? theme.palette.gray.g50 : "white",
                  }}
                  key={row.id}
                >
                  {columns.map((column) => (
                    <CustomTableCell
                      column={column}
                      row={row}
                      index={index}
                      isTransactions={isTransactions}
                      handleOpenEditNoteModal={handleOpenEditNoteModal}
                      handleOpenRefundModal={handleOpenRefundModal}
                      handleSendPaymentLink={handleSendPaymentLink}
                      resendLinkLoading={resendLinkLoading}
                      setRefundsOpen={setRefundsOpen}
                    />
                  ))}
                </TableRow>
                {Array.isArray(row.refunds) && row.refunds.length > 0 && (
                  <TableRow>
                    <TableCell
                      style={{
                        border: 0,
                        paddingBottom: 0,
                        paddingTop: 0,
                        backgroundColor: theme.palette.secondary.s400,
                      }}
                      colSpan={12}
                    >
                      <Collapse in={refundsOpen} timeout="auto" unmountOnExit>
                        <RefundsTable
                          refunds={row.refunds}
                          open={refundsOpen}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {items?.length === 0 && (
        <Stack justifyContent={"center"} alignItems={"center"} height={"200px"}>
          <Typography variant="h5">{t("wallet.noTransactions")}</Typography>
        </Stack>
      )}
      <Stack
        container
        justifyContent={"center"}
        alignItems={"center"}
        marginTop={"1rem"}
      >
        <Pagination
          count={pagination}
          variant="outlined"
          color="secondary"
          size="large"
          page={tabStates[tabIndex]?.pagination}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous:
                  i18n.language === "ar" ? ArrowForwardIcon : ArrowBackIcon,
                next: i18n.language === "ar" ? ArrowBackIcon : ArrowForwardIcon,
              }}
              {...item}
            />
          )}
          onChange={(e, page) => {
            handleStateChange(tabIndex, "pagination", page);
            if (tabIndex === 0) {
              fetchOrders(page);
            } else if (tabIndex === 1) {
              fetchTransactions(page);
            } else if (tabIndex === 2) {
              fetchSettlements(page);
            } else {
              fetchDocuments(page);
            }
          }}
        />
      </Stack>
    </Stack>
  );
};
export default DataTable;
