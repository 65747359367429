import { Tab, Tabs, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { TbTablePlus } from "react-icons/tb";
import { LuArrowRightLeft } from "react-icons/lu";
import { useSelector } from "react-redux";
import { CgSpaceBetween } from "react-icons/cg";
import { IoDocumentsOutline } from "react-icons/io5";

const CustomTabs = ({ tabIndex, setTabIndex }) => {
  // SECTION - general
  const theme = useTheme();
  const { t } = useTranslation();

  // SECTION - useSelector
  const betaUser = useSelector((state) => state.authReducer.betaUser);
  const canViewWallet = useSelector(
    (state) => state.walletReducer.userCanViewWallet
  );

  // SECTION - tabs
  const tabsData = [
    {
      tabTitle: t("wallet.bnplOrders"),
      tabIcon: <TbTablePlus fontSize={"1.2rem"} />,
    },
    {
      tabTitle: t("wallet.bnplTransactions"),
      tabIcon: <LuArrowRightLeft fontSize={"1.2rem"} />,
    },
    ...(betaUser && canViewWallet
      ? [
          {
            tabTitle: t("wallet.bnplSettlements"),
            tabIcon: <CgSpaceBetween fontSize={"1.2rem"} />,
          },
          {
            tabTitle: t("wallet.documents"),
            tabIcon: <IoDocumentsOutline fontSize={"1.2rem"} />,
          },
        ]
      : []), // Append the third tab if betaUser is true
  ];

  return (
    <Tabs
      value={tabIndex}
      onChange={(e, newTabIndex) => setTabIndex(newTabIndex)}
      variant="standard"
      sx={{ backgroundColor: theme.palette.white }}
      indicatorColor="secondary"
      textColor="secondary"
    >
      {tabsData.map((tab, index) => (
        <Tab
          label={tab.tabTitle}
          value={index}
          icon={tab.tabIcon}
          iconPosition="start"
          sx={{
            backgroundColor:
              tabIndex === index ? theme.palette.gray.g50 : "#fff",
            borderRadius:
              tabIndex === index
                ? `${theme.borderRadius.r15} ${theme.borderRadius.r15} 0 0`
                : "0",
            gap: "0.5rem",
            padding: "0px",
            fontWeight: theme.typography.bold,
            paddingX: "0.6rem",
            direction: "row",
          }}
        />
      ))}
    </Tabs>
  );
};

export default CustomTabs;
