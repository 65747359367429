import { useTheme, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { useEffect } from "react";
import { isAuthenticated } from "../../utils/api/Authentication";
import { Stack, useMediaQuery } from "@mui/system";
import { CloseOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { togglePromotionBanner } from "../../app/features/banner/banner";

const PromotionBanner = () => {
  // SECTION - general
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentLanguage = localStorage.getItem("lng");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  //SECTION - useSelector
  const showBanner = useSelector(
    (state) => state.bannerReducer.promotionBanner.open
  );
  const homeItemsTableScrollDirection = useSelector(
    (state) =>
      state.interactiveUserInterfaceReducer.homeItemsTableScrollDirection
  );

  // SECTION - useEffect
  useEffect(() => {}, [location, showBanner]);

  return (
    isAuthenticated() &&
    showBanner &&
    ((isMobile && homeItemsTableScrollDirection === "up") || !isMobile) &&
    (location.pathname === "/home" ||
      location.pathname.includes("wallet") ||
      location.pathname.includes("installment")) && (
      <Stack bgcolor={"secondary.main"} width={"100%"} padding={"0.5rem"}>
        <Stack
          direction={{ xs: "column", lg: "row" }}
          gap={isMobile ? "0.5rem" : "1rem"}
          marginX={isMobile ? "2rem" : "3rem"}
          color={"white"}
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
        >
          <Typography variant="body1">
            {t("wallet.promotion.banner")}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigate("/cart");
            }}
            endIcon={
              currentLanguage === "ar" ? (
                <IoArrowBack
                  style={{
                    marginRight: theme.spacing(1),
                  }}
                />
              ) : (
                <IoArrowForward />
              )
            }
            sx={{
              color: "white",
              bgcolor: "secondary.s500",
              borderRadius: "25px",
              maxHeight: "25px",
              textTransform: "none",
              padding: "0.5rem",
              direction: i18n.language === "ar" ? "rtl" : "ltr",
              width: "fit-content",
            }}
          >
            <Typography variant="body1">
              {t("wallet.promotion.goToCart")}
            </Typography>
          </Button>
        </Stack>
        <CloseOutlined
          size={24}
          onClick={() => {
            dispatch(togglePromotionBanner());
          }}
          sx={{
            position: "absolute",
            [i18n.language !== "ar" ? "right" : "left"]: "0.5rem",
            cursor: "pointer",
            color: "white",
          }}
        />
      </Stack>
    )
  );
};

export default PromotionBanner;
