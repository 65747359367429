import { Button } from "@mui/material";
import React from "react";

const ButtonComp = (props) => {
  return (
    <Button
      color={props.color ? props.color : "primary"}
      variant={props.variant ? props.variant : "contained"}
      onClick={(e) => {
        props.onClick !== undefined && props.onClick(e);
      }}
      disabled={props.disabled ? props.disabled : false}
      fullWidth={props.fullWidth ? true : false}
      size={props.size ? props.size : "medium"}
      type={props.type ? props.type : ""}
      sx={
        props.variant === "contained" || props.variant === undefined
          ? {
              color: "white",
              textTransform: "none",
              ...props.sx,
              gap: "0.5rem",
              backgroundColor: props.backgroundColor
                ? props.backgroundColor
                : "auto",
              height: props.height ? props.height : "auto",
              borderRadius: props.borderRadius ? props.borderRadius : "auto",
              paddingX: props.paddingX ? props.paddingX : "auto",
            }
          : {
              textTransform: "none",
              ...props.sx,
              gap: "0.5rem",
              backgroundColor: props.backgroundColor
                ? props.backgroundColor
                : "auto",
              height: props.height ? props.height : "auto",
              borderRadius: props.borderRadius ? props.borderRadius : "auto",
              paddingX: props.paddingX ? props.paddingX : "auto",
              "&:disabled": {
                backgroundColor: props.disabledBackgroundColor
                  ? props.disabledBackgroundColor
                  : "grey",
                color: props.disabledColor ? props.disabledColor : "lightgrey",
              },
            }
      }
      component={props.component ? props.component : "button"}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      disableRipple={props.disableRipple ? true : false}
    >
      {props.content}
    </Button>
  );
};

export default ButtonComp;
