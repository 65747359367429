import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  LinearProgress,
  OutlinedInput,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoSearchOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  checkClientValidityForRewards,
  getClientsLoyaltyTiers,
  getClientsTransactions,
} from "../../app/features/rewards/rewards.js";
import { useNavigate } from "react-router-dom";
import ProgressBar from "./ProgressBar.js";
import { AiFillQuestionCircle } from "react-icons/ai";

const Rewards = () => {
  //SECTION - general
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //SECTION - useState
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  const fetchClientTransactionsStatus = useSelector(
    (state) => state.rewardsReducer.fetchClientTransactionsStatus
  );
  const clientID = useSelector(
    (state) => state.rewardsReducer.validRewardsClientID
  );
  const clientTransactions = useSelector(
    (state) => state.rewardsReducer.clientTransactions
  );
  const fetchClientLoyaltyTiersStatus = useSelector(
    (state) => state.rewardsReducer.fetchClientLoyaltyTiersStatus
  );
  const clientLoyaltyTiers = useSelector(
    (state) => state.rewardsReducer.clientLoyaltyTiers
  );
  const clientTransactionsPagination = useSelector(
    (state) => state.rewardsReducer.clientTransactionsPagination
  );
  const specificClientStatistics = useSelector(
    (state) => state.rewardsReducer.specificClientStatistics
  );
  const checkClientValidityForRewardsStatus = useSelector(
    (state) => state.rewardsReducer.checkClientValidityForRewardsStatus
  );
  const rewardsClientValidity = useSelector(
    (state) => state.rewardsReducer.clientValidForRewards
  );

  //if client is not in rewards. then navigate to home
  if (!rewardsClientValidity) {
    navigate("/");
  }

  //SECTION - useEffect
  useEffect(() => {
    if (clientID) {
      dispatch(getClientsLoyaltyTiers({ clientID: clientID }));
      dispatch(
        getClientsTransactions({
          clientID: clientID,
          page: 1,
          searchQuery: searchQuery,
        })
      );
    } else {
      dispatch(checkClientValidityForRewards());
    }
  }, [clientID, dispatch, searchQuery]);

  const handleSearch = () => {
    // Implement your search functionality here
    dispatch(
      getClientsTransactions({
        clientID: clientID,
        page: 1,
        searchQuery: searchQuery,
      })
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  //SECTION - table related
  const columns = [
    {
      id: "id",
      label: t("rewards.id"),
      align: "center",
    },
    {
      id: "operation",
      label: t("rewards.operation"),
      align: "center",
    },
    {
      id: "points",
      label: t("rewards.points"),
      align: "center",
    },

    {
      id: "creator",
      label: t("rewards.createdBy"),
      align: "center",
    },
    // {
    //     id: "sourceAndNumber",
    //     label: t("rewards.source/number"),
    //     align: "center",
    // },
    {
      id: "date",
      label: t("rewards.date"),
      align: "center",
    },
  ];

  return (
    <Stack>
      <Box
        padding={theme.spacing(2)}
        backgroundColor={theme.palette.gray.g100}
        borderRadius={`0px 0px 50px 50px`}
        sx={{
          boxShadow: "0px 4px 0px 2px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Stack gap={theme.spacing(2)}>
          <Typography fontSize={"2rem"} fontWeight={"bold"}>
            {t("rewards.rewardSystem")}
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent={"space-between"}
            gap={{ xs: theme.spacing(2) }}
            alignItems={{ xs: "flex-start", sm: "center" }}
          >
            <Stack
              direction={"row"}
              gap={theme.spacing(2)}
              alignItems={"center"}
            >
              <Typography fontSize={"1.5rem"} fontWeight={"bold"}>
                {t("rewards.nameHoldings", {
                  name:
                    i18n.language === "ar"
                      ? clientLoyaltyTiers?.client.branch_company.name_ar
                      : clientLoyaltyTiers?.client.branch_company.name_en,
                })}
              </Typography>
              <Stack
                sx={{
                  paddingX: theme.spacing(1),
                  paddingY: theme.spacing(0.5),
                  backgroundColor: theme.palette.gray.g200,
                  borderRadius: theme.borderRadius.r15,
                }}
                textAlign={"center"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {fetchClientLoyaltyTiersStatus === "loading" ||
                checkClientValidityForRewardsStatus === "loading" ? (
                  <CircularProgress size={25} color="secondary" />
                ) : i18n.language === "ar" ? (
                  clientLoyaltyTiers?.active_tier.name_ar
                ) : (
                  clientLoyaltyTiers?.active_tier.name_en
                )}
              </Stack>
            </Stack>
          </Stack>
          <Grid container direction="row" alignItems="center">
            <Grid
              container
              xs={6}
              md={2.4}
              justifyContent={{ md: "center" }}
              padding={theme.spacing(0.5)}
            >
              <Stack textAlign={"flex-start"}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={theme.spacing(1)}
                >
                  <Typography
                    fontWeight={"bold"}
                    fontSize={{ xs: "1rem", sm: "1.3rem" }}
                    color={theme.palette.secondary.dark}
                  >
                    {t("rewards.currentPoint")}
                  </Typography>
                  <Tooltip
                    title={
                      <Typography>
                        {t("rewards.currentPointsExplanation")}
                      </Typography>
                    }
                  >
                    <Stack>
                      <AiFillQuestionCircle fontSize={"1.3rem"} />
                    </Stack>
                  </Tooltip>
                </Stack>
                {fetchClientLoyaltyTiersStatus === "loading" ? (
                  <CircularProgress size={25} color="secondary" />
                ) : (
                  <Typography
                    color={theme.palette.success.main}
                    fontWeight={"bold"}
                    fontSize={{ xs: "1rem", sm: "1.2rem" }}
                  >
                    {t("rewards.numAndPoints", {
                      points: specificClientStatistics?.running_points,
                    })}
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid
              container
              xs={6}
              md={2.4}
              justifyContent={{ md: "center" }}
              padding={theme.spacing(0.5)}
            >
              <Stack textAlign={"flex-start"}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={theme.spacing(1)}
                >
                  <Typography
                    fontWeight={"bold"}
                    fontSize={{ xs: "1rem", sm: "1.3rem" }}
                    color={theme.palette.secondary.dark}
                  >
                    {t("rewards.activePoints")}
                  </Typography>
                  <Tooltip
                    title={
                      <Typography>
                        {t("rewards.activePointsExplanation")}
                      </Typography>
                    }
                  >
                    <Stack>
                      <AiFillQuestionCircle fontSize={"1.3rem"} />
                    </Stack>
                  </Tooltip>
                </Stack>
                {fetchClientLoyaltyTiersStatus === "loading" ? (
                  <CircularProgress size={25} color="secondary" />
                ) : (
                  <Typography
                    color={theme.palette.success.main}
                    fontWeight={"bold"}
                    fontSize={{ xs: "1rem", sm: "1.2rem" }}
                  >
                    {t("rewards.numAndPoints", {
                      points: specificClientStatistics?.unused_points,
                    })}
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid
              container
              xs={6}
              md={2.4}
              justifyContent={{ md: "center" }}
              padding={theme.spacing(0.5)}
            >
              <Stack textAlign={"flex-start"}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={theme.spacing(1)}
                >
                  <Typography
                    fontWeight={"bold"}
                    fontSize={{ xs: "1rem", sm: "1.3rem" }}
                    color={theme.palette.secondary.dark}
                  >
                    {t("rewards.totalPoints")}
                  </Typography>
                  <Tooltip
                    title={
                      <Typography>
                        {t("rewards.totalPointsExplaination")}
                      </Typography>
                    }
                  >
                    <Stack>
                      <AiFillQuestionCircle fontSize={"1.3rem"} />
                    </Stack>
                  </Tooltip>
                </Stack>

                {fetchClientTransactionsStatus === "loading" ? (
                  <CircularProgress size={25} color="secondary" />
                ) : (
                  <Typography
                    fontWeight={"bold"}
                    fontSize={{ xs: "1rem", sm: "1.2rem" }}
                  >
                    {t("rewards.numAndPoints", {
                      points: specificClientStatistics?.total_points,
                    })}
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid
              container
              xs={6}
              md={2.4}
              justifyContent={{ md: "center" }}
              padding={theme.spacing(0.5)}
            >
              <Stack textAlign={"flex-start"}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={theme.spacing(1)}
                >
                  <Typography
                    fontWeight={"bold"}
                    fontSize={{ xs: "1rem", sm: "1.3rem" }}
                    color={theme.palette.secondary.dark}
                  >
                    {t("rewards.usedPoints")}
                  </Typography>
                  <Tooltip
                    title={
                      <Typography>
                        {t("rewards.usedPointsExplaination")}
                      </Typography>
                    }
                  >
                    <Stack>
                      <AiFillQuestionCircle fontSize={"1.3rem"} />
                    </Stack>
                  </Tooltip>
                </Stack>

                {fetchClientTransactionsStatus === "loading" ? (
                  <CircularProgress size={25} color="secondary" />
                ) : (
                  <Typography
                    color={theme.palette.error.main}
                    fontWeight={"bold"}
                    fontSize={{ xs: "1rem", sm: "1.2rem" }}
                  >
                    {t("rewards.numAndPoints", {
                      points: -specificClientStatistics?.used_points,
                    })}
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid
              container
              xs={6}
              sm
              justifyContent={{ md: "center" }}
              padding={theme.spacing(0.5)}
            >
              <Stack textAlign={"flex-start"}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={theme.spacing(1)}
                >
                  <Typography
                    fontWeight={"bold"}
                    fontSize={{ xs: "1rem", sm: "1.3rem" }}
                    color={theme.palette.secondary.dark}
                  >
                    {t("rewards.expiredPoints")}
                  </Typography>
                  <Tooltip
                    title={
                      <Typography>
                        {t("rewards.expiredPointsExplaination")}
                      </Typography>
                    }
                  >
                    <Stack>
                      <AiFillQuestionCircle fontSize={"1.3rem"} />
                    </Stack>
                  </Tooltip>
                </Stack>

                {fetchClientTransactionsStatus === "loading" ? (
                  <CircularProgress size={25} color="secondary" />
                ) : (
                  <Typography
                    color={theme.palette.error.main}
                    fontWeight={"bold"}
                    fontSize={{ xs: "1rem", sm: "1.2rem" }}
                  >
                    {t("rewards.numAndPoints", {
                      points: -specificClientStatistics?.expired_points,
                    })}
                  </Typography>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Box>
            <ProgressBar
              currentTier={
                i18n.language === "ar"
                  ? clientLoyaltyTiers?.running_tier?.name_ar
                  : clientLoyaltyTiers?.running_tier?.name_en
              }
              nextTier={
                i18n.language === "ar"
                  ? clientLoyaltyTiers?.next_tier?.name_ar
                  : clientLoyaltyTiers?.next_tier?.name_en
              }
              currentTierPoints={specificClientStatistics?.running_points}
              nextTierPoints={clientLoyaltyTiers?.next_tier?.points_start_limit}
              runningTierPoints={
                clientLoyaltyTiers?.running_tier.points_start_limit
              }
            />
          </Box>
        </Stack>
      </Box>
      <Box padding={theme.spacing(2)}>
        <Grid
          direction={"column"}
          container
          xs={12}
          sx={{ padding: "1rem" }}
          gap={theme.spacing(2)}
        >
          <Grid
            container
            xs={12}
            gap={theme.spacing(1)}
            justifyContent={"space-between"}
          >
            <Grid
              container
              xs={12}
              // semiSm={8}
              // sm={8}
              // md={10}
              justifyContent={"flex-start"}
            >
              <OutlinedInput
                color="primary"
                sx={{
                  borderRadius: "10px",
                  height: "50px",
                  backgroundColor: theme.palette.white,
                  width: "100%",
                  px: "1rem",
                }}
                placeholder={t("rewards.search")}
                id="search-by-part"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={handleKeyPress}
                endAdornment={
                  <InputAdornment position="end" sx={{ paddingX: "0.5rem" }}>
                    <IoSearchOutline />
                  </InputAdornment>
                }
              />
            </Grid>
            {/* <Grid container xs justifyContent={"center"}>
                            <Button
                                sx={{
                                    borderRadius: theme.borderRadius.r10,
                                    borderWidth: "2px",
                                    backgroundColor: "white",
                                    fontWeight: "bold",
                                    "& .MuiButton-endIcon": {
                                        margin: 0,
                                    },
                                    "&:hover": {
                                        borderWidth: "2px",
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                startIcon={
                                    i18n.dir() === "ltr" && <BsDownload />
                                }
                                endIcon={i18n.dir() === "rtl" && <BsDownload />}
                            >
                                CSV
                            </Button>
                        </Grid> */}
          </Grid>
          <Grid xs={12} container>
            {(fetchClientTransactionsStatus === "loading" ||
              checkClientValidityForRewardsStatus === "loading") && (
              <Box width={"100%"}>
                <LinearProgress color="secondary" />
              </Box>
            )}
            <TableContainer
              sx={{ borderRadius: theme.borderRadius.r10 }}
              style={{ maxHeight: 600, overflow: "auto" }}
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  backgroundColor: theme.palette.gray.g50,
                }}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{
                          backgroundColor: theme.palette.gray.g50,
                          borderBottom: `1px solid ${theme.palette.gray.g400}`,
                        }}
                      >
                        <Typography
                          fontWeight={theme.typography.bold}
                          color={theme.palette.secondary.main}
                        >
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientTransactions?.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.number}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];

                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              sx={{
                                borderBottom:
                                  index !== clientTransactions.length - 1
                                    ? `1px solid ${theme.palette.gray.g400}`
                                    : "0px",
                              }}
                            >
                              {column.id === "points" ? (
                                <Typography
                                  color={
                                    parseFloat(value) < 0
                                      ? theme.palette.error.main
                                      : theme.palette.success.main
                                  }
                                >
                                  {value}
                                </Typography>
                              ) : column.id === "operation" ? (
                                <Typography>{t(`rewards.${value}`)}</Typography>
                              ) : (
                                <Typography>{value}</Typography>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Pagination
              count={clientTransactionsPagination}
              variant="outlined"
              size="large"
              value={pageNumber}
              onChange={(e, page) => {
                setPageNumber(page);
                dispatch(
                  getClientsTransactions({
                    clientID: clientID,
                    page: page,
                    searchQuery: searchQuery,
                  })
                );
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default Rewards;
