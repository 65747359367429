import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { watchTowerInstance } from "../../../utils/api/axiosConfig";

const initialState = {
  brands: [],
  status: "idle",
};

export const fetchBrands = createAsyncThunk(
  "brand/fetchBrands",
  async (thunkAPI) => {
    const response = await watchTowerInstance.get(
      `inventory/specified-manufacturers/`
    );
    if (response.status < 200 || response.status >= 300) {
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBrands.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.brands = action.payload;
    });
    builder.addCase(fetchBrands.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchBrands.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  },
});

export default brandSlice.reducer;

export const getBrandStatus = (state) => state.brandReducer.status;
