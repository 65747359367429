import { Typography } from "@mui/material";
import { completedStatuses, processingStatuses } from "./constants";

export const getStatusStyle = (value, theme) => {
  if (completedStatuses.includes(value)) {
    return {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.main,
    };
  } else if (processingStatuses.includes(value)) {
    return {
      backgroundColor: theme.palette.yellow.light,
      color: theme.palette.yellow.main,
    };
  } else {
    return {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
    };
  }
};
export const getTypeStyle = (value, theme) => {
  const isReverse = value.toLowerCase().includes("reverse");

  switch (true) {
    case value === "Payment":
      return {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.main,
      };
    case value === "Credit Note":
      return {
        backgroundColor: theme.palette.yellow.light,
        color: theme.palette.yellow.main,
      };
    case value === "Service Fee":
      return {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
      };
    case value === "Withdraw":
      return {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      };
    case isReverse:
      return {
        backgroundColor: theme.palette.teal.veryLight,
        color: theme.palette.teal.main,
      };
    default:
      return {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.main,
      };
  }
};
export const getDocumentTypeStyle = (value, theme) => {
  if (value === "tax_invoice") {
    return {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    };
  } else if (value === "credit_note") {
    return {
      backgroundColor: theme.palette.yellow.light,
      color: theme.palette.yellow.main,
    };
  } else if (value === "purchase_approval") {
    return {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.main,
    };
  } else {
    return {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
    };
  }
};

export const formatNumericColumns = (columnId, columnValue, theme) => {
  const columnStyles = {
    sales_amount: theme.palette.secondary.main,
    withdraw_amount: theme.palette.success.main,
    refunds_amount: theme.palette.error.dark,
    sales_service_fees_amount: theme.palette.error.dark,
    credit_note_service_fees_amount: theme.palette.error.dark,
    purchases_amount: theme.palette.error.dark,
  };

  const color = columnStyles[columnId] || theme.palette.gray.g600;
  const prefix = [
    "refunds_amount",
    "sales_service_fees_amount",
    "credit_note_service_fees_amount",
    "purchases_amount",
  ].includes(columnId)
    ? "- "
    : "";

  return (
    <Typography style={{ whiteSpace: "nowrap", color }}>
      {prefix + columnValue}
    </Typography>
  );
};
