import React from "react";
import {
    List,
    ListItemButton,
    ListItemIcon,
    useTheme,
    Typography,
    Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ukFlag from "../../../assets/images/flags/ukFlag.jpg";
import saudiFlag from "../../../assets/images/flags/saudiFlag.png";
import indiaFlag from "../../../assets/images/flags/indiaFlag.png";

const LangForm = ({
    isInDrawer,
    isInProfile,
    handleCloseDrawer,
    handleCloseProfile,
}) => {
    //SECTION - GENERAL
    const theme = useTheme();
    const { i18n } = useTranslation();

    //SECTION - CUSTOM STYLES
    const menuItemStyle = {
        borderRadius: "5px",
        height: "50px",
        backgroundColor: theme.palette.gray.g50,
        border: `1px solid ${theme.palette.gray.g100} `,
        "&:hover": {
            border: `1px solid ${theme.palette.secondary.main} `,
        },
    };
    const imgStyles = {
        maxWidth: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius: theme.borderRadius.r5,
    };

    //SECTION - FUNCTIONS
    const changeLanguage = (e, language) => {
        localStorage.setItem("lng", language);
        if (isInDrawer === true) {
            handleCloseDrawer();
        } else if (isInProfile === true) {
            handleCloseProfile();
        }
        i18n.changeLanguage(language);
    };

    return (
        <>
            <List
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: theme.spacing(1),
                }}
            >
                <ListItemButton
                    sx={{ ...menuItemStyle }}
                    onClick={(e) => changeLanguage(e, "ar")}
                >
                    <ListItemIcon>
                        <Box height={"20px"}>
                            <img
                                style={imgStyles}
                                src={saudiFlag}
                                alt="saudiFlag"
                            />
                        </Box>
                    </ListItemIcon>
                    <Typography> العربية</Typography>
                </ListItemButton>
                <ListItemButton
                    sx={{ ...menuItemStyle }}
                    defaultValue={"en"}
                    onClick={(e) => changeLanguage(e, "en")}
                >
                    <ListItemIcon>
                        <Box height={"20px"}>
                            <img style={imgStyles} src={ukFlag} alt="ukFlag" />
                        </Box>
                    </ListItemIcon>
                    <Typography>English</Typography>
                </ListItemButton>
                <ListItemButton
                    sx={{ ...menuItemStyle }}
                    defaultValue={"hi"}
                    onClick={(e) => changeLanguage(e, "hi")}
                >
                    <ListItemIcon>
                        <Box height={"20px"}>
                            <img
                                style={imgStyles}
                                src={indiaFlag}
                                alt="indiaFlag"
                            />
                        </Box>
                    </ListItemIcon>
                    <Typography>Hindi</Typography>
                </ListItemButton>
            </List>
        </>
    );
};

export default LangForm;
