// RefundsTable.js
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Stack,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { formatDateTime } from "../../../../utils/Helpers/general";
import { useTranslation } from "react-i18next";
import { getStatusStyle } from "../../utils/styles";
import { NumericFormat } from "react-number-format";

const RefundsTable = ({ refunds }) => {
  //SECTION - general
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack width={"100%"}>
      <Typography variant="h6" textAlign="center" gutterBottom component="div">
        {t("wallet.refunds")}
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">{t("wallet.refundID")}</TableCell>
            <TableCell align="center">{t("wallet.price")}</TableCell>
            <TableCell align="center">{t("wallet.transactionDate")}</TableCell>
            <TableCell align="center">{t("wallet.refundStatus")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(refunds) && refunds.length > 0 ? (
            refunds.map((refund) => (
              <TableRow key={refund.date}>
                <TableCell component="th" scope="row" align="center">
                  {refund.refund_reference}
                </TableCell>
                <TableCell align="center">
                  {
                    <NumericFormat
                      value={refund.amount}
                      suffix={` ${t("currency.SAR")} `}
                      thousandSeparator=","
                      displayType="text"
                      type="text"
                      renderText={(value) => <Typography>{value}</Typography>}
                    />
                  }
                </TableCell>

                <TableCell align="center">
                  {formatDateTime(refund.created_at)}
                </TableCell>
                <TableCell align="center">
                  <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    direction={"row"}
                  >
                    <Stack
                      width={"fit-content"}
                      sx={getStatusStyle(refund.status, theme)}
                      px={"0.5rem"}
                      borderRadius={theme.borderRadius.r10}
                    >
                      <Typography>{t(`wallet.${refund.status}`)}</Typography>
                    </Stack>
                  </Stack>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                {t("wallet.noRefunds")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Stack>
  );
};

export default RefundsTable;
