import { Grid2, useMediaQuery, useTheme } from "@mui/material";
import CustomHits from "./CustomHits";
import CustomHierarchicalMenu from "./CustomHierarchicalMenu";

const HomeTable = () => {
  //SECTION - general
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("semiLg"));

  return (
    <Grid2 container my={"1rem"}>
      <Grid2
        container
        size={3.4}
        sx={{
          display: isSmallScreen && "none",
          paddingX: "1rem",
          height: "80vh",
          overflowY: !isSmallScreen && "scroll",
          overflowX: !isSmallScreen && "hidden",
        }}
      >
        <CustomHierarchicalMenu
          attributes={["categories.lvl0", "categories.lvl1"]}
          sortBy={["count:desc"]}
          limit={50}
        />
      </Grid2>
      <Grid2 size={isSmallScreen ? 12 : 8.6}>
        <CustomHits />
      </Grid2>
    </Grid2>
  );
};

export default HomeTable;
