import CustomRefinement from "./CustomRefinement";
import {
  Box,
  InputAdornment,
  Grid,
  OutlinedInput,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { IoSearchOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useSearchBox } from "react-instantsearch";

const CustomSearchBox = (props) => {
  //SECTION - algolia related
  const { refine } = useSearchBox(props);

  //SECTION - general
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("semiLg"));

  return (
    <Box
      backgroundColor={theme.palette.gray.g100}
      px={{ xs: "0.75rem", sm: "1rem" }}
      py={"0.75rem"}
      my={{ xs: "0.5rem", sm: "2rem" }}
      width={"100%"}
      position={"sticky"}
      top={"60px"}
      zIndex={999}
    >
      <Grid
        container
        alignItems={"center"}
        textAlign={"center"}
        overflow={"hidden"}
      >
        <Grid xs={12}>
          <OutlinedInput
            dir="ltr"
            color="primary"
            inputProps={{
              maxLength: 50,
            }}
            sx={{
              boxShadow: theme.shadows[3],
              borderRadius: "10px",
              overflow: "hidden",
              height: "40px",
              backgroundColor: theme.palette.white,
              width: "98%",
            }}
            onChange={(event) => refine(event.currentTarget.value)}
            placeholder={t("home.search")}
            id="search-by-part"
            endAdornment={
              <InputAdornment position="end">
                <IoSearchOutline />
              </InputAdornment>
            }
          />
        </Grid>
        {isSmallScreen && (
          <Grid item xs={12} mt={theme.spacing(2)}>
            <CustomRefinement limit={100} attribute="manufacturer.name_en" />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export default CustomSearchBox;
