import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import IsEmpty from "../../../utils/validation/IsEmpty";
import {
  watchTowerInstance,
  towerInstance,
} from "../../../utils/api/axiosConfig";
import { jwtDecode } from "jwt-decode";
import { formulateTowerError } from "../../../utils/Helpers/formulateTowerError";
import { formulateWatchTowerError } from "../../../utils/Helpers/formulateWatchTowerError";
import { notifyWithError } from "../error/error";

const initialState = {
  cartId: null,
  items: [],
  createdAt: null,
  shippingPrice: "FREE",
  constantDiscount: null,
  coupon: null,
  isCouponApplied: false,
  saleOrderId: null,
  totalOrders: null,
  pricingConfig: null,
  error: null,
  fetchCartStatus: "idle",
  updateCartStatus: "idle",
  couponCartStatus: "idle",
  fetchPricingConfigStatus: "idle",
  confirmOrderCartStatus: "idle",
};

// export const fetchCartItems = createAsyncThunk(
//   "cart/fetchCartItems",
//   async () => {
//     try {
//       const response = await SingletonClient.getCartItems();
//       console.warn("the rertuned response is", response);

//       return response;
//     } catch (error) {
//       throw Error("Failed to fetch items from the API");
//     }
//   }
// );

export const fetchCartItems = createAsyncThunk(
  "cart/fetchCartItems",
  async (thunkAPI) => {
    const response = await towerInstance.post(`sale-order`, {
      app_id: 4,
    });
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

// export const fetchPricingConfig = createAsyncThunk(
//   "cart/fetchPricingConfig",
//   async () => {
//     try {
//       const response = await SingletonClient.getPricingConfig();
//       return response;
//     } catch (error) {
//       throw Error("Failed to fetch shipping price from the API");
//     }
//   }
// );

export const fetchPricingConfig = createAsyncThunk(
  "cart/fetchPricingConfig",
  async (thunkAPI) => {
    const response = await towerInstance.get(`configuration/shipping-price`);
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

// export const putCartItems = createAsyncThunk(
//   "cart/putCartItems",
//   async (data) => {
//     try {
//       const { items, cartId } = data;
//       const response = await SingletonClient.updateCartItems({ items }, cartId);
//       return response;
//     } catch (error) {
//       throw Error("Failed to adding/deleting/updating items from the API");
//     }
//   }
// );

export const putCartItems = createAsyncThunk(
  "cart/putCartItems",
  async (data, thunkAPI) => {
    const { items, cartId } = data;
    const response = await towerInstance.put(
      `sale-order/${cartId}/customer/edit-bulk`,
      { items: items }
    );
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(formulateTowerError(response.data));
    } else {
      return response.data;
    }
  }
);

// export const submitCartOrder = createAsyncThunk(
//   "cart/submitCartOrder",
//   async (data) => {
//     try {
//       const { address_id, app_id, cartId } = data;
//       const obj = { address_id, app_id };
//       const response = await SingletonClient.submitOrder(obj, cartId);
//       console.warn("we submit and get", response.data);

//       return response;
//     } catch (error) {
//       throw Error("Failed to submitting items from the API");
//     }
//   }
// );

export const submitCartOrder = createAsyncThunk(
  "cart/submitCartOrder",
  async (data, thunkAPI) => {
    const { address_id, app_id, cartId } = data;
    const obj = { address_id, app_id };
    const response = await towerInstance.put(
      `sale-order/${cartId}/customer-approve`,
      obj
    );
    console.warn("we submit and get", response.data, { a1: "hi" });
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

// export const applyCoupon = createAsyncThunk(
//   "cart/applyCoupon",
//   async (data) => {
//     try {
//       const { couponCode, cartId } = data;
//       const response = await SingletonClient.applyCoupon(couponCode, cartId);
//       return response;
//     } catch (error) {
//       throw Error("Failed to apply coupon");
//     }
//   }
// );

export const applyCoupon = createAsyncThunk(
  "cart/applyCoupon",
  async (data, thunkAPI) => {
    const { couponCode, cartId } = data;
    const response = await watchTowerInstance.post(`coupon/verify/${cartId}/`, {
      user_id: jwtDecode(localStorage.getItem("accessToken")).sub,
      code: couponCode,
    });
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(formulateWatchTowerError(response.data));
    } else {
      return response.data;
    }
  }
);

// export const removeCoupon = createAsyncThunk(
//   "cart/removeCoupon",
//   async (data) => {
//     const { couponId, cartId } = data;
//     try {
//       const response = await SingletonClient.removeCoupon(couponId, cartId);
//       return response;
//     } catch (error) {
//       throw Error("Failed to remove coupon");
//     }
//   }
// );

export const removeCoupon = createAsyncThunk(
  "cart/removeCoupon",
  async (data, thunkAPI) => {
    const { couponId, cartId } = data;
    const response = await watchTowerInstance.patch(
      `coupon/${couponId}/unlink/${cartId}/`
    );
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(formulateWatchTowerError(response.data));
    } else {
      return response.data;
    }
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    flushItems: (state, action) => {
      state.items = [];
    },
    resetUpdateCartStatus: (state, action) => {
      state.updateCartStatus = "idle";
    },
    resetCart: (state, action) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCartItems.fulfilled, (state, action) => {
      if (!IsEmpty(action.payload)) {
        state.cartId = action.payload.data.id;
        state.items = action.payload.data.saleOrderItems;
        state.createdAt = action.payload.data.created_at;
        // state.shippingPrice = action.payload.data.shipping_price;
        state.coupon = action.payload.data.coupon_details;
        state.constantDiscount = action.payload.data.constant_discount;
        state.totalOrders = action.payload.data.total_orders;
        state.isCouponApplied =
          Object.keys(action.payload.data.coupon_details).length === 0
            ? false
            : true;
        state.fetchCartStatus = "succeeded";
      }
    });
    builder.addCase(fetchCartItems.pending, (state, action) => {
      state.fetchCartStatus = "loading";
    });
    builder.addCase(fetchCartItems.rejected, (state, action) => {
      state.fetchCartStatus = "failed";
      state.error = action.error.message;
    });

    builder.addCase(putCartItems.fulfilled, (state, action) => {
      if (!IsEmpty(action.payload)) {
        state.items = action.payload.data.saleOrderItems;
        state.updateCartStatus = "succeeded";
      }
    });
    builder.addCase(putCartItems.pending, (state, action) => {
      state.updateCartStatus = "loading";
    });
    builder.addCase(putCartItems.rejected, (state, action) => {
      state.updateCartStatus = "failed";
      state.error = action.error.message;
    });

    builder.addCase(submitCartOrder.fulfilled, (state, action) => {
      if (action.payload.errorCode === "resourceNotFoundError") {
        state.confirmOrderCartStatus = "failed";
      } else {
        if (!IsEmpty(action.payload)) {
          state.saleOrderId = action.payload.data.sale_order_id;
          state.isCouponApplied = false;
          state.confirmOrderCartStatus = "succeeded";
        }
      }
    });
    builder.addCase(submitCartOrder.pending, (state, action) => {
      state.confirmOrderCartStatus = "loading";
    });
    builder.addCase(submitCartOrder.rejected, (state, action) => {
      state.confirmOrderCartStatus = "failed";
      state.error = action.error.message;
    });

    builder.addCase(applyCoupon.fulfilled, (state, action) => {
      if (!IsEmpty(action?.payload)) {
        state.coupon = action.payload;
        state.isCouponApplied = true;
        state.couponCartStatus = "succeeded";
      }
    });
    builder.addCase(applyCoupon.pending, (state, action) => {
      state.couponCartStatus = "loading";
    });
    builder.addCase(applyCoupon.rejected, (state, action) => {
      state.couponCartStatus = "failed";
      state.error = action.error.message;
    });

    builder.addCase(removeCoupon.fulfilled, (state, action) => {
      state.coupon = null;
      state.isCouponApplied = false;
      state.couponCartStatus = "succeeded";
    });
    builder.addCase(removeCoupon.pending, (state, action) => {
      state.couponCartStatus = "loading";
    });
    builder.addCase(removeCoupon.rejected, (state, action) => {
      state.couponCartStatus = "failed";
      state.error = action.error.message;
    });

    builder.addCase(fetchPricingConfig.fulfilled, (state, action) => {
      if (!IsEmpty(action.payload)) {
        state.pricingConfig = action.payload.data[0];
        state.fetchPricingConfigStatus = "succeeded";
      }
    });
    builder.addCase(fetchPricingConfig.pending, (state, action) => {
      state.fetchPricingConfigStatus = "loading";
    });
    builder.addCase(fetchPricingConfig.rejected, (state, action) => {
      state.fetchPricingConfigStatus = "failed";
      state.error = action.error.message;
    });
  },
});

export const { flushItems, resetUpdateCartStatus, resetCart } =
  cartSlice.actions;
export default cartSlice.reducer;

export const getCartId = (state) => state.cartReducer.cartId;
export const getCartItems = (state) => state.cartReducer.items;
export const getSaleOrderId = (state) => state.cartReducer.saleOrderId;
export const getCoupon = (state) => state.cartReducer.coupon;
export const getFetchCartStatus = (state) => state.cartReducer.fetchCartStatus;
export const getCouponCartStatus = (state) =>
  state.cartReducer.couponCartStatus;
export const getConfirmOrderCartStatus = (state) =>
  state.cartReducer.confirmOrderCartStatus;
export const isCouponApplied = (state) => state.cartReducer.isCouponApplied;
export const getConstantDiscount = (state) =>
  state.cartReducer.constantDiscount;
export const getPutCartStatus = (state) => state.cartReducer.updateCartStatus;
export const getPricingConfig = (state) => state.cartReducer.pricingConfig;
export const getTotalOrders = (state) => state.cartReducer.totalOrders;
