import { Typography, useTheme, Stack, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import shippingImage from "../../assets/images/orderTracking/shippingImage.svg";
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection";
import { useDispatch, useSelector } from "react-redux";
import { getOrderTrackingInformation } from "../../app/features/orderTracking/orderTracking";
import { useParams } from "react-router-dom";
import PaymentSection from "./PaymentSection";
import NeedAssistanceSection from "./NeedAssistanceSection";
import validation from "../../utils/Helpers/validation";

const OrderTracking = () => {
  //SECTION - general
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isAboveMedium = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();

  //SECTION -  Extracting ID from URL
  const { id } = useParams();

  //SECTION - useSelector
  const orderTrackingInformation = useSelector(
    (state) => state.orderTrackingReducer.orderTrackingInformation
  );

  //SECTION - useState
  const [currentStep, setCurrentStep] = useState(null);
  const [checked, setChecked] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  //SECTION - stepper

  const [steps, setSteps] = useState([
    {
      status: "orderTracking.statuses.orderApproved",
      description: "orderTracking.descriptions.orderApprovedDescription",
      mapping: [
        "draft",
        "approved",
        "price",
        "pricing",
        "unknown_price",
        "waiting_approvement",
      ],
    },
    {
      status: "orderTracking.statuses.preparing",
      description: "orderTracking.descriptions.preparingDescription",
      mapping: ["prepare"],
    },
    {
      status: "orderTracking.statuses.packaging",
      description: "orderTracking.descriptions.packagingDescription",
      mapping: [""],
    },
    {
      status: "orderTracking.statuses.withShippingCompany",
      description: "orderTracking.descriptions.withShippingCompanyDescription",
      mapping: [""],
    },
    {
      status: "orderTracking.statuses.completed",
      description: "orderTracking.descriptions.completedDescription",
      mapping: ["completed", "invoice"],
    },
  ]);

  //SECTION - useEffect
  useEffect(() => {
    dispatch(getOrderTrackingInformation(id));
  }, [dispatch, id]);

  useEffect(() => {
    const waitingPaymentStep = {
      status: "orderTracking.statuses.waitingPayment",
      description: t("orderTracking.descriptions.waitingPaymentDescription"),
      mapping: [""],
    };

    if (orderTrackingInformation && !checked) {
      let currentStep = steps.find((step) =>
        step.mapping.includes(orderTrackingInformation.status)
      );
      const initialStepIndex = steps.findIndex(
        (step) => step.status === currentStep?.status
      );
      setCurrentStepIndex(initialStepIndex);

      const updates = [];

      // NOTE - prepare state
      if (orderTrackingInformation.allow_prepare) {
        currentStep = steps[1];
        updates.push({ index: 1, step: currentStep });
      }

      // NOTE - packaging state
      if (
        orderTrackingInformation.ready_to_package_at ||
        orderTrackingInformation.ready_to_delivery_at
      ) {
        currentStep = steps[2];
        updates.push({ index: 2, step: currentStep });
      }

      // NOTE - shipping state
      if (orderTrackingInformation.shipping_file_url) {
        let updatedSteps = steps.map((step, index) =>
          index === 3
            ? {
                ...step,
                status: !validation.isEmpty(
                  orderTrackingInformation?.send_to_saleperson_id
                )
                  ? "orderTracking.statuses.withSalePerson"
                  : "orderTracking.statuses.withShippingCompany",
                description: !validation.isEmpty(
                  orderTrackingInformation?.send_to_saleperson_id
                )
                  ? "orderTracking.descriptions.withSalePersonDescription"
                  : "orderTracking.descriptions.withShippingCompanyDescription",
                mapping: [""],
              }
            : step
        );
        setSteps(updatedSteps);
        currentStep = updatedSteps[4];
        updates.push({ index: 4, step: currentStep });
      }

      // NOTE - apply the updates
      if (updates.length > 0) {
        const { index, step } = updates[updates.length - 1];
        setCurrentStepIndex(index);
        setCurrentStep(step);
      } else {
        setCurrentStep(currentStep);
      }

      // NOTE - add a step for payment if needed
      if (
        orderTrackingInformation?.is_need_payment &&
        !["completed", "invoice"].includes(orderTrackingInformation.status)
      ) {
        let updatedSteps = [...steps];
        const lastUpdateIndex =
          updates.length > 0
            ? updates[updates.length - 1].index
            : initialStepIndex;
        updatedSteps.splice(lastUpdateIndex + 1, 0, waitingPaymentStep);
        setSteps(updatedSteps);
        setCurrentStepIndex(lastUpdateIndex + 1);
        setCurrentStep(waitingPaymentStep);
      }

      setChecked(true);
    }
  }, [orderTrackingInformation, i18n.language, checked, steps, t]);

  return (
    <Stack
      direction={"column"}
      gap={{ xs: "16px", md: "32px" }}
      paddingX={{ xs: "14px", md: "32px" }}
      paddingTop={{ xs: "16px", md: "32px" }}
      justifyContent={"center"}
      alignItems={"center"}
      position={"relative"}
    >
      <img
        src={shippingImage}
        alt="shippingImage"
        style={{
          position: "absolute",
          top: "0px",
          zIndex: -1,
          height: isAboveMedium ? "300px" : "200px",
          width: "100%",
          objectFit: "cover",
        }}
      />
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"4px"}
        paddingY={{ xs: "16px", md: "24px" }}
        height={"140px"}
        textAlign={"center"}
      >
        <Typography
          fontWeight={theme.typography.bold}
          // fontSize={{ xs: "24px", md: "32px", lg: "48px" }}
          variant="h2"
          color={"white"}
          style={{
            textShadow: "0 0 4px black",
          }}
        >
          {t("orderTracking.trackYourOrder")}
        </Typography>

        <Typography color={theme.palette.gray.g200} variant="h6">
          {t("orderTracking.trackYourOrderDetails")}
        </Typography>
      </Stack>
      <FirstSection
        steps={steps}
        currentStep={currentStep}
        currentStepIndex={currentStepIndex}
      />
      <SecondSection
        steps={steps}
        currentStep={currentStep}
        currentStepIndex={currentStepIndex}
      />
      <PaymentSection />
      <NeedAssistanceSection />
    </Stack>
  );
};

export default OrderTracking;
