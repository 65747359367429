import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { watchTowerInstance } from "../../../utils/api/axiosConfig";
import { notifyWithError } from "../error/error";
import { formulateWatchTowerError } from "../../../utils/Helpers/formulateWatchTowerError";

const initialState = {
    brands: [],
    fetchClientTransactionsStatus: "idle",
    clientTransactionsPagination: null,
    clientTransactions: null,
    fetchClientLoyaltyTiersStatus: "idle",
    clientLoyaltyTiers: null,
    checkClientValidityForRewardsStatus: "idle",
    clientValidForRewards: false,
    validRewardsClientID: null,
};
// export const checkClientValidityForRewards = createAsyncThunk(
//     "rewards/checkClientValidityForRewards",
//     async (thunkAPI) => {
//         const response = await SingletonClient.checkClientValidityForRewards();
//         if (response.status > 299) {
//             return thunkAPI.rejectWithValue(response.data);
//         } else {
//             return response.data;
//         }
//     }
// );

export const checkClientValidityForRewards = createAsyncThunk(
    "rewards/checkClientValidityForRewards",
    async (thunkAPI) => {
        const response = await watchTowerInstance.get(
            `rewards/loyalty-tiers/enrollment-status`
        );
        if (response.status < 200 || response.status >= 300) {
            thunkAPI.dispatch(
                notifyWithError({
                    message: formulateWatchTowerError(response.data),
                    type: "error",
                })
            );
            return thunkAPI.rejectWithValue(response.data);
        } else {
            return response.data;
        }
    }
);

// export const getClientsTransactions = createAsyncThunk(
//     "rewards/getClientsTransactions",
//     async (data, thunkAPI) => {
//         const response = await SingletonClient.getClientsTransactions(data);
//         if (response.status > 299) {
//             return thunkAPI.rejectWithValue(response.data);
//         } else {
//             return response.data;
//         }
//     }
// );

export const getClientsTransactions = createAsyncThunk(
    "rewards/getClientsTransactions",
    async (data, thunkAPI) => {
        const response = await watchTowerInstance.get(
            `rewards/transactions/client-profile/?client_id=${data.clientID}&page=${data.page}&search=${data.searchQuery}`
        );
        if (response.status < 200 || response.status >= 300) {
            thunkAPI.dispatch(
                notifyWithError({
                    message: formulateWatchTowerError(response.data),
                    type: "error",
                })
            );
            return thunkAPI.rejectWithValue(response.data);
        } else {
            return response.data;
        }
    }
);
// export const getClientsLoyaltyTiers = createAsyncThunk(
//     "rewards/getClientsLoyaltyTiers",
//     async (data, thunkAPI) => {
//         const response = await SingletonClient.getClientsLoyaltyTiers(data);
//         if (response.status > 299) {
//             return thunkAPI.rejectWithValue(response.data);
//         } else {
//             return response.data;
//         }
//     }
// );

export const getClientsLoyaltyTiers = createAsyncThunk(
    "rewards/getClientsLoyaltyTiers",
    async (data, thunkAPI) => {
        const response = await watchTowerInstance.get(
            `rewards/loyalty-tiers/status/?client_id=${data.clientID}`
        );
        if (response.status < 200 || response.status >= 300) {
            thunkAPI.dispatch(
                notifyWithError({
                    message: formulateWatchTowerError(response.data),
                    type: "error",
                })
            );
            return thunkAPI.rejectWithValue(response.data);
        } else {
            return response.data;
        }
    }
);

export const rewardsSlice = createSlice({
    name: "rewards",
    initialState,
    reducers: {
        resetRewards: (state, action) => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getClientsTransactions.fulfilled, (state, action) => {
            state.fetchClientTransactionsStatus = "succeeded";
            state.clientTransactionsPagination =
                action.payload.transactions.last_count;
            let initialTransactions = [];
            action.payload.transactions.results.forEach((transaction) => {
                initialTransactions.push({
                    id: transaction.id,
                    operation: transaction.reason,
                    points: transaction.amount,
                    sourceAndNumber: transaction.reason,
                    creator: transaction?.created_by?.name || "-",
                    date: moment(transaction.created_at).format("DD-MM-YYYY"),
                });
            });
            state.clientTransactions = initialTransactions;

            state.specificClientStatistics = {
                running_points: action.payload.running_balance,
                unused_points: action.payload.unused_points,
                expired_points: action.payload.expired_points,
                total_points: action.payload.total_points,
                used_points: action.payload.used_points,
            };
        });
        builder.addCase(getClientsTransactions.pending, (state, action) => {
            state.fetchClientTransactionsStatus = "loading";
        });
        builder.addCase(getClientsTransactions.rejected, (state, action) => {
            state.fetchClientTransactionsStatus = "failed";
        });
        builder.addCase(getClientsLoyaltyTiers.fulfilled, (state, action) => {
            state.fetchClientLoyaltyTiersStatus = "succeeded";
            state.clientLoyaltyTiers = {
                active_tier: action.payload.active_tier,
                next_tier: action.payload.next_tier,
                running_tier: action.payload.running_tier,
                client: action.payload.client,
            };
        });
        builder.addCase(getClientsLoyaltyTiers.pending, (state, action) => {
            state.fetchClientLoyaltyTiersStatus = "loading";
        });
        builder.addCase(getClientsLoyaltyTiers.rejected, (state, action) => {
            state.fetchClientLoyaltyTiersStatus = "failed";
        });
        builder.addCase(
            checkClientValidityForRewards.fulfilled,
            (state, action) => {
                state.checkClientValidityForRewardsStatus = "succeeded";
                if (action.payload.is_in_loyalty_program) {
                    state.validRewardsClientID =
                        action.payload.company_branch_id;
                    state.clientValidForRewards = true;
                } else {
                    state.clientValidForRewards = false;
                }
            }
        );
        builder.addCase(
            checkClientValidityForRewards.pending,
            (state, action) => {
                state.checkClientValidityForRewardsStatus = "loading";
            }
        );
        builder.addCase(
            checkClientValidityForRewards.rejected,
            (state, action) => {
                state.checkClientValidityForRewardsStatus = "failed";
                state.clientValidForRewards = false;
            }
        );
    },
});
export const { resetRewards } = rewardsSlice.actions;
export default rewardsSlice.reducer;
