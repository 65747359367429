import { Button, Typography } from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { SlArrowLeft } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";

const StyledButton = ({ stackStyles, buttonStyles, onClick }) => {
  //SECTION - general
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  return (
    <Stack
      sx={{
        border: `2px solid ${theme.palette.primary.main}`,
        width: "210px",
        height: "60px",
        borderRadius: theme.borderRadius.r15,
        left: i18n.language === "ar" ? "2rem" : "auto",
        right: i18n.language === "en" ? "2rem" : "auto",
        ...stackStyles,
      }}
    >
      <Button
        variant="contained"
        color="secondary"
        endIcon={i18n.language === "ar" ? <SlArrowLeft /> : <SlArrowRight />}
        sx={{
          padding: "0.5rem",
          width: "210px",
          borderRadius: theme.borderRadius.r15,
          flexDirection: "row",
          gap: "10px",
          height: "60px",
          top: "4px",
          right: i18n.language === "ar" ? "3px" : "auto",
          left: i18n.language === "en" ? "3px" : "auto",
          ...buttonStyles,
        }}
        onClick={onClick}
      >
        <Typography
          fontWeight={theme.typography.bold}
          variant="h5"
          sx={{ textTransform: "none" }}
        >
          {t("wallet.promotion.knowMore")}
        </Typography>
      </Button>
    </Stack>
  );
};

export default StyledButton;
