import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import autobiaLogo from "../../../assets/images/logos/autobia.png";
import googlePlay from "../../../assets/images/logos/googlePlay.png";
import appStore from "../../../assets/images/logos/appStore.png";
import { BiLogoTelegram } from "react-icons/bi";
import {
  IoCall,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoTwitter,
} from "react-icons/io5";
import { RiWhatsappFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VATIcon from "../../../assets/images/legal/VATIcon.png";
import VATRegistrationCertificateModal from "./VATRegistrationCertificateModal";

const Footer = () => {
  //SECTION - GENERAL
  const theme = useTheme();
  const { t } = useTranslation();

  //SECTION - CUSTOM STYLES
  const contancUsLinkStyles = {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    color: theme.palette.primary.main,
  };

  //SECTION - useState
  const [openVATCertificateModal, setOpenVATCertificateModal] = useState(false);

  return (
    <Box bgcolor={"#fff9f1"}>
      <Grid container gap={{ xs: theme.spacing(4), sm: "0px" }} p={"1rem"}>
        <Grid xs={12} sm={3}>
          <Stack gap={theme.spacing(2)} px={{ sm: theme.spacing(1) }}>
            <img
              alt="autobiaLogo"
              style={{ width: "100px" }}
              src={autobiaLogo}
            />
            <Typography>{t("footer.description")}</Typography>
          </Stack>
        </Grid>
        <Grid xs={12} sm={9} container>
          <Grid xs={12} sm={4} md={3}>
            <Stack py={theme.spacing(2)} px={{ sm: theme.spacing(1) }}>
              <Typography
                fontSize={"1.2rem"}
                fontWeight={theme.typography.bold}
              >
                {t("footer.downloadApp")}
              </Typography>
              <Stack
                direction={{ xs: "row", sm: "column" }}
                gap={theme.spacing(2)}
                py={theme.spacing(1)}
              >
                <Box
                  width={{
                    xs: "100%",
                    semiSm: "fit-content",
                  }}
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.autobia.autobia"
                    )
                  }
                  sx={{ cursor: "pointer" }}
                >
                  <img
                    alt="googlePlay"
                    style={{
                      maxWidth: "125px",
                    }}
                    src={googlePlay}
                  />
                </Box>
                <Box
                  width={{
                    xs: "100%",
                    semiSm: "fit-content",
                  }}
                  onClick={() =>
                    window.open("https://apps.apple.com/sa/app/id1661648763")
                  }
                  sx={{ cursor: "pointer" }}
                >
                  <img
                    alt="appStore"
                    style={{ maxWidth: "125px" }}
                    src={appStore}
                  />
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid xs={6} sm={4} md={3}>
            <Stack py={theme.spacing(2)} px={{ sm: theme.spacing(1) }}>
              <Typography
                fontSize={"1.2rem"}
                fontWeight={theme.typography.bold}
              >
                {t("footer.links")}
              </Typography>

              <Stack>
                <Link style={{ color: "#000" }} to={"/cart"}>
                  {t("footer.shoppingCart")}
                </Link>
                <Link style={{ color: "#000" }} to={"/orders"}>
                  {t("footer.orders")}
                </Link>
                <Link style={{ color: "#000" }} to={"/offers"}>
                  {t("footer.offers")}
                </Link>
                <Link
                  style={{ color: "#000" }}
                  to={"https://store.autobia.co/?new=true"}
                >
                  {t("footer.oldWebsite")}
                </Link>
                {/* <Link style={{ color: "#000" }} to={"#"}>
                  {t("footer.about")}
                </Link> */}
              </Stack>
            </Stack>
          </Grid>
          <Grid xs={6} sm={4} md={3}>
            <Stack py={theme.spacing(2)}>
              <Typography
                fontSize={"1.2rem"}
                fontWeight={theme.typography.bold}
              >
                {t("footer.contactUs")}
              </Typography>
              <Stack>
                <Box width="fit-content">
                  <Link
                    style={contancUsLinkStyles}
                    to={"https://wa.me/+966920033172"}
                  >
                    <RiWhatsappFill />
                    {t("phones.whatsUp")}
                  </Link>
                </Box>
                <Box width="fit-content">
                  <Link
                    style={contancUsLinkStyles}
                    to={"https://t.me/autobiabot"}
                  >
                    <BiLogoTelegram />
                    {t("autobia.telegram")}
                  </Link>
                </Box>
                <Box width="fit-content">
                  <Link style={contancUsLinkStyles} to={"tel:+966920033172"}>
                    <IoCall />
                    {t("phones.helpCenter")}
                  </Link>
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid xs={12} sm={12} md={3}>
            <Stack py={theme.spacing(2)}>
              <Typography
                fontSize={"1.2rem"}
                fontWeight={theme.typography.bold}
              >
                {t("footer.followUs")}
              </Typography>
              <Stack direction={"row"} gap={theme.spacing(2)}>
                <Box sx={{ cursor: "pointer" }}>
                  <IoLogoLinkedin
                    fontSize={"2rem"}
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/autobia/mycompany/"
                      )
                    }
                  />
                </Box>
                <Box sx={{ cursor: "pointer" }}>
                  <IoLogoTwitter
                    fontSize={"2rem"}
                    onClick={() =>
                      window.open("https://twitter.com/autobia_sa")
                    }
                  />
                </Box>
                <Box sx={{ cursor: "pointer" }}>
                  <IoLogoInstagram
                    onClick={() =>
                      window.open("https://www.instagram.com/autobia.sa/")
                    }
                    fontSize={"2rem"}
                    sx={{ cursor: "pointer" }}
                  />
                </Box>
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenVATCertificateModal(true)}
            >
              <Stack justifyContent={"center"} alignItems={"center"}>
                <img
                  style={{
                    width: "100%",
                    height: "50px",
                    objectFit: "contain",
                  }}
                  src={VATIcon}
                  alt={"VATIcon"}
                ></img>
              </Stack>
              <Stack
                sx={{ paddingX: "0.5rem" }}
                textAlign={"start"}
                direction={"column"}
                gap={"0.2rem"}
              >
                <Typography fontWeight={theme.typography.bold}>
                  {t("footer.VATNumberText")}
                </Typography>
                <Typography
                  fontSize={"0.9rem"}
                  sx={{ textDecoration: "underline" }}
                >
                  {t("footer.VATNumber")}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} container padding={"1rem"} justifyContent={"center"}>
        <Typography fontSize={"1rem"}>{t("footer.copyrights")}</Typography>
      </Grid>
      <VATRegistrationCertificateModal
        open={openVATCertificateModal}
        onClose={() => setOpenVATCertificateModal(false)}
      />
    </Box>
  );
};

export default Footer;
