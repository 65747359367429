import { Typography } from "@mui/material";
import { Box, Stack, useTheme } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";

const TermsAndConditions = ({
  termsAndConditionsRef,
  refundRate,
  commissionRate,
}) => {
  //SECTION - general
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack gap={theme.spacing(1)}>
      <Typography fontSize={{ xs: "1.2rem", sm: "1.8rem" }} fontWeight={"bold"}>
        {t("wallet.termsAndConditions.title")}
      </Typography>
      <Box
        width={"100%"}
        id="termsAndConditions"
        ref={termsAndConditionsRef}
        sx={{
          backgroundColor: theme.palette.gray.g100,
          padding: {
            xs: theme.spacing(1),
            sm: theme.spacing(2),
          },
          borderRadius: theme.borderRadius.r15,
          // maxHeight: "500px",
          overflowY: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            width: 0,
          },
          "& ol": {
            listStyleType: "decimal",
            paddingInlineStart: "10px",
          },
          "& li::marker": {
            fontWeight: "bold",
          },
        }}
      >
        <Typography component="div">
          <ol>
            {Array.from({ length: 15 }, (_, i) => i + 1).map((key, index) => {
              const termKey = `T&Cpoint${key}`;
              const isPercRequired = key === 9 || key === 10;
              const description = isPercRequired
                ? t(`wallet.termsAndConditions.${termKey}.description`, {
                    perc: key === 9 ? commissionRate : refundRate,
                  })
                : t(`wallet.termsAndConditions.${termKey}.description`);

              return (
                <li key={key} style={{ marginBottom: 10 }}>
                  <Typography variant="h6">
                    <strong>
                      {t(`wallet.termsAndConditions.${termKey}.title`)}
                      {": "}
                    </strong>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                  </Typography>
                </li>
              );
            })}
          </ol>
        </Typography>
      </Box>
    </Stack>
  );
};

export default TermsAndConditions;
