import {
  Collapse,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  IoCard,
  IoEarthOutline,
  IoLogOutOutline,
  IoPerson,
} from "react-icons/io5";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { BiGift, BiSolidHelpCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FiFileText } from "react-icons/fi";
import LangForm from "./LangForm";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { logOut } from "../../../app/features/auth/auth";
import { notifyWithError } from "../../../app/features/error/error";
import { useDispatch } from "react-redux";

const NavbarProfile = () => {
  //SECTION - GENERAL
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //SECTION - useState
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLangList, setOpenLangList] = useState(false); //opens list languages
  const [isProfileColorRingActive, setIsProfileColorRingActive] =
    useState(false);

  //SECTION - CUSTOM STYLES
  const menuItemStyle = {
    borderRadius: "5px",
    height: "50px",
    backgroundColor: theme.palette.gray.g50,
    border: `1px solid ${theme.palette.gray.g100} `,
    "&:hover": {
      border: `1px solid ${theme.palette.secondary.main} `,
    },
  };

  //SECTION - FUNCTIONS
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsProfileColorRingActive(true);
  };

  const handleCloseProfile = () => {
    setAnchorEl(null);
    setIsProfileColorRingActive(false);
  };

  const handleOpenLang = () => {
    setOpenLangList(!openLangList);
  };

  //SECTION - OTHERS
  const open = Boolean(anchorEl);
  const id = open ? "profile-popover" : undefined;
  const rewardsClientValidity = useSelector(
    (state) => state.rewardsReducer.clientValidForRewards
  );
  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls={"profile"}
        aria-describedby={id}
        sx={{
          padding: theme.spacing(2),
          border: `1px ${
            isProfileColorRingActive
              ? theme.palette.primary.dark
              : theme.palette.gray.g500
          } solid`,
          color: `${
            isProfileColorRingActive
              ? theme.palette.primary.main
              : theme.palette.gray.g400
          }`,
          "&:hover": {
            border: `1px ${theme.palette.primary.dark} solid`,
            color: theme.palette.primary.main,
          },
        }}
        onClick={handleClick}
      >
        <IoPerson />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseProfile}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack padding={"1rem"} width={"350px"}>
          <MenuList
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(1),
            }}
          >
            {/* <MenuItem sx={menuItemStyle}>
              <ListItemIcon>
                <IoPersonOutline
                  color={theme.palette.secondary.main}
                  fontSize={"1.5rem"}
                />
              </ListItemIcon>
              <ListItemText>{t("navbar.myProfile")}</ListItemText>
            </MenuItem> */}
            {/* <MenuItem sx={menuItemStyle}>
              <ListItemIcon>
                <IoWallet
                  color={theme.palette.secondary.main}
                  fontSize={"1.5rem"}
                />
              </ListItemIcon>
              <ListItemText>{t("navbar.wallet")}</ListItemText>
            </MenuItem> */}
            {/* <MenuItem
              sx={menuItemStyle}
              onClick={() => {
                navigate("/rewards");
                handleCloseProfile();
              }}
            >
              <ListItemIcon>
                <BiGift
                  color={theme.palette.secondary.main}
                  fontSize={"1.5rem"}
                />
              </ListItemIcon>
              <ListItemText>{t("navbar.rewardsSystem")}</ListItemText>
            </MenuItem> */}
            {rewardsClientValidity && (
              <MenuItem
                sx={menuItemStyle}
                onClick={() => {
                  navigate("/rewards");
                  handleCloseProfile();
                }}
              >
                <ListItemIcon>
                  <BiGift
                    color={theme.palette.secondary.main}
                    fontSize={"1.5rem"}
                  />
                </ListItemIcon>

                <ListItemText> {t("navbar.rewards")}</ListItemText>
              </MenuItem>
            )}
            <MenuItem
              sx={menuItemStyle}
              onClick={() => {
                window.open(
                  "https://deals-uploads.s3.eu-central-1.amazonaws.com/public/files/general/Web+Ordering+steps_02+(2)+(1).pdf"
                );
                handleCloseProfile();
              }}
            >
              <ListItemIcon>
                <BiSolidHelpCircle
                  color={theme.palette.secondary.main}
                  fontSize={"1.5rem"}
                />
              </ListItemIcon>
              <ListItemText> {t("navbar.usageInstructions")}</ListItemText>
            </MenuItem>
            <MenuItem
              sx={menuItemStyle}
              onClick={() => {
                navigate("/payment");
                handleCloseProfile();
              }}
            >
              <ListItemIcon>
                <IoCard
                  color={theme.palette.secondary.main}
                  fontSize={"1.5rem"}
                />
              </ListItemIcon>
              <ListItemText>{t("navbar.paymentMethods")}</ListItemText>
            </MenuItem>
            {/* <MenuItem sx={menuItemStyle}>
              <ListItemIcon>
                <IoSwapVertical
                  color={theme.palette.secondary.main}
                  fontSize={"1.5rem"}
                />
              </ListItemIcon>
              <ListItemText>{t("navbar.brands")}</ListItemText>
            </MenuItem> */}
            {/* <MenuItem
              sx={menuItemStyle}
              onClick={() => {
                navigate("/FAQ");
                handleCloseProfile();
              }}
            >
              <ListItemIcon>
                <IoHelpCircle
                  color={theme.palette.secondary.main}
                  fontSize={"1.5rem"}
                />
              </ListItemIcon>
              <ListItemText>{t("navbar.FAQ")}</ListItemText>
            </MenuItem> */}
            <MenuItem
              sx={menuItemStyle}
              onClick={() => {
                navigate("/terms");
                handleCloseProfile();
              }}
            >
              <ListItemIcon>
                <FiFileText
                  color={theme.palette.secondary.main}
                  fontSize={"1.5rem"}
                />
              </ListItemIcon>
              <ListItemText>{t("navbar.termsAndConditions")}</ListItemText>
            </MenuItem>
            <MenuItem
              sx={{
                ...menuItemStyle,
                display: { xs: "flex", semiLg: "none" },
              }}
              onClick={handleOpenLang}
            >
              <ListItemIcon>
                <IoEarthOutline
                  color={theme.palette.secondary.main}
                  fontSize={"1.5rem"}
                />
              </ListItemIcon>
              <Typography>{t("navbar.languages")}</Typography>
              {openLangList ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>
            <Collapse in={openLangList} timeout="auto" unmountOnExit>
              <LangForm
                isInProfile={"true"}
                handleCloseProfile={handleCloseProfile}
              />
            </Collapse>
            {/* <MenuItem sx={menuItemStyle}>
              <ListItemIcon>
                <BiSupport
                  color={theme.palette.secondary.main}
                  fontSize={"1.5rem"}
                />
              </ListItemIcon>
              <ListItemText>{t("navbar.support")}</ListItemText>
            </MenuItem> */}
            <Divider />
            <MenuItem
              onClick={() => {
                dispatch(logOut()).then((payload) => {
                  if (payload.type === "auth/logOut/fulfilled") {
                    navigate("/login");
                  } else {
                    dispatch(
                      notifyWithError({
                        message: t("errorCodes.logOutError"),
                        type: "error",
                      })
                    );
                  }
                });
              }}
              sx={{
                backgroundColor: theme.palette.error.main,
                color: theme.palette.white,
                borderRadius: "5px",
                height: "50px",
                "&:hover": {
                  backgroundColor: theme.palette.error.dark,
                },
              }}
            >
              <ListItemIcon>
                <IoLogOutOutline color={theme.palette.white} />
              </ListItemIcon>
              <ListItemText>{t("navbar.logout")}</ListItemText>
            </MenuItem>
          </MenuList>
        </Stack>
      </Popover>
    </>
  );
};

export default NavbarProfile;
