import {
  Grid,
  Typography,
  useTheme,
  Button,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { BsFillCartPlusFill } from "react-icons/bs";
import FindBrandLogo from "../../utils/data/FindBrandLogo";
import AddItemModal from "./AddItemModal";
import { useState, React } from "react";
import WillBePricedWrapper from "../../components/common/WillBePricedWrapper";

const OrderItem = ({ item, brands }) => {
  //SECTION - general
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));

  //SECTION - useState
  const [open, setOpen] = useState(false);
  const [openToolTip, setOpenToolTip] = useState(false);

  //SECTION - functions
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const requiresTruncation = isMobileScreen
    ? item.item.description?.length > 10
    : isTabletScreen
    ? item.item.description?.length > 15
    : item.item.description?.length > 30;
  const truncatedDescription = isMobileScreen
    ? requiresTruncation
      ? item.item.description?.substring(0, 8) + "..." // Truncate the description
      : item.item.description
    : isTabletScreen
    ? requiresTruncation
      ? item.item.description.substring(0, 15) + "..." // Truncate the description
      : item.item.description
    : requiresTruncation
    ? item.item.description?.substring(0, 30) + "..." // Truncate the description
    : item.item.description;
  return (
    <>
      <AddItemModal
        open={open}
        onClose={handleClose}
        item={item}
        brands={brands}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="left"
        sx={{
          borderBottom: `1px solid ${theme.palette.gray.g400}`,
        }}
      >
        <Grid
          xs={6}
          semiMd={5}
          md={4}
          container
          justifyContent="center"
          alignItems="center"
          direction={"row"}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={3}
            semiMd={3}
            md={3}
            paddingY={theme.spacing(0.5)}
          >
            <img
              style={{
                width: "35px",
                height: "35px",
                objectFit: "contain",
              }}
              src={FindBrandLogo(brands, item.item.manufacturer.id)}
              alt={item.item.manufacturer.name_en}
            />
            <Typography
              fontSize={{
                xs: "0.5rem",
                semiMd: "0.6rem",
                lg: "0.7rem",
                xxl: "0.8rem",
              }}
              sx={{
                color: theme.palette.gray.g500,
                fontWeight: theme.typography.bold,
                whiteSpace: "nowrap",
              }}
            >
              {item.brand.name_en}
            </Typography>
          </Grid>
          <Grid semiMd={1} md={1} xs={0.5} lg={2.2}></Grid>
          <Grid
            xs={8.3}
            semiMd={8}
            md={8}
            lg={6.8}
            container
            direction={"column"}
            justifyContent="flex-start"
          >
            <Typography color={theme.palette.secondary.dark} fontSize={"1rem"}>
              {item.item.part_number}
            </Typography>
            <Tooltip
              title={requiresTruncation ? item.item.description : ""}
              open={openToolTip}
              onClick={() => setOpenToolTip(true)}
              onClose={() => setOpenToolTip(false)}
              onMouseEnter={() => setOpenToolTip(true)}
              onMouseLeave={() => setOpenToolTip(false)}
            >
              <Typography
                color={theme.palette.secondary.main}
                fontSize={"0.9rem"}
                whiteSpace={"nowrap"}
              >
                {truncatedDescription}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid
          xs={1.7}
          semiMd={1}
          md={4}
          container
          direction="row"
          justifyContent={{
            xs: "flex-start",
            semiMd: "center",
            md: "center",
          }}
          alignItems="center"
        >
          <Typography color={theme.palette.secondary.dark} fontSize={"0.9rem"}>
            {item.items_detail.quantity}
          </Typography>
        </Grid>
        <Grid
          xs={4}
          semiMd={5}
          md={4}
          semiLg={4}
          container
          direction="row"
          alignItems="center"
        >
          <Grid xs={1} semiMd={4} semiLg={4}></Grid>
          <Grid
            xs={7}
            semiMd={4}
            semiLg={4}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <WillBePricedWrapper
              id={item.item.part_number}
              Child={() => (
                <Typography
                  color={theme.palette.secondary.dark}
                  fontSize={"0.9rem"}
                >
                  {item.items_detail.selling_price !== 0
                    ? item.items_detail.selling_price + " " + t("currency.SAR")
                    : t("home.willBePriced")}
                </Typography>
              )}
              selling_price={
                item.items_detail.selling_price !== 0
                  ? item.items_detail.selling_price + " " + t("currency.SAR")
                  : t("home.willBePriced")
              }
            />
          </Grid>
          <Grid
            xs={4}
            semiMd={4}
            semiLg={4}
            gap={theme.spacing(0.3)}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Button disabled={item.status !== "active"} onClick={handleOpen}>
              <BsFillCartPlusFill
                color={
                  item.status === "active"
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.light
                }
                fontSize={"1.5rem"}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default OrderItem;
