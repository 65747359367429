import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "../utils/api/Authentication";

const LoginProtected = ({ children }) => {
    if (isAuthenticated()) {
        return <Navigate to="/home" replace />;
    } else {
        if (!localStorage.getItem("otp_token")) {
            return <Navigate to="login" replace />;
        }
    }
    return children ? children : <Outlet />;
};
export default LoginProtected;
