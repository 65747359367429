import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import validation from "../../utils/Helpers/validation";

const ItemsTable = () => {
  //SECTION - GENERAL
  const theme = useTheme();
  const { t } = useTranslation();

  //SECTION - useState
  const [formattedItems, setFormattedItems] = useState([]);

  //SECTION - useSelector
  const orderTrackingInformation = useSelector(
    (state) => state.orderTrackingReducer.orderTrackingInformation
  );

  //SECTION - table related
  const columns = [
    {
      id: "itemDetails",
      label: t("orderTracking.itemDetails"),
      align: "center",
    },
    {
      id: "orderedQuantity",
      label: t("orderTracking.orderedQuantity"),
      align: "center",
    },
    {
      id: "availableQuantity",
      label: t("orderTracking.availableQuantity"),
      align: "center",
    },
    {
      id: "unavailableQuantity",
      label: t("orderTracking.unavailableQuantity"),
      align: "center",
    },
  ];

  //SECTION - useEffect
  useEffect(() => {
    if (!validation.isEmpty(orderTrackingInformation?.items)) {
      setFormattedItems(
        orderTrackingInformation?.items?.map((item) => {
          return {
            itemDetails: item.brand_item.part_number,
            orderedQuantity: item.ordered_quantity,
            availableQuantity: item.quantity,
            unavailableQuantity: item.unavailable_quantity,
          };
        })
      );
    }
  }, [orderTrackingInformation]);

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        sx={{
          maxHeight: "500px",
          overflow: "auto",
          backgroundColor: theme.palette.gray.g25second,
          borderRadius: theme.borderRadius.r10,
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{ backgroundColor: theme.palette.gray.g25second }}
                >
                  <Typography
                    variant="body2"
                    fontWeight={theme.typography.bold}
                    color={theme.palette.gray.g600}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {formattedItems.map((item) => {
              return (
                <TableRow hover key={item.itemDetails}>
                  {columns.map((column) => {
                    const value = item[column.id];
                    return (
                      <TableCell key={column.id} align={"center"}>
                        <Typography variant="body2">{value}</Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default ItemsTable;
