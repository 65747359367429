import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTheme } from "@emotion/react";
import ButtonComp from "../../components/common/ButtonComp";
import { useTranslation, Trans } from "react-i18next";
import { CircularProgress, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getPutCartStatus, putCartItems } from "../../app/features/cart/cart";
import { BiError } from "react-icons/bi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  textAlign: "center",
  width: { xs: "90%", sm: "700px" },
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "25px",
  pt: "2rem",
};

const DeleteAllModal = ({ open, handleClose, cartId, cartItems }) => {
  //SECTION - GENERAL
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentLang = localStorage.getItem("lng");

  //SECTION - Selectors
  const putCartStatus = useSelector(getPutCartStatus);

  //SECTION - CUSTOM STYLES
  const btnStyles = {
    fontSize: { xs: "1.5rem", sm: "2rem" },
    "&:hover": {
      bgcolor: "transparent",
    },
  };

  //SECTION - FUNCTIONS
  const deleteAllItems = () => {
    const allItems = cartItems.map((item) => ({
      id: item.id,
      quantity: 0,
    }));
    const data = { items: allItems, cartId: cartId };
    dispatch(putCartItems(data)).then((res) => {
      if (res.type === "cart/putCartItems/fulfilled") {
        handleClose();
      } else {
        alert("Error happened.");
      }
    });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} overflow={"hidden"} p={theme.spacing(2)}>
          {putCartStatus === "loading" ? (
            <CircularProgress size={"8rem"} />
          ) : (
            <BiError fontSize={"8rem"} color={theme.palette.error.main} />
          )}
          <Typography fontSize={{ xs: "1.5rem", sm: "2rem" }} py={"3rem"}>
            <Trans t={t}>{t("cart.areYouSureDeleteAll")}</Trans>
          </Typography>

          <Stack direction={currentLang === "ar" ? "row-reverse" : "row"}>
            <ButtonComp
              content={t("cart.delete")}
              variant={"text"}
              disableRipple
              onClick={() => {
                deleteAllItems();
              }}
              fullWidth
              sx={{
                ...btnStyles,
                color: theme.palette.error.main,
              }}
            />
            <ButtonComp
              content={t("cart.cancel")}
              variant={"text"}
              disableRipple
              onClick={() => {
                handleClose();
              }}
              fullWidth
              sx={{
                ...btnStyles,
                color: theme.palette.success.main,
              }}
            />
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteAllModal;
