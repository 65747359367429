import { useEffect, useState } from "react";
// import Carousel from "react-material-ui-carousel";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  EffectFade,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation"; // Ensure this is included if using navigation
import "swiper/css/scrollbar"; // Ensure this is included if using scrollbar
import "swiper/css/effect-fade"; // Ensure this is included if using the fade effect
import { Container } from "@mui/material";
import BannerOffer from "./BannerOffer";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAdvertisement,
  getAdvertisement,
} from "../../../app/features/offer/offer";

const Banner = () => {
  //SECTION - GENERAL
  const dispatch = useDispatch();
  const [firstImageLoaded, setFirstImageLoaded] = useState(false);

  //SECTION - useSelectors
  const offers = useSelector(getAdvertisement);

  //SECTION - useEffects
  useEffect(() => {
    dispatch(fetchAdvertisement());
  }, [dispatch]);
  return (
    <Container maxWidth="lg" sx={{ marginTop: "1rem" }}>
      {/* load the first image to get the height for the carousel (bug in the library) */}
      {offers && offers.length > 0 && (
        <img
          src={offers[0]?.filer?.file}
          onLoad={() => setFirstImageLoaded(true)}
          style={{ display: "none" }}
          alt="first-img"
        />
      )}
      {firstImageLoaded && (
        <Swiper
          loop={true}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          slidesPerView={1}
          modules={[Navigation, Pagination, Scrollbar, Autoplay, EffectFade]}
        >
          {offers &&
            offers.map((eachOffer) => (
              <SwiperSlide key={eachOffer.id}>
                <BannerOffer key={eachOffer.id} eachOffer={eachOffer} />
              </SwiperSlide>
            ))}
        </Swiper>
      )}
    </Container>
  );
};

export default Banner;
