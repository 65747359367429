import i18next from "i18next";
import { statuses } from "./statuses";
const CalculateOrderPrice = (
  orderItems,
  orderShippingPrice,
  orderConstantDiscount,
  orderCoupon,
  status
) => {
  //define initial values
  let priceWithoutDiscount = 0;
  //apply constant discount
  let discount = parseFloat(orderConstantDiscount);
  let cashBack = 0;
  // let shippingPrice = 0;
  let vat = 0;
  // let totalPrice = 0;
  let priced = true;

  //add the price of items and if any reuqires pricing, set priced to false
  orderItems.forEach((item) => {
    if (item.items_detail.selling_price === 0) {
      priced = false;
    } else {
      priceWithoutDiscount +=
        item.items_detail.selling_price * item.items_detail.quantity;
    }
  });
  const calculateDiscountPercentage = (basketSize) => {
    if (
      basketSize < orderCoupon.coupon_details.discount_thresholds[0].threshold
    ) {
      return "0";
    } else if (
      orderCoupon.coupon_details.discount_thresholds.length === 1 &&
      basketSize >= orderCoupon.coupon_details.discount_thresholds[0].threshold
    ) {
      return orderCoupon.coupon_details.discount_thresholds[0].percentage;
    } else {
      for (
        let i = 0;
        i < orderCoupon.coupon_details.discount_thresholds.length;
        i++
      ) {
        if (i + 1 < orderCoupon.coupon_details.discount_thresholds.length) {
          if (
            basketSize >=
              orderCoupon.coupon_details.discount_thresholds[i].threshold &&
            basketSize <
              orderCoupon.coupon_details.discount_thresholds[i + 1].threshold
          ) {
            return orderCoupon.coupon_details.discount_thresholds[i].percentage;
          }
        } else {
          return orderCoupon.coupon_details.discount_thresholds[
            orderCoupon.coupon_details.discount_thresholds.length - 1
          ].percentage;
        }
      }
    }
  };
  //if all items are priced
  if (priced) {
    if (
      [
        statuses.draft,
        statuses.price,
        statuses.pricing,
        statuses.waiting_approvement,
      ].includes(status)
    ) {
      if (Object.keys(orderCoupon.coupon_details).length !== 0) {
        if (orderCoupon.coupon_details.coupon_type === "discount") {
          //discount
          if (
            (priceWithoutDiscount - discount) *
              (calculateDiscountPercentage(priceWithoutDiscount) / 100) >
            parseFloat(orderCoupon.coupon_details.max_discount_limit)
          ) {
            discount =
              discount +
              parseFloat(orderCoupon.coupon_details.max_discount_limit);
          } else {
            discount =
              discount +
              (priceWithoutDiscount - discount) *
                (calculateDiscountPercentage(priceWithoutDiscount) / 100);
          }
        } else if (orderCoupon.coupon_details.coupon_type === "cashback") {
          //cashBack
          if (
            (priceWithoutDiscount - discount) *
              (calculateDiscountPercentage(priceWithoutDiscount) / 100) >
            parseFloat(orderCoupon.coupon_details.max_discount_limit)
          ) {
            cashBack = parseFloat(
              orderCoupon.coupon_details.max_discount_limit
            );
          } else {
            cashBack =
              (priceWithoutDiscount - discount) *
              (calculateDiscountPercentage(priceWithoutDiscount) / 100);
          }
        }
      }
    } else {
      if (orderCoupon?.coupon_type !== null) {
        if (parseInt(orderCoupon.coupon_type) === 2) {
          //discount
          if (
            (priceWithoutDiscount - discount) *
              (parseFloat(orderCoupon.coupon_percentage) / 100) >
            parseFloat(orderCoupon.coupon_max_discount)
          ) {
            discount = discount + parseFloat(orderCoupon.coupon_max_discount);
          } else {
            discount =
              discount +
              (priceWithoutDiscount - discount) *
                (parseFloat(orderCoupon.coupon_percentage) / 100);
          }
        } else if (parseInt(orderCoupon.coupon_type) === 1) {
          //cashBack

          if (
            (priceWithoutDiscount - discount) *
              (parseFloat(orderCoupon.coupon_percentage) / 100) >
            parseFloat(orderCoupon.coupon_max_discount)
          ) {
            cashBack = parseFloat(orderCoupon.coupon_max_discount);
          } else {
            cashBack =
              (priceWithoutDiscount - discount) *
              (parseFloat(orderCoupon.coupon_percentage) / 100);
          }
        }
      }
    }

    //calculate the price without VAT
    const totalPriceWithoutVat =
      parseFloat(priceWithoutDiscount) -
      parseFloat(discount) +
      parseFloat(orderShippingPrice);

    //calculate the VAT
    vat = 0.15 * totalPriceWithoutVat;

    //calculate the price with VAT + shipping
    const totalPriceWithVat = totalPriceWithoutVat + vat;
    return {
      priceWithoutDiscount: priceWithoutDiscount.toFixed(2),
      discount: discount.toFixed(2),
      discountMessage:
        discount === 0
          ? i18next.t("order.noDiscount")
          : i18next.t("order.discountMessage", {
              maxPrice: orderCoupon.max_price,
              discount: orderCoupon.discount,
            }),
      cashBack: cashBack.toFixed(2),
      cashBackMessage:
        cashBack === 0
          ? i18next.t("order.noCashback")
          : i18next.t("order.discountMessage", {
              maxPrice: orderCoupon.max_price,
              discount: orderCoupon.discount,
            }),
      shippingPrice:
        orderShippingPrice === "0"
          ? "FREE"
          : parseFloat(orderShippingPrice).toFixed(2),
      totalPriceWithoutVat: totalPriceWithoutVat.toFixed(2),
      vat: vat.toFixed(2),
      totalPriceWithVat: totalPriceWithVat.toFixed(2),
    };
  } else {
    return {
      priceWithoutDiscount: "Will be priced",
      discount: "Will be priced",
      cashBack: "Will be priced",
      shippingPrice:
        orderShippingPrice === "0"
          ? "FREE"
          : parseFloat(orderShippingPrice).toFixed(2),
      totalPriceWithoutVat: "Will be priced",
      vat: "Will be priced",
      totalPriceWithVat: "Will be priced",
    };
  }
};

export default CalculateOrderPrice;
