import {
  Grid,
  Typography,
  ListItemText,
  ListItemButton,
  useTheme,
} from "@mui/material";

const BrandListItem = ({ brand, handleRefinement }) => {
  //SECTION - general
  const theme = useTheme();

  return (
    <ListItemButton
      sx={{
        backgroundColor: brand.isRefined
          ? theme.palette.secondary.main
          : "white",
        "&:hover": {
          backgroundColor: brand.isRefined
            ? theme.palette.secondary.main
            : theme.palette.gray.g100,
        },
      }}
      onClick={() => handleRefinement(brand)}
    >
      <ListItemText>
        <Grid
          direction={"row"}
          container
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid>
            <Typography
              fontWeight={theme.typography.bold}
              color={brand.isRefined ? "white" : "black"}
            >
              {brand.label}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              bgcolor={brand.isRefined ? "white" : theme.palette.secondary.main}
              borderRadius={theme.borderRadius.r10}
              padding={theme.spacing(0.5)}
              color={brand.isRefined ? theme.palette.secondary.main : "white"}
              textAlign={"center"}
              width={"40px"}
            >
              {brand.count}
            </Typography>
          </Grid>
        </Grid>
      </ListItemText>
    </ListItemButton>
  );
};

export default BrandListItem;
