import React from "react";
import Grid from "@mui/material/Grid";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { useMediaQuery, useTheme } from "@mui/system";

const ShimmerSkeleton = () => {
  //SECTION - general
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      direction={"row"}
      justifyContent={{ xs: "center", md: "space-between" }}
      spacing={"1rem"}
    >
      <Grid item xs={12} md={6}>
        <ShimmerThumbnail
          height={isSmallScreen ? 200 : 300}
          width={"100%"}
          rounded
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ShimmerThumbnail
          height={isSmallScreen ? 200 : 300}
          width={"100%"}
          rounded
        />
      </Grid>
    </Grid>
  );
};

export default ShimmerSkeleton;
