import { Stack, Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import FindBrandLogo from "../../../utils/data/FindBrandLogo";
import { useRefinementList } from "react-instantsearch";

const CustomRefinementList = (props) => {
  //SECTION - algolia related
  const { items, refine } = useRefinementList(props);

  //SECTION - general
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  //SECTION - useSelector
  const brands = useSelector((state) => state.brandReducer.brands);

  return (
    <>
      <Stack
        direction={"row"}
        paddingX={"0.2rem"}
        sx={{
          overflowX: "scroll",
        }}
        gap={{ xs: "0.25rem", semiSm: "0.5rem" }}
      >
        {items.map((item) => {
          return (
            <Box
              sx={{
                border: `1px solid`,
                borderColor: item.isRefined
                  ? theme.palette.primary.main
                  : theme.palette.gray.g200,
                backgroundColor: theme.palette.white,
                borderRadius: "10px",
                color: item.isRefined ? theme.palette.secondary.main : "gray",
              }}
              key={item.label}
              onClick={(event) => {
                event.preventDefault();
                refine(item.value);
              }}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                padding={"0.5rem"}
                gap={{ xs: "0.25rem", semiSm: "0.5rem" }}
              >
                <Stack
                  container
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <img
                    style={{
                      width: isMobile ? "35px" : "45px",
                      height: isMobile ? "35px" : "45px",
                      objectFit: "contain",
                    }}
                    src={FindBrandLogo(brands, item.label)}
                    alt={item.label}
                  />
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: theme.palette.secondary.main,
                      color: theme.palette.white,
                      padding: "3px",
                      minWidth: "2rem",
                      fontSize: "0.8rem",
                      borderRadius: "5px",
                      fontWeight: theme.typography.bold,
                    }}
                    variant="body2"
                  >
                    {item.count}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          );
        })}
      </Stack>
    </>
  );
};

export default CustomRefinementList;
