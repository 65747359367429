import { Button, Typography } from "@mui/material";
import { Stack, useMediaQuery, useTheme } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaPhoneVolume } from "react-icons/fa6";

const NeedAssistanceSection = () => {
  //SECTION - general
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isAboveMedium = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack
      paddingX={{ xs: "0px", sm: "50px" }}
      maxWidth={"1600px"}
      sx={{ width: "100%" }}
      gap={"16px"}
    >
      <Stack
        sx={{
          backgroundColor: theme.palette.gray.g25,
          borderRadius: "12px",
        }}
        padding={"16px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={"8px"}
        textAlign={"center"}
        direction={"row"}
        marginBottom={"16px"}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          textAlign={"center"}
          gap="8px"
        >
          <FaPhoneVolume
            fontSize={isAboveMedium ? "1.5rem" : "1.25rem"}
            color={theme.palette.secondary.main}
          />
          <Typography
            variant="h6"
            fontWeight={theme.typography.bold}
            color={theme.palette.gray.g700}
          >
            {t("orderTracking.needAssistance")}
          </Typography>
        </Stack>
        {isAboveMedium ? (
          <Stack sx={{ cursor: "pointer" }}>
            <Typography
              color={theme.palette.secondary.main}
              variant="body1"
              fontWeight={theme.typography.bold}
              sx={{ textTransform: "none", textDecoration: "underline" }}
              onClick={() => window.open("tel:+966920033172")}
            >
              {i18n.language === "ar" ? "966920033172+" : "+966920033172"}
            </Typography>
          </Stack>
        ) : (
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.secondary.light,
              borderRadius: "8px",
              height: "34px",
              gap: "6px",
              ":hover": {
                backgroundColor: theme.palette.secondary.light,
              },
              paddingY: { xs: "4px", md: "8px" },
              paddingX: { xs: "8px", md: "16px" },
            }}
          >
            <Typography
              color={theme.palette.secondary.main}
              variant="body1"
              fontWeight={theme.typography.bold}
              sx={{ textTransform: "none" }}
              onClick={() => window.open("tel:+966920033172")}
            >
              {t("orderTracking.contactUs")}
            </Typography>
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default NeedAssistanceSection;
