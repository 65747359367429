import moment from "moment";

export const formatDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

export const formatDateTime = (date) => {
  return moment(date).format("DD/MM/YYYY hh:mm:ss");
};

export const formatDateShort = (date) => {
  return moment(date).format("DD/MM");
};

export const formatTimeShort = (date) => {
  return moment(date).format("HH:mm");
};

export function ccyFormat(num, max = 2) {
  const price = parseFloat(num);
  return `${price.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: max,
  })}`;
}

export function reduceFeesAmount(amount, commission_rate, refund_rate) {
  const finalAmount =
    amount -
    amount *
      (refund_rate != null ? commission_rate - refund_rate : commission_rate);
  return Number(finalAmount.toFixed(2));
}
