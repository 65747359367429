import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { towerInstance } from "../../../utils/api/axiosConfig";
import { notifyWithError } from "../error/error";
import { formulateTowerError } from "../../../utils/Helpers/formulateTowerError";

const initialState = {
  userAddress: {
    address_id: null,
    company_name: null,
    tax_number: null,
    phone: null,
    country_id: 1,
    administrative_area_id: null,
    city_name: null,
    shipping_company: null,
    default: false,
    note: null,
    address: null,
  },
  isNewUserAddress: false,
  status: "idle",
  fetchAddressStatus: "idle",
  error: null,
};

// export const fetchAddress = createAsyncThunk("cart/fetchAddress", async () => {
//   try {
//     const response = await SingletonClient.getAddress();
//     //if user doesn't have address.
//     if (!("data" in response)) {
//       return response;
//     }
//     //if user have address, then return it
//     return response;
//   } catch (error) {
//     throw Error("Failed to fetch address from the API");
//   }
// });
export const fetchAddress = createAsyncThunk(
  "address/fetchAddress",
  async (thunkAPI) => {
    const response = await towerInstance.get(`address`);

    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

// export const updateAddress = createAsyncThunk(
//   "cart/updateAddress",
//   async (data) => {
//     try {
//       const response = await SingletonClient.updateAddress(data);
//       return response.data;
//     } catch (error) {
//       throw Error("Failed to updating address from the API");
//     }
//   }
// );

export const updateAddress = createAsyncThunk(
  "address/updateAddress",
  async (data, thunkAPI) => {
    const response = await towerInstance.put(`address`, data);

    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data.data;
    }
  }
);

// export const addAddress = createAsyncThunk("cart/addAddress", async (data) => {
//   try {
//     const response = await SingletonClient.addAddress(data);
//     return response.data;
//   } catch (error) {
//     throw Error("Failed to adding a new address from the API");
//   }
// });

export const addAddress = createAsyncThunk(
  "address/addAddress",
  async (data, thunkAPI) => {
    const response = await towerInstance.post(`address`, data);

    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data.data;
    }
  }
);
export const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    resetAddress: (state, action) => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchAddress.fulfilled, (state, action) => {
      state.fetchAddressStatus = "succeeded";
      //If user doesn't have address ( new user )
      if (!("data" in action.payload)) {
        state.isNewUserAddress = true;
      } else if (action.payload !== undefined) {
        state.userAddress.address_id = action.payload.data.id;
        state.userAddress.company_name = action.payload.data.company_name;
        state.userAddress.tax_number = action.payload.data.tax_number;
        state.userAddress.phone = action.payload.data.phone;
        state.userAddress.country_id = 1;
        state.userAddress.administrative_area_id =
          action.payload.data.administrative_area_id;
        state.userAddress.city_name = action.payload.data.city_name;
        state.userAddress.shipping_company =
          action.payload.data.shipping_company;
        state.userAddress.default = action.payload.data.default;
        state.userAddress.note = action.payload.data.note;
        state.userAddress.address = action.payload.data.address;
        state.isNewUserAddress = false;
      }
    });
    builder.addCase(fetchAddress.pending, (state, action) => {
      state.fetchAddressStatus = "loading";
    });
    builder.addCase(fetchAddress.rejected, (state, action) => {
      state.fetchAddressStatus = "failed";
      state.error = action.error.message;
    });
    builder.addCase(updateAddress.fulfilled, (state, action) => {
      state.status = "succeeded";
      if (action.payload !== undefined) {
        state.userAddress.address_id = action.payload.id;
        state.userAddress.company_name = action.payload.company_name;
        state.userAddress.tax_number = action.payload.tax_number;
        state.userAddress.phone = action.payload.phone;
        state.userAddress.country_id = 1;
        state.userAddress.administrative_area_id =
          action.payload.administrative_area_id;
        state.userAddress.city_name = action.payload.city_name;
        state.userAddress.shipping_company = action.payload.shipping_company;
        state.userAddress.default = action.payload.default;
        state.userAddress.note = action.payload.note;
        state.userAddress.address = action.payload.address;
        state.isNewUserAddress = false;
      }
    });
    builder.addCase(updateAddress.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(updateAddress.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });

    builder.addCase(addAddress.fulfilled, (state, action) => {
      state.status = "succeeded";
      if (action.payload !== undefined) {
        state.userAddress.address_id = action.payload.id;
        state.userAddress.company_name = action.payload.company_name;
        state.userAddress.tax_number = action.payload.tax_number;
        state.userAddress.phone = action.payload.phone;
        state.userAddress.country_id = 1;
        state.userAddress.administrative_area_id =
          action.payload.administrative_area_id;
        state.userAddress.city_name = action.payload.city_name;
        state.userAddress.shipping_company = action.payload.shipping_company;
        state.userAddress.default = action.payload.default;
        state.userAddress.note = action.payload.note;
        state.userAddress.address = action.payload.address;
        state.isNewUserAddress = false;
      }
    });
    builder.addCase(addAddress.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(addAddress.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  },
});

export const { resetAddress } = addressSlice.actions;
export default addressSlice.reducer;

export const getUserAddress = (state) => state.addressReducer.userAddress;
export const getAddressStatus = (state) => state.addressReducer.status;
export const getFetchAddressStatus = (state) =>
  state.addressReducer.fetchAddressStatus;
export const getIsNewUserAddress = (state) =>
  state.addressReducer.isNewUserAddress;
