import i18next from "i18next";

const displayOrderStatus = (returnedOrderStatus) => {
    switch (returnedOrderStatus) {
        case "prepare":
            return {
                status: i18next.t("order.prepare"),
                color: "primary",
            };
        case "approved":
            return {
                status: i18next.t("order.approved"),
                color: "primary",
            };
        case "waiting_approvement":
            return {
                status: i18next.t("order.waiting_approvement"),
                color: "error",
            };
        case "pricing":
            return {
                status: i18next.t("order.pricing"),
                color: "primary",
            };
        case "invoice":
            return {
                status: i18next.t("order.invoice"),
                color: "success",
            };
        case "completed":
            return {
                status: i18next.t("order.completed"),
                color: "success",
            };
        default:
            return {
                status: "",
                color: "primary",
            };
    }
};

export default displayOrderStatus;
