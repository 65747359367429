import { Modal, Box, useTheme, useMediaQuery } from "@mui/material";
import VATRegistrationCertificateOne from "../../../assets/images/legal/VATRegistrationCertificateOne.png";
import { useEffect } from "react";
import { IoClose } from "react-icons/io5";

const VATRegistrationCertificateModal = ({ open, onClose }) => {
  //SECTION - genral
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //SECTION - styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "500px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: "0.5rem",
    overflowY: "auto",
  };

  const imgStyle = {
    width: "100%",
    objectFit: "contain",
    pointerEvents: "none",
  };

  //SECTION - useEffect to disable actions on images
  useEffect(() => {
    if (open) {
      const handleContextmenu = (e) => {
        e.preventDefault();
      };
      document.addEventListener("contextmenu", handleContextmenu);
      return () => {
        document.removeEventListener("contextmenu", handleContextmenu);
      };
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        {isSmallScreen && (
          <Box sx={{ cursor: "pointer" }} onClick={onClose}>
            <IoClose fontSize={"1.7rem"} />
          </Box>
        )}
        <img
          src={VATRegistrationCertificateOne}
          alt="VATRegistrationCertificate"
          style={imgStyle}
        />
      </Box>
    </Modal>
  );
};

export default VATRegistrationCertificateModal;
