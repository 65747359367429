const logger = (store) => (next) => (action) => {
    if (process.env.REACT_APP_ENV !== "production") {
        console.group(action.type);
        console.log("The action is ", action);
    }
    //here we dispatch the action
    const returnValue = next(action);
    //after, the state will be updated
    if (process.env.REACT_APP_ENV !== "production") {
        console.log("The new state is ", store.getState());
        console.groupEnd();
    }
    return returnValue;
};
export default logger;
