export const gestureAnimations = {
  whileHover: { scale: 1.05 },
  whileTap: { scale: 0.9 },
};

export const titleVariants = {
  hidden: { opacity: 0, x: 20 },
  visible: (delay) => ({
    x: 0,
    opacity: 1,
    transition: {
      delay: delay,
      duration: 1,
      ease: [0.31, 0.13, 0.28, 1.02],
    },
  }),
};

export const subtitleVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: (delay) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: delay,
      duration: 1,
      ease: [0.31, 0.13, 0.28, 1.02],
    },
  }),
};

export const slideInFromTopVariants = {
  initial: {
    y: -50, // Starts 50 pixels above the final position
    opacity: 0,
  },
  whileInView: {
    y: 0, // Moves to the final position
    opacity: 1,
    transition: {
      duration: 0.8,
    },
  },
};
