import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  LinearProgress,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  IoCloseCircleOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  putCartItems,
  getCartId,
  getCartItems,
  getPutCartStatus,
  getFetchCartStatus,
} from "../../app/features/cart/cart";
import { useDispatch, useSelector } from "react-redux";
import CartItem from "./CartItem";
import { MdRemoveShoppingCart } from "react-icons/md";
import IsEmpty from "../../utils/validation/IsEmpty";
import DeleteAllModal from "./DeleteAllModal";
import { BiError } from "react-icons/bi";
import getTotalItemsQuantity from "../../utils/Helpers/getTotalItemsQuantity";
import LoadingOverlay from "../../components/common/LoadingOverlay";

const CartTable = () => {
  //SECTION - GENERAL
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const timeoutRef = useRef(null);

  //SECTION - useStates
  const [allItems, setAllItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [openDeleteAllModal, setOpenDeleteAllModal] = useState(false);

  //SECTION - Selectors
  const itemsInBasket = useSelector(getCartItems);
  const cartId = useSelector(getCartId);
  const putCartStatus = useSelector(getPutCartStatus);
  const fetchCartStatus = useSelector(getFetchCartStatus);
  const cartItems = useSelector(getCartItems);

  //SECTION - useEffects

  useEffect(() => {
    if (allItems.length === 0) return;

    const debouncedUpdateCart = () => {
      const data = { items: allItems, cartId: cartId };
      dispatch(putCartItems(data));
    };

    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(debouncedUpdateCart, 1000);

    return () => clearTimeout(timeoutRef.current);
  }, [allItems, cartId, dispatch]);

  //SECTION - FUNCTIONS

  const handleOpenDeleteAllModal = () => setOpenDeleteAllModal(true);
  const handleCloseDeleteAllModal = () => setOpenDeleteAllModal(false);

  const deleteItem = (item) => {
    const data = { items: [item], cartId: cartId };
    dispatch(putCartItems(data))
      .then(() => {
        //Delete the item once its deleted, also filter out any duplicated items if there exist.
        const updatedItems = allItems.filter(
          (eachItem) => eachItem.id !== item.id
        );
        setAllItems(updatedItems);
      })
      .catch((error) => {});
  };

  return (
    <Grid container flexWrap={"nowrap"} direction={"column"}>
      <LoadingOverlay isLoading={putCartStatus === "loading"} />
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        pb={theme.spacing(2)}
        position={"relative"}
      >
        <Typography
          color={theme.palette.secondary.dark}
          fontWeight={theme.typography.bold}
        >
          {t("cart.manyItems", { numOfItems: itemsInBasket.length })}
          {" • "}
          {t("cart.manyQuantities", {
            numOfQuantity: getTotalItemsQuantity(itemsInBasket),
          })}
        </Typography>
        <Stack
          direction={
            localStorage.getItem("lng") === "ar" ? "row-reverse" : "row"
          }
          alignItems={"center"}
          onClick={() => {
            cartItems.length !== 0 && handleOpenDeleteAllModal();
          }}
          sx={{ cursor: cartItems.length !== 0 && "pointer" }}
        >
          <IoTrashOutline
            color={
              cartItems.length !== 0
                ? theme.palette.primary.main
                : theme.palette.gray.g400
            }
            fontSize={"1.5rem"}
          />
          <Typography
            fontWeight={theme.typography.bold}
            color={
              cartItems.length !== 0
                ? theme.palette.primary.main
                : theme.palette.gray.g400
            }
          >
            {t("cart.deleteAll")}
          </Typography>
        </Stack>
      </Stack>
      <DeleteAllModal
        handleClose={handleCloseDeleteAllModal}
        open={openDeleteAllModal}
        cartItems={cartItems}
        cartId={cartId}
      />
      <Grid pb={theme.spacing(2)} container>
        <OutlinedInput
          color="primary"
          sx={{
            boxShadow: theme.shadows[3],
            borderRadius: "10px",
            overflow: "hidden",
            height: "50px",
            backgroundColor: theme.palette.white,
            width: "100%",
            px: "1rem",
          }}
          placeholder={t("cart.search")}
          id="search-by-part"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          endAdornment={
            !IsEmpty(searchQuery) && (
              <InputAdornment
                position="start"
                onClick={() => {
                  setSearchQuery("");
                }}
                sx={{ cursor: "pointer" }}
              >
                <IoCloseCircleOutline
                  color={theme.palette.error.main}
                  fontSize={"1.4rem"}
                />
              </InputAdornment>
            )
          }
          startAdornment={
            <InputAdornment position="end" sx={{ paddingX: "0.5rem" }}>
              <IoSearchOutline />
            </InputAdornment>
          }
        />
      </Grid>
      <Box position={"sticky"} zIndex={99} top={85}>
        <Grid
          container
          backgroundColor={theme.palette.gray.g100}
          py={theme.spacing(1)}
          px={theme.spacing(2)}
          alignItems={"center"}
          borderRadius={theme.borderRadius.r10}
        >
          <Grid item xs={5} semiLg={5.5} lg={5.8} xxl={5.8}>
            {t("cart.item")}
          </Grid>
          <Grid
            item
            xs={4.5}
            semiLg={4}
            lg={4}
            xxl={4.5}
            justifyContent={{ xs: "center", lg: "flex-start" }}
          >
            {t("cart.price")}
          </Grid>
          <Grid item xs={2.5} semiLg={2} lg={1.7} xxl={1.2}>
            {t("cart.quantity")}
          </Grid>
        </Grid>
        {/**NOTE - loading bar indicating change in item quantity */}
        <Box width={"100%"} height={"1px"}>
          {putCartStatus === "loading" && (
            <LinearProgress
              color="secondary"
              sx={{ borderRadius: theme.borderRadius.r20 }}
            />
          )}
        </Box>
      </Box>

      {/**NOTE - mapping items */}
      <Stack paddingTop={theme.spacing(1)} gap={theme.spacing(0.5)}>
        {itemsInBasket?.length !== 0 &&
          itemsInBasket
            .filter((eachItem) => {
              const partNumber = eachItem?.item?.part_number;
              const description = eachItem?.item?.description;
              const partNumberMatch = partNumber
                ? partNumber.toLowerCase().includes(searchQuery.toLowerCase())
                : false;
              const partDescriptionMatch = description
                ? description.toLowerCase().includes(searchQuery.toLowerCase())
                : false;

              return partNumberMatch || partDescriptionMatch;
            })
            .map((eachItem) => {
              return (
                eachItem.status === "active" && (
                  <CartItem
                    eachItem={eachItem}
                    setAllItems={setAllItems}
                    deleteItem={deleteItem}
                    key={eachItem.id}
                  />
                )
              );
            })}

        {/*NOTE - if no items in basket, then show user that there is no items*/}
        {itemsInBasket.length === 0 && (
          <Stack
            justifyContent={"center"}
            textAlign={"center"}
            alignItems={"center"}
            height={"500px"}
          >
            {fetchCartStatus === "loading" ? (
              <CircularProgress />
            ) : fetchCartStatus === "failed" ? (
              <>
                <BiError size={"10rem"} color={theme.palette.error.main} />
                <Typography variant="h6" fontWeight={theme.typography.bold}>
                  {t("cart.fetchCartError")}
                </Typography>
              </>
            ) : (
              <>
                <MdRemoveShoppingCart
                  fontSize={"3rem"}
                  color={theme.palette.gray.g400}
                />
                <Typography variant="h6" color={theme.palette.gray.g400}>
                  {t("cart.yourCartIsEmpty")}
                </Typography>
                <Typography
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/");
                  }}
                  fontWeight={theme.typography.bold}
                  color={theme.palette.secondary.main}
                >
                  {t("cart.shopNow")}
                </Typography>
              </>
            )}
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default CartTable;
