import {
  Button,
  Grid,
  TableCell,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import {
  formatDateTime,
  reduceFeesAmount,
} from "../../../../utils/Helpers/general";
import { Stack } from "@mui/system";
import { Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  formatNumericColumns,
  getDocumentTypeStyle,
  getStatusStyle,
  getTypeStyle,
} from "../../utils/styles";
import { RiDownloadLine } from "react-icons/ri";
import { NumericFormat } from "react-number-format";
import AddIcon from "@mui/icons-material/Add";
import { VscFilePdf } from "react-icons/vsc";
import { PiMicrosoftExcelLogo } from "react-icons/pi";
import { useDispatch } from "react-redux";
import { downloadDocument } from "../../../../app/features/wallet/wallet";

const CustomTableCell = ({
  index,
  column,
  row,
  handleOpenEditNoteModal,
  handleOpenRefundModal,
  resendLinkLoading,
  handleSendPaymentLink,
  isTransactions,
  setRefundsOpen,
}) => {
  //SECTION - general
  const theme = useTheme();
  const value = row[column.id];
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //SECTION - useState
  const [openedTooltip, setOpenedTooltip] = useState(null);

  return (
    <TableCell align={column.align} sx={{ maxWidth: column.minWidth }}>
      {column.id === "logo" ? (
        <img
          style={{
            width: "33.75px",
            height: "33.75px",
            objectFit: "contain",
            borderRadius: "50%",
            backgroundColor: "white",
          }}
          src={value}
          alt={"company_logo"}
        />
      ) : column.id === "note" ? (
        <Stack
          direction={"row"}
          gap={"0.5rem"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography color={theme.palette.gray.g600}>
            {value ? value : t("wallet.noNote")}
          </Typography>
          <Tooltip
            arrow
            title={
              <Typography variant="body2">
                {value ? t("wallet.editNote") : t("wallet.createNote")}
              </Typography>
            }
          >
            {value ? (
              <Edit
                color="secondary"
                style={{ cursor: "pointer", fontSize: "1.1rem" }}
                onClick={() => {
                  handleOpenEditNoteModal(
                    row.id,
                    row.orderID,
                    row.clientName,
                    row.price,
                    row.clientNumber,
                    row.note
                  );
                }}
              />
            ) : (
              <AddIcon
                color="secondary"
                style={{ cursor: "pointer", fontSize: "1.1rem" }}
                onClick={() => {
                  handleOpenEditNoteModal(
                    row.id,
                    row.orderID,
                    row.clientName,
                    row.price,
                    row.clientNumber,
                    row.note
                  );
                }}
              />
            )}
          </Tooltip>
        </Stack>
      ) : column.id === "action" ? (
        <>
          {row.status === "completed" &&
            moment(moment.now()).diff(moment(row.transactionDate), "days") <=
              7 && (
              <Grid container flexDirection="column">
                <Button
                  onClick={() => {
                    handleOpenRefundModal(
                      row.id,
                      row.orderID,
                      row.clientName,
                      row.price,
                      row.refunds,
                      row.commissionRate,
                      row.refundRate
                    );
                  }}
                  sx={{
                    borderRadius: theme.borderRadius.r10,
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    paddingX: theme.spacing(2),
                    marginY: theme.spacing(0.3),
                    paddingY: theme.spacing(0.2),
                  }}
                  color="secondary"
                  variant="outlined"
                >
                  <Typography>{t("wallet.createRefund")}</Typography>
                </Button>
              </Grid>
            )}

          {Array.isArray(row.refunds) && row.refunds.length > 0 && (
            <Link
              component="button"
              variant="body1"
              onClick={() => {
                setRefundsOpen((prev) => !prev);
              }}
              color="secondary"
            >
              {t("wallet.showRefunds")}
            </Link>
          )}
        </>
      ) : column.id === "actions" ? (
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          gap={"0.5rem"}
          direction={"row"}
        >
          {row.xlsx_file_id && (
            <Stack
              sx={{ cursor: "pointer", color: theme.palette.secondary.main }}
              onClick={() =>
                dispatch(
                  downloadDocument({
                    id: row.id,
                    fileType: row.type,
                    fileID: row.xlsx_file_id,
                  })
                )
              }
            >
              <PiMicrosoftExcelLogo
                fontSize={"1.3rem"}
                color={theme.palette.success.s800}
              />
            </Stack>
          )}
          {row.pdf_file_id && (
            <Stack
              sx={{ cursor: "pointer", color: theme.palette.secondary.main }}
              onClick={() =>
                dispatch(
                  downloadDocument({
                    id: row.id,
                    fileType: row.type,
                    fileID: row.pdf_file_id,
                  })
                )
              }
            >
              <VscFilePdf
                fontSize={"1.3rem"}
                color={theme.palette.error.main}
              />
            </Stack>
          )}
        </Stack>
      ) : column.id === "transfer_file_url" ? (
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ cursor: "pointer", color: theme.palette.secondary.main }}
          onClick={() => window.open(value)}
        >
          {value ? <RiDownloadLine fontSize={"1.2rem"} /> : "-"}
        </Stack>
      ) : column.id === "status" ? (
        <Stack>
          <Tooltip
            onClick={() => {
              setOpenedTooltip(row.orderID);
              setTimeout(() => {
                setOpenedTooltip(null);
              }, [1000]);
            }}
            open={row.orderID === openedTooltip}
            id={row.orderID}
            onMouseEnter={() => setOpenedTooltip(row.orderID)}
            onMouseLeave={() => setOpenedTooltip(null)}
            arrow
            title={
              <Typography>{t(`wallet.ordersStatuses.${value}`)} </Typography>
            }
          >
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
            >
              <Stack
                width={"fit-content"}
                sx={getStatusStyle(value, theme)}
                px={"0.5rem"}
                borderRadius={theme.borderRadius.r10}
              >
                <Typography style={{ whiteSpace: "nowrap" }}>
                  {t(`wallet.${value}`)}
                </Typography>
              </Stack>
            </Stack>
          </Tooltip>
          {/* 
            SHOW BUTTON ONLY IF: 
            1) Status of transaction is `pending_payment`. 
            2) The date of the payment link (`paymentLinkSentAt`) is not `null`.
            3) At least 2 minutes have passed since the payment link was created.
          */}
          {value === "pending_payment" &&
            row.paymentLinkSentAt !== null &&
            moment().isAfter(
              moment(row.paymentLinkSentAt).add(2, "minutes")
            ) && (
              <Typography
                sx={{
                  textDecoration: "underline",
                  color: theme.palette.secondary.main,
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleSendPaymentLink(row.id);
                }}
              >
                {t(
                  `wallet.${resendLinkLoading[row.id] ? "loading" : "sendSMS"}`
                )}
              </Typography>
            )}
        </Stack>
      ) : column.id === "documentType" ? (
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          direction={"row"}
        >
          <Stack
            width={"fit-content"}
            sx={getDocumentTypeStyle(value, theme)}
            px={"0.5rem"}
            borderRadius={theme.borderRadius.r10}
          >
            <Typography style={{ whiteSpace: "nowrap" }}>
              {t(`wallet.documentsStatuses.${value}`)}
            </Typography>
          </Stack>
        </Stack>
      ) : column.id === "type" ? (
        <Stack gap={"0.2rem"}>
          <Tooltip
            arrow
            title={
              <Typography>
                {row.type === "Service Fee"
                  ? t(`wallet.transactionsStatuses.Service Fee`, {
                      taxAmount: row.tax_amount ? row.tax_amount : "-",
                    })
                  : row.type === "Credit Note"
                  ? t(`wallet.transactionsStatuses.Credit Note`, {
                      taxAmount: row.tax_amount ? row.tax_amount : "-",
                    })
                  : row.withdrawalType === "external_transfer"
                  ? t(`wallet.transactionsStatuses.Withdraw_external_transfer`)
                  : row.withdrawalType === "internal_transfer"
                  ? t(`wallet.transactionsStatuses.Withdraw_internal_transfer`)
                  : row.description && value.includes("Reverse")
                  ? row.description
                  : value.includes("Reverse")
                  ? t(`wallet.transactionsStatuses.reverse operation`)
                  : t(`wallet.transactionsStatuses.${value}`)}
              </Typography>
            }
            onClick={() => {
              setOpenedTooltip(row.orderID);
              setTimeout(() => {
                setOpenedTooltip(null);
              }, [1000]);
            }}
            onMouseEnter={() => setOpenedTooltip(row.orderID)}
            onMouseLeave={() => setOpenedTooltip(null)}
            open={row.orderID === openedTooltip}
            id={row.orderID}
          >
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
            >
              <Stack
                width={"fit-content"}
                sx={getTypeStyle(value, theme)}
                px={"0.5rem"}
                borderRadius={theme.borderRadius.r10}
              >
                <Typography style={{ whiteSpace: "nowrap" }}>
                  {row.withdrawalType === "internal_transfer"
                    ? t(`wallet.internalWithdrawal`)
                    : row.withdrawalType === "external_transfer"
                    ? t(`wallet.externalWithdrawal`)
                    : t(`wallet.${value}`.toLowerCase())}
                </Typography>
              </Stack>
            </Stack>
          </Tooltip>
          {["Service Fee", "Credit Note"].includes(row.type) && (
            <Typography fontSize={"0.7rem"} color={theme.palette.gray.g400}>
              {t(`wallet.VATInclusive`)}
            </Typography>
          )}
        </Stack>
      ) : column.id === "settlementStatus" ? (
        <Tooltip
          onClick={() => {
            setOpenedTooltip(row.settlement_id);
            setTimeout(() => {
              setOpenedTooltip(null);
            }, [1000]);
          }}
          open={row.settlement_id === openedTooltip}
          id={row.settlement_id}
          onMouseEnter={() => setOpenedTooltip(row.settlement_id)}
          onMouseLeave={() => setOpenedTooltip(null)}
          arrow
          title={
            <Typography>{t(`wallet.settlementsStatuses.${value}`)} </Typography>
          }
        >
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
          >
            <Stack
              width={"fit-content"}
              sx={getStatusStyle(value, theme)}
              px={"0.5rem"}
              borderRadius={theme.borderRadius.r10}
            >
              <Typography style={{ whiteSpace: "nowrap" }}>
                {t(
                  `wallet.${
                    value === "completed" ? "settlementCompleted" : value
                  }`
                )}
              </Typography>
            </Stack>
          </Stack>
        </Tooltip>
      ) : [
          "price",
          "balance",
          "sales_amount",
          "refunds_amount",
          "service_fees_amount",
          "vat_transaction_amount",
          "purchases_amount",
          "withdraw_amount",
          "sales_service_fees_amount",
          "credit_note_service_fees_amount",
        ].includes(column.id) ? (
        <NumericFormat
          value={value}
          suffix={` ${t("currency.SAR")} `}
          thousandSeparator=","
          displayType="text"
          type="text"
          renderText={(value) => (
            <Typography variant="body1">{value}</Typography>
          )}
        />
      ) : column.id === "priceAfterFees" ? (
        <NumericFormat
          value={
            isTransactions
              ? row.price - row.commissionAmount
              : reduceFeesAmount(row.price, row.commissionRate, null)
          }
          suffix={` ${t("currency.SAR")} `}
          thousandSeparator=","
          displayType="text"
          type="text"
          renderText={(value) => <Typography>{value}</Typography>}
        />
      ) : [
          "current_settlement_date",
          "previous_settlement_date",
          "transactionDate",
        ].includes(column.id) ? (
        <Typography noWrap>{formatDateTime(value)}</Typography>
      ) : (
        <Typography color={theme.palette.gray.g600}>{value}</Typography>
      )}
    </TableCell>
  );
};

export default CustomTableCell;
