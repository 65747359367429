import * as Sentry from "@sentry/react";

export const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ traceXHR: true, traceFetch: true }),
      Sentry.replayIntegration(),
      Sentry.httpClientIntegration(),
    ],
    tracesSampleRate: 1.0, // Capture 100% of the transactions, adjust in production
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/herokuapp\.com\/api/,
      /^https:\/\/autobia\.com\/api/,
    ],
    beforeBreadcrumb(breadcrumb, hint) {
      // Filter out console breadcrumbs
      if (breadcrumb.category === "console") {
        return null; // Returning null removes the breadcrumb
      }
      // Check for 'xhr' category breadcrumbs
      if (breadcrumb.category === "xhr") {
        const xhr = hint.xhr;

        // Capture the request body
        const requestBody = xhr._requestBody || "No request body";

        // Capture the response body
        let responseBody = "No response body";
        if (xhr.response) {
          // Depending on the response type (text, JSON, etc.), handle accordingly
          if (xhr.responseType === "" || xhr.responseType === "text") {
            responseBody = xhr.responseText || "No response body";
          } else if (xhr.responseType === "json") {
            responseBody = xhr.response || "No response body";
          }
        }

        // Add request and response data to the breadcrumb
        breadcrumb.data = {
          ...breadcrumb.data,
          url: xhr.responseURL,
          method: xhr.__sentry_xhr_v3__.method || xhr.method || "GET",
          status_code: xhr.status,
          request_body: requestBody,
          response_body: responseBody,
        };
      }

      return breadcrumb;
    },
    replaysSessionSampleRate:
      process.env.REACT_APP_ENV === "production" ? 0.1 : 1.0, // Sample 10% of sessions, adjust in production
    replaysOnErrorSampleRate: 1.0, // Capture 100% of sessions with errors
    environment: process.env.REACT_APP_ENV || "development", // Set environment here
  });
};

export const captureException = (error, context = {}) => {
  Sentry.captureException(error, { extra: context });
};

export const setUserContext = (userId, attributes) => {
  Sentry.setUser({ id: userId, ...attributes });
};
