import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "../utils/api/Authentication";

const AuthProtected = ({ children }) => {
    const redirectPath = "/login";
    if (isAuthenticated()) {
        return children ? children : <Outlet />;
    }
    return <Navigate to={redirectPath} replace />;
};
export default AuthProtected;
