import { Box } from "@mui/material";
import BannerModalOffer from "./BannerModalOffer";
import { useState } from "react";

const BannerOffer = ({ eachOffer }) => {
  //SECTION - useState
  const [open, setOpen] = useState(false);

  //SECTION - FUNCTIONS
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box flex={1}>
      <img
        src={eachOffer?.filer?.file}
        alt={eachOffer?.title_en}
        onClick={() => {
          handleOpen();
        }}
        style={{
          objectFit: "contain",
          width: "100%",
          borderRadius: "1rem",
        }}
      />
      <BannerModalOffer
        handleClose={handleClose}
        open={open}
        data={eachOffer}
      />
    </Box>
  );
};

export default BannerOffer;
