import { Box, Stack, useTheme } from "@mui/material";
import React from "react";
import ukFlag from "../../../assets/images/flags/ukFlag.jpg";
import saudiFlag from "../../../assets/images/flags/saudiFlag.png";
import indiaFlag from "../../../assets/images/flags/indiaFlag.png";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUserLang } from "../../../app/features/auth/auth";

const NavbarLang = () => {
  //SECTION - GENERAL
  const { i18n } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const currentLanguage = localStorage.getItem("lng") || "ar";
  const dispatch = useDispatch();

  //SECTION - CUSTOM STYLES
  const imgStyles = (lang) => {
    const isSelected = currentLanguage === lang;
    return {
      maxWidth: "100%",
      height: "100%",
      objectFit: "fill",
      border: isSelected && `2px ${theme.palette.primary.main} solid`,
      borderRadius: theme.borderRadius.r5,
      boxShadow:
        isSelected && `0px 0px 10px 0.1px ${theme.palette.primary.main}`,
    };
  };

  //SECTION - FUNCTIONS
  const changeLanguage = (language) => {
    localStorage.setItem("lng", language);
    i18n.changeLanguage(language);
    //update user lang in DB
    if (localStorage.getItem("watchTowerAccessToken")) {
      dispatch(updateUserLang({ lang: language.toUpperCase() }));
    }
  };

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      px={theme.spacing(2)}
      display={
        location.pathname === "/otp" || location.pathname === "/login"
          ? "flex"
          : { xs: "none", md: "flex" }
      }
    >
      <Box
        height={"40px"}
        width={"fit-content"}
        padding={theme.spacing(1)}
        onClick={() => {
          changeLanguage("ar");
        }}
        sx={{
          cursor: "pointer",
        }}
      >
        <img style={imgStyles("ar")} src={saudiFlag} alt="saudiFlag" />
      </Box>
      <Box
        height={"40px"}
        padding={theme.spacing(1)}
        onClick={() => {
          changeLanguage("hi");
        }}
        sx={{ cursor: "pointer" }}
      >
        <img style={imgStyles("hi")} src={indiaFlag} alt="indiaFlag" />
      </Box>
      <Box
        height={"40px"}
        padding={theme.spacing(1)}
        onClick={() => {
          changeLanguage("en");
        }}
        sx={{ cursor: "pointer" }}
      >
        <img style={imgStyles("en")} src={ukFlag} alt="ukFlag" />
      </Box>
    </Stack>
  );
};

export default NavbarLang;
