import { Grid, Typography } from "@mui/material";
import { Stack, useMediaQuery, useTheme } from "@mui/system";
import React from "react";
import { motion } from "framer-motion";
import {
  gestureAnimations,
  subtitleVariants,
  titleVariants,
} from "../../../../utils/framerMotionStyles/framerMotionStyles";
import { useTranslation } from "react-i18next";

const DetailedStepCard = ({ title, text, icon, number, top, delay }) => {
  //SECTION - general
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const { i18n } = useTranslation();

  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
      sx={{ padding: "0.5rem", position: "relative" }}
    >
      <motion.div {...gestureAnimations}>
        <Stack
          direction={"column"}
          sx={{
            border: `1px solid ${theme.palette.primary.main}`,
            height: "150px",
            borderRadius: theme.borderRadius.r15,
            padding: "1rem",
            position: "relative",
          }}
          gap={"1rem"}
        >
          <motion.div
            variants={titleVariants}
            custom={delay}
            initial="hidden"
            whileInView={"visible"}
            viewport={{ once: true }}
          >
            <Stack direction={"row"} gap={"0.8rem"} alignItems={"flex-end"}>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                textAlign={"center"}
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: theme.borderRadius.r10,
                  width: "40px",
                  height: "40px",
                }}
              >
                {icon}
              </Stack>
              <Typography variant="h6" fontWeight={theme.typography.bold}>
                {title}
              </Typography>
            </Stack>
          </motion.div>
          <motion.div
            variants={subtitleVariants}
            custom={delay}
            initial="hidden"
            whileInView={"visible"}
            viewport={{ once: true }}
          >
            <Stack>
              <Typography
                variant="body1"
                color={theme.palette.secondary.main}
                fontWeight={"500"}
              >
                {text}
              </Typography>
            </Stack>
          </motion.div>
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              position: "absolute",
              bottom: "0.5rem",
              left: i18n.language === "ar" ? "1rem" : "auto",
              right: i18n.language === "en" ? "1rem" : "auto",
            }}
          >
            <Typography
              fontSize={"2rem"}
              sx={{
                fontWeight: theme.typography.bold,
                WebkitTextFillColor: "transparent",
                WebkitTextStroke: `1px ${theme.palette.primary.main}`,
              }}
            >
              {number}
            </Typography>
          </Stack>

          <Stack
            sx={{
              height: "40px",
              position: "absolute",
              right: i18n.language === "en" ? "-2px" : "auto",
              left: i18n.language === "ar" ? "-2px" : "auto",
              top: isLargeScreen ? top : "30px",
              border: `2px solid ${theme.palette.secondary.main}`,
              borderRadius: theme.borderRadius.r25,
            }}
          />
        </Stack>
      </motion.div>
    </Grid>
  );
};

export default DetailedStepCard;
