import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingOverlay = ({ isLoading }) => {
  return (
    <Backdrop
      style={{ zIndex: 1100, color: "#fff", opacity: 0.5 }}
      open={isLoading}
    >
      <CircularProgress />
    </Backdrop>
  );
};

export default LoadingOverlay;
