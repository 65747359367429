import * as React from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { BiSupport, BiLogoTelegram } from "react-icons/bi";
import { IoCall, IoCloseOutline } from "react-icons/io5";
import { RiWhatsappFill } from "react-icons/ri";

const SpeedDialBtn = () => {
    return (
        <SpeedDial
            ariaLabel="SpeedDial openIcon example"
            sx={{ position: "fixed", bottom: 16, right: 16 }}
            icon={
                <SpeedDialIcon
                    icon={<BiSupport fontSize={"1.5rem"} />}
                    openIcon={<IoCloseOutline fontSize={"1.5rem"} />}
                />
            }
        >
            <SpeedDialAction
                key={"whatsapp"}
                icon={<RiWhatsappFill color="#25D366" fontSize={"1.5rem"} />}
                tooltipTitle={"Whatsapp"}
                href="https://wa.me/+966920033172"
            />
            <SpeedDialAction
                key={"telegram"}
                icon={<BiLogoTelegram color="#2AABEE" fontSize={"1.5rem"} />}
                tooltipTitle={"Telegram"}
                href="https://t.me/autobiabot"
            />
            <SpeedDialAction
                key={"call"}
                icon={<IoCall color="red" fontSize={"1.5rem"} />}
                tooltipTitle={"Call us"}
                href="tel:+966920033172"
            />
        </SpeedDial>
    );
};

export default SpeedDialBtn;
