import i18n from "../../i18n";

export const formulateTowerError = (response) => {
    let messageContent = "";
    if (response.type === "error") {
        if (response.errorCode) {
            messageContent += i18n.t(`errorCodes.${response.errorCode}`) + "\n";
        }
        if (response.message) {
            messageContent += response.message + "\n";
        }
        if (response.errorList) {
            Object.keys(response.errorList).forEach((errorType) => {
                response.errorList[errorType].forEach((error) => {
                    messageContent += "\n" + error;
                });
            });
        }
    }

    return messageContent;
};
