import { useTranslation } from "react-i18next";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { FaRegAddressCard } from "react-icons/fa";
import { MdCreditScore, MdPersonAddAlt1 } from "react-icons/md";
import { TbHandTwoFingers } from "react-icons/tb";
import { BiTimer } from "react-icons/bi";
import { TfiStatsUp } from "react-icons/tfi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { IoLocationOutline } from "react-icons/io5";
import { LuWallet } from "react-icons/lu";
import { useTheme } from "@mui/system";

export const usePromotionData = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const stepsStyles = {
    fontSize: "5rem",
    color: theme.palette.secondary.main,
  };

  const steps = [
    {
      title: t("wallet.promotion.steps.oneTitle"),
      text: t("wallet.promotion.steps.oneText"),
      icon: <HiOutlineClipboardDocumentList style={stepsStyles} />,
    },
    {
      title: t("wallet.promotion.steps.twoTitle"),
      text: t("wallet.promotion.steps.twoText"),
      icon: <FaRegAddressCard style={stepsStyles} />,
    },
    {
      title: t("wallet.promotion.steps.threeTitle"),
      text: t("wallet.promotion.steps.threeText"),
      icon: <MdCreditScore style={stepsStyles} />,
    },
  ];

  const advantagesStyles = {
    fontSize: "1.8rem",
    color: "#fff",
  };

  const advantages = [
    {
      title: t("wallet.promotion.advantages.oneTitle"),
      text: t("wallet.promotion.advantages.oneText"),
      icon: <TbHandTwoFingers style={advantagesStyles} />,
      number: "01",
      top: "30px",
    },
    {
      title: t("wallet.promotion.advantages.twoTitle"),
      text: t("wallet.promotion.advantages.twoText"),
      icon: <BiTimer style={advantagesStyles} />,
      number: "02",
      top: "70px",
    },
    {
      title: t("wallet.promotion.advantages.threeTitle"),
      text: t("wallet.promotion.advantages.threeText"),
      icon: <MdPersonAddAlt1 style={advantagesStyles} />,
      number: "03",
      top: "100px",
    },
    {
      title: t("wallet.promotion.advantages.fourTitle"),
      text: t("wallet.promotion.advantages.fourText"),
      icon: <TfiStatsUp style={advantagesStyles} />,
      number: "04",
      top: "30px",
    },
    {
      title: t("wallet.promotion.advantages.fiveTitle"),
      text: t("wallet.promotion.advantages.fiveText"),
      icon: <RiMoneyDollarCircleLine style={advantagesStyles} />,
      number: "05",
      top: "70px",
    },
    {
      title: t("wallet.promotion.advantages.sixTitle"),
      text: t("wallet.promotion.advantages.sixText"),
      icon: <IoLocationOutline style={advantagesStyles} />,
      number: "06",
      top: "100px",
    },
    {
      title: t("wallet.promotion.advantages.sevenTitle"),
      text: t("wallet.promotion.advantages.sevenText"),
      icon: <LuWallet style={advantagesStyles} />,
      number: "07",
      top: "30px",
    },
  ];

  return { steps, advantages };
};
