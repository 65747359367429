import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { GiPartyPopper } from "react-icons/gi";
import { Stack } from "@mui/material";
import Lottie from "react-lottie";
import fireworksLottie from "../../assets/lotties/fireworksLottie.json";
import { jwtDecode } from "jwt-decode";

const RegisterInterestModal = ({
  open,
  handleClose,
  header,
  icon,
  text,
  iconShadow = true,
  showFireworks = true,
  buttonColor = "secondary",
}) => {
  //SECTION - GENERAL
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={handleClose}>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          textAlign: "center",
          width: { xs: "90%", sm: "600px" },
          transform: "translate(-50%, -50%)",
          backgroundColor: "#fff",
          borderRadius: "25px",
        }}
        gap={"0.5rem"}
      >
        <Stack
          sx={{
            width: "100%",
            height: showFireworks ? "230px" : "200px",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {showFireworks && (
            <Stack
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: 0, // ensures Lottie is behind
              }}
            >
              <Lottie
                speed={1}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: fireworksLottie,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
              />
            </Stack>
          )}

          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              padding: iconShadow ? "1.5rem" : "auto",
              borderRadius: iconShadow ? "50%" : "auto",
              boxShadow: iconShadow
                ? `4px 4px 4px ${theme.palette.secondary.main}, -4px -4px 4px ${theme.palette.secondary.main}`
                : "auto",
              backgroundColor: "#fff",
              position: "relative",
              zIndex: 1,
            }}
          >
            {icon || (
              <GiPartyPopper
                fontSize={"8rem"}
                color={theme.palette.secondary.main}
              />
            )}
          </Stack>
        </Stack>

        <Stack sx={{ paddingX: "1rem" }}>
          <Typography fontWeight={600} variant="h5">
            {header || t("labels.thanksForYourInterest")}
          </Typography>
          <Typography variant="h6">
            {text}
            <span dir="ltr">
              {localStorage.getItem("watchTowerAccessToken") != null
                ? jwtDecode(localStorage.getItem("watchTowerAccessToken"))?.user
                    ?.phone
                : "-"}
            </span>
          </Typography>
        </Stack>

        <Stack sx={{ width: "100%", backgroundColor: "secondary" }}>
          <Button
            onClick={() => {
              handleClose();
            }}
            fullWidth
            variant="contained"
            color={buttonColor}
            sx={{
              height: "2.5rem",
              fontSize: { xs: "1rem", sm: "1.6rem" },
              fontWeight: theme.typography.bold,
              borderRadius: "0px 0px 15px 15px",
            }}
          >
            <Typography variant="body1" color="#fff" fontWeight={600}>
              {t("wallet.promotion.acceptRegistering")}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default RegisterInterestModal;
