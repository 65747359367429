import { useTheme } from "@emotion/react";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiCopy, FiCheck } from "react-icons/fi";

const ModifiedBankComponent = ({ name, account, number, IBAN, img }) => {
  // SECTION - GENERAL
  const theme = useTheme();
  const { t } = useTranslation();

  //SECTION - useState
  const [showIcon, setShowIcon] = useState({
    iban: false,
    bankNumber: false,
  }); // State to control the visibility of the icons

  //SECTION - CUSTOM STYLING
  const imgStyles = {
    borderRadius: "10px",
    height: "100px",
    width: "150px",
    objectFit: "contain",
  };

  const copyNumberStyles = {
    flexDirection: "row",
    gap: theme.spacing(1),
    alignItems: "center",
    cursor: "pointer",
  };

  //SECTION - FUNCTIONS
  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text);
    setShowIcon((prevState) => ({ ...prevState, [field]: true }));

    setTimeout(() => {
      setShowIcon((prevState) => ({ ...prevState, [field]: false }));
    }, 2000);
  };

  return (
    <Stack
      bgcolor={"white"}
      direction={"column"}
      borderRadius={theme.borderRadius.r15}
      alignItems={"center"}
      p={theme.spacing(2)}
      width={"100%"}
      boxShadow={theme.shadows[2]}
      border={`2px solid ${theme.palette.gray.g200}`}
      sx={{
        ":hover": {
          border: `2px solid ${theme.palette.primary.main}`,
        },
      }}
    >
      <Stack textAlign={"center"}>
        <img style={imgStyles} alt="ss" src={img} />
        <Typography fontWeight={theme.typography.bold} variant="h6">
          {name}
        </Typography>
      </Stack>
      <Divider
        orientation={"horizontal"}
        flexItem
        sx={{
          my: theme.spacing(2),
          mx: theme.spacing(2),
        }}
      />
      <Stack
        gap={{ xs: theme.spacing(1), sm: theme.spacing(2) }}
        width={"100%"}
      >
        <Grid container>
          <Grid xs={12} semiMd={3}>
            <Typography
              fontWeight={theme.typography.bold}
              color={theme.palette.secondary.main}
              variant="body1"
            >
              {t("payment.accountName")}
            </Typography>
          </Grid>
          <Grid xs={12} semiMd={9} px={theme.spacing(1)}>
            <Typography variant="body1" fontWeight={theme.typography.bold}>
              {account}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={12} semiMd={3}>
            <Typography
              variant="body1"
              fontWeight={theme.typography.bold}
              color={theme.palette.secondary.main}
            >
              {t("payment.bankNumber")}
            </Typography>
          </Grid>
          <Grid xs={12} semiMd={9} px={theme.spacing(1)}>
            <Stack
              sx={copyNumberStyles}
              width={"fit-content"}
              onClick={() => handleCopy(number, "bankNumber")}
            >
              <Typography variant="body1" fontWeight={theme.typography.bold}>
                {number}
              </Typography>
              {showIcon.bankNumber ? (
                <FiCheck color={theme.palette.success.main} />
              ) : (
                <FiCopy color={theme.palette.secondary.main} />
              )}
            </Stack>
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={12} semiMd={3}>
            <Typography
              variant="body1"
              fontWeight={theme.typography.bold}
              color={theme.palette.secondary.main}
            >
              {t("payment.IBAN")}
            </Typography>
          </Grid>
          <Grid xs={12} semiMd={9} px={theme.spacing(1)}>
            <Stack
              sx={copyNumberStyles}
              width={"fit-content"}
              onClick={() => handleCopy(IBAN, "iban")}
            >
              <Typography variant="body1" fontWeight={theme.typography.bold}>
                {IBAN}
              </Typography>
              {showIcon.iban ? (
                <FiCheck color={theme.palette.success.main} />
              ) : (
                <FiCopy color={theme.palette.secondary.main} />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default ModifiedBankComponent;
