import { useEffect, useRef, useState } from "react";
import {
  useTheme,
  Button,
  Typography,
  Input,
  useMediaQuery,
  Tooltip,
  Grid2,
  Stack,
  Popover,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ValidateInputOnlyNum from "../../../utils/validation/ValidateInputOnlyNum";
import FindBrandLogo from "../../../utils/data/FindBrandLogo";
import { getCartItems } from "../../../app/features/cart/cart";
import { digitsArToEn } from "@persian-tools/persian-tools";
import WillBePricedWrapper from "../../../components/common/WillBePricedWrapper";
import { TiArrowUpThick, TiArrowDownThick } from "react-icons/ti";
import { DisplayStatus, getTruncatedDescription } from "../utils/functions";
import { useSmartlook } from "../../../utils/api/smartlook";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { IoClose } from "react-icons/io5";
import RegisterInterestModal from "../../../components/common/RegisterInterestModal";
import { jwtDecode } from "jwt-decode";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { notifyWithError } from "../../../app/features/error/error";
import {
  registerItemInterest,
  resetState,
} from "../../../app/features/registerInterests/registerInterests";
import { formulateTowerError } from "../../../utils/Helpers/formulateTowerError";
import SpecialOrderModal from "../SpecialOrderModal";
import AddAlertIcon from "@mui/icons-material/AddAlert";

const ItemHit = ({ hit, setAllItems, hierarchyLevel }) => {
  //SECTION - GENERAL
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { trackCustomEvent } = useSmartlook();
  const dispatch = useDispatch();

  //SECTION - useState
  const [item, setItem] = useState({});
  const [quantity, setQuantity] = useState(0);
  const [openToolTip, setOpenToolTip] = useState(false);
  const [showReplacements, setShowReplacements] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [registerInterestModalOpen, setRegisterInterestModalOpen] =
    useState(false);
  const [specialOrderModalOpen, setSpecialOrderModalOpen] = useState(false);

  //SECTION - Selectors
  const brands = useSelector((state) => state.brandReducer.brands);
  const cartItems = useSelector(getCartItems);
  const inputRef = useRef(null);
  const registerItemInterestStatus = useSelector(
    (state) => state.registerInterestsReducer.registerItemInterestStatus
  );

  //SECTION - useEffects
  //NOTE - update item
  useEffect(() => {
    const getData = setTimeout(() => {
      if (Object.keys(item).length !== 0) {
        setAllItems((prevItems) => {
          const existingItem = prevItems.find(
            (prevItem) => prevItem.id === item.id
          );

          if (existingItem) {
            // Item already exists, update the quantity
            const newQuantity = item.quantity >= 0 ? item.quantity : 0;

            return prevItems.map((prevItem) => {
              if (prevItem.id === item.id) {
                return { ...prevItem, quantity: newQuantity };
              } else {
                return prevItem;
              }
            });
          } else {
            // Item does not exist, add it to the array
            return [...prevItems, item];
          }
        });
      }
    }, 1000);

    return () => clearTimeout(getData);
  }, [item, setAllItems]);

  //NOTE - set item quantity from cart
  useEffect(() => {
    const found = cartItems.find((eachItem) => eachItem.id === hit.id);
    if (found) {
      setQuantity(found.items_detail.quantity);
    } else {
      setQuantity(0);
    }
  }, [cartItems, hit.id]);

  //SECTION - function
  const { selling_price, color, isActive } = DisplayStatus(
    hit.status,
    hit.selling_price
  );

  const truncatedDescription = getTruncatedDescription(
    hit.description,
    isMobileScreen,
    isLargeScreen
  );

  const handleChangeItemQuantity = (e) => {
    if (e.target.value === "") {
      setQuantity(0);
    } else if (isNaN(e.target.value)) {
      e.preventDefault();
    } else if (parseInt(digitsArToEn(e.target.value)) > 999999) {
      setQuantity((prev) => prev);
    } else {
      setQuantity(parseInt(digitsArToEn(e.target.value)));
      setItem({
        id: hit.id,
        quantity: digitsArToEn(e.target.value),
      });
      if (hierarchyLevel === "two") {
        trackCustomEvent("Add an item to cart from replacements section");
      }
    }
  };

  const handlePopoverOpen = (event) => {
    trackCustomEvent("Customer expressed interest in an unavailable item", {
      part_number: hit?.part_number,
      brand: hit?.brand?.name_en,
      manufacturer: hit?.manufacturer?.name_en,
    });
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleNotifyUserOption = () => {
    dispatch(
      registerItemInterest({ brandItemId: hit.id, special: false })
    ).then((payload) => {
      if (payload.type === "registerInterests/registerItemInterest/fulfilled") {
        trackCustomEvent(
          "Customer clicked on notify me when available option",
          {
            part_number: hit?.part_number,
            brand: hit?.brand?.name_en,
            manufacturer: hit?.manufacturer?.name_en,
          }
        );
        setRegisterInterestModalOpen(true);
      } else {
        dispatch(
          notifyWithError({
            message:
              formulateTowerError(payload.payload) ||
              t("errorCodes.generalErrorTryAgain"),
            type: "error",
          })
        );
      }
    });
  };
  return (
    <Grid2 container size={12}>
      <SpecialOrderModal
        open={specialOrderModalOpen}
        item={hit}
        handleClose={() => {
          setSpecialOrderModalOpen(false);
          setTimeout(() => {
            dispatch(resetState());
          }, 300); // Delay to allow modal animation to complete before state reset
        }}
      />
      <RegisterInterestModal
        open={registerInterestModalOpen}
        handleClose={() => {
          setRegisterInterestModalOpen(false);
          setTimeout(() => {
            dispatch(resetState());
          }, 300); // Delay to allow modal animation to complete before state reset
        }}
        text={t("feedback.itemAvailableNotificationMessage")}
        iconShadow={false}
        showFireworks={false}
        buttonColor={"success"}
        icon={
          <IoMdCheckmarkCircleOutline
            fontSize={"10rem"}
            color={theme.palette.success.main}
          />
        }
      />

      <Grid2
        size={12}
        container
        item
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          backgroundColor: !isActive ? "gray.g25" : "#fff", // Set background to white with 50% opacity
          border: `2px solid ${theme.palette.gray.g200}`,
          borderTopRightRadius: theme.borderRadius.r15,
          borderTopLeftRadius: theme.borderRadius.r15,
          borderBottomLeftRadius:
            hit?.replacements?.length > 0 ? "auto" : theme.borderRadius.r15,
          borderBottomRightRadius:
            hit?.replacements?.length > 0 ? "auto" : theme.borderRadius.r15,
          padding: "0.6rem",

          "&:hover": {
            border: isActive
              ? `2px solid ${theme.palette.secondary.main}`
              : "auto",
            backgroundColor: ` ${theme.palette.gray.g25}`,
          },
        }}
      >
        <Grid2
          size={{ xs: 1, sm: 1 }}
          container
          item
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
          textAlign={"center"}
          alignContent={"center"}
          sx={{ opacity: isActive ? 1 : 0.5 }}
        >
          <img
            style={{
              width: "35px",
              height: "35px",
              objectFit: "contain",
            }}
            src={FindBrandLogo(brands, hit.manufacturer.id)}
            alt={hit.manufacturer.name_en}
          />
          <Typography
            fontSize={{
              xs: "0.5rem",
              semiMd: "0.6rem",
              lg: "0.7rem",
              opacity: isActive ? 1 : 0.5,
            }}
            sx={{
              color: theme.palette.gray.g500,
              fontWeight: theme.typography.bold,
            }}
          >
            {hit.brand.name_en}
          </Typography>
        </Grid2>
        <Grid2
          container
          item
          direction="row"
          size={{ xs: 2.5, sm: 4 }}
          sx={{ paddingX: isMobileScreen ? "0.7rem" : "1rem" }}
        >
          <Grid2
            size={12}
            item
            container
            alignItems={"flex-start"}
            direction={"column"}
            justifyContent={"center"}
          >
            <Typography
              dir="ltr"
              fontWeight={theme.typography.bold}
              variant={isMobileScreen ? "body2" : "body1"}
              whiteSpace={"nowrap"}
              color={!isActive ? "gray.g400" : "auto"}
            >
              {hit.part_number}
            </Typography>
            <Tooltip
              title={
                <Typography variant="body1">{truncatedDescription}</Typography>
              }
              arrow
              open={openToolTip}
              onClick={() => setOpenToolTip(true)}
              onClose={() => setOpenToolTip(false)}
              onMouseEnter={() => setOpenToolTip(true)}
              onMouseLeave={() => setOpenToolTip(false)}
            >
              <Typography
                fontWeight={theme.typography.bold}
                whiteSpace={"nowrap"}
                variant="body2"
                color={isActive ? "secondary.main" : "gray.g400"}
              >
                {truncatedDescription}
              </Typography>
            </Tooltip>
          </Grid2>
        </Grid2>
        <Grid2
          size={{ xs: 8.4, sm: 7 }}
          item
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
          direction={"row"}
          gap={{ xs: "0.2rem", md: "0.5rem" }}
        >
          <WillBePricedWrapper
            selling_price={selling_price}
            Child={() => (
              <Typography
                variant={isMobileScreen ? "body2" : "body1"}
                fontWeight={theme.typography.bold}
                color={color}
                marginInlineEnd={3}
              >
                {selling_price}
              </Typography>
            )}
            id={hit.part_number}
          />

          {isActive ? (
            <>
              <Button
                color="tertiary"
                disabled={quantity === 0}
                onClick={() => {
                  if (quantity > 0) {
                    setQuantity((prevQuantity) => prevQuantity - 1);
                    setItem({
                      id: hit.id,
                      quantity: quantity - 1,
                    });
                  }
                }}
                sx={{
                  padding: "0px",
                  minWidth: "initial",
                  borderRadius: "0.5rem",
                  border: `1px solid ${theme.palette.gray.g200}`,
                  "&:hover": {
                    backgroundColor: theme.palette.gray.g100,
                    border: `1px solid ${theme.palette.gray.g400}`,
                  },
                  backgroundColor: theme.palette.gray.g50,
                  width: { xs: "fit-content", md: "40px" },
                  height: "40px",
                  opacity: quantity === 0 && 0.5,
                }}
              >
                <RemoveIcon sx={{ color: "black" }} />
              </Button>
              <Input
                value={quantity}
                ref={inputRef}
                disableUnderline
                inputProps={{
                  style: {
                    textAlign: "center",
                    padding: "0px",
                    height: "40px",
                  },
                  onKeyPress: ValidateInputOnlyNum, // only enter numbers, no chars
                }}
                sx={{
                  maxWidth: "4rem",
                  fontWeight: theme.typography.bold,
                  border: `1px solid ${theme.palette.gray.g200}`,
                  borderRadius: "0.5rem",
                  backgroundColor: theme.palette.gray.g50,
                  width: { xs: "50px", md: "60px" },
                  height: "40px",
                  padding: "0px",
                }}
                onClick={(e) =>
                  inputRef.current.querySelector("input").select()
                }
                onChange={(e) => handleChangeItemQuantity(e)}
                onPaste={(e) => {
                  e.preventDefault();
                }}
              />
              <Button
                color="tertiary"
                disabled={quantity === 999999}
                onClick={() => {
                  setQuantity((prevQuantity) => prevQuantity + 1);
                  setItem({
                    id: hit.id,
                    quantity: quantity + 1,
                  });
                  if (hierarchyLevel === "two") {
                    trackCustomEvent(
                      "Add an item to cart from replacements section"
                    );
                  }
                }}
                sx={{
                  padding: "0px",
                  minWidth: "initial",
                  borderRadius: "0.5rem",
                  border: `1px solid ${theme.palette.gray.g200}`,
                  "&:hover": {
                    backgroundColor: theme.palette.gray.g100,
                    border: `1px solid ${theme.palette.gray.g400}`,
                  },
                  backgroundColor: theme.palette.gray.g50,
                  width: { xs: "fit-content", md: "40px" },
                  height: "40px",
                  opacity: quantity === 999999 && 0.5,
                }}
              >
                <AddIcon sx={{ color: "black" }} />
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  borderRadius: "10px",
                  direction: "ltr",
                }}
                onClick={handlePopoverOpen}
                onMouseEnter={handlePopoverOpen}
                startIcon={<AddAlertIcon />}
              >
                <Typography variant="body2" fontWeight={600}>
                  {t("labels.options")}
                </Typography>
              </Button>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: i18n.language === "ar" ? "right" : "left",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: i18n.language === "ar" ? "left" : "right",
                }}
                onClose={handlePopoverClose}
                slotProps={{
                  paper: {
                    sx: {
                      background: "#fff",
                      borderRadius: "16px",
                      boxShadow: theme.shadows[4],
                      padding: "1rem",
                    },
                  },
                }}
              >
                <Stack
                  direction={"column"}
                  gap={"0.5rem"}
                  alignItems={"center"}
                  sx={{ minWidth: "200px" }}
                  onMouseLeave={handlePopoverClose}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <Stack />
                    <Typography
                      variant="body1"
                      color="gray.g600"
                      fontWeight={600}
                    >
                      {t("labels.optionsAvailable")}
                    </Typography>
                    <Stack
                      sx={{
                        cursor: "pointer",
                        width: "30px",
                        height: "30px",
                        "&: hover": { backgroundColor: "gray.g200" },
                        borderRadius: "50%",
                      }}
                      justifyContent={"center"}
                      alignItems={"center"}
                      onClick={handlePopoverClose}
                    >
                      <IoClose fontSize={"1.1rem"} />
                    </Stack>
                  </Stack>
                  <Divider flexItem />
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    sx={{
                      color: "white",
                      borderRadius: "12px",
                      padding: "0.5rem",
                      paddingX: "1rem",
                      boxShadow: theme.shadows[4],
                      direction: "ltr",
                    }}
                    startIcon={<NotificationsActiveIcon />}
                    onClick={handleNotifyUserOption}
                    disabled={registerItemInterestStatus === "loading"}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      sx={{ textTransform: "none" }}
                    >
                      {registerItemInterestStatus === "loading"
                        ? t("labels.loading")
                        : t("feedback.notifyMeWhenAvailable")}
                    </Typography>
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      color: "white",
                      borderRadius: "12px",
                      paddingX: "1rem",
                      boxShadow: theme.shadows[4],
                      direction: "ltr",
                    }}
                    startIcon={<ShoppingCartIcon />}
                    onClick={() => {
                      setSpecialOrderModalOpen(true);
                      trackCustomEvent(
                        "Customer clicked on special order option",
                        {
                          part_number: hit?.part_number,
                          brand: hit?.brand?.name_en,
                          manufacturer: hit?.manufacturer?.name_en,
                        }
                      );
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      sx={{ textTransform: "none" }}
                    >
                      {t("home.specialOrder")}
                    </Typography>
                  </Button>
                </Stack>
              </Popover>
            </>
          )}
        </Grid2>
      </Grid2>

      {hit?.replacements?.length > 0 &&
        hierarchyLevel === "one" &&
        hit?.replacements?.some(
          (replacement) => replacement.status === "active"
        ) && (
          <>
            <Stack
              sx={{
                backgroundColor: theme.palette.secondary.light,
                width: "100%",
              }}
            >
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                gap="0.25rem"
                direction={"row"}
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  WebkitBorderBottomRightRadius: "15px",
                  WebkitBorderBottomLeftRadius: "15px",
                  paddingY: "0.3rem",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.dark,
                  },
                }}
                onClick={() => {
                  trackCustomEvent(
                    showReplacements
                      ? `Hided replacements of ${hit?.part_number}`
                      : `Show replacements of ${hit?.part_number}`
                  );
                  setShowReplacements((prev) => !prev);
                }}
              >
                <Typography
                  color="#fff"
                  variant={isMobileScreen ? "body2" : "body1"}
                  fontWeight={600}
                >
                  {t(
                    `home.${
                      showReplacements ? "hideReplacements" : "showReplacements"
                    }`
                  )}
                </Typography>
                {showReplacements ? (
                  <TiArrowUpThick color="#fff" fontSize={"1.2rem"} />
                ) : (
                  <TiArrowDownThick color="#fff" fontSize={"1.2rem"} />
                )}
              </Stack>
            </Stack>
            {showReplacements && (
              <Stack
                direction={"column"}
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.secondary.light,
                  padding: { xs: "0.5rem", md: "1rem" },
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}
              >
                <Typography
                  variant={isMobileScreen ? "body2" : "body1"}
                  color={theme.palette.secondary.main}
                  fontWeight={600}
                >
                  {t("home.itemReplacements")}
                </Typography>
                <Stack marginTop={"0.5rem"} gap={"0.5rem"}>
                  {hit?.replacements
                    ?.filter((replacement) => replacement.status === "active")
                    .map((replacement, index) => (
                      <>
                        <Stack direction={"row"} width={"100%"}>
                          <ItemHit
                            hit={replacement}
                            setAllItems={setAllItems}
                            hierarchyLevel={"two"}
                          />
                        </Stack>
                      </>
                    ))}
                </Stack>
              </Stack>
            )}
          </>
        )}
    </Grid2>
  );
};

export default ItemHit;
