import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import TimelineItem from "@mui/lab/TimelineItem";
import { Stack } from "@mui/system";
import { useTheme } from "@emotion/react";
import { GoDotFill } from "react-icons/go";
import { IoIosCheckmark } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { BiError } from "react-icons/bi";
import { MdAvTimer } from "react-icons/md";

const MobileTimeline = ({ steps, currentStep, currentStepIndex }) => {
  //SECTION - general
  const theme = useTheme();
  const activeStep = currentStepIndex;
  const { t } = useTranslation();

  return (
    <Timeline>
      {steps.map((step, index) => (
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0", width: "0px", height: "0px", display: "none" }}
            align="right"
            variant="body2"
            color="text.secondary"
          ></TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                backgroundColor:
                  currentStep?.status === "orderTracking.statuses.completed"
                    ? theme.palette.success.main
                    : step?.status === "orderTracking.statuses.waitingPayment"
                    ? theme.palette.error.main
                    : index === activeStep
                    ? theme.palette.primary.main
                    : index < activeStep
                    ? theme.palette.success.main
                    : theme.palette.gray.g400,
              }}
            >
              {currentStep?.status === "orderTracking.statuses.completed" ? (
                <IoIosCheckmark fontSize={"1.2rem"} />
              ) : step?.status === "orderTracking.statuses.waitingPayment" ? (
                <BiError fontSize={"1.2rem"} />
              ) : index === activeStep ? (
                <MdAvTimer fontSize={"1.2rem"} />
              ) : index < activeStep ? (
                <IoIosCheckmark fontSize={"1.2rem"} />
              ) : (
                <GoDotFill fontSize={"1.2rem"} />
              )}
            </TimelineDot>
            {index !== steps.length - 1 && (
              <TimelineConnector
                sx={{
                  backgroundColor:
                    index < activeStep
                      ? theme.palette.success.main
                      : theme.palette.gray.g400,
                }}
              />
            )}
          </TimelineSeparator>
          <TimelineContent sx={{ width: "20px" }} style={{ width: "20px" }}>
            <Stack
              width={"100%"}
              justifyContent={"flex-start"}
              direction={"row"}
            >
              <Stack
                direction={"column"}
                justifyContent={"flex-start"}
                textAlign={"start"}
              >
                <Typography
                  fontSize={"14px"}
                  fontWeight={theme.typography.bold}
                >
                  {t(step.status)}
                </Typography>
                <Typography fontSize={"12px"} color={theme.palette.gray.g500}>
                  {t(step.description)}
                </Typography>
              </Stack>
            </Stack>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default MobileTimeline;
