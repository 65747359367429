import ReactMarkdown from "react-markdown";
import {
  Typography,
  Fade,
  Modal,
  Box,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import ButtonComp from "../../../components/common/ButtonComp";
import moment from "moment";
import {
  fetchSpecificOffer,
  selectOffer,
} from "../../../app/features/offer/offer";
import { useDispatch, useSelector } from "react-redux";
import { getSpecificOfferStatus } from "../../../app/features/offer/offer";
import { useNavigate } from "react-router-dom";

//SECTION - CUSTOM STYLES
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: { xs: "90%", sm: "75%" },
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "25px",
  boxShadow: 24,
  p: { xs: 3, sm: 4 },
};

const BannerModalOffer = ({ open, handleClose, data }) => {
  //SECTION - general
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //SECTION - useSelector
  const specificOfferStatus = useSelector(getSpecificOfferStatus);

  //SECTION - GENERAL
  const theme = useTheme();
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("lng");

  //SECTION - functions
  const handleSubmit = () => {
    dispatch(selectOffer(data.action[0].detail.params.offerId));
    dispatch(fetchSpecificOffer(data.action[0].detail.params.offerId)).then(
      () => navigate("/offers")
    );
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box>
            <img
              src={data?.filer?.file}
              alt={data?.title_en}
              style={{
                objectFit: "contain",
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
          <Typography variant="h5" component="h2" mt={theme.spacing(2)}>
            {currentLanguage && currentLanguage === "ar"
              ? data?.title_ar
              : data?.title_en}
          </Typography>
          <Typography
            variant="body2"
            color={theme.palette.gray.g400}
            mt={theme.spacing(1)}
          >
            {moment(data?.expiration_at).format("DD/MM/YYYY")}
          </Typography>
          <Typography variant="body2" my={theme.spacing(4)}>
            <ReactMarkdown>
              {currentLanguage && currentLanguage === "ar"
                ? data?.description_ar
                : data?.description_en}
            </ReactMarkdown>
          </Typography>
          {(data?.button_ar.trim() !== "" || data?.button_en.trim() !== "") && (
            <ButtonComp
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleSubmit}
              content={
                specificOfferStatus === "loading" ? (
                  <CircularProgress size={25} />
                ) : currentLanguage && currentLanguage === "ar" ? (
                  data?.button_ar.toUpperCase()
                ) : (
                  data?.button_en.toUpperCase()
                )
              }
            />
          )}
          <Typography
            variant="body2"
            color={theme.palette.gray.g400}
            textAlign={"center"}
            mt={theme.spacing(1)}
          >
            {t("bannerOffersModal.validTill") +
              moment(data?.expiration_at).format("DD/MM/YYYY")}
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
};

export default BannerModalOffer;
