import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import {
  watchTowerInstance,
  towerInstance,
} from "../../../utils/api/axiosConfig";
import { notifyWithError } from "../error/error";
import { formulateWatchTowerError } from "../../../utils/Helpers/formulateWatchTowerError";
import { formulateTowerError } from "../../../utils/Helpers/formulateTowerError";
import AutobiaLogoImage from "../../../assets/images/logos/autobia_orange_square.png";
import i18next from "i18next";
import i18n from "../../../i18n";

const initialState = {
  wallet: null,
  repFirstName: null,
  repLastName: null,
  status: "idle",
  createInvoiceStatus: "idle",
  validClientStatus: "idle",
  validClient: null,
  getWatchtowerTokenStatus: "idle",
  merchants: null,
  companyBranchId: null,
  getMerchantProvidersStatus: "idle",
  getCompanyBranchIdStatus: "idle",
  getClientWalletStatus: "idle",
  getClientAutobiaWalletStatus: "idle",
  installamentServicesWallet: null,
  getClientBNPLOrdersStatus: "idle",
  getClientBNPLTransactionsStatus: "idle",
  resendPaymentLinkStatus: "idle",
  clientBNPLOrders: null,
  clientBNPLTransactions: null,
  pagination: null,
  errorStatus: null,
  clientWallets: null,
  autobiaWallet: null,
  createRefundState: "idle",
  editNoteState: "idle",
  issuePaymentGatewayStatementState: "idle",
  issuePaymentGatewayStatementExcelState: "idle",
  issuePaymentGatewayOrdersStatementState: "idle",
  paymentGatewayStatement: null,
  getPaymentGatewayStatementState: "idle",
  userCanViewWallet: false,
  registerInterestStatus: "idle",
  settlementData: null,
  getSettlementDataStatus: "idle",
  getClientBNPLDocumentsStatus: "idle",
  documents: null,
  downloadDocumentStatus: "idle",
};

export const getClientBNPLDocuments = createAsyncThunk(
  "wallet/getClientBNPLDocuments",
  async (data, thunkAPI) => {
    let queryParams = `page_size=20&page=${data.pagination}`;

    // Handle file type filter
    if (
      data.filterQuery &&
      data.filterQuery !== "all" &&
      data.filterQuery !== ""
    ) {
      queryParams += `&file_type=${data.filterQuery}`;
    }

    // Handle date_from (start date) with correct ISO 8601 format
    if (data.date_from) {
      const formattedStartDate = new Date(data.date_from).toISOString();
      queryParams += `&start_date=${encodeURIComponent(formattedStartDate)}`;
    }

    // Handle date_to (end date) with correct ISO 8601 format
    if (data.date_to) {
      const formattedEndDate = new Date(data.date_to).toISOString();
      queryParams += `&end_date=${encodeURIComponent(formattedEndDate)}`;
    }

    // API call
    const response = await watchTowerInstance.get(
      `payment-gateway/files/?${queryParams}`
    );

    // Handle response
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const payWithInstallment = createAsyncThunk(
  "wallet/payWithInstallment",
  async (data, thunkAPI) => {
    const response = await watchTowerInstance.post(
      `payment-gateway/pay-with-bnpl-interest/`,
      data
    );

    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const getSettlementData = createAsyncThunk(
  "wallet/getSettlementData",
  async (data, thunkAPI) => {
    // Build query parameters
    let queryParams = `page=${data.pagination}&search=${data.searchQuery}`;
    if (
      data.filterQuery &&
      data.filterQuery !== "all" &&
      data.filterQuery !== ""
    ) {
      queryParams += `&status=${data.filterQuery}`;
    }
    if (data.date_from) {
      queryParams += `&date_from=${encodeURIComponent(data.date_from)}`;
    }
    if (data.date_to) {
      queryParams += `&date_to=${encodeURIComponent(data.date_to)}`;
    }
    // Add response type
    queryParams += `&response_type=${
      data.response_type === "csv" ? "csv" : "json"
    }`;

    const response = await watchTowerInstance.get(
      `payment-gateway/all-settlement/?${queryParams}`
    );

    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      if (data.response_type === "csv") {
        const blob = new Blob([response.data], { type: response.data.type });
        const excelFileURL = URL.createObjectURL(blob);
        return { url: excelFileURL };
      } else {
        return response.data;
      }
    }
  }
);

export const getClientBNPLOrders = createAsyncThunk(
  "wallet/getClientBNPLOrders",
  async (data, thunkAPI) => {
    const response = await watchTowerInstance.get(
      `payment-gateway/client-transaction/?page=${data.pagination}&search=${
        data.searchQuery
      }${
        data.filterQuery &&
        data.filterQuery !== "all" &&
        data.filterQuery !== ""
          ? `&status=${data.filterQuery}`
          : ""
      }`
    );
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const getClientBNPLTransactions = createAsyncThunk(
  "wallet/getClientBNPLTransactions",
  async (data, thunkAPI) => {
    const response = await watchTowerInstance.get(
      `payment-gateway/transactions/?page=${data.pagination}&search=${
        data.searchQuery
      }${
        data.filterQuery &&
        data.filterQuery !== "all" &&
        data.filterQuery !== ""
          ? `&type=${data.filterQuery}`
          : ""
      }`
    );
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const getClientWallet = createAsyncThunk(
  "wallet/getClientWallet",
  async (thunkAPI) => {
    const response = await watchTowerInstance.get(
      `payment-gateway/client-total-wallet/`
    );
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);
export const getClientAutobiaWallet = createAsyncThunk(
  "wallet/getClientAutobiaWallet",
  async (thunkAPI) => {
    const response = await watchTowerInstance.get(
      `payment-gateway/client-autobia-balance/`
    );
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const getMerchantProviders = createAsyncThunk(
  "wallet/getMerchantProviders",
  async (thunkAPI) => {
    const response = await watchTowerInstance.get(
      `payment-gateway/merchant-provider/`
    );
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const getCompanyBranchId = createAsyncThunk(
  "wallet/getCompanyBranchId",
  async (thunkAPI) => {
    const response = await watchTowerInstance.get(
      `customer-company-branch/get/`
    );
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const createInvoice = createAsyncThunk(
  "wallet/createInvoice",
  async (data, thunkAPI) => {
    const response = await watchTowerInstance.post(
      `payment-gateway/create-invoice/`,
      data
    );
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const checkClientValidity = createAsyncThunk(
  "wallet/checkClientValidity",
  async (thunkAPI) => {
    const response = await watchTowerInstance.get(
      `payment-gateway/merchant/check`
    );
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const resendPaymentLink = createAsyncThunk(
  "wallet/resendPaymentLink",
  async (data, thunkAPI) => {
    const response = await watchTowerInstance.post(
      `payment-gateway/resend-payment-link/`,
      data
    );
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const getWatchTowerToken = createAsyncThunk(
  "wallet/getWatchTowerToken",
  async (thunkAPI) => {
    const response = await towerInstance.post(`user/watchtower/customer/token`);

    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const createRefund = createAsyncThunk(
  "wallet/createRefund",
  async (data, thunkAPI) => {
    const response = await watchTowerInstance.post(
      `payment-gateway/create-refund/`,
      data
    );

    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const editNote = createAsyncThunk(
  "wallet/editNote",
  async (data, thunkAPI) => {
    const response = await watchTowerInstance.patch(
      `payment-gateway/${data.id}/update-note/`,
      data.note
    );

    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const issuePaymentGatewayOrdersStatement = createAsyncThunk(
  "wallet/issuePaymentGatewayOrdersStatement",
  async (data, thunkAPI) => {
    const response = await watchTowerInstance.post(
      `payment-gateway/statement/issue-statement/`,
      data,
      {
        responseType: "blob", // Set the responseType to 'blob' to receive binary data as Blob
      }
    );
    let responseData = response.data;

    if (response.headers["content-type"].includes("application/json")) {
      // If it's JSON, parse the response as JSON
      responseData = JSON.parse(await response.data.text());
    }

    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(responseData),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(responseData);
    } else {
      // Create a Blob from the response data
      const blob = new Blob([responseData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }); // Specify the MIME type for Excel file

      return blob;
    }
  }
);
export const issuePaymentGatewayStatementPDF = createAsyncThunk(
  "wallet/issuePaymentGatewayStatement",
  async (data, thunkAPI) => {
    const response = await watchTowerInstance.post(
      `payment-gateway/statement/issue-pdf-statement/`,
      data
    );

    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: response.data.errors[0],
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);
export const issuePaymentGatewayStatementExcel = createAsyncThunk(
  "wallet/issuePaymentGatewayStatementExcel",
  async (data, thunkAPI) => {
    const response = await watchTowerInstance.post(
      `payment-gateway/statement/issue-pdf-statement/?response_type=CSV`,
      data,
      {
        responseType: "blob", // Set the responseType to 'blob' to receive binary data as Blob
      }
    );
    let responseData = response.data;

    if (response.headers["content-type"].includes("application/json")) {
      // If it's JSON, parse the response as JSON
      responseData = JSON.parse(await response.data.text());
    }

    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError({
            context: responseData.errors[0],
          }),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(responseData);
    } else {
      // Create a Blob from the response data
      const blob = new Blob([responseData], {
        type: "text/csv",
      }); // Specify the MIME type for Excel file

      return blob;
    }
  }
);

export const getPaymentGatewayStatement = createAsyncThunk(
  "wallet/getPaymentGatewayStatement",
  async (thunkAPI) => {
    const response = await watchTowerInstance.get(
      `payment-gateway/statement/list-all-statement?page=1`
    );

    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const registerInterest = createAsyncThunk(
  "wallet/registerInterest",
  async (thunkAPI) => {
    const response = await watchTowerInstance.post(
      `payment-gateway/merchant/register-interest/`
    );
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateWatchTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);
export const downloadDocument = createAsyncThunk(
  "wallet/downloadDocument",
  async (data, thunkAPI) => {
    try {
      const response = await watchTowerInstance.get(
        `payment-gateway/files/${data.id}/download/${data.fileType}/${data.fileID}/`,
        {
          responseType: "blob",
        }
      );
      if (response.status < 200 || response.status >= 300) {
        thunkAPI.dispatch(
          notifyWithError({
            message: formulateWatchTowerError(response.data),
            type: "error",
          })
        );
        return thunkAPI.rejectWithValue(response.data);
      } else {
        // Extract the filename from the 'Content-Disposition' header
        const contentDisposition = response.headers["content-disposition"];
        let fileName = data.fileName || "downloaded-file";

        if (contentDisposition && contentDisposition.includes("filename=")) {
          const match = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (match != null && match[1]) {
            fileName = match[1].replace(/['"]/g, ""); // Clean up quotes if any
          }
        }
        // Create a Blob object and download the file
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName || "downloaded-file";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);

        return response.data;
      }
    } catch (error) {
      thunkAPI.dispatch(
        notifyWithError({
          message: "An error occurred while downloading the document.",
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    resetWallet: (state, action) => initialState,
    setUserFirstAndLastName: (state, action) => {
      state.repFirstName = jwtDecode(action.payload).user.first_name ?? "";
      state.repLastName = jwtDecode(action.payload).user.last_name ?? "";
    },
  },
  extraReducers: (builder) => {
    //SECTION - Create invoice
    builder.addCase(getMerchantProviders.fulfilled, (state, action) => {
      state.getMerchantProvidersStatus = "succeeded";
      state.merchants = action?.payload;
    });
    builder.addCase(getMerchantProviders.pending, (state, action) => {
      state.getMerchantProvidersStatus = "loading";
    });
    builder.addCase(getMerchantProviders.rejected, (state, action) => {
      state.getMerchantProvidersStatus = "failed";
      state.errorStatus = action.payload;
    });
    builder.addCase(createInvoice.fulfilled, (state, action) => {
      state.createInvoiceStatus = "succeeded";
    });
    builder.addCase(createInvoice.pending, (state, action) => {
      state.createInvoiceStatus = "loading";
    });
    builder.addCase(createInvoice.rejected, (state, action) => {
      state.createInvoiceStatus = "failed";
      // state.errorStatus = action.payload;
      // if (state.errorStatus === 500) {
      //   window.location.href = "/error/500";
      // }
    });
    builder.addCase(checkClientValidity.fulfilled, (state, action) => {
      if (
        action.payload.context.company_status &&
        action.payload.context.wallet_status
      ) {
        state.validClient = true;
      } else {
        state.validClient = false;
      }
      state.userCanViewWallet = action.payload.context.view_payment_wallet;
      state.validClientStatus = "succeeded";
    });
    builder.addCase(checkClientValidity.pending, (state, action) => {
      state.validClientStatus = "loading";
    });
    builder.addCase(checkClientValidity.rejected, (state, action) => {
      state.validClientStatus = "failed";
      state.errorStatus = action.payload;
    });
    builder.addCase(getWatchTowerToken.fulfilled, (state, action) => {
      localStorage.setItem("watchTowerAccessToken", action.payload.data.access);
      setUserFirstAndLastName(action.payload.data.access);
      state.getWatchtowerTokenStatus = "succeeded";
    });
    builder.addCase(getWatchTowerToken.pending, (state, action) => {
      state.getWatchtowerTokenStatus = "loading";
    });
    builder.addCase(getWatchTowerToken.rejected, (state, action) => {
      state.getWatchtowerTokenStatus = "failed";
      state.errorStatus = action.payload;
      if (state.errorStatus === 500) {
        window.location.href = "/error/500";
      }
    });
    builder.addCase(createRefund.fulfilled, (state, action) => {
      state.createRefundState = "succeeded";
    });
    builder.addCase(createRefund.pending, (state, action) => {
      state.createRefundState = "loading";
    });
    builder.addCase(createRefund.rejected, (state, action) => {
      state.createRefundState = "failed";
      // state.errorStatus = action.payload.status;
      // if (state.errorStatus === 500) {
      //   window.location.href = "/error/500";
      // }
    });
    builder.addCase(editNote.fulfilled, (state, action) => {
      state.editNoteState = "succeeded";
    });
    builder.addCase(editNote.pending, (state, action) => {
      state.editNoteState = "loading";
    });
    builder.addCase(editNote.rejected, (state, action) => {
      state.editNoteState = "failed";
      // state.errorStatus = action.payload.status;
      // if (state.errorStatus === 500) {
      //   window.location.href = "/error/500";
      // }
    });
    builder.addCase(
      issuePaymentGatewayOrdersStatement.fulfilled,
      (state, action) => {
        state.issuePaymentGatewayOrdersStatementState = "succeeded";
      }
    );
    builder.addCase(
      issuePaymentGatewayOrdersStatement.pending,
      (state, action) => {
        state.issuePaymentGatewayOrdersStatementState = "loading";
      }
    );
    builder.addCase(
      issuePaymentGatewayOrdersStatement.rejected,
      (state, action) => {
        state.issuePaymentGatewayOrdersStatementState = "failed";
        // state.errorStatus = action.payload.status;
        // if (state.errorStatus === 500) {
        //   window.location.href = "/error/500";
        // }
      }
    );
    builder.addCase(
      issuePaymentGatewayStatementPDF.fulfilled,
      (state, action) => {
        state.issuePaymentGatewayStatementState = "succeeded";
      }
    );
    builder.addCase(
      issuePaymentGatewayStatementPDF.pending,
      (state, action) => {
        state.issuePaymentGatewayStatementState = "loading";
      }
    );
    builder.addCase(
      issuePaymentGatewayStatementPDF.rejected,
      (state, action) => {
        state.issuePaymentGatewayStatementState = "failed";
        // if (state.errorStatus === 500) {
        //   window.location.href = "/error/500";
        // }
      }
    );
    builder.addCase(
      issuePaymentGatewayStatementExcel.fulfilled,
      (state, action) => {
        state.issuePaymentGatewayStatementExcelState = "succeeded";
      }
    );
    builder.addCase(
      issuePaymentGatewayStatementExcel.pending,
      (state, action) => {
        state.issuePaymentGatewayStatementExcelState = "loading";
      }
    );
    builder.addCase(
      issuePaymentGatewayStatementExcel.rejected,
      (state, action) => {
        state.issuePaymentGatewayStatementExcelState = "failed";
        // if (state.errorStatus === 500) {
        //   window.location.href = "/error/500";
        // }
      }
    );
    builder.addCase(getPaymentGatewayStatement.fulfilled, (state, action) => {
      state.getPaymentGatewayStatementState = "succeeded";
      state.paymentGatewayStatement = action.payload;
    });
    builder.addCase(getPaymentGatewayStatement.pending, (state, action) => {
      state.getPaymentGatewayStatementState = "loading";
    });
    builder.addCase(getPaymentGatewayStatement.rejected, (state, action) => {
      state.getPaymentGatewayStatementState = "failed";
      // state.errorStatus = action.payload.status;
      // if (state.errorStatus === 500) {
      //   window.location.href = "/error/500";
      // }
    });
    builder.addCase(getCompanyBranchId.fulfilled, (state, action) => {
      state.getCompanyBranchIdStatus = "succeeded";
      state.companyBranchId = action.payload?.company_branch_id;
    });
    builder.addCase(getCompanyBranchId.pending, (state, action) => {
      state.getCompanyBranchIdStatus = "loading";
    });
    builder.addCase(getCompanyBranchId.rejected, (state, action) => {
      state.getCompanyBranchIdStatus = "failed";
      // state.errorStatus = action.payload;
      // if (state.errorStatus === 500) {
      //   window.location.href = "/error/500";
      // }
    });
    builder.addCase(getClientWallet.fulfilled, (state, action) => {
      state.getClientWalletStatus = "succeeded";
      state.installamentServicesWallet = 0;
      if (action.payload[0]) {
        action.payload.map(
          (wallet) =>
            (state.installamentServicesWallet += parseFloat(
              wallet.total_amount
            ))
        );
      }
      state.clientWallets = action.payload;
    });
    builder.addCase(getClientWallet.pending, (state, action) => {
      state.getClientWalletStatus = "loading";
    });
    builder.addCase(getClientWallet.rejected, (state, action) => {
      state.getClientWalletStatus = "failed";
      state.errorStatus = action.payload;
    });
    builder.addCase(getClientAutobiaWallet.fulfilled, (state, action) => {
      state.getClientAutobiaWalletStatus = "succeeded";
      state.autobiaWallet = action.payload.autobia_wallet;
    });
    builder.addCase(getClientAutobiaWallet.pending, (state, action) => {
      state.getClientAutobiaWalletStatus = "loading";
    });
    builder.addCase(getClientAutobiaWallet.rejected, (state, action) => {
      state.getClientAutobiaWalletStatus = "failed";
      state.errorStatus = action.payload;
    });
    builder.addCase(resendPaymentLink.fulfilled, (state, action) => {
      state.resendPaymentLinkStatus = "succeeded";
      state.resendPaymentLink = action?.payload?.session_id;
    });
    builder.addCase(resendPaymentLink.pending, (state, action) => {
      state.resendPaymentLinkStatus = "loading";
    });
    builder.addCase(resendPaymentLink.rejected, (state, action) => {
      state.resendPaymentLinkStatus = "failed";
      // state.errorStatus = action.payload?.status;
      // if (state.errorStatus === 500) {
      //   window.location.href = "/error/500";
      // }
    });
    builder.addCase(getClientBNPLOrders.fulfilled, (state, action) => {
      let bnplOrdersArray = [];
      state.getClientBNPLOrdersStatus = "succeeded";
      action.payload.results &&
        action.payload.results.forEach((element) => {
          let tableElement = {
            status: element.status,
            transactionDate: element.created_at
              ? element.created_at
              : moment().subtract(10, "d").format("YYYY-MM-DD"),
            clientNumber: element.consumer_phone,
            clientName: element.consumer_full_name,
            orderID: element.order_identifier,
            logo: element.payment_gateway_type.logo.file,
            price: element.amount,
            paymentLinkSentAt: element.payment_link_sent_at,
            id: element.id,
            refunds: element.refunds,
            commissionRate: element.commission_rate,
            refundRate: element.refund_rate,
            note: element.note ?? "",
          };
          bnplOrdersArray.push(tableElement);
        });
      state.clientBNPLOrders = bnplOrdersArray;
      state.pagination = action.payload.last_count;
    });
    builder.addCase(getClientBNPLOrders.pending, (state, action) => {
      state.getClientBNPLOrdersStatus = "loading";
    });
    builder.addCase(getClientBNPLOrders.rejected, (state, action) => {
      state.getClientBNPLOrdersStatus = "failed";
      // state.errorStatus = action.payload;
      // if (state.errorStatus === 500) {
      //   window.location.href = "/error/500";
      // }
    });
    builder.addCase(getClientBNPLTransactions.fulfilled, (state, action) => {
      let bnplTransactionsArray = [];

      state.getClientBNPLTransactionsStatus = "succeeded";
      action.payload.results &&
        action.payload.results.forEach((element) => {
          let tableElement = {
            type: element.transaction_type.name_en,
            transactionDate: element.transaction_date ?? "-",
            clientNumber:
              element.content_type.id !== 213
                ? element.object_id?.payment_gateway_session?.consumer_phone ??
                  "-"
                : "-",
            clientName:
              element.content_type.id !== 213
                ? element.object_id?.payment_gateway_session
                    ?.consumer_full_name ?? "-"
                : "-",
            orderID:
              element.content_type.id === 213 &&
              element.transaction_type.name_en === "Withdraw"
                ? `${
                    element.description.match(/#-(.+)$/)?.[1] ??
                    element.description
                  }`
                : element.content_type.id === 213
                ? `${element.transaction_type.name_en}-${element.id}`
                : element.content_type.id === 171
                ? element.object_id.refund_reference
                : element.object_id.payment_gateway_session.order_identifier,
            logo:
              element.transaction_type.id < 3 &&
              element.object_id?.payment_gateway_session?.payment_gateway_type
                ? element.object_id.payment_gateway_session.payment_gateway_type
                    ?.logo.file
                : AutobiaLogoImage ?? "",
            price: element.amount,
            id: element.id,
            transaction_type: element.transaction_type.id,
            description:
              i18next.language === "ar" && element.description_ar
                ? element.description_ar
                : element.description,
            tax_amount: element.tax_amount,
            withdrawalType: element.object_id.withdraw_type,
            balance: element.balance,
          };
          bnplTransactionsArray.push(tableElement);
        });
      state.clientBNPLTransactions = bnplTransactionsArray;
      state.pagination = action.payload.last_count;
    });
    builder.addCase(getClientBNPLTransactions.pending, (state, action) => {
      state.getClientBNPLTransactionsStatus = "loading";
    });
    builder.addCase(getClientBNPLTransactions.rejected, (state, action) => {
      state.getClientBNPLTransactionsStatus = "failed";
      // state.errorStatus = action.payload;
      // if (state.errorStatus === 500) {
      //   window.location.href = "/error/500";
      // }
    });
    builder.addCase(registerInterest.fulfilled, (state, action) => {
      state.registerInterestStatus = "succeeded";
    });
    builder.addCase(registerInterest.pending, (state, action) => {
      state.registerInterestStatus = "loading";
    });
    builder.addCase(registerInterest.rejected, (state, action) => {
      state.registerInterestStatus = "failed";
    });
    builder.addCase(payWithInstallment.fulfilled, (state, action) => {
      state.payWithInstallmentStatus = "succeeded";
    });
    builder.addCase(payWithInstallment.pending, (state, action) => {
      state.payWithInstallmentStatus = "loading";
    });
    builder.addCase(payWithInstallment.rejected, (state, action) => {
      state.payWithInstallmentStatus = "failed";
    });
    builder.addCase(getSettlementData.fulfilled, (state, action) => {
      state.getSettlementDataStatus = "succeeded";
      state.pagination = action.payload.last_count;
      state.settlementData = action.payload.results
        ? action.payload?.results?.map((element) => ({
            ...element,
            settlementStatus: element.status,
            sales_service_fees_amount: element?.service_fees_amount,
            credit_note_service_fees_amount: element?.credit_note_amount,
          }))
        : state.settlementData; // Do not change the state if results is not present (in case of downloading csv)
    });

    builder.addCase(getSettlementData.pending, (state, action) => {
      state.getSettlementDataStatus = "loading";
    });
    builder.addCase(getSettlementData.rejected, (state, action) => {
      state.getSettlementDataStatus = "failed";
    });
    builder.addCase(getClientBNPLDocuments.fulfilled, (state, action) => {
      state.getClientBNPLDocumentsStatus = "succeeded";
      state.pagination = action.payload.last_count;
      let formattedDocuments = [];
      action.payload.results.forEach((item) =>
        formattedDocuments.push({
          ...item,
          name: i18n.language === "ar" ? item.name_ar : item.name_en,
          created_at: moment(item.created_at).format("YYYY-MM-DD"),
          documentType: item.type,
        })
      );
      state.documents = formattedDocuments;
    });
    builder.addCase(getClientBNPLDocuments.pending, (state, action) => {
      state.getClientBNPLDocumentsStatus = "loading";
    });
    builder.addCase(getClientBNPLDocuments.rejected, (state, action) => {
      state.getClientBNPLDocumentsStatus = "failed";
    });
    builder.addCase(downloadDocument.fulfilled, (state, action) => {
      state.downloadDocumentStatus = "succeeded";
    });
    builder.addCase(downloadDocument.pending, (state, action) => {
      state.downloadDocumentStatus = "loading";
    });
    builder.addCase(downloadDocument.rejected, (state, action) => {
      state.downloadDocumentStatus = "failed";
    });
  },
});

export default walletSlice.reducer;
export const { resetWallet } = walletSlice.actions;
export const { setUserFirstAndLastName } = walletSlice.actions;
