import { useTheme } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import validation from "../../utils/Helpers/validation";
import { Typography } from "@mui/material";

const StyledDatePicker = ({ value, onChange, title }) => {
  //SECTION - general
  const theme = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Typography
        variant="body2"
        color={theme.palette.gray.g600}
        fontWeight="bold"
        paddingBottom="0.25rem"
      >
        {title}
      </Typography>
      <DatePicker
        value={validation.isEmpty(value) ? moment() : value}
        onChange={onChange}
        slotProps={{
          textField: {
            sx: {
              width: "100%",
              backgroundColor: "white",
              borderRadius: "10px",
              height: "40px",
              padding: "0",
              "& .MuiOutlinedInput-root": {
                height: "100%",
                "& fieldset": {
                  borderRadius: "10px",
                },
                "& .MuiInputBase-input": {
                  padding: "10px 14px",
                  height: "100%",
                  boxSizing: "border-box",
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.secondary.main,
                },
              },
            },
          },
          popper: {
            sx: {
              "& .MuiPickersDay-root": {
                color: theme.palette.text.primary, // Default color
              },
              "& .MuiPickersDay-root.Mui-selected": {
                backgroundColor: theme.palette.secondary.main, // Background color for selected date
                color: "white", // Text color for selected date
                borderRadius: "50%", // Ensures circular shape
                "&:hover": {
                  backgroundColor: theme.palette.secondary.dark, // Darker background on hover
                },
              },
              "& .MuiPickersDay-root.Mui-dayToday": {
                border: `2px solid ${theme.palette.secondary.main}`, // Circle color for today's date
                borderRadius: "50%", // Ensures circular shape
              },
              "& .MuiPickersDay-root.Mui-dayToday.Mui-selected": {
                backgroundColor: theme.palette.secondary.main, // Background color for today's date if selected
                color: "white", // Text color for today's date if selected
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default StyledDatePicker;
