import { useState, useRef, useEffect, React } from "react";
import {
    Grid,
    Typography,
    TextField,
    Stack,
    Box,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import ButtonComp from "../../components/common/ButtonComp";
import googlePlay from "../../assets/images/logos/googlePlay.png";
import appStore from "../../assets/images/logos/appStore.png";
import { digitsArToEn } from "@persian-tools/persian-tools";
import { useDispatch } from "react-redux";
import { loginWithOTP } from "../../app/features/auth/auth";
import { getWatchTowerToken } from "../../app/features/wallet/wallet";

const LoginWithOTP = () => {
    //SECTION - general
    const direction = i18n.language === "ar" ? "ltr" : "inherit";
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useDispatch();

    //SECTION - useState/useRef
    const [otpValues, setOtpValues] = useState(["", "", "", ""]);
    const [invalidOTP, setInvalidOTP] = useState(false);
    const otpInputRefs = useRef([]);

    //SECTION - useEffect
    useEffect(() => {
        if (otpValues.every((value) => value !== "")) {
            setInvalidOTP(false);
        }
    }, [otpValues]);

    //SECTION - functions

    const handleChange = (index, value) => {
        const digitRegex = /^[\d٠١٢٣٤٥٦٧٨٩]$/;

        if (digitRegex.test(value)) {
            const updatedOtpValues = [...otpValues];
            updatedOtpValues[index] = value;
            setOtpValues(updatedOtpValues);

            if (index < otpValues.length - 1) {
                otpInputRefs.current[index + 1].focus();
                otpInputRefs.current[index + 1].select();
            }
        } else if (value === "") {
            const updatedOtpValues = [...otpValues];
            updatedOtpValues[index] = value;
            setOtpValues(updatedOtpValues);

            if (index > 0) {
                otpInputRefs.current[index - 1].focus();
                otpInputRefs.current[index - 1].select();
            }
        }
    };

    const handleSubmit = async () => {
        const isFilled = otpValues.every((value) => value !== "");
        if (isFilled) {
            const otp = otpValues.join("");

            dispatch(loginWithOTP(digitsArToEn(otp))).then((payload) => {
                if (payload.type === "auth/loginWithOTP/fulfilled") {
                    navigate("/home");
                    dispatch(getWatchTowerToken());
                } else {
                    setInvalidOTP(true);
                }
            });
        } else {
            setInvalidOTP(true);
        }
    };

    return (
        <Grid
            container
            justifyContent="center"
            alignItems={{ xs: "", sm: "center", md: "center" }}
            height="100vh"
            marginTop={isMobileScreen && "10%"}
            backgroundColor={{
                xs: "background.default",
                sm: "primary.light",
                md: "primary.light",
            }}
            sx={{ overflow: "hidden" }}
        >
            <Stack
                direction="column"
                spacing={4}
                bgcolor="background.default"
                width={{ xs: "100%", sm: "70%", semiMd: "60%", md: "40%" }}
                height={{ xs: "60%", sm: "65%", semiMd: "65%", md: "70%" }}
                sx={{
                    maxWidth: "800px",
                    maxHeight: "750px",
                    direction: direction,
                }}
                justifyContent="center"
                alignItems="center"
                borderRadius="0.5rem"
                padding="0.5rem"
            >
                <Typography variant="h3" fontWeight="bold">
                    {t("login.header")}
                </Typography>
                <Typography variant="body1">
                    {t("otp.otpwelcomeUser")}
                    <Typography
                        variant="body1"
                        color="secondary.main"
                        align="center"
                    >
                        {localStorage.getItem("phone")}
                    </Typography>
                </Typography>
                <Stack
                    direction="row"
                    gap={"1rem"}
                    textAlign={"center"}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleSubmit();
                        }
                    }}
                >
                    {otpValues.map((value, index) => (
                        <TextField
                            key={index}
                            inputRef={(ref) =>
                                (otpInputRefs.current[index] = ref)
                            }
                            id={`outlined-basic-${index}`}
                            label=""
                            type="tel"
                            variant="outlined"
                            placeholder=""
                            sx={{
                                width: "60px",
                            }}
                            value={value}
                            onChange={(e) =>
                                handleChange(index, e.target.value)
                            }
                            error={invalidOTP}
                        />
                    ))}
                </Stack>
                {invalidOTP && (
                    <Typography color="error.main">
                        {t("otp.verificationRejected")}
                    </Typography>
                )}
                <ButtonComp
                    onClick={handleSubmit}
                    disabled={!otpValues.every((value) => value !== "")}
                    variant="contained"
                    color="secondary"
                    size="large"
                    content={t("login.login")}
                    sx={{
                        width: "80%",
                        maxWidth: "300px",
                    }}
                />
                <Stack
                    direction={"row"}
                    gap={theme.spacing(2)}
                    py={theme.spacing(1)}
                >
                    <Box
                        width={{ xs: "100%", semiSm: "fit-content" }}
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                            window.open(
                                "https://play.google.com/store/apps/details?id=com.autobia.autobia"
                            )
                        }
                    >
                        <img
                            alt="googlePlay"
                            style={{
                                maxWidth: "125px",
                            }}
                            src={googlePlay}
                        />
                    </Box>
                    <Box
                        width={{ xs: "100%", semiSm: "fit-content" }}
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                            window.open(
                                "https://apps.apple.com/sa/app/id1661648763"
                            )
                        }
                    >
                        <img
                            alt="appStore"
                            style={{ maxWidth: "125px" }}
                            src={appStore}
                        />
                    </Box>
                </Stack>
                <Typography>{t("login.copyrights")}</Typography>
            </Stack>
        </Grid>
    );
};

export default LoginWithOTP;
