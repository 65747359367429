import React from "react";
import Slider from "react-slick";
import WalletBalanceCard from "./cards/WalletBalanceCard";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const WalletsSlider = ({ balanceCardWalletObjects }) => {
  //SECTION - general
  const { i18n } = useTranslation();

  //SECTION - useSelector
  const getWalletStatus = useSelector(
    (state) => state.walletReducer.getClientWalletStatus
  );
  const getAutobiaWalletStatus = useSelector(
    (state) => state.walletReducer.getClientAutobiaWalletStatus
  );

  //SECTION - settings
  const sliderSettings = {
    dots: false,
    arrows: false,
    speed: 2000,
    cssEase: "linear",
    infinite: false,
    slidesToShow: 2,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 720,
        settings: {
          currentSlide: 1,
          autoplay: true,
          autoplaySpeed: 5000,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 1,
          dots: true,
        },
      },
    ],
  };
  return (
    <Slider
      {...sliderSettings}
      style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
    >
      {balanceCardWalletObjects &&
        balanceCardWalletObjects.length > 0 &&
        balanceCardWalletObjects.map((wallet) => (
          <WalletBalanceCard
            wallet={wallet}
            key={wallet.index}
            walletLoaded={
              wallet.index === 0
                ? getAutobiaWalletStatus === "succeeded"
                : getWalletStatus === "succeeded"
            }
          />
        ))}
    </Slider>
  );
};

export default WalletsSlider;
