import { FormControlLabel, Popover, Switch, Typography } from "@mui/material";
import React, { useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Box, Stack, useTheme } from "@mui/system";
import { HiExclamation } from "react-icons/hi";
import { useTranslation } from "react-i18next";

const CustomSwitch = ({
  useWalletBalance,
  setUseWalletBalance,
  installmentBalance,
}) => {
  // SECTION - general
  const theme = useTheme();
  const { t } = useTranslation();

  // SECTION - Custom styles
  const StyledSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      backgroundColor: theme.palette.teal.grayTeal,
      transition: "background-color 300ms ease-in-out", // Add smooth transition
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
        color: "white",
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          "white"
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none", // Remove unnecessary shadow
      width: 16,
      height: 16,
      margin: 2,
      backgroundColor: "white",
    },
  }));

  // Use refs to reference the Box and Popover elements
  const boxRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSwitchChange = (e) => {
    if (installmentBalance < 1) {
      setAnchorEl(boxRef.current);
    } else {
      setUseWalletBalance(e.target.checked);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      ref={boxRef} // Reference to the Box
      sx={{ position: "relative" }}
    >
      <FormControlLabel
        control={
          <StyledSwitch
            color="success"
            checked={useWalletBalance}
            onChange={handleSwitchChange}
          />
        }
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            mt: 1, // Adjust this value to ensure the popover appears correctly below the Box
            zIndex: 1300, // Ensure the z-index is higher than other elements
          },
        }}
      >
        <Stack
          direction={{ xs: "column" }}
          padding={"0.5rem"}
          gap={"0.25rem"}
          alignItems={"center"}
          sx={{
            maxWidth: "300px",
          }}
          textAlign={"center"}
        >
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={"white"}
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              border: `2px solid ${theme.palette.gray.g200}`,
            }}
          >
            <HiExclamation fontSize={"2rem"} color={theme.palette.error.main} />
          </Stack>
          <Typography variant="body2" fontWeight={theme.typography.bold}>
            {t("wallet.promotion.noBalance")}
          </Typography>
        </Stack>
      </Popover>
    </Box>
  );
};

export default CustomSwitch;
