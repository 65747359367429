import { Modal, Typography, Box, useTheme } from "@mui/material";
import { BiCheckCircle } from "react-icons/bi";
import ButtonComp from "../../components/common/ButtonComp";
import { useTranslation } from "react-i18next";
import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import getTotalItemsQuantity from "../../utils/Helpers/getTotalItemsQuantity";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    textAlign: "center",
    width: { xs: "90%", sm: "700px" },
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "25px",
    pt: "2rem",
};

const OrderSuccessModal = ({ open, handleClose, orderId, orderItems }) => {
    //SECTION - general
    const theme = useTheme();
    const { t } = useTranslation();

    //SECTION - useState
    const [progress, setProgress] = useState(0);

    //SECTION - useEffect
    useEffect(() => {
        let timer;
        if (open) {
            setProgress(0);
            timer = setInterval(() => {
                setProgress((oldProgress) => {
                    const diff = 5;
                    const newProgress = oldProgress + diff;
                    if (newProgress >= 110) {
                        clearInterval(timer);
                        handleClose();
                        return 120;
                    }
                    return newProgress;
                });
            }, 300);
        } else {
            setProgress(0);
        }
        return () => {
            clearInterval(timer);
        };
    }, [open, handleClose]);

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <BiCheckCircle
                        fontSize={"8rem"}
                        color={theme.palette.success.main}
                    />
                    <Typography
                        fontWeight={theme.typography.bold}
                        fontSize={{ xs: "1.5rem", sm: "2rem" }}
                    >
                        {t("cart.thanksForShopping")}
                    </Typography>
                    <Typography fontSize={"1.5rem"} pt={theme.spacing(4)}>
                        {t("cart.yourOrderNumber")}:
                        <Typography
                            fontWeight={theme.typography.bold}
                            fontSize={"1.5rem"}
                            variant="p"
                        >
                            {orderId}
                        </Typography>
                    </Typography>
                    {/* <Typography fontSize={"1.5rem"}>
            {t("cart.numberOfItems")}: {orderItems.length} {t("cart.items")}
          </Typography> */}
                    <Typography
                        color={theme.palette.secondary.dark}
                        fontWeight={theme.typography.bold}
                        fontSize={"1.5rem"}
                    >
                        {t("cart.manyItems", { numOfItems: orderItems.length })}
                        {" • "}
                        {t("cart.manyQuantities", {
                            numOfQuantity: getTotalItemsQuantity(orderItems),
                        })}
                    </Typography>
                    <Box sx={{ width: "100%", mt: "2rem" }}>
                        <LinearProgress
                            variant="determinate"
                            color="success"
                            value={progress}
                        />
                    </Box>
                    <ButtonComp
                        content={t("cart.done")}
                        color="success"
                        onClick={() => {
                            handleClose();
                        }}
                        fullWidth
                        sx={{
                            height: "3rem",
                            fontSize: { xs: "1rem", sm: "1.6rem" },
                            fontWeight: theme.typography.bold,
                            borderRadius: "0px 0px 15px 15px",
                        }}
                    />
                </Box>
            </Modal>
        </>
    );
};
export default OrderSuccessModal;
