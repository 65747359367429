import { useTheme } from "@emotion/react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { AiFillExclamationCircle } from "react-icons/ai";
import { ccyFormat } from "../../../../utils/Helpers/general";

const WalletBalanceCard = ({ wallet, walletLoaded = true }) => {
  //SECTION - general
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  return (
    <Card
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
      sx={{
        backgroundColor: "white",
        margin: "0.5rem",
        borderRadius: theme.borderRadius.r15,
      }}
    >
      <Stack
        sx={{
          backgroundColor: theme.palette.gray.g75,
          height: "285px",
          borderRadius: theme.borderRadius.r15,
        }}
      >
        {wallet?.payment_gateway_type && (
          <>
            <CardHeader
              title={
                wallet.payment_gateway_type[
                  i18n.language === "ar" ? "name_ar" : "name_en"
                ]
              }
              action={
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    alignItems: "center",
                  }}
                  alt="logo"
                  src={wallet.payment_gateway_type.logo.file}
                />
              }
              sx={{ paddingBottom: 0 }}
            />

            <CardContent>
              {walletLoaded && (
                <>
                  <Grid container justifyContent={"space-between"}>
                    <Grid
                      container
                      alignItems={"center"}
                      direction={"row"}
                      xs={5}
                      gap={1}
                    >
                      <Typography>{t("wallet.balanceCard.balance")}</Typography>
                      <Tooltip
                        title={
                          <Typography>
                            {t("wallet.balanceCard.balanceToolTip")}
                          </Typography>
                        }
                      >
                        <Stack sx={{ cursor: "pointer" }}>
                          <AiFillExclamationCircle
                            color={theme.palette.gray.g400}
                          />
                        </Stack>
                      </Tooltip>
                    </Grid>
                    <Grid container xs={7} justifyContent={"flex-end"}>
                      <Typography
                        fontWeight={theme.typography.bold}
                        fontSize={theme.spacing(2.8)}
                        color={
                          parseFloat(wallet.available_amount) < 0
                            ? theme.palette.error.main
                            : theme.palette.tertiary.main
                        }
                      >
                        {ccyFormat(
                          parseFloat(wallet.available_amount).toFixed(2)
                        ) +
                          " " +
                          t("currency.SAR")}
                      </Typography>
                    </Grid>
                  </Grid>
                  {wallet.isBNPLwallet && (
                    <>
                      {/* <Grid
                  container
                  justifyContent={"space-between"}
                  sx={{ marginTop: theme.spacing(2) }}
                >
                  <Grid item xs={7}>
                    <Typography
                      color={theme.palette.gray.g500}
                      fontSize={"0.8rem"}
                    >
                      {t("wallet.balanceCard.withdrawnToBank")}
                    </Typography>
                  </Grid>
                  <Grid item xs={5} justifyContent={"flex-end"}>
                    <Typography
                      fontSize={theme.spacing(2)}
                      fontWeight={theme.typography.bold}
                      textAlign={"end"}
                    >
                      {ccyFormat(
                        parseFloat(wallet.withdraw_transfer).toFixed(2)
                      ) +
                        " " +
                        t("currency.SAR")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={7}>
                    <Typography
                      color={theme.palette.gray.g500}
                      fontSize={"0.8rem"}
                    >
                      {t("wallet.balanceCard.withdrawnToAutobiaWallet")}
                    </Typography>
                  </Grid>
                  <Grid item xs={5} justifyContent={"flex-end"}>
                    <Typography
                      fontSize={theme.spacing(2)}
                      fontWeight={theme.typography.bold}
                      textAlign={"end"}
                    >
                      {ccyFormat(
                        parseFloat(wallet.withdraw_payment).toFixed(2)
                      ) +
                        " " +
                        t("currency.SAR")}
                    </Typography>
                  </Grid>
                </Grid> */}
                      <Grid container justifyContent={"space-between"}>
                        <Grid item xs={7}>
                          <Typography
                            color={theme.palette.gray.g500}
                            fontSize={"0.8rem"}
                          >
                            {t("wallet.balanceCard.withdrawalsToBankAccount")}
                          </Typography>
                        </Grid>
                        <Grid item xs={5} justifyContent={"flex-end"}>
                          <Typography
                            fontSize={theme.spacing(2)}
                            fontWeight={theme.typography.bold}
                            textAlign={"end"}
                          >
                            {ccyFormat(
                              parseFloat(
                                wallet.total_external_transfer
                              ).toFixed(2)
                            ) +
                              " " +
                              t("currency.SAR")}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent={"space-between"}>
                        <Grid item xs={7}>
                          <Typography
                            color={theme.palette.gray.g500}
                            fontSize={"0.8rem"}
                          >
                            {t("wallet.balanceCard.withdrawalsToAutobiaWallet")}
                          </Typography>
                        </Grid>
                        <Grid item xs={5} justifyContent={"flex-end"}>
                          <Typography
                            fontSize={theme.spacing(2)}
                            fontWeight={theme.typography.bold}
                            textAlign={"end"}
                          >
                            {ccyFormat(
                              parseFloat(
                                wallet.total_internal_transfer
                              ).toFixed(2)
                            ) +
                              " " +
                              t("currency.SAR")}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent={"space-between"}>
                        <Grid item xs={7}>
                          <Typography
                            color={theme.palette.gray.g500}
                            fontSize={"0.8rem"}
                          >
                            {t("wallet.balanceCard.withdrawnTotal")}
                          </Typography>
                        </Grid>
                        <Grid item xs={5} justifyContent={"flex-end"}>
                          <Typography
                            fontSize={theme.spacing(2)}
                            fontWeight={theme.typography.bold}
                            textAlign={"end"}
                          >
                            {ccyFormat(
                              parseFloat(wallet.withdraw_payment).toFixed(2)
                            ) +
                              " " +
                              t("currency.SAR")}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent={"space-between"}>
                        <Grid item xs={7}>
                          <Typography
                            color={theme.palette.gray.g500}
                            fontSize={"0.8rem"}
                          >
                            {t("wallet.balanceCard.totalTransactions")}
                          </Typography>
                        </Grid>
                        <Grid item xs={5} justifyContent={"flex-end"}>
                          <Typography
                            fontSize={theme.spacing(2)}
                            fontWeight={theme.typography.bold}
                            textAlign={"end"}
                          >
                            {ccyFormat(
                              parseFloat(wallet.total_amount).toFixed(2)
                            ) +
                              " " +
                              t("currency.SAR")}
                          </Typography>
                        </Grid>
                      </Grid>
                      {wallet.bnplWallets && (
                        <>
                          <Divider sx={{ marginY: theme.spacing(1) }} />
                          {wallet.bnplWallets.map(
                            (bnplWallet) =>
                              bnplWallet.payment_gateway_type && (
                                <Grid
                                  container
                                  justifyContent={"space-between"}
                                >
                                  <Grid item xs={7}>
                                    <Typography
                                      color={theme.palette.gray.g500}
                                      fontSize={"0.8rem"}
                                    >
                                      {`${t(
                                        "wallet.balanceCard.totalTransactions"
                                      )} (${
                                        bnplWallet.payment_gateway_type[
                                          `name_${i18n.language}`
                                        ]
                                      })`}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={5} justifyContent={"flex-end"}>
                                    <Typography
                                      fontSize={theme.spacing(2)}
                                      fontWeight={theme.typography.bold}
                                      textAlign={"end"}
                                    >
                                      {ccyFormat(
                                        parseFloat(
                                          bnplWallet.total_amount
                                        ).toFixed(2)
                                      ) +
                                        " " +
                                        t("currency.SAR")}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )
                          )}
                        </>
                      )}
                    </>
                  )}
                  {!wallet.isBNPLwallet && (
                    <Grid
                      container
                      justifyContent={"space-between"}
                      sx={{ marginTop: theme.spacing(2) }}
                    >
                      <Grid item xs={7}>
                        <Typography
                          color={theme.palette.gray.g500}
                          fontSize={"0.8rem"}
                        >
                          {t("wallet.balanceCard.cashbackBalance")}
                        </Typography>
                      </Grid>
                      <Grid item xs={5} justifyContent={"flex-end"}>
                        <Typography
                          fontSize={theme.spacing(2)}
                          fontWeight={theme.typography.bold}
                          textAlign={"end"}
                        >
                          {ccyFormat(parseFloat(wallet.cashback).toFixed(2)) +
                            " " +
                            t("currency.SAR")}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {!walletLoaded && (
                <Grid>
                  <Typography justifyContent={"center"} alignItems={"center"}>
                    {t("wallet.balanceCard.loadingFailed")}
                  </Typography>
                </Grid>
              )}
            </CardContent>
          </>
        )}
      </Stack>
    </Card>
  );
};

export default WalletBalanceCard;
