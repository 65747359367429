import { OutlinedInput, Select, Typography } from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";

const StyledSelect = ({
  value,
  selectItems,
  onChange,
  title,
  renderedValue,
}) => {
  //SECTION - general
  const theme = useTheme();
  const { i18n } = useTranslation();

  //SECTION - styles
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Stack sx={{ width: "100%" }}>
      <Typography
        variant={"body2"}
        color={theme.palette.gray.g600}
        fontWeight={"bold"}
        paddingBottom={"0.25rem"}
      >
        {title}
      </Typography>
      <Select
        displayEmpty
        value={value}
        onChange={onChange}
        fullWidth
        color="secondary"
        input={
          <OutlinedInput
            color="secondary"
            inputProps={{
              sx: {
                padding: "9px",
                backgroundColor: "white",
              },
            }}
          />
        }
        MenuProps={MenuProps}
        sx={{
          borderRadius: theme.borderRadius.r10,
          height: "40px",
          overflow: "hidden",
          "& .MuiSelect-icon": {
            left: i18n.language === "ar" ? 0 : "auto",
            right: i18n.language === "ar" ? "auto" : 0,
          },
        }}
      >
        {selectItems}
        {/* {purchaseOrderTypes.map((warehouse) => (
          <MenuItem value={warehouse.value}>
            <em>{warehouse[`name_${i18n.languages[0]}`]}</em>
          </MenuItem>
        ))} */}
      </Select>
    </Stack>
  );
};

export default StyledSelect;
