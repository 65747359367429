import i18n from "../../i18n";

export const formulateWatchTowerError = (response) => {
    let messageContent = "";
    if (!response?.status) {
        if (response?.context) {
            messageContent += response.context + "\n";
        }
        if (response.errors) {
            if (Array.isArray(response.errors)) {
                response.errors.forEach((errorObj) => {
                    // Iterate over each key-value pair in the error object
                    Object.entries(errorObj).forEach(([errorType, errors]) => {
                        errors.forEach((error) => {
                            // Append both the error type and the error message
                            messageContent += "\n" + errorType + ": " + error;
                        });
                    });
                });
            } else {
                Object.keys(response.errors).forEach((errorType) => {
                    response.errors[errorType].forEach((error) => {
                        messageContent += "\n" + errorType + ": " + error;
                    });
                });
            }
        }
    }
    if (response.detail) {
        messageContent += response.detail + "\n";
    }
    if (response.message) {
        messageContent += response.message + "\n";
    }
    if (messageContent === "") {
        messageContent = i18n.t("errorCodes.generalError");
    }
    return messageContent;
};
