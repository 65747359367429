import { useTheme } from "@emotion/react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import StyledSelect from "../../components/common/StyledSelect";
import StyledInput from "../../components/common/StyledInput";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { registerItemInterest } from "../../app/features/registerInterests/registerInterests";
import FindBrandLogo from "../../utils/data/FindBrandLogo";
import { jwtDecode } from "jwt-decode";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useSmartlook } from "../../utils/api/smartlook";

const SpecialOrderModal = ({ open, handleClose, item }) => {
  // SECTION - general
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { trackCustomEvent } = useSmartlook();

  // SECTION - useSelector
  const registerItemInterestStatus = useSelector(
    (state) => state.registerInterestsReducer.registerItemInterestStatus
  );
  const brands = useSelector((state) => state.brandReducer.brands);

  // SECTION - formik
  const validationSchema = yup.object({
    unitsNeeded: yup
      .number()
      .typeError(
        t("formValidation.number", {
          field: t("labels.numberOfUnitsNeeded"),
        })
      )
      .required(
        t("formValidation.required", { field: t("labels.numberOfUnitsNeeded") })
      )
      .min(
        1,
        t("formValidation.min", {
          field: t("labels.numberOfUnitsNeeded"),
          min: 1,
        })
      )
      .max(
        99999,
        t("formValidation.max", {
          field: t("labels.numberOfUnitsNeeded"),
          max: 99999,
        })
      ),
    expectedPrice: yup
      .number(
        t("formValidation.number", { field: t("labels.expectedPricePerUnit") })
      )
      .typeError(
        t("formValidation.number", {
          field: t("labels.expectedPricePerUnit"),
        })
      )
      .required(
        t("formValidation.required", {
          field: t("labels.expectedPricePerUnit"),
        })
      )
      .min(
        0.01,
        t("formValidation.min", {
          field: t("labels.expectedPricePerUnit"),
          min: 0.01,
        })
      )
      .max(
        99999.99,
        t("formValidation.max", {
          field: t("labels.expectedPricePerUnit"),
          max: 99999.99,
        })
      ),
  });
  const formik = useFormik({
    initialValues: {
      unitsNeeded: "",
      expectedPrice: "",
      neededIn: "1-2 weeks",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        registerItemInterest({
          brandItemId: item.id,
          expected_price: values.expectedPrice,
          time_needed: values.neededIn,
          needed_quantity: values.unitsNeeded,
          special: true,
        })
      );
      trackCustomEvent("Customer confirmed a special order", {
        part_number: item?.part_number,
        brand: item?.brand?.name_en,
        manufacturer: item?.manufacturer?.name_en,
      });
    },
  });

  //SECTION - functions
  const handleCloseModal = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <Dialog
      onClose={handleCloseModal}
      open={open}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "15px",
          padding: "1.5rem",
          maxWidth: "500px",
          width: "90vw",
          gap: "0.5rem",
        },
        "& .MuiDialogTitle-root": {
          padding: "0px",
        },
        "& .MuiDialogContent-root": {
          padding: "0rem",
          marginTop: "0.5rem",
        },
        "& .MuiDialogActions-root": {
          padding: "0px",
          marginTop: "0.5rem",
        },
      }}
    >
      <DialogTitle>
        <Stack
          justifyContent={"space-between"}
          direction={"row"}
          alignItems={"center"}
        >
          <Stack />
          <Typography fontWeight={theme.typography.bold} variant="h6">
            {t("home.specialOrder")}
          </Typography>
          <Stack sx={{ cursor: "pointer" }} onClick={handleCloseModal}>
            <AiOutlineClose fontSize={"1.25rem"} />
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {registerItemInterestStatus === "succeeded" ? (
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              backgroundColor: "#fff",
              position: "relative",
              zIndex: 1,
            }}
            textAlign={"center"}
            gap={"0.5rem"}
          >
            <IoMdCheckmarkCircleOutline
              fontSize={"10rem"}
              color={theme.palette.success.main}
            />
            <Stack sx={{ paddingX: "1rem" }}>
              <Typography fontWeight={600} variant="h5">
                {t("labels.thanksForYourInterest")}
              </Typography>
              <Typography variant="body1">
                {t("feedback.specialOrderConfirmationMessage")}
                <span dir="ltr">
                  {localStorage.getItem("watchTowerAccessToken") != null
                    ? jwtDecode(localStorage.getItem("watchTowerAccessToken"))
                        ?.user?.phone
                    : "-"}
                </span>
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack gap={"0.5rem"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={"2rem"}
              sx={{
                backgroundColor: theme.palette.gray.g25,
                border: `2px solid ${theme.palette.gray.g200}`,
                borderRadius: "10px",
                paddingX: "1rem",
                paddingY: "0.5rem",
              }}
            >
              <Stack
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <img
                  style={{
                    width: "35px",
                    height: "35px",
                    objectFit: "contain",
                  }}
                  src={FindBrandLogo(brands, item.manufacturer.id)}
                  alt={item.manufacturer.name_en}
                />
                <Typography
                  fontSize={{
                    xs: "0.5rem",
                    semiMd: "0.6rem",
                    lg: "0.7rem",
                  }}
                  sx={{
                    color: theme.palette.gray.g500,
                    fontWeight: theme.typography.bold,
                  }}
                >
                  {item.brand.name_en}
                </Typography>
              </Stack>
              <Stack
                direction={"column"}
                textAlign={i18n.language === "ar" ? "end" : "start"}
              >
                <Typography
                  dir="ltr"
                  fontWeight={theme.typography.bold}
                  variant={"body1"}
                  whiteSpace={"nowrap"}
                >
                  {item.part_number}
                </Typography>
                <Typography
                  textAlign={"start"}
                  fontWeight={theme.typography.bold}
                  variant="body2"
                  color={"secondary.main"}
                >
                  {item.description}
                </Typography>
              </Stack>
            </Stack>
            <StyledInput
              title={t("labels.numberOfUnitsNeeded")}
              name="unitsNeeded"
              placeholder={t("placeholders.itemsCount")}
              icon={
                <Typography fontWeight={600} color="secondary" variant="body1">
                  {t("labels.item")}
                </Typography>
              }
              value={formik.values.unitsNeeded}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.unitsNeeded && Boolean(formik.errors.unitsNeeded)
              }
              helperText={
                formik.touched.unitsNeeded && formik.errors.unitsNeeded
              }
            />
            <StyledInput
              title={t("labels.expectedPricePerUnit")}
              placeholder={t("placeholders.price")}
              name="expectedPrice"
              icon={
                <Typography fontWeight={600} color="secondary" variant="body1">
                  {t("currency.SAR")}
                </Typography>
              }
              value={formik.values.expectedPrice}
              onChange={formik.handleChange}
              error={
                formik.touched.expectedPrice &&
                Boolean(formik.errors.expectedPrice)
              }
              helperText={
                formik.touched.expectedPrice && formik.errors.expectedPrice
              }
            />
            <StyledSelect
              title={t("labels.requestedIn")}
              selectItems={["immediately", "1-2 weeks", "any time"].map(
                (option) => (
                  <MenuItem key={option} value={option}>
                    {t(`home.requiredInOptions.${option}`)}
                  </MenuItem>
                )
              )}
              name="neededIn"
              value={formik.values.neededIn}
              onChange={(e) => {
                formik.setFieldValue("neededIn", e.target.value);
              }}
            />
          </Stack>
        )}

        <DialogActions>
          {registerItemInterestStatus === "succeeded" ? (
            <Button
              color="success"
              variant="contained"
              fullWidth
              onClick={handleCloseModal}
              sx={{ borderRadius: "10px", marginTop: "0.25rem" }}
            >
              <Typography variant="body1" fontWeight={600}>
                {t("actions.accept")}
              </Typography>
            </Button>
          ) : (
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              disabled={
                registerItemInterestStatus === "loading" ||
                Object.keys(formik.errors).length > 0 ||
                !formik.values.unitsNeeded ||
                !formik.values.expectedPrice
              }
              onClick={formik.handleSubmit}
              sx={{ borderRadius: "10px", marginTop: "0.25rem" }}
            >
              <Typography variant="body1" fontWeight={600}>
                {registerItemInterestStatus === "loading"
                  ? t("labels.loading")
                  : t("actions.confirm")}
              </Typography>
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default SpecialOrderModal;
