import {
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
  useTheme,
} from "@mui/material";
import { IoSearchOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useSearchBox } from "react-instantsearch";
import { MdPictureAsPdf } from "react-icons/md";
import { useSelector } from "react-redux";
import { currentlySelectedOffer } from "../../../app/features/offer/offer";

const CustomSearchBox = (props) => {
  //SECTION - algolia related
  const { refine } = useSearchBox(props);

  //SECTION - general
  const { t } = useTranslation();
  const theme = useTheme();

  //SECTION - useSelector
  const currentOffer = useSelector(currentlySelectedOffer);

  return (
    <>
      <Grid
        container
        paddingX={{ xs: "0.2rem", semiMd: "0.3rem", xxl: "1rem" }}
        direction={"column"}
        xs={12}
        justifyContent={"center"}
        sx={{ height: "60px" }}
      >
        <Grid
          container
          xs={9.5}
          md={10}
          lg={10.5}
          xl={11}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <OutlinedInput
            color="primary"
            sx={{
              borderRadius: "10px",
              overflow: "hidden",
              backgroundColor: theme.palette.white,
              width: "100%",
              height: "40px",
            }}
            onChange={(event) => refine(event.currentTarget.value)}
            placeholder={t("home.search")}
            id="search-by-part"
            endAdornment={
              <InputAdornment position="end">
                <IoSearchOutline />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid
          container
          xs={2.5}
          md={2}
          lg={1.5}
          xl={1}
          justifyContent={"center"}
          direction={"row"}
          alignItems={"center"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (currentOffer) {
              const getPdfFile = currentOffer.filer_list.find(
                (e) => e.type.id === 3
              );
              window.open(getPdfFile.file);
            }
          }}
        >
          <Typography fontSize={"1rem"} color={theme.palette.primary.main}>
            {t("offers.downloadPDF")}
          </Typography>
          <MdPictureAsPdf
            color={theme.palette.primary.main}
            fontSize={"2rem"}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default CustomSearchBox;
