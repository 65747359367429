import { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  putCartItems,
  getCartId,
  getPutCartStatus,
} from "../../../app/features/cart/cart";
import { useInfiniteHits, useInstantSearch } from "react-instantsearch";
import { useTranslation } from "react-i18next";
import ItemHit from "./ItemHit";
import LoadingOverlay from "../../../components/common/LoadingOverlay";

const CustomInfiniteHits = (props) => {
  //SECTION - algolia related
  const { hits, showMore, isLastPage } = useInfiniteHits({
    ...props,
  });
  const { status } = useInstantSearch();
  const timeoutRef = useRef(null);

  //SECTION - general
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();

  //SECTION - useState
  const [allItems, setAllItems] = useState([]);

  //SECTION - useSelector
  const cartId = useSelector(getCartId);
  const cartLoading = useSelector(getPutCartStatus);

  //SECTION - useEffect
  useEffect(() => {
    if (allItems.length === 0) return;

    const debouncedUpdateCart = () => {
      const data = { items: allItems, cartId: cartId };
      dispatch(putCartItems(data));
    };

    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(debouncedUpdateCart, 1000);

    return () => clearTimeout(timeoutRef.current);
  }, [allItems, cartId, dispatch]);

  return (
    <>
      <LoadingOverlay isLoading={cartLoading === "loading"} />
      {status !== "idle" ? (
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"600px"}
        >
          <CircularProgress size={100} color="primary" />
        </Grid>
      ) : (
        <Stack
          paddingTop={theme.spacing(2)}
          gap={theme.spacing(0.6)}
          paddingX={{ xs: "0.2rem", semiMd: "0.3rem", xxl: "1rem" }}
          sx={{
            height: "80vh",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
          width={"100%"}
        >
          {hits.map((hit) => {
            return <ItemHit hit={hit} key={hit.id} setAllItems={setAllItems} />;
          })}
          {!isLastPage && (
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              marginTop={theme.spacing(1)}
            >
              <Grid
                xs={4}
                semiSm={4.5}
                sm={5}
                semiMd={4.5}
                md={5.5}
                xl={6}
              ></Grid>
              <Grid
                xs={8}
                semiSm={7.5}
                sm={7}
                semiMd={7.5}
                md={6.5}
                xl={6}
                container
                justifyContent={"flex-start"}
              >
                <Typography
                  onClick={() => showMore()}
                  fontWeight={theme.typography.bold}
                  color={theme.palette.primary.main}
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  fontSize={{ xs: theme.spacing(2.5) }}
                >
                  {t("home.showMore")}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Stack>
      )}
    </>
  );
};

export default CustomInfiniteHits;
