import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  type: "",
  errorList: [],
  statusCode: "",
  errorCode: "",
  token: "",
  // values: null,
  show: false,
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    notifyWithSuccess: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.statusCode = action.payload.statusCode;
      state.errorCode = action.payload.errorCode;
      // state.values = action.payload.values;
      state.show = true;
    },
    notifyWithError: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.statusCode = action.payload.statusCode;
      state.errorCode = action.payload.errorCode;
      state.errorList = action.payload.errorList;
      state.token = action.payload.token ? action.payload.token : "";
      // state.values = action.payload.values;
      state.show = true;
    },
    flushNotification: (state, action) => {
      state.message = "";
      state.type = "";
      state.errorList = [];
      state.statusCode = "";
      state.errorCode = "";
      state.token = "";
      // state.values = null;
      state.show = false;
    },
    closeSnackbar: (state, action) => initialState, // Reset state to initialState
  },
});

export const {
  notifyWithError,
  notifyWithSuccess,
  flushNotification,
  closeSnackbar,
  flushState,
} = errorSlice.actions;
export default errorSlice.reducer;
export const getStatus = (state) => state.errorReducer.type;
export const getMessage = (state) => state.errorReducer.message;
export const getShow = (state) => state.errorReducer.show;
export const getErrorList = (state) => state.errorReducer.errorList;
export const getErrorCode = (state) => state.errorReducer.errorCode;
