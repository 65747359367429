import {
  Box,
  Stack,
  useTheme,
  Backdrop,
  IconButton,
  Fade,
  Modal,
  Typography,
  Grid,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import { editNote } from "../../../../app/features/wallet/wallet";
import { notifyWithSuccess } from "../../../../app/features/error/error";

//SECTION - styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 800,
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
  maxHeight: "90vh",
  overflowY: "auto",
};
const EditNoteModal = ({
  open,
  handleClose,
  sessionId,
  transaction_id,
  client_name,
  invoice_total_amount,
  phone_number,
  note,
  fetchOrders,
}) => {
  //SECTION - general
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  //SECTION - useSelector
  const editNoteState = useSelector(
    (state) => state.walletReducer.editNoteState
  );

  //SECTION - functions
  const resetFields = () => {
    formik.values.note = "";
  };

  const handleSubmitForm = (newNote) => {
    dispatch(
      editNote({
        id: sessionId,
        note: newNote,
      })
    ).then((payload) => {
      if (payload.type === "wallet/editNote/fulfilled") {
        dispatch(
          notifyWithSuccess({
            message: t("wallet.noteEditedSuccessfully"),
            type: "success",
          })
        );
        fetchOrders();
        resetFields();
        handleClose();
      }
    });
  };

  const formik = useFormik({
    initialValues: { note: note },
    enableReinitialize: true,
    onSubmit: handleSubmitForm,
  });

  return (
    <Modal
      open={open}
      onClose={() => {
        resetFields();
        handleClose();
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Stack gap={theme.spacing(2)}>
            <Stack
              alignItems={"center"}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <IconButton sx={{ visibility: "hidden" }}>
                <AiOutlineClose fontSize={"1.5rem"} />
              </IconButton>
              <Typography
                fontWeight={theme.typography.bold}
                fontSize={"1.8rem"}
              >
                {t("wallet.editNote")}
              </Typography>
              <IconButton
                onClick={() => {
                  resetFields();
                  handleClose();
                }}
              >
                <AiOutlineClose fontSize={"1.5rem"} />
              </IconButton>
            </Stack>

            <Grid
              container
              direction="column"
              gap={1}
              sx={{
                backgroundColor: theme.palette.gray.g75,
                borderRadius: theme.borderRadius.r15,
                paddingY: "1rem",
                paddingX: "1rem",
                minHeight: 100,
              }}
            >
              <Grid
                container
                direction={{ md: "row" }}
                justifyContent="space-between"
                spacing={1}
              >
                <Grid item justifyContent="flex-start" md={2}>
                  <Typography
                    color="secondary"
                    fontWeight={theme.typography.bold}
                  >
                    {t("wallet.transaction_id")}
                  </Typography>
                </Grid>
                <Grid item justifyContent="flex-start" md>
                  <Typography fontWeight={theme.typography.bold}>
                    {transaction_id}
                  </Typography>
                </Grid>
                <Grid item justifyContent="flex-start" md={2}>
                  <Typography
                    color="secondary"
                    fontWeight={theme.typography.bold}
                  >
                    {t("wallet.client_Name")}
                  </Typography>
                </Grid>
                <Grid item justifyContent="flex-start" md>
                  <Typography fontWeight={theme.typography.bold}>
                    {client_name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                direction={{ md: "row" }}
                justifyContent="flex-start"
                gap={1}
              >
                <Grid item justifyContent="flex-start" md={2}>
                  <Typography
                    color="secondary"
                    fontWeight={theme.typography.bold}
                  >
                    {t("wallet.totalAmount")}
                  </Typography>
                </Grid>
                <Grid item justifyContent="flex-start" md>
                  <Typography fontWeight={theme.typography.bold}>
                    {invoice_total_amount} {t("wallet.riyal")}
                  </Typography>
                </Grid>
                <Grid item justifyContent="flex-start" md={2}>
                  <Typography
                    color="secondary"
                    fontWeight={theme.typography.bold}
                  >
                    {t("wallet.clientNumber")}:
                  </Typography>
                </Grid>
                <Grid item justifyContent="flex-start" md>
                  <Typography fontWeight={theme.typography.bold}>
                    {phone_number}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <form onSubmit={formik.handleSubmit}>
              <Stack gap={theme.spacing(2)}>
                <Grid
                  container
                  alignItems={{
                    xs: "flex-start",
                    sm: "center",
                  }}
                  flexDirection={{
                    xs: "column",
                    sm: "row",
                  }}
                >
                  <Grid
                    xs={12}
                    sm={4}
                    textAlign={i18n.dir() === "ltr" ? "left" : "right"}
                  >
                    <Typography fontWeight={theme.typography.bold}>
                      {t("wallet.noteColumn")}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={8}
                    container
                    gap={theme.spacing(1)}
                    sx={{ width: "100%" }}
                  >
                    <TextField
                      color="secondary"
                      fullWidth
                      size="small"
                      name="note"
                      multiline
                      rows={4}
                      value={formik.values.note}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={t("wallet.addNote")}
                      InputProps={{
                        sx: {
                          borderRadius: "10px",
                          overflow: "hidden",
                          backgroundColor: theme.palette.white,
                        },
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: i18n.dir() === "ltr" ? "left" : "right",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Box mt={theme.spacing(2)}>
                  <Button
                    color="secondary"
                    variant="contained"
                    fullWidth
                    disabled={!formik.dirty || editNoteState === "loading"}
                    onClick={formik.handleSubmit}
                  >
                    {editNoteState === "loading" ? (
                      <CircularProgress size={25} color="white" />
                    ) : (
                      <Typography>{t("wallet.editNote")}</Typography>
                    )}
                  </Button>
                </Box>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default EditNoteModal;
