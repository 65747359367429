import {
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import OrderItem from "./OrderItem";
import CalculateOrderPrice from "../../utils/data/CalculateOrderPrice";
import { useState, useEffect } from "react";
import { fetchCartItems } from "../../app/features/cart/cart";
import { useDispatch, useSelector } from "react-redux";
import ButtonComp from "../../components/common/ButtonComp";
import {
  customerConfirmOrder,
  getConfirmOrderStatus,
} from "../../app/features/order/order";
import OrderSuccessModal from "./OrderSuccessModal";
import OrderErrorModal from "./OrderErrorModal";
import displayOrderStatus from "../../utils/data/DisplayOrderStatus";
import { updateUnconfirmedOrders } from "../../app/features/order/order";
import { NumericFormat } from "react-number-format";
import { BsQuestionCircleFill } from "react-icons/bs";

const SelectedOrderDetails = ({
  selectedOrder,
  brands,
  refetchOrder,
  updateUIOrderId,
}) => {
  //SECTION - general
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("semiMd"));

  //SECTION - useState
  const [open, setOpen] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  //SECTION - useSelector
  const confirmOrderCurrentStatus = useSelector(getConfirmOrderStatus);

  //SECTION - useEffect
  useEffect(() => {
    dispatch(fetchCartItems());
  }, [dispatch]);

  const watchTowerCouponData = {
    coupon_activated_at: selectedOrder?.coupon_activated_at,
    coupon_applied_at: selectedOrder?.coupon_applied_at,
    coupon_applied_by: selectedOrder?.coupon_applied_by,
    coupon_id: selectedOrder?.coupon_id,
    coupon_percentage: selectedOrder?.coupon_percentage,
    coupon_type: selectedOrder?.coupon_type,
    coupon_max_discount: selectedOrder?.coupon_max_price,
    coupon_details: selectedOrder?.coupon_details,
  };

  //SECTION - functions
  const {
    priceWithoutDiscount,
    discount,
    cashBack,
    shippingPrice,
    vat,
    totalPriceWithVat,
    totalPriceWithoutVat,
    discountMessage,
    cashBackMessage,
  } = CalculateOrderPrice(
    selectedOrder.saleOrderItems,
    selectedOrder.shipping_price,
    selectedOrder.constant_discount,
    watchTowerCouponData,
    selectedOrder.status
  );
  const handleClose = () => {
    setOpen(false);
    refetchOrder(selectedOrder.id);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const handleConfirm = () => {
    dispatch(customerConfirmOrder(selectedOrder.id)).then((data) => {
      if (data.error) {
        setOpenErrorModal(true);
      } else {
        setOpen(true);
        updateUIOrderId(selectedOrder.id);
        updateUnconfirmedOrders(selectedOrder);
      }
    });
  };

  return (
    selectedOrder && (
      <>
        <OrderSuccessModal
          open={open}
          handleClose={handleClose}
          orderId={selectedOrder.id}
          orderItems={selectedOrder.saleOrderItems}
        />
        <OrderErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          message={t("order.confirmOrderError")}
          buttonText={t("order.done")}
        />
        {!isSmallScreen && (
          <Grid
            semiMd={6.8}
            md={7.4}
            lg={7.8}
            xxl={8.5}
            container
            borderRadius={theme.borderRadius.r15}
            bgcolor={theme.palette.gray.g100}
            gap={theme.spacing(1)}
            paddingTop={theme.spacing(2)}
            height="fit-content"
            maxHeight="90vh"
            sx={{ overflowY: "auto" }}
          >
            <Grid
              xs={12}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap={theme.spacing(0.5)}
            >
              <Typography
                fontWeight={theme.typography.bold}
                color={theme.palette.secondary.dark}
                variant={"h6"}
              >
                {t("order.orderDetails")}
              </Typography>
              <Typography
                fontWeight={theme.typography.bold}
                color={theme.palette.secondary.dark}
                variant={"h6"}
              >
                {t("order.orderNo") + " " + selectedOrder.id}
              </Typography>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                wrap="true"
                gap={theme.spacing(0.5)}
              >
                <Typography color={theme.palette.gray.g400} variant={"h6"}>
                  {t("order.orderStatus")}
                </Typography>
                <Typography
                  color={
                    theme.palette[
                      displayOrderStatus(selectedOrder.status).color
                    ].main
                  }
                  variant={"h6"}
                  fontWeight={theme.typography.bold}
                >
                  {displayOrderStatus(selectedOrder.status).status}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Grid
                xs={12}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  borderBottom: `1px solid ${theme.palette.gray.g400}`,
                }}
              >
                <Grid
                  xs={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography color={theme.palette.gray.g400}>
                    {t("order.items")}
                  </Typography>
                </Grid>
                <Grid
                  xs={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography color={theme.palette.gray.g400}>
                    {t("order.quantity")}
                  </Typography>
                </Grid>
                <Grid
                  xs={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography color={theme.palette.gray.g400}>
                    {t("order.priceTableHeader")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                {selectedOrder.saleOrderItems.map((item) => {
                  return (
                    <OrderItem key={item.id} item={item} brands={brands} />
                  );
                })}
              </Grid>
            </Grid>
            {selectedOrder.status !== "pricing" ? (
              <Grid xs={12}>
                <Grid
                  paddingX={theme.spacing(2)}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  wrap="true"
                  gap={theme.spacing(0.5)}
                >
                  <Typography color={theme.palette.gray.g400} variant={"h6"}>
                    {t("order.totalWithoutDiscount")}
                  </Typography>
                  {
                    <NumericFormat
                      value={priceWithoutDiscount}
                      thousandSeparator=","
                      displayType="text"
                      renderText={(value) => (
                        <Typography
                          color={theme.palette.secondary.dark}
                          variant={"h6"}
                        >{`${value} ${t("currency.SAR")}`}</Typography>
                      )}
                    />
                  }
                </Grid>
                <Grid
                  paddingX={theme.spacing(2)}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  wrap="true"
                  gap={theme.spacing(0.5)}
                >
                  <Grid container alignItems={"center"} gap={theme.spacing(1)}>
                    <Typography color={theme.palette.gray.g400} variant={"h6"}>
                      {t("order.discount")}
                    </Typography>
                    <Tooltip title={discountMessage}>
                      <Stack sx={{ cursor: "pointer" }} alignItems={"center"}>
                        <BsQuestionCircleFill
                          color={theme.palette.primary.main}
                        />
                      </Stack>
                    </Tooltip>
                  </Grid>
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                  >
                    <Typography
                      color={theme.palette.secondary.dark}
                      variant={"h6"}
                    >
                      {discount + " " + t("currency.SAR")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  paddingX={theme.spacing(2)}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  wrap="true"
                  gap={theme.spacing(0.5)}
                >
                  <Grid container alignItems={"center"} gap={theme.spacing(1)}>
                    <Typography color={theme.palette.gray.g400} variant={"h6"}>
                      {t("order.cashBack")}
                    </Typography>
                    <Tooltip title={cashBackMessage}>
                      <Stack sx={{ cursor: "pointer" }} alignItems={"center"}>
                        <BsQuestionCircleFill
                          color={theme.palette.primary.main}
                        />
                      </Stack>
                    </Tooltip>
                  </Grid>
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                  >
                    <Typography
                      color={theme.palette.secondary.dark}
                      variant={"h6"}
                    >
                      {cashBack + " " + t("currency.SAR")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  paddingX={theme.spacing(2)}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  wrap="true"
                  gap={theme.spacing(0.5)}
                >
                  <Typography color={theme.palette.gray.g400} variant={"h6"}>
                    {t("order.shippingPrice")}
                  </Typography>
                  {shippingPrice === "FREE" ? (
                    <Typography
                      color={theme.palette.secondary.dark}
                      variant={"h6"}
                    >
                      {t("order.free")}
                    </Typography>
                  ) : (
                    <NumericFormat
                      value={shippingPrice}
                      thousandSeparator=","
                      displayType="text"
                      renderText={(value) => (
                        <Typography
                          color={theme.palette.secondary.dark}
                          variant={"h6"}
                        >{`${value} ${t("currency.SAR")}`}</Typography>
                      )}
                    />
                  )}
                </Grid>
                <Grid
                  paddingX={theme.spacing(2)}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  wrap="true"
                  gap={theme.spacing(0.5)}
                >
                  <Typography color={theme.palette.gray.g400} variant={"h6"}>
                    {t("order.priceWithoutVAT")}
                  </Typography>
                  {
                    <NumericFormat
                      value={totalPriceWithoutVat}
                      thousandSeparator=","
                      displayType="text"
                      renderText={(value) => (
                        <Typography
                          color={theme.palette.secondary.dark}
                          variant={"h6"}
                        >{`${value} ${t("currency.SAR")}`}</Typography>
                      )}
                    />
                  }
                </Grid>
                <Grid
                  paddingX={theme.spacing(2)}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  wrap="true"
                  gap={theme.spacing(0.5)}
                >
                  <Typography color={theme.palette.gray.g400} variant={"h6"}>
                    {t("order.vat")} 15%
                  </Typography>
                  {
                    <NumericFormat
                      value={vat}
                      thousandSeparator=","
                      displayType="text"
                      renderText={(value) => (
                        <Typography
                          color={theme.palette.secondary.dark}
                          variant={"h6"}
                        >{`${value} ${t("currency.SAR")}`}</Typography>
                      )}
                    />
                  }
                </Grid>
                <Grid
                  paddingX={theme.spacing(2)}
                  paddingBottom={
                    selectedOrder.status === "waitng_approvement"
                      ? theme.spacing(0.5)
                      : theme.spacing(2)
                  }
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  wrap="true"
                  gap={theme.spacing(0.5)}
                >
                  <Typography
                    color={theme.palette.secondary.main}
                    fontWeight={theme.typography.bold}
                    variant={"h6"}
                  >
                    {t("order.total")}
                  </Typography>
                  {
                    <NumericFormat
                      value={totalPriceWithVat}
                      thousandSeparator=","
                      displayType="text"
                      renderText={(value) => (
                        <Typography
                          color={theme.palette.secondary.main}
                          variant={"h6"}
                          fontWeight={theme.typography.bold}
                        >{`${value} ${t("currency.SAR")}`}</Typography>
                      )}
                    />
                  }
                </Grid>
                {selectedOrder.status === "waiting_approvement" && (
                  <Grid container height={"50px"}>
                    <ButtonComp
                      onClick={handleConfirm}
                      variant="contained"
                      color="secondary"
                      content={
                        confirmOrderCurrentStatus === "loading" ? (
                          <CircularProgress size={30} />
                        ) : (
                          t("order.confirm")
                        )
                      }
                      fullWidth
                      sx={{
                        borderRadius: `0px 0px ${theme.borderRadius.r15} ${theme.borderRadius.r15}`,
                        height: "100%",
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid
                container
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                padding={"2rem"}
              >
                <Typography
                  color={theme.palette.secondary.main}
                  variant={"h6"}
                  fontWeight={theme.typography.bold}
                  textAlign={"center"}
                >
                  {t("order.waitPricing")}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </>
    )
  );
};

export default SelectedOrderDetails;
