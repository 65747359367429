import { useEffect, useState } from "react";
import { validateWatchtowerToken } from "../../../utils/api/Authentication";
import { useDispatch } from "react-redux";
import {
  checkClientValidity,
  getWatchTowerToken,
  setUserFirstAndLastName,
} from "../../../app/features/wallet/wallet";
import { useNavigate } from "react-router-dom";

const useCheckClientValidity = () => {
  //NOTE - This hook checks for the client validity for BNPL services and return checking attempts related data to use it when needed

  //SECTION - general
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //SECTION - useState
  const [fetchAttempts, setFetchAttempts] = useState(0);
  const [fetchAttemptsLoading, setFetchAttemptsLoading] = useState(true);

  //SECTION - useEffect
  useEffect(() => {
    // Check if the WatchTowerAccessToken is already in local storage
    const watchTowerAccessToken = localStorage.getItem("watchTowerAccessToken");
    if (watchTowerAccessToken) {
      // Token is available
      if (validateWatchtowerToken()) {
        dispatch(setUserFirstAndLastName(watchTowerAccessToken));
        // Token is valid (not expired), stop loading and check client validity
        setFetchAttemptsLoading(false);
        dispatch(checkClientValidity()).then((payload) => {
          if (payload.payload === 401 || payload.payload === 403) {
            if (fetchAttempts < 2) {
              // Maximum attempts not reached, dispatch the action to get the token
              // and wait for it to complete before incrementing fetchAttempts
              dispatch(getWatchTowerToken()).then(() => {
                setFetchAttempts(fetchAttempts + 1);
              });
            } else {
              // Maximum number of attempts reached, redirect the user to the home page
              navigate("/");
            }
          }
        });
      } else {
        // Token is not valid (expired)
        if (fetchAttempts < 2) {
          // Maximum attempts not reached, dispatch the action to get the token
          // and wait for it to complete before incrementing fetchAttempts
          dispatch(getWatchTowerToken()).then(() => {
            setFetchAttempts(fetchAttempts + 1);
          });
        } else {
          // Maximum number of attempts reached, redirect the user to the home page
          navigate("/");
        }
      }
    } else {
      if (fetchAttempts < 2) {
        // Maximum attempts not reached, dispatch the action to get the token
        // and wait for it to complete before incrementing fetchAttempts
        dispatch(getWatchTowerToken()).then(() => {
          setFetchAttempts(fetchAttempts + 1);
        });
      } else {
        // Maximum number of attempts reached, redirect the user to the home page
        navigate("/");
      }
    }
  }, [fetchAttempts, dispatch, navigate]);

  return { fetchAttemptsLoading, fetchAttempts };
};

export default useCheckClientValidity;
