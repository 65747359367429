import { useTheme } from "@emotion/react";
import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import autobiaLogo from "../../assets/images/logos/autobia.png";

const TermsAndConditions = () => {
    //SECTION - GENERAL
    const theme = useTheme();

    return (
        <Container maxWidth={"md"}>
            <Stack
                alignItems={"center"}
                justifyContent={"center"}
                textAlign={"center"}
                borderRadius={theme.borderRadius.r20}
                my={"1rem"}
                border={"1px solid black"}
                gap={theme.spacing(2)}
            >
                <img
                    src={autobiaLogo}
                    alt="autobia-logo-ar"
                    style={{ width: "150px", marginTop: theme.spacing(2) }}
                />
                <Box width={"90%"}>
                    <Typography
                        mt={theme.spacing(2)}
                        fontWeight={theme.typography.bold}
                        fontSize={"2rem"}
                    >
                        اتفاقية الاستخدام (الشروط والأحكام)
                    </Typography>
                    <Box textAlign={"right"}>
                        <h1>
                            <br />
                            مقدمة:
                        </h1>
                        <ul>
                            <li>
                                &nbsp;تخضع جميع المنتجات التي يتم عرضها عبر منصة
                                Autobia إلى اشتراطات ومواصفات منتجيها أو موزعيها
                                المحليين أو الدوليين، ولا تتحمل Autobia أي
                                مسؤولية تجاه الأعمال التصنيعية، أو التخزينية، أو
                                عمليات النقل والشحن.
                            </li>
                            <li>
                                بخلاف ما يقدم من ضمان من قبل المورد/ البائع -إن
                                وجد-، لا تقدم Autobia أي مسؤولية أو ضمان تجاه أي
                                من المنتجات التي يتم توفيرها عبر المنصة، ويتفهم
                                المستخدم طبيعة هذه التعاقدات وما يتبعها من آثار.
                            </li>
                            <li>
                                &nbsp;تهدف Autobia من خلال الموقع والتطبيقات إلى
                                صناعة رحلة عميل متميزة مع التسوق الإلكتروني؛
                                ولذلك دونا الشروط والأحكام المتعلقة بمحطات هذه
                                الرحلة كما يلي:
                                <br />
                                <br />
                            </li>
                        </ul>
                        <h1>مصطلحاتنا:</h1>
                        <ol>
                            <li>
                                المستخدم/ العميل: هو الشخص الذي يقوم بطلب
                                البضائع والسلع من تطبيق/ موقع Autobia، ويتوجب أن
                                يتمتع بالأهلية النظامية الكافية للقيام بهذه
                                العملية.
                            </li>
                        </ol>
                        <ol>
                            <li>
                                Autobia أو (نحن) تعني شركة إمدادات المركبة
                                للتجارة وسجلها التجاري:2050137637، وعنوانها
                                المملكة العربية السعودية، الدمام، 3217، الحريري،
                                النهضة، 6390.
                            </li>
                        </ol>
                        <ol>
                            <li>
                                (الصفحة الرئيسية) تعني الصفحة الرئيسية للتطبيق/
                                الموقع، والتي تبين عناوين وأقسام التطبيق/ الموقع
                                والعلامات التجارية التي نقوم بتسهيل عرضها
                                للمستخدم.
                            </li>
                            <li>
                                (الأقسام) تعني التصنيفات الرئيسية والفرعية التي
                                لدينا في الموقع/ التطبيق.
                            </li>
                            <li>
                                (الحساب) يعني التفاصيل الخاصة بالمستخدم وتاريخ
                                عمليات التسوق ويتم إنشاؤه عند التسجيل للتسوق من
                                خلال موقع أو تطبيقات Autobia.
                            </li>
                            <li>
                                (السعر التجاري) يعني أسعار الأصناف التي يرغب
                                المورد/ مالك السلعة بيعها به، ويعرض في منصة
                                وتطبيقات Autobia و يشمل ما يضاف إلى قيمة المنتج
                                من مصاريف إضافية (الضرائب، ما يستجد من مصاريف)،
                                بخلاف مصاريف النقل.
                            </li>
                            <li>
                                (الاسترداد النقدي) يعني المبلغ الذي يجمعه عملاء
                                Autobia في محافظهم الإلكترونية في حساب العميل
                                على الموقع/ التطبيق، ويمكن للعميل استخدام رصيد
                                الاسترداد النقدي في محفظته الإلكترونية للدفع
                                مقابل مشترياته في أي وقت عن طريق اختيار الدفع
                                باستخدام هذا الرصيد بدلاً من الدفع نقدًا أو
                                ببطاقة الائتمان.
                            </li>
                            <li>
                                (الرمز الترويجي) هو حرف فريد من نوعه و (أو) رمز
                                رقمي، يعطي المستخدم فرصة الحصول على خصم أو
                                استرداد نقدي أو هدية معينة حسب الشروط الموضحة
                                لكل رمز.
                                <br />
                                <br />
                            </li>
                        </ol>
                        <h1>شروطنا:</h1>
                        <div>
                            <br />
                            &zwnj;أ. الوصول:
                        </div>
                        <ol>
                            <li>
                                قد يتوقف الموقع/ التطبيق عن العمل لبعض الوقت في
                                حالات الصيانة الدورية أو الطارئة، أو لأسباب
                                قاهرة خارجة عن الإرادة.
                            </li>
                            <li>
                                سيتم دورياً إصلاح الأخطاء والأعطال وفق
                                الإمكانيات المتاحة لدى Autobia.
                            </li>
                            <li>
                                باستخدام العميل لنوافذنا الإلكترونية، فإنه يدرك
                                تماماً أن هذا الاستخدام يقع ضمن مسؤوليته عن
                                التصرفات العقدية التي يقوم بإبرامها وأن الهدف
                                الذي يستخدم من أجله Autobia هدف مشروع.
                            </li>
                            <li>
                                يتحمل العميل بشكل منفرد تبعات مشاركته اسم
                                المستخدم وكلمة المرور الخاصة به في تطبيق / موقع
                                Autobia أو البريد الإلكتروني أو رمز الدخول
                                المرسل من autobia.sa مع أي شخص، أو منشأة، أو
                                جهة.
                            </li>
                            <li>
                                لا يحق للمستخدم إستخدام أو استغلال أي ثغرة أو
                                أخطاء برمجية للتأثير على خدمة autobia.sa أو
                                الحصول على فائدة غير مقدمة بشكل صريح ويلتزم
                                المستخدم بالتعويض عند قيامه بذلك.
                            </li>
                            <li>
                                يحق لـ Autobia رفض التعامل مع العميل في حال
                                أستخدم التهديد أو العنف الجسدي أو اللفظي تجاه أي
                                من موظفي خدمة العملاء أو التوصيل أو أي شخص ذو
                                مصلحة في التعامل مع المنصة.&zwnj;
                            </li>
                            <li>
                                هذا الموقع يستخدم ملفات تعريف الارتباط (الكوكيز)
                                لمراقبة تفضيلات التصفح.
                            </li>
                            <li>
                                إن استخدامك لأي معلومات أو مواد على هذا الموقع
                                هو على مسؤوليتك الخاصة بالكامل، ولن نكون مسؤولين
                                عنها. تقع على عاتقك مسؤولية التأكد من أن أي
                                منتجات أو خدمات أو معلومات متوفرة من خلال هذا
                                الموقع تلبي متطلباتك الخاصة.
                            </li>
                            <li>
                                يحتوي هذا الموقع على مواد مملوكة لنا أو مرخصة
                                لنا. وتشمل هذه المواد، على سبيل المثال لا الحصر،
                                التصميم والتخطيط والمظهر والرسومات.
                            </li>
                            <li>
                                يحظر الاستنساخ إلا وفقا لإشعار حقوق الطبع
                                والنشر، الذي يشكل جزءا من هذه الشروط والأحكام.
                            </li>
                            <li>
                                جميع العلامات التجارية المستنسخة في هذا الموقع،
                                والتي ليست ملكا للمشغل أو مرخّصة له، معترف بها
                                على الموقع.
                            </li>
                            <li>
                                قد يؤدي الاستخدام غير المصرح به لهذا الموقع
                                الإلكتروني إلى المطالبة بتعويضات و/ أو يعتبر
                                جريمة جنائية.
                            </li>
                            <li>
                                من وقت لآخر، قد يتضمن موقع الويب هذا أيضا روابط
                                لمواقع أخرى. يتم توفير هذه الروابط لراحتك لتقديم
                                مزيد من المعلومات. إنهم لا يشيرون إلى أننا نؤيد
                                موقع (مواقع) الويب. نحن لا نتحمل أي مسؤولية عن
                                محتوى موقع (مواقع) الويب المرتبط.
                            </li>
                            <li>
                                يخضع استخدامك لهذا الموقع وأي نزاع ينشأ عن هذا
                                الاستخدام للموقع لقوانين المملكة العربية
                                السعودية.
                                <br />
                                <br />
                            </li>
                        </ol>
                        <div>
                            <br />
                            ب. المحتوى:
                            <br />
                            <br />
                        </div>
                        <ol>
                            <li>
                                دون التزام بوقت أو نوع أو كمية فإن Autobia تقوم
                                بتحديث محتوى منصاتها بشكل دوري.
                            </li>
                            <li>
                                يتوجب على العميل بشكل منفرد ملاحظة تاريخ التحديث
                                &ndash; إن وجد- مع ضرورة إدراك إمكانية وجود خطأ،
                                أو سهو.
                            </li>
                            <li>
                                قد نقوم بتحديث التطبيق/ الموقع من وقت لآخر،
                                ويمكن أن يغير المحتوى في أي وقت. ومع ذلك، يرجى
                                ملاحظة أن أي محتوى على التطبيق/ الموقع قد يكون
                                قديمًا.
                            </li>
                            <li>
                                حقوق الملكية الفكرية وجميع الحقوق، على التطبيق/
                                موقع الويب، أو أي جزء من التطبيق/ الموقع، سواء
                                كانت بواسطة Autobia أو بواسطة المستخدم، أو أي
                                طرف ثالث، مثل ما تفضله من أصناف مملوكة لـ
                                Autobia.
                            </li>
                            <li>
                                يدرك المستخدم المسؤولية القانونية التي تقع حال
                                استخدامه لأي بيانات تتبع لـ Autobia دون إذن
                                مكتوب، ويتعرض لما تفرضه Autobia من عقوبات جراء
                                ذلك.
                            </li>
                            <li>
                                حال وجود نظام أو تشريع يمنع Autobia من امتلاك أي
                                حقوق أو بيانات متحصلة بشكل تلقائي من استخدام
                                الموقع/ التطبيق، فإن العميل يوافق على منح
                                Autobia كافة الصلاحيات في حدود ما يسمح به
                                القانون لجمع واستخدام ومشاركة تلك البيانات.
                            </li>
                            <li>
                                يعتبر المستخدم مسؤولًا عن حماية بياناته الشخصية،
                                واستخدام برمجيات الحماية اللازمة والكافية من أجل
                                ذلك.
                                <br />
                                <br />
                            </li>
                        </ol>
                        <div>
                            <span>&zwnj;ج. التعديل:</span>&nbsp;
                        </div>
                        <ul>
                            <li>
                                من وقت لآخر سنقوم بإجراء تغييرات أو تعديلات
                                تتعلق بهذه السياسة، وضمن ما تسمح به الأنظمة
                                المعمول بها سنقوم بإعلام المستخدم بذلك.
                                <br />
                                <br />
                            </li>
                        </ul>
                        <div>
                            <br />
                            د. سياسة الخصوصية:
                            <br />
                            <br />
                        </div>
                        <ol>
                            <li>
                                <br />
                                تعتبر الخصوصية والأمن أقصى الأولويات في حيث أننا
                                لم نقم على الإطلاق بمشاركة أو طباعة أو بيع
                                معلومات أي عميل لأي طرف آخر.
                            </li>
                            <li>
                                عند قيامك بتقديم المعلومات الشخصية على موقعنا
                                سنعمل بكل جهد على حماية معلوماتك على الإنترنت
                                وخارجه .
                            </li>
                            <li>
                                نقوم باستخدام مجموعة متنوعة من تقنيات وإجراءات
                                الأمان للمساعدة على حماية معلوماتك الشخصية من
                                الوصول أو الاستخدام أو الكشف غير المصرح به حالما
                                نقوم بإستلامها. على سبيل المثال، نحن نقوم بتخزين
                                معلوماتك الشخصية على أنظمة كمبيوتر ذات وصول
                                محدود لمصرح لهم بالإطلاع على هذه المعلومات، ويتم
                                تدريب موظفينا على التعامل الآمن مع هذه المعلومات
                                والبيانات وإبقائهم مطلعين على آخر المستجدات التي
                                تتعلق بالإجراءات الأمنية.
                                <br />
                                <br />
                            </li>
                        </ol>
                        <div>&zwnj;هـ. &nbsp;شروط &nbsp;الشراء:</div>
                        <div>
                            سيتم تطبيق شروط الشراء المذكورة أدناه حال قيام
                            المستخدم بالبدء بالعملية عبر الموقع/ التطبيق:
                        </div>
                        <ol>
                            <li>
                                يجب أن تكون مؤهلاً التأهيل القانوني للقيام
                                بعملية الشراء.
                            </li>
                            <li>
                                يجب أن تمتلك الأدوات اللازمة لدفع القيم النقدية
                                بشكل مباشر أو عن طريق البطاقات والمحافظ
                                الإلكترونية المقبولة لدى Autobia، وما تفرضه من
                                رسوم لقاء أنواع عمليات الدفع النقدية أو
                                الإلكترونية.
                            </li>
                            <li>
                                يجب معاملة بيانات الدخول بأقصى درجات السرية من
                                قبل المستخدم لقيام المسؤولية تجاهه بمجرد استخدام
                                الحساب التابع لتلك البيانات من قبله أو من قبل أي
                                شخص، أو جهة، او منشأة تمكنت من الدخول إليه عن
                                طريق بيانات الدخول الخاصة بالحساب.
                            </li>
                            <li>
                                حال وصولك إلى قناعة أوليه بأن بياناتك لم تعد
                                آمنه بالقدر الكافي، أو إذا كنت تعتقد أن شخصًا
                                آخر قد تعرف أو يقوم باستخدام بياناتك للدخول إلى
                                حسابك في Autobia، تواصل معنا على الفور.
                            </li>
                            <li>
                                يوافق المستخدم على التنازل عن حقه في الوصول إلى
                                حسابه في Autobia حال قيامها بتعليق ذلك بسبب وجود
                                شبهة أي نشاط احتيالي، وعند وجود سوء استخدام
                                للخدمات، أو أي سبب تعتقد Autobia أنه مناسب
                                موضوعياً لإلغاء أو تعليق الحساب.
                            </li>
                            <li>
                                وصول إشعار الطلب الخاص بك دون الإشارة إلى تأكيد
                                الطلب من قبل Autobia لا يعد قبولاً منتجاً لآثاره
                                الشرعية أو النظامية، كما يحق لــAutobia إلغاء
                                الطلب متى ما رأت أنه مناسب موضوعياً للإلغاء.
                            </li>
                            <li>
                                بعض السلع/ البضائع / الخدمات قد تتطلب شروط
                                إضافية يتم الإشارة إليها بشكل مستقل.
                                <br />
                                <br />
                            </li>
                        </ol>
                        <div>
                            <br />
                            فيما يتعلق بالسعر:
                            <br />
                            <br />
                        </div>
                        <ol>
                            <li>
                                سيكون سعر السلعة/ المنتج المحدد التي قمت بطلبها
                                هو السعر الموجود على الموقع/ التطبيق وقت تقديم
                                المستخدم لطلب الشراء أو تعديله.
                            </li>
                            <li>
                                يبذل الفريق في Autobia ما في وسعه لتقديم أفضل
                                خدمة ممكنة، لكن قد تحدث أخطاء أثناء تقديم
                                معلومات عن المنتجات والأسعار من قبل تطبيق/ موقع
                                Autobia.
                            </li>
                            <li>
                                لا يمكننا تأكيد سعر المنتج إلا بعد تقديم طلب
                                الشراء.
                            </li>
                            <li>
                                في حالة إدراج منتج بسعر غير صحيح أو بمعلومات غير
                                صحيحة بسبب خطأ في التسعير أو معلومات المنتج،
                                سيكون لنا الحق، وفقًا لتقديرنا المنفرد، في
                                الاتصال بك لغرض التوجيه أو إلغاء طلب الشراء
                                الخاص بك وإخطارك بهذا الإلغاء.
                            </li>
                            <li>
                                في كل الحالات يحق ل Autobia تعديل الأسعار
                                وإبلاغك حال وجود أي تعديل.
                            </li>
                            <li>
                                في حالة قبول تطبيق/ موقع Autobia لطلب الشراء
                                الخاص بك، سيتم خصم نفس القيمة من حساب بطاقة
                                الائتمان الخاصة بك وإخطارك عن طريق البريد
                                الإلكتروني، أو الرسائل النصية،و ستكون ملتزمًا
                                بالدفع نقدًا عند التسليم إذا تم اختيار الدفع
                                النقدي أو عندما لا نتمكن من الخصم من البطاقة.
                            </li>
                            <li>
                                يحق لـ Autobia معالجة الدفع ببطاقة الائتمان قبل
                                إرسال المنتج الذي طلبته.
                            </li>
                            <li>
                                في كل الحالات إذا توجب علينا إلغاء طلب الشراء
                                بعد معالجة المدفوعات، سيتم إعادة المبلغ المذكور
                                إلى حساب بطاقة الائتمان/الخصم الخاصة بك، أو قد
                                يتم توفير رصيد دائن يودع في محفظتك أو حسابك لدى
                                الموقع/ التطبيق.
                            </li>
                            <li>
                                يوافق المستخدم على أن الأسعار وتوافر المنتجات
                                عرضة للتغيير دون أي إشعار مسبق. علاوة على ذلك ،
                                يستند وصف / أداء المنتجات على الكتالوج
                                والمطبوعات الفنية التي يطبعها الموردون / الوكيل.
                                فبالتالي، فإن الكتابة المتوفرة على كل منتج لا
                                تنتسب إلينا وتخضع للتغيير دون إشعار مسبق.
                            </li>
                            <li>
                                إضافة إلى السعر، قد يتطلب أيضًا دفع رسوم التوصيل
                                أو التحصيل.
                                <br />
                                <br />
                            </li>
                        </ol>
                        <div>
                            <br />
                            فيما يتعلق بقبول طلب الشراء:
                            <br />
                            <br />
                        </div>
                        <ol>
                            <li>
                                وفقًا لقرارنا المنفرد تحتفظ Autobia بالحق في رفض
                                أو إلغاء أي طلب شراء لأي سببٍ كان.
                            </li>
                            <li>
                                تقيد عمليات الشراء أحياناً أو دائماً ببعض
                                القيود:
                                <ol>
                                    <li>قيود الكميات المتاحة للشراء.</li>
                                    <li>
                                        قيود عدم الدقة أو الأخطاء في معلومات
                                        المنتج أو التسعير.
                                    </li>
                                    <li>
                                        المشاكل التي يتم تحديدها من قبل قسم
                                        الائتمان والاحتيال الخاص بـ Autobia.
                                    </li>
                                    <li>
                                        أو ما يشبهها مما نحدده حسب الاحتياج
                                        وبإرادة منفردة هي بعض الحالات التي قد
                                        تؤدي إلى إلغاء الطلب.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                قد يتم طلب المزيد من المعلومات قبل قبول أي طلب
                                شراء.
                            </li>
                            <li>
                                سوف نتواصل معك إذا تم إلغاء طلبك بالكامل أو أي
                                جزء منه. أو إذا كانت هناك حاجة إلى معلومات
                                إضافية لقبول طلبك.
                            </li>
                            <li>
                                إذا تم إلغاء طلبك بعد خصم المبلغ من بطاقة
                                الائتمان/الخصم الخاصة بك، ستتم إعادة المبلغ
                                المذكور إلى &nbsp;محفظتك لدى منصة Autobia، وفي
                                حال رغبتك بتحويل المبلغ لحساب بطاقة الإئتمان
                                يتوجب على العميل إبلاغ Autobia بالمبالغ المطلوب
                                تحويلها، ويقع هذا الإبلاغ ضمن حدود مسؤوليتك، دون
                                أدنى تحمل من Autobia.
                            </li>
                            <li>
                                تقع على عاتق المستخدم مسؤولية التأكد من فاعلية
                                البطاقة الإئتمانية/الخصم عند تنفيذ عملية إعادة
                                المبلغ.
                                <br />
                                <br />
                            </li>
                        </ol>
                        <div>
                            <br />
                            فيما يتعلق بعدم استلام الطلب:
                            <br />
                            <br />
                        </div>
                        <ol>
                            <li>
                                في حالة عدم وجود أي شخص مخول لاستلام الطلب في
                                الموقع المحدد من المستخدم، سينتظر السائق لمدة 10
                                دقائق كحد أقصى قبل مغادرة الموقع المحدد.
                            </li>
                            <li>
                                سيكون تواصل السائق مع المستخدم عبر الاتصال برقم
                                الجوال المسجل فقط.
                                <br />
                                <br />
                            </li>
                        </ol>
                        <div>
                            <br />
                            فيما يتعلق بتعديل الطلب:
                            <br />
                            <br />
                        </div>
                        <ol>
                            <li>
                                يحق لــ Autobia عدم قبول طلبات التعديل والإلغاء،
                                وفي حال قبول تلك الطلبات سيتوجب عليك متابعة طلبك
                                مع فريق الدعم الفني.
                            </li>
                            <li>
                                بالنظر لكيفية عمل أنظمة Autobia، إذا كنت ترغب في
                                إضافة منتجات إلى طلبك الذي قمت بتقديمه، فيمكنك
                                إعادة الطلب عن طريق الاتصال بخدمة العملاء
                            </li>
                            <li>
                                الطلبات التي يتم إلغاءها بعد تسليمها أو إلغاؤها
                                جزئيًا أثناء التوصيل، تخضع لرسوم إلغاء تحددها
                                Autobia على ألا تزيد في حدها الأعلى عن قيمة
                                الطلب، على أن يتم خصمها من المبلغ المسترد مقابل
                                التسليم والمعالجة.
                            </li>
                            <li>
                                يوافق المستخدم ويتنازل عن حقه بعدم الاعتراض على
                                القرار الذي اتخذ من Autobia حيال استلامه للطلبات
                                ويقبله كما وصل إليه.
                            </li>
                            <li>
                                إذا قام المستخدم بطلب أصناف و تبين أنها غير
                                متوفرة ، يمكنه اختيار استبدالها بأقرب صنف أو
                                إلغاء الصنف كليا.
                                <br />
                                <br />
                            </li>
                        </ol>
                        <div>
                            <br />
                            فيما يتعلق بنقص المخزون من السلع:
                            <br />
                            <br />
                        </div>
                        <ol>
                            <li>
                                تبذل Autobia كافة الجهود لضمان التوافر الدائم
                                للسلع، وحال نقص أو عدم وجود المنتجات فسنقدم
                                للمستخدم خيار/ خيارات الحصول على منتج/ منتجات
                                بديلة، أو مماثلة، أو إلغاء الصنف تمامًا أو/و
                                استبدال المنتج بمنتج مختلف الحجم من نفس العلامة
                                التجارية، وفي جميع الحالات إذا كان سعر المنتج
                                البديل أعلى من سعر المنتج المطلوب يتحمل العميل
                                الفرق، وإذا كان ارخص يتم تحصيل سعر المنتج
                                الجديد. وإذا تم الدفع المقدم سيتم إرجاع الفرق
                                للعميل
                            </li>
                            <li>
                                تحتفظ Autobia بالحق، وفقًا لتقديرها الخاص، في
                                وضع حد لكمية الأصناف المشتراه لكل شخص/ منشأة، /
                                أو لكل طلب.
                            </li>
                            <li>
                                يمكن فرض القيود المذكورة على الطلبات المقدمة من
                                نفس الحساب، أو نفس بطاقة الائتمان، أو طلبات
                                الشراء التي تستخدم نفس عنوان الفواتير و/أو
                                التوصيل. وستقدم Autobia بلاغاً للعميل في حالة
                                تطبيق ذلك.
                            </li>
                            <li>
                                لــ Autobia الحق في حظر البيع للموزعين أو تجار
                                التجزئة أو الجملة، أو لأي طرف ينوي إعادة بيع
                                المنتجات.
                                <br />
                                <br />
                            </li>
                        </ol>
                        <div>
                            <br />
                            فيما يتعلق باستخدام البطاقات الائتمانية:
                            <br />
                            <br />
                        </div>
                        <ol>
                            <li>
                                إذا لاحظ المستخدم أن بطاقة الائتمان الخاصة به قد
                                تم استخدامها دون موافقته، يُرجى إبلاغ Autobiaعلى
                                الفور وبحد أقصى 8 ساعات من اكتشاف ذلك. كما يجب
                                على المستخدم إبلاغ الجهة المصدرة لبطاقة الائتمان
                                الخاصة به في حدود الوقت الذي وافق عليه في النظام
                                الخاص بالبطاقة.
                            </li>
                            <li>
                                في حال إضافة مبالغ في محفظتك بالخطأ عن طريق
                                Autobia يحق لautobia.sa خصم المبلغ دون الإبلاغ
                                وإلغاء الطلب، في حال تم استخدام تلك المبالغ لدفع
                                قيمة الطلب.&nbsp;
                                <br />
                                <br />
                            </li>
                        </ol>
                        <div>
                            <br />
                            فيما يتعلق بانتهاء صلاحية المنتجات:
                            <br />
                            <br />
                        </div>
                        <ol>
                            <li>
                                نؤدي دورنا بعناية في انتقاء السلع/ المنتجات ذات
                                تاريخ الصلاحية المناسب.
                            </li>
                            <li>
                                في حالة وجود أي صنف منتهي الصلاحية، تقتصر
                                مسؤولية Autobia على رد المبالغ المتعلقة به مع
                                استلامه لذا يرجى ابلاغنا فورًا.
                                <br />
                                <br />
                            </li>
                        </ol>
                        <div>
                            <br />
                            فيما يتعلق باستخدام كوبون الخصم أو الكوبون الترويجي:
                            <br />
                            <br />
                        </div>
                        <ol>
                            <li>
                                قد تستلزم الرموز الترويجية (الكوبون) حد أدنى
                                لقيمة الطلب لكل عملية شراء، وكذلك قيود أو
                                استثناءات أخرى مثل المدة والكمية وغيرها. سيتم
                                إيضاح الشروط مع كل رمز ترويجي.
                                <br />
                                <br />
                            </li>
                        </ol>
                        <div>&nbsp;</div>
                        <ol>
                            <li>
                                يسمح باستخدام كوبون واحد فقط لكل عملية شراء، أو
                                لكل حساب، أو لأي فترة محددة من قبل Autobia.
                            </li>
                            <li>
                                استخدام الكوبونات يكون وقت تقديم طلب الشراء فقط.
                            </li>
                            <li>
                                تتم مراجعة جميع الطلبات من قبل فريق الدعم/خدمة
                                العملاء في Autobia قبل التنفيذ.
                            </li>
                            <li>
                                إذا وجد أن بعض طلبات الشراء مخالفة لأي من شروط
                                وأحكام الموقع، سيتم تعديل طلبات الشراء وفقًا
                                لذلك.
                            </li>
                            <li>
                                ليس للكوبونات الترويجية قيمة مالية. بل هي حق خصم
                                مشروط بما تحدده Autobia عند الإعلان عنها.
                                <br />
                                <br />
                            </li>
                        </ol>
                        <div>
                            <br />
                            فيما يتعلق بالاسترجاع والاستبدال والشكاوى:
                            <br />
                            <br />
                        </div>
                        <ol>
                            <li>
                                عند ملاحظتك وجود عيب في المنتج عند التسليم، يحق
                                لك رفض الاستلام ويحق لك طلب الاستبدال، أو
                                استرداد المبالغ على أن تخضع لما يلي ذكره:
                            </li>
                            <li>
                                في حالة لم يتم شحن الطلب، في حدود ( 24 ساعة)
                                بإمكان المستخدم التعديل أو الإلغاء بدون رسوم،
                                أما في حالة تم الشحن، سيتم تحميل العميل برسوم
                                إعادة المنتج بشرط أنه لم يتم فتح المنتج و بقي في
                                حالته الأصلية.
                            </li>
                            <li>
                                لأية أصناف تتطلب الاستبدال أو الاسترجاع، يرجى
                                الاتصال بخدمة العملاء لموقع وتطبيق Autobia في
                                أقرب فرصة.
                            </li>
                            <li>
                                في حالة ملاحظتك لتلف ظاهر في المنتجات الواردة في
                                طلبك/ طلباتك، يحق لك رفض قبول الطلب ويمكنك رفع
                                شكوى حول الطلب إلى عنوان البريد الإلكتروني
                                الآتي: support@autobia.sa، أو من خلال الدعم
                                الفني للموقع أو التطبيق.
                            </li>
                            <li>
                                يرجى التأكد في كل الحالات من إرفاق طلب الشكوى
                                بصورة للمنتج التالف كي يتم التحقق من حالة
                                المنتج، مع مراعاة عدم فتح المنتج أو غلافه
                                المغلق.
                            </li>
                            <li>
                                قبول الشكوى من قبل فريق خدمة العملاء لدى
                                Autobia، يعني بالضرورة استغراق معالجة المبالغ
                                المستردة النقدية أو بطاقات الائتمان 14 يوماً من
                                أيام العمل على أوسع مفهوم ممكن خاصة عند وجود طرف
                                ثالث، وستزيد المدة حال وجود طارئ أو عطلة رسمية
                                أو عائق تقني.
                            </li>
                            <li>
                                في كل حالات الإرجاع والاستبدال يجب أن يكون
                                المنتج في عبوته الأصلية ولن يقبل أي منتج لا
                                يستوفي هذه الشروط وأي شرط تحدده Autobia.
                                <br />
                                <br />
                            </li>
                        </ol>
                        <div>و. &zwnj;التعديل على اتفاقية الاستخدام:</div>
                        <ol>
                            <li>
                                يحق لـ Autobia في كل وقت تعديل اتفاقية الاستخدام
                                دون أي سابق إخطار.
                            </li>
                            <li>
                                يمكن الوصول إلى أحدث إصدار من اتفاقية الاستخدام
                                على الصفحات الخاصة بالموقع والتطبيق في أي وقت.
                            </li>
                            <li>
                                إذا كانت التعديلات على الشروط والأحكام غير
                                مقبولة للمستخدم، يرجى التوقف عن استخدام نوافذنا
                                الإلكترونية فوراً.
                            </li>
                            <li>
                                استخدامك لخدمات Autobia عبر الموقع / التطبيق
                                يعني موافقتك والتزامك بما ورد في اتفاقية
                                الاستخدام وأي اتفاقية تصدرها Autobia لاحقاً.
                            </li>
                        </ol>
                        <div>&nbsp;</div>
                    </Box>
                </Box>
                <Stack
                    py={theme.spacing(2)}
                    gap={theme.spacing(1)}
                    width={"100%"}
                    textAlign={"left"}
                ></Stack>
            </Stack>
        </Container>
    );
};

export default TermsAndConditions;
