import { Typography } from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import React from "react";

const CustomDivider = ({ text }) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        width: "100%",
      }}
      direction={"row"}
      alignItems={"center"}
    >
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          backgroundColor: theme.palette.primary.main,
          width: "100%",
          height: "2px",
        }}
      ></Stack>
      <Stack padding={"2rem"} sx={{ width: "fit-content" }}>
        <Typography
          variant="h4"
          color={theme.palette.secondary.main}
          fontWeight={theme.typography.regular}
          sx={{
            cursor: "pointer",
            width: "100%", // Ensures Typography takes full width of its container
            whiteSpace: "nowrap", // Prevents text from wrapping
            overflow: "hidden", // Hides overflow
          }}
        >
          {text}
        </Typography>
      </Stack>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          backgroundColor: theme.palette.primary.main,
          width: "100%",
          height: "2px",
        }}
      ></Stack>
    </Stack>
  );
};

export default CustomDivider;
