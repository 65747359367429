import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getClientAutobiaWallet,
  getClientWallet,
  getMerchantProviders,
} from "../../../app/features/wallet/wallet";
import AutobiaLogoImage from "../../../assets/images/logos/autobia_orange_square.png";
import WalletIconImage from "../../../assets/images/logos/wallet.png";

const useSetInstallmentWallets = () => {
  //NOTE - This hook sets wallet objects with the required format and returns them for usage in UI elements

  //SECTION - general
  const dispatch = useDispatch();

  //SECTION - useState
  const [balanceCardWalletObjects, setBalanceCardWalletObjects] = useState([]);

  //SECTION - useSelector
  const validClientRequestStatus = useSelector(
    (state) => state.walletReducer.validClientStatus
  );
  const autobiaWallet = useSelector(
    (state) => state.walletReducer.autobiaWallet
  );
  const clientWallets = useSelector(
    (state) => state.walletReducer.clientWallets
  );

  useEffect(() => {
    if (validClientRequestStatus === "succeeded") {
      dispatch(getClientWallet());
      if (!autobiaWallet) {
        dispatch(getClientAutobiaWallet());
      }
      dispatch(getMerchantProviders());
    }
  }, [validClientRequestStatus, autobiaWallet, dispatch]);

  useEffect(() => {
    if (autobiaWallet !== null && clientWallets !== null) {
      let wallets = [];
      const totalAutobiaWallet = {
        index: 1,
        isBNPLwallet: false,
        title_prefix: false,
        available_amount: autobiaWallet?.balance || 0,
        cashback: autobiaWallet?.cashback || 0,
        payment_gateway_type: {
          name_ar: "محفظة أوتوبيا",
          name_en: "Autobia Wallet",
          logo: {
            file: AutobiaLogoImage,
          },
        },
      };

      const totalwallet = clientWallets.find(
        (wallet) => wallet.payment_gateway_type === null
      );

      const totalBNPLWallet = {
        index: 2,
        isBNPLwallet: true,
        title_prefix: false,
        available_amount: totalwallet?.available_balance || 0,
        total_amount: totalwallet?.total_payment || 0,
        withdraw_transfer: totalwallet?.total_withdrawal || 0,
        withdraw_payment: totalwallet?.total_withdrawal || 0,
        payment_gateway_type: {
          name_ar: "مجموع محافظ التقسيط",
          name_en: "Total Installment Wallets",
          logo: {
            file: WalletIconImage,
          },
        },
        bnplWallets: clientWallets,
        total_external_transfer: totalwallet?.total_external_transfer || 0,
        total_internal_transfer: totalwallet?.total_internal_transfer || 0,
      };

      wallets.push(totalAutobiaWallet);
      wallets.push(totalBNPLWallet);

      setBalanceCardWalletObjects(wallets);
    }
  }, [clientWallets, autobiaWallet]);

  return { balanceCardWalletObjects };
};

export default useSetInstallmentWallets;
