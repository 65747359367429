import { digitsArToEn } from "@persian-tools/persian-tools";

const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = digitsArToEn(String.fromCharCode(keyCode));
    const numericRegex = /^[0-9]+$/;

    if (!numericRegex.test(keyValue)) {
        event.preventDefault();
    }
};
export default handleKeyPress;
