import { Grid, useTheme, SwipeableDrawer } from "@mui/material";
import { IoChevronDownOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { fetchCartItems } from "../../app/features/cart/cart";
import { useEffect } from "react";
import CustomSearchBox from "./Algolia/CustomSearchBox";
import CustomInfiniteHits from "./Algolia/CustomHits";

const OfferBottomDrawer = ({
  open,
  handleClose,
  handleOpen,
  selectedOffer,
  selectedOfferId,
}) => {
  //SECTION - general
  const theme = useTheme();
  const dispatch = useDispatch();

  //SECTION - useEffect
  useEffect(() => {
    dispatch(fetchCartItems());
  }, [dispatch]);

  return (
    <>
      {selectedOffer && (
        <>
          <SwipeableDrawer
            anchor={"bottom"}
            onClose={handleClose}
            open={open}
            onOpen={handleOpen}
          >
            <Grid
              conatiner
              borderRadius={theme.borderRadius.r15}
              bgcolor={theme.palette.gray.g100}
              gap={theme.spacing(1)}
              paddingTop={theme.spacing(1)}
              sx={{
                maxHeight: "80vh",
                height: "100%",
                overflowY: "auto",
              }}
            >
              <Grid
                xs={12}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                gap={theme.spacing(1)}
              >
                <IoChevronDownOutline
                  fontSize={"2rem"}
                  onClick={handleClose}
                  color={theme.palette.gray.g400}
                />
              </Grid>
              <Grid xs={12} container>
                <CustomSearchBox />
                <CustomInfiniteHits selectedOfferId={selectedOfferId} />
              </Grid>
            </Grid>
          </SwipeableDrawer>
        </>
      )}
    </>
  );
};

export default OfferBottomDrawer;
