import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  homeItemsTableScrollDirection: null,
};

export const interactiveUserInterfaceSlice = createSlice({
  name: "interactiveUserInterface",
  initialState,
  reducers: {
    setHomeItemsTableScrollDirection: (state, action) => {
      state.homeItemsTableScrollDirection = action.payload;
    },
  },
});

export const { setHomeItemsTableScrollDirection } =
  interactiveUserInterfaceSlice.actions;
export default interactiveUserInterfaceSlice.reducer;
