import { Button, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import i18n from "../../../i18n";

const ErrorPage = ({ generalError = false }) => {
  //SECTION - general
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { code } = useParams();

  const errorMessage = t(`errorCodes.${code}`, {
    defaultValue:
      i18n.language === "en"
        ? "Sorry, an unknown error occurred"
        : "عذرا, لقد حصل خطأ غير معروف",
  });

  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
      textAlign={"center"}
      sx={{ height: "80vh" }}
    >
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        direction={"column"}
        textAlign={"center"}
        gap={theme.spacing(2)}
      >
        <Typography
          fontWeight={theme.typography.bold}
          fontSize={{ xs: theme.spacing(6), md: theme.spacing(10) }}
          color={theme.palette.primary.main}
        >
          {generalError
            ? t("errorCodes.generalErrorTryAgain")
            : t("errorCodes.sorry")}
        </Typography>
        {!generalError && (
          <Typography
            fontWeight={theme.typography.bold}
            fontSize={{ xs: theme.spacing(4), md: theme.spacing(8) }}
            textAlign={"center"}
          >
            {code + " - " + errorMessage}
          </Typography>
        )}

        <Button
          variant="contained"
          size="large"
          onClick={() => {
            if (code === "404-OT") {
              window.location.href = "tel:+966920033172";
            } else {
              navigate("/");
            }
          }}
          sx={{
            padding: theme.spacing(1.5),
            border: `2px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.primary.light,
            color: "black",
            borderRadius: theme.borderRadius.r15,
            "&:hover": {
              backgroundColor: theme.palette.primary.veryLight,
            },
          }}
        >
          {code === "404-OT" ? (
            <Typography
              fontWeight={theme.typography.bold}
              fontSize={{
                xs: theme.spacing(2),
                md: theme.spacing(4),
              }}
              textTransform={"none"}
            >
              {t("general.callCustomerSupport")}
            </Typography>
          ) : (
            <Typography
              fontWeight={theme.typography.bold}
              fontSize={{
                xs: theme.spacing(2),
                md: theme.spacing(4),
              }}
              textTransform={"none"}
            >
              {t("navigate.homePage")}
            </Typography>
          )}
        </Button>
      </Stack>
    </Stack>
  );
};

export default ErrorPage;
