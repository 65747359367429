import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
    Box,
    CircularProgress,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import LinearProgress, {
    linearProgressClasses,
} from "@mui/material/LinearProgress";
import React from "react";
import { useTranslation } from "react-i18next";
import { AiFillQuestionCircle } from "react-icons/ai";
import { useSelector } from "react-redux";

const BorderLinearProgress = styled(LinearProgress)(({ currentLang }) => ({
    height: 10,
    borderRadius: 5,
    transform: currentLang === "ar" && "scaleX(-1)",
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#e8e9e9",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        transform: currentLang === "ar" && "scaleX(-1)",
        boxShadow: "0 0px 4px rgba(237, 158, 83, 1.00)",
    },
}));

const ProgressBar = ({
    currentTier,
    nextTier,
    runningTierPoints,
    nextTierPoints,
    currentTierPoints,
}) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const fetchClientLoyaltyTiersStatus = useSelector(
        (state) => state.rewardsReducer.fetchClientLoyaltyTiersStatus
    );

    return (
        <Stack gap={theme.spacing(1)}>
            {fetchClientLoyaltyTiersStatus === "loading" ? (
                <CircularProgress size={25} color="secondary" />
            ) : (
                <Stack
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    gap={theme.spacing(1)}
                >
                    <Typography fontWeight={"bold"}>
                        {t("rewards.tier", { currentTier: currentTier })}
                    </Typography>
                    <Tooltip
                        title={
                            <Typography>
                                {t("rewards.currentMonthNextMonthExplanation")}
                            </Typography>
                        }
                    >
                        <Stack>
                            <AiFillQuestionCircle fontSize={"1.3rem"} />
                        </Stack>
                    </Tooltip>
                </Stack>
            )}

            <Box sx={{ width: "100%", px: theme.spacing(2) }}>
                <BorderLinearProgress
                    variant="determinate"
                    currentLang={i18n.language}
                    value={
                        nextTierPoints
                            ? ((currentTierPoints - runningTierPoints) /
                                  (nextTierPoints - runningTierPoints)) *
                              100
                            : 100
                    }
                />
            </Box>
            {fetchClientLoyaltyTiersStatus === "loading" ? (
                ""
            ) : (
                <Typography
                    textAlign={i18n.language === "en" ? "right" : "left"}
                >
                    {nextTierPoints
                        ? t("rewards.pointsToReach", {
                              numPoints: (
                                  nextTierPoints - currentTierPoints
                              ).toFixed(2),
                              nextTier: nextTier,
                          })
                        : t("rewards.maxTier")}
                </Typography>
            )}
        </Stack>
    );
};

export default ProgressBar;
