import React from "react";
import CustomSearchBox from "./Algolia/CustomSearchBox";
import { Grid } from "@mui/material";
import CustomHits from "./Algolia/CustomHits";

const OfferDetails = ({ selectedOfferId }) => {
  return (
    <>
      <Grid
        semiMd={7.5}
        xxl={8.5}
        container
        height="fit-content"
        maxHeight="80vh"
        // sx={{ overflowY: "auto" }}
      >
        <CustomSearchBox />
        <CustomHits selectedOfferId={selectedOfferId} />
      </Grid>
    </>
  );
};

export default OfferDetails;
