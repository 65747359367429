import { Badge, IconButton } from "@mui/material";
import React from "react";
import { IoCartOutline } from "react-icons/io5";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import getTotalItemsQuantity from "../../../utils/Helpers/getTotalItemsQuantity";

const NavbarCart = () => {
    //SECTION - GENERAL
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    //SECTION - Selectors
    const itemsInBasket = useSelector((state) => state.cartReducer.items);

    //SECTION - FUNCTIONS
    // Calculate the total quantity of items
    const totalQuantity = getTotalItemsQuantity(itemsInBasket);

    return (
        <>
            <IconButton
                size="large"
                onClick={() => {
                    navigate("/cart");
                }}
                aria-label="Cart"
                sx={{ padding: theme.spacing(2) }}
            >
                <Badge
                    badgeContent={totalQuantity === 0 ? 0 : totalQuantity}
                    color={"error"}
                >
                    <IoCartOutline
                        color={
                            location.pathname === "/cart"
                                ? theme.palette.primary.main
                                : theme.palette.tertiary.main
                        }
                    />
                </Badge>
            </IconButton>
        </>
    );
};

export default NavbarCart;
