import brokenImage from "../../assets/images/brokenImage.png";
const FindBrandLogo = (brands, id) => {
  let brandLink = "";
  if (Array.isArray(brands)) {
    brands.forEach((brand) => {
      if (brand.id === id || brand.name_en === id) {
        brandLink = brand.filer.file;
      }
    });
  }

  return brandLink === "" ? brokenImage : brandLink;
};
export default FindBrandLogo;
