import { useTheme } from "@emotion/react";
import {
  Box,
  Divider,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import styled from "@emotion/styled";
import i18next from "i18next";
import { FaLock, FaLockOpen } from "react-icons/fa";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.gray.g200,
  border: `2px solid ${theme.palette.gray.g400}`,
  zIndex: 1,
  color: "#fff",
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: theme.palette.gray.g200,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.light,
    border: `2px solid ${theme.palette.primary.main}`,
  }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 15,
    left: i18next.dir() === "ltr" ? "calc(-50% + 16px)" : "calc(50% + 16px)",
    right: i18next.dir() === "ltr" ? "calc(50% + 16px)" : "calc(-50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.gray.g400,
    borderRadius: 1,
  },
}));

const CircularProgressWithLabel = (props) => {
  const theme = useTheme();
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        {...props}
        size={50}
        thickness={3}
        sx={{
          backgroundColor: theme.palette.gray.g200,
          borderRadius: "50%",
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

const IncrementalStepper = ({ subtotalValue, discountAndThresholds }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  // const steps = [
  //   { label: "Start", percentage: 0, threshold: 0 }, // Initial step with label "Start"
  //   { label: "Step 1", percentage: 10, threshold: 100 },
  //   { label: "Step 2", percentage: 25, threshold: 400 },
  //   { label: "Step 3", percentage: 55, threshold: 900 },
  //   { label: "Step 4", percentage: 66, threshold: 1000 },
  // ];
  const steps = [
    { label: "Start", percentage: 0, threshold: 0 },
    ...discountAndThresholds,
  ];

  const [cicularProggress, setCicularProggress] = useState(10);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    // Calculate the nextStep based on the subtotalValue and threshold values
    let nextStep = 0;
    let stepPercentage = 0;

    for (let i = 0; i < steps.length; i++) {
      if (parseFloat(subtotalValue) >= steps[i].threshold) {
        setActiveStep(i);
        nextStep = i + 1;
      } else {
        break; // No need to check further steps if not met
      }
    }

    // Calculate the difference or the remaining to reach a step
    const stepDifference =
      nextStep === 0
        ? parseFloat(subtotalValue)
        : parseFloat(subtotalValue) - steps[nextStep - 1].threshold;

    //If we have reached the last node, then let the percentage be 100
    if (steps[nextStep]?.threshold === undefined) {
      stepPercentage = 100;
    } else {
      //else, calculate the percentage between each node in terms of 100%
      stepPercentage =
        (stepDifference /
          (steps[nextStep]?.threshold - steps[nextStep - 1]?.threshold || 1)) *
        100;
    }

    setCicularProggress(stepPercentage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtotalValue]);

  const ColorlibStepIcon = (props) => {
    const { active, completed, className, icon } = props;

    return (
      <Stack textAlign={"center"} alignItems={"center"} gap={1}>
        <ColorlibStepIconRoot
          ownerState={{ completed, active }}
          className={className}
        >
          {completed ? (
            <FaLockOpen color={theme.palette.primary.main} />
          ) : (
            <FaLock color={theme.palette.gray.g400} />
          )}
        </ColorlibStepIconRoot>
        <Typography fontSize={"0.9rem"} fontWeight={theme.typography.bold}>
          {`${icon.percentage}%`}
        </Typography>
        <Box
          sx={{
            backgroundColor: completed
              ? theme.palette.primary.light
              : theme.palette.grey[300],
            padding: theme.spacing(0.5),
            borderRadius: theme.borderRadius.r15,
            width: "fit-content",
          }}
        >
          <Typography fontSize={"0.6rem"} fontWeight={theme.typography.bold}>
            {icon.label === "Start"
              ? t("cart.couponTierStart")
              : `${t("cart.spendToGet", {
                  price: icon.threshold,
                })} ${t("currency.SAR")}`}
          </Typography>
        </Box>
      </Stack>
    );
  };

  return (
    <Box>
      <Stack direction={"row"}>
        <Box>
          <CircularProgressWithLabel value={cicularProggress} />
        </Box>
        <Stack mx={theme.spacing(1)}>
          <Typography fontWeight={"bold"} color={theme.palette.secondary.dark}>
            {t("cart.incrementalCouponApplied")}
          </Typography>
          <Typography fontWeight={"bold"} fontSize={"0.8rem"}>
            {activeStep < steps.length - 1
              ? t("cart.remainingTillNextCoupon", {
                  amount:
                    steps[activeStep + 1].threshold - parseFloat(subtotalValue),
                  currency: t("currency.SAR"),
                  percentage: steps[activeStep + 1].percentage,
                })
              : t("cart.congratsEndOfCoupon")}
          </Typography>
        </Stack>
      </Stack>

      <Divider sx={{ marginBottom: theme.spacing(1) }} />
      <Stack
        sx={{
          gap: theme.spacing(1),
        }}
      >
        <Stack
          sx={{
            width: "100%",
            overflow: "scroll", // Hide scrollbars
            position: "relative",
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar for WebKit browsers (Chrome, Safari)
            },
            "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
            "scrollbar-width": "none", // Hide scrollbar for Firefox
          }}
          spacing={4}
        >
          <Stepper
            alternativeLabel
            activeStep={activeStep + 1}
            connector={<ColorlibConnector />}
            sx={{ zIndex: 2 }}
          >
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel StepIconComponent={ColorlibStepIcon} icon={label} />
              </Step>
            ))}
          </Stepper>
        </Stack>
      </Stack>
    </Box>
  );
};

export default IncrementalStepper;
