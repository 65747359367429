import { useTheme } from "@emotion/react";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import BankComponent from "./BankComponent";
import { useTranslation } from "react-i18next";
import alinmaBank from "../../assets/images/logos/alinmaBank.png";
import alrajhiBank from "../../assets/images/logos/alrajhiBank.png";
import alahliBank from "../../assets/images/logos/alahliBank.png";

const Payment = ({ styles, disablePadding }) => {
  // SECTION - GENERAL
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box m={theme.spacing(1)} sx={styles}>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        p={theme.spacing(1)}
        gap={theme.spacing(2)}
      >
        <Typography
          textAlign={"center"}
          fontWeight={theme.typography.bold}
          color={theme.palette.error.main}
        >
          {t("payment.note")}
        </Typography>
        <BankComponent
          name={t("payment.alrajhiBank")}
          account={t("payment.autobia")}
          number={"503608010675918"}
          IBAN={"SA3880000503608010675918"}
          img={alrajhiBank}
        />
        <BankComponent
          name={t("payment.alinmaBank")}
          account={t("payment.autobia")}
          number={"68202884646000"}
          IBAN={"SA0305000068202884646000"}
          img={alinmaBank}
        />
        <BankComponent
          name={t("payment.alahliBank")}
          account={t("payment.autobia")}
          number={"08200000458004"}
          IBAN={"SA5810000008200000458004"}
          img={alahliBank}
        />
      </Stack>
    </Box>
  );
};

export default Payment;
