import { Typography } from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import React from "react";
import { motion } from "framer-motion";
import {
  gestureAnimations,
  titleVariants,
} from "../../../../utils/framerMotionStyles/framerMotionStyles";

const StepCard = ({ title, text, icon, delay }) => {
  //SECTION - general
  const theme = useTheme();

  return (
    <motion.div {...gestureAnimations}>
      <Stack
        direction={"column"}
        justifyContent={"center"}
        gap={"1rem"}
        alignItems={"center"}
        textAlign={"center"}
        padding={"1rem"}
        sx={{
          border: `1px solid ${theme.palette.gray.g200}`,
          borderRadius: theme.borderRadius.r15,
          width: "245px",
          height: "245px",
          borderBottom: `4px solid ${theme.palette.secondary.main}`,
          boxShadow: `0px 4px 8px ${theme.palette.gray.g400}`,
        }}
      >
        <motion.text
          variants={titleVariants}
          initial="hidden"
          whileInView="visible"
          custom={delay} // Using the `custom` prop to pass the delay
          viewport={{ once: true }}
        >
          <Typography
            color={theme.palette.primary.main}
            fontWeight={theme.typography.bold}
            variant="h5"
          >
            {title}
          </Typography>
        </motion.text>
        <motion.text
          variants={titleVariants}
          initial="hidden"
          whileInView="visible"
          custom={delay} // Using the `custom` prop to pass the delay
          viewport={{ once: true }}
        >
          {icon}
          <Typography fontWeight={theme.typography.bold} variant="h6">
            {text}
          </Typography>
        </motion.text>
      </Stack>
    </motion.div>
  );
};

export default StepCard;
