import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { watchTowerInstance } from "../../../utils/api/axiosConfig";

const initialState = {
  cities: [],
  administrativeAreas: [],
  getCitiesStatus: "idle",
  getAdministrativeAreasStatus: "idle",
};

export const fetchAdministrativeAreas = createAsyncThunk(
  "common/fetchAdministrativeAreas",
  async (thunkAPI) => {
    const response = await watchTowerInstance.get(`/administrative-areas/`);
    if (response.status < 200 || response.status >= 300) {
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const fetchCitiesByAdministrativeArea = createAsyncThunk(
  "common/fetchCitiesByAdministrativeArea",
  async (data, thunkAPI) => {
    const response = await watchTowerInstance.get(
      `cities/${
        data?.administrativeAreaID
          ? `?administrative_area=${data?.administrativeAreaID}`
          : ""
      }`
    );
    if (response.status < 200 || response.status >= 300) {
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchCitiesByAdministrativeArea.fulfilled,
      (state, action) => {
        state.cities = action.payload;
        state.getCitiesStatus = "succeeded";
      }
    );
    builder.addCase(
      fetchCitiesByAdministrativeArea.pending,
      (state, action) => {
        state.getCitiesStatus = "loading";
      }
    );
    builder.addCase(
      fetchCitiesByAdministrativeArea.rejected,
      (state, action) => {
        state.getCitiesStatus = "failed";
      }
    );
    builder.addCase(fetchAdministrativeAreas.fulfilled, (state, action) => {
      console.warn("the ares are", action.payload);
      state.administrativeAreas = action.payload;
      state.getAdministrativeAreasStatus = "succeeded";
    });
    builder.addCase(fetchAdministrativeAreas.pending, (state, action) => {
      state.getAdministrativeAreasStatus = "loading";
    });
    builder.addCase(fetchAdministrativeAreas.rejected, (state, action) => {
      state.getAdministrativeAreasStatus = "failed";
    });
  },
});

export default commonSlice.reducer;
