import { Grid, Typography } from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import React from "react";
import alrajhiBank from "../../assets/images/logos/alrajhiBank.png";
import alinmaBank from "../../assets/images/logos/alinmaBank.png";
import alahliBank from "../../assets/images/logos/alahliBank.png";
import { useTranslation } from "react-i18next";
import ModifiedBankComponent from "./ModifiedBankComponent";

const PaymentSection = () => {
  //SECTION - general
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  return (
    <Stack
      paddingX={{ xs: "0px", sm: "50px" }}
      maxWidth={"1600px"}
      sx={{ width: "100%" }}
      gap={{ xs: "0px", md: "16px" }}
      direction={"column"}
      alignItems={"flex-start"}
    >
      <Typography
        textAlign={"center"}
        fontWeight={theme.typography.bold}
        color={theme.palette.error.main}
      >
        {t("payment.note")}
      </Typography>
      <Grid xs={12} container>
        <Grid
          xs={12}
          lg={4}
          container
          paddingLeft={{
            xs: "0rem",
            lg: i18n.language === "ar" ? "1rem" : "0rem",
          }}
          paddingRight={{
            xs: "0rem",
            lg: i18n.language === "en" ? "1rem" : "0rem",
          }}
          paddingY={{ xs: "1rem", lg: "0rem" }}
        >
          <ModifiedBankComponent
            name={t("payment.alrajhiBank")}
            account={t("payment.autobia")}
            number={"503608010675918"}
            IBAN={"SA3880000503608010675918"}
            img={alrajhiBank}
          />
        </Grid>
        <Grid
          xs={12}
          lg={4}
          container
          paddingLeft={{
            xs: "0rem",
            lg: i18n.language === "ar" ? "1rem" : "0rem",
          }}
          paddingY={{ xs: "1rem", lg: "0rem" }}
          paddingRight={{
            xs: "0rem",
            lg: i18n.language === "en" ? "1rem" : "0rem",
          }}
        >
          <ModifiedBankComponent
            name={t("payment.alinmaBank")}
            account={t("payment.autobia")}
            number={"68202884646000"}
            IBAN={"SA0305000068202884646000"}
            img={alinmaBank}
          />
        </Grid>
        <Grid
          xs={12}
          lg={4}
          container
          paddingY={{ xs: "1rem", lg: "0rem" }}
          paddingRight={{
            xs: "0rem",
            lg: i18n.language === "en" ? "1rem" : "0rem",
          }}
        >
          <ModifiedBankComponent
            name={t("payment.alahliBank")}
            account={t("payment.autobia")}
            number={"08200000458004"}
            IBAN={"SA5810000008200000458004"}
            img={alahliBank}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default PaymentSection;
