import ValidateToken from "../validation/ValidateToken";
import IsEmpty from "../validation/IsEmpty";
import { jwtDecode } from "jwt-decode";

export function isAuthenticated() {
  if (localStorage.getItem("accessToken")) {
    const user = jwtDecode(localStorage.getItem("accessToken"));
    if (!IsEmpty(user && user.exp)) {
      if (ValidateToken(user.exp)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function validateWatchtowerToken() {
  if (localStorage.getItem("watchTowerAccessToken")) {
    if (
      ValidateToken(
        jwtDecode(localStorage.getItem("watchTowerAccessToken")).exp
      )
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
