import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { towerInstance } from "../../../utils/api/axiosConfig";

const initialState = {
  registerItemInterestStatus: "idle",
};

export const registerItemInterest = createAsyncThunk(
  "registerInterests/registerItemInterest",
  async (data, thunkAPI) => {
    const response = await towerInstance.post(
      `notification-out-of-stock/${data.brandItemId}`,
      {
        special: data.special,
        ...(data.special && {
          expected_price: parseFloat(data.expected_price),
          time_needed: data.time_needed,
          needed_quantity: parseInt(data.needed_quantity),
        }),
      }
    );
    if (response.status < 200 || response.status >= 300) {
      return thunkAPI.rejectWithValue(response.data);
    } else {
      return response.data;
    }
  }
);

export const registerInterestsSlice = createSlice({
  name: "registerInterests",
  initialState,
  reducers: {
    resetState: (state) => {
      state.registerItemInterestStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(registerItemInterest.fulfilled, (state, action) => {
      state.registerItemInterestStatus = "succeeded";
    });
    builder.addCase(registerItemInterest.pending, (state, action) => {
      state.registerItemInterestStatus = "loading";
    });
    builder.addCase(registerItemInterest.rejected, (state, action) => {
      state.registerItemInterestStatus = "failed";
    });
  },
});
export const { resetState } = registerInterestsSlice.actions;

export default registerInterestsSlice.reducer;
