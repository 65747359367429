import { Modal, Typography, Box } from "@mui/material";
import { BiError } from "react-icons/bi";
import { useTheme } from "@emotion/react";
import ButtonComp from "../../components/common/ButtonComp";
import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    textAlign: "center",
    width: { xs: "90%", sm: "700px" },
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "25px",
    pt: "2rem",
};

const OrderErrorModal = ({ open, handleClose, message, buttonText }) => {
    //SECTION - general
    const theme = useTheme();

    //SECTION - useState
    const [progress, setProgress] = useState(0);

    //SECTION - useEffect
    useEffect(() => {
        let timer;
        if (open) {
            setProgress(0);
            timer = setInterval(() => {
                setProgress((oldProgress) => {
                    const diff = 5;
                    const newProgress = oldProgress + diff;
                    if (newProgress >= 110) {
                        clearInterval(timer);
                        handleClose();
                        return 120;
                    }
                    return newProgress;
                });
            }, 300);
        } else {
            setProgress(0);
        }
        return () => {
            clearInterval(timer);
        };
    }, [open, handleClose]);

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <BiError
                        fontSize={"8rem"}
                        color={theme.palette.error.main}
                    />
                    <Typography
                        fontWeight={theme.typography.bold}
                        fontSize={{ xs: "1.5rem", sm: "2rem" }}
                    >
                        {message}
                    </Typography>

                    <Box sx={{ width: "100%", mt: "2rem" }}>
                        <LinearProgress
                            variant="determinate"
                            color="error"
                            value={progress}
                        />
                    </Box>
                    <ButtonComp
                        content={buttonText}
                        color="error"
                        onClick={() => {
                            handleClose();
                        }}
                        fullWidth
                        sx={{
                            height: "3rem",
                            fontSize: { xs: "1rem", sm: "1.6rem" },
                            fontWeight: theme.typography.bold,
                            borderRadius: "0px 0px 15px 15px",
                        }}
                    />
                </Box>
            </Modal>
        </>
    );
};
export default OrderErrorModal;
