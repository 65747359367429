import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkClientValidity } from "../../app/features/wallet/wallet";
import PromotionPage from "./PromotionPage";
import { CircularProgress, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ErrorPage from "../../components/Template/Error/ErrorPage";
import IsEmpty from "../../utils/validation/IsEmpty";

const IndexWallet = () => {
  //SECTION - general
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("semiMd"));
  const navigate = useNavigate();

  //SECTION - useSelector
  const validClientRequestStatus = useSelector(
    (state) => state.walletReducer.validClientStatus
  );
  const validClient = useSelector((state) => state.walletReducer.validClient);

  //SECTION - useEffect
  useEffect(() => {
    dispatch(checkClientValidity());
  }, [dispatch]);

  useEffect(() => {
    if (validClientRequestStatus === "succeeded") {
      if (validClient) {
        navigate("/wallet/createInvoice/");
      }
    }
  }, [validClientRequestStatus, validClient, navigate]);

  return (
    <>
      {validClientRequestStatus === "loading" ? (
        <Grid
          xs={12}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "80vh" }}
        >
          <CircularProgress size={!isSmallScreen ? 100 : 70} />
        </Grid>
      ) : validClientRequestStatus === "failed" && IsEmpty(validClient) ? (
        <ErrorPage generalError={true} />
      ) : validClientRequestStatus === "succeeded" && !validClient ? (
        <PromotionPage />
      ) : null}
    </>
  );
};

export default IndexWallet;
