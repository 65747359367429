import i18n from "../../../i18n";
export const DisplayStatus = (status, selling_price) => {
  const defaultStatus = {
    selling_price: "",
    color: "primary.main",
    isActive: true,
  };

  if (status === "active") {
    if (selling_price === 0 || selling_price === "") {
      return {
        ...defaultStatus,
        selling_price: i18n.t("home.willBePriced"),
        color: "secondary.main",
      };
    }
    return {
      ...defaultStatus,
      selling_price: `${selling_price} ${i18n.t("currency.SAR")}`,
    };
  }

  if (["out_of_stock", "inactive"].includes(status)) {
    return {
      ...defaultStatus,
      selling_price: i18n.t("home.notAvailable"),
      isActive: false,
    };
  }
  return defaultStatus;
};

export const getTruncatedDescription = (
  description = "",
  isMobileScreen,
  isLargeScreen
) => {
  if (!description) {
    return "";
  }

  const limit = isMobileScreen ? 13 : isLargeScreen ? 40 : description?.length;
  return description?.length > limit
    ? `${description?.slice(0, limit)}...`
    : description;
};
