import { useHierarchicalMenu } from "react-instantsearch";
import { useTheme, List } from "@mui/material";
import { useSelector } from "react-redux";
import ListItem from "./ListItem";
import { useState, useEffect } from "react";

const CustomHierarchicalMenu = (props) => {
  //SECTION - general
  const { items, refine } = useHierarchicalMenu({
    ...props,
  });
  const theme = useTheme();

  //SECTION - useState
  const [selectedItem, setSelectedItem] = useState(null);

  //SECTION - useSelector
  const brands = useSelector((state) => state.brandReducer.brands);

  //SECTION - useEffect
  useEffect(() => {}, [selectedItem]);

  //SECTION - functions
  const handleRefinement = (item) => {
    refine(item.value);
  };

  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        border: `2px solid ${theme.palette.gray.g200}`,
        borderRadius: theme.borderRadius.r15,
        padding: "0px",
      }}
      component="nav"
    >
      {items.map((item, index) => {
        return (
          <ListItem
            key={index}
            firstItem={index === 0}
            lastItem={index === items.length - 1}
            item={item}
            handleRefinement={handleRefinement}
            brands={brands}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        );
      })}
    </List>
  );
};

export default CustomHierarchicalMenu;
