import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BiCheckCircle } from "react-icons/bi";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
} from "@mui/material";
import { useEffect, useState } from "react";

const SuccessInvoiceModal = ({ open, handleClose }) => {
  //SECTION - GENERAL
  const theme = useTheme();
  const { t } = useTranslation();

  //SECTION - useSatate
  const [progress, setProgress] = useState(0);

  //SECTION - useEffects
  useEffect(() => {
    let timer;

    if (open) {
      setProgress(0);
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          const diff = 5;
          const newProgress = oldProgress + diff;
          if (newProgress >= 110) {
            clearInterval(timer);
            handleClose(true);
            return 120;
          }
          return newProgress;
        });
      }, 300);
    } else {
      setProgress(0);
    }

    return () => {
      clearInterval(timer);
    };
  }, [open, handleClose]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        <Grid container flexDirection={"row"} gap={2} alignItems={"center"}>
          <Typography
            fontWeight={theme.typography.bold}
            fontSize={{ xs: "1rem", sm: "1.5rem" }}
          >
            {t("wallet.invoiceSuccessfullyCreated")}
          </Typography>
          <BiCheckCircle
            fontSize={"2rem"}
            color={theme.palette.success["s500"]}
          />
        </Grid>
      </DialogTitle>
      <Box sx={{ width: "100%" }}>
        <LinearProgress
          variant="determinate"
          color="success"
          value={progress}
        />
      </Box>
      <DialogContent sx={{ paddingY: theme.spacing(5) }}>
        <Typography fontSize={{ xs: "1rem" }}>
          {t("wallet.youWillBeRedirectedToWallet")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"space-around"} gap={2}>
          <Button
            sx={{ flex: 1, paddingY: theme.spacing(1) }}
            color="success"
            variant="contained"
            onClick={() => {
              handleClose(true);
            }}
          >
            {t("wallet.confirm")}
          </Button>
          <Button
            sx={{
              flex: 1,
              paddingY: theme.spacing(1),
              color: theme.palette.tertiary.main,
            }}
            onClick={() => {
              handleClose(false);
            }}
          >
            {t("wallet.createNewInvoice")}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessInvoiceModal;
