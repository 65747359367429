import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillExclamationCircle, AiOutlineClose } from "react-icons/ai";
import { NumericFormat } from "react-number-format";
import Backdrop from "@mui/material/Backdrop";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  createRefund,
  getClientWallet,
} from "../../../../app/features/wallet/wallet";
import { notifyWithSuccess } from "../../../../app/features/error/error";
import { ccyFormat } from "../../../../utils/Helpers/general";

//SECTION - styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 800,
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
  maxHeight: "90vh",
  overflowY: "auto",
};

const CreateRefundModal = ({
  open,
  handleClose,
  sessionId,
  transaction_id,
  client_name,
  invoice_total_amount,
  refundsList,
  refund_rate,
  fetchOrders,
}) => {
  //SECTION - general
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [remainingBalance, setRemainingBalance] = useState(0);

  //SECTION - useEffect
  useEffect(() => {
    const calculateRemainingAvailableBalance = () => {
      var remainingBalance = invoice_total_amount;
      refundsList.forEach((refund) => {
        remainingBalance -= refund.amount;
      });
      setRemainingBalance(remainingBalance.toFixed(2));
    };
    if (open) {
      calculateRemainingAvailableBalance();
    }
  }, [open, invoice_total_amount, refundsList]);

  //SECTION - useSelector
  const createRefundState = useSelector(
    (state) => state.walletReducer.createRefundState
  );

  //SECTION - Yup Validation Schema
  const FORM_VALIDATION = Yup.object().shape({
    amount: Yup.number()
      .typeError(
        t("formValidation.number", {
          field: i18n.language === "ar" ? "المبلغ" : "Amount",
        })
      )
      .required(
        t("formValidation.required", {
          field: i18n.language === "ar" ? "المبلغ" : "Amount",
        })
      )
      .positive(
        t("formValidation.positiveNumber", {
          field: i18n.language === "ar" ? "المبلغ" : "Amount",
        })
      )
      .max(
        remainingBalance,
        `${t("wallet.yupMoreThanRemainingBalance", {
          amount: ccyFormat(remainingBalance),
        })} ${t("wallet.riyal")}`
      ),
    reason: Yup.string().required(
      t("formValidation.required", {
        field: i18n.language === "ar" ? "السبب" : "Reason",
      })
    ),
  });

  //SECTION - functions
  const resetFields = () => {
    formik.values.amount = "";
    formik.values.reason = "";
    formik.errors.amount = "";
    formik.errors.reason = "";
    // setRefundFeesAmount(0);
    setRemainingBalance(0);
  };

  const handleSubmitForm = (values) => {
    dispatch(
      createRefund({
        payment_gateway_session_id: sessionId,
        amount: parseFloat(values.amount),
        reason: values.reason,
        platform: "Web Store",
      })
    ).then((payload) => {
      if (payload.type === "wallet/createRefund/fulfilled") {
        dispatch(
          notifyWithSuccess({
            message: t("wallet.refundCreatedSuccessfully"),
            type: "success",
          })
        );
        fetchOrders();
        dispatch(getClientWallet());
        resetFields();
        handleClose();
      }
    });
  };

  //SECTION - Formik
  const formik = useFormik({
    initialValues: { amount: "", reason: "" },
    enableReinitialize: true,
    validationSchema: FORM_VALIDATION,
    onSubmit: handleSubmitForm,
  });

  return (
    <Modal
      open={open}
      onClose={() => {
        resetFields();
        handleClose();
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Stack gap={theme.spacing(2)}>
            <Stack
              alignItems={"center"}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <IconButton sx={{ visibility: "hidden" }}>
                <AiOutlineClose fontSize={"1.5rem"} />
              </IconButton>
              <Typography
                fontWeight={theme.typography.bold}
                fontSize={"1.8rem"}
              >
                {t("wallet.createRefund")}
              </Typography>
              <IconButton
                onClick={() => {
                  resetFields();
                  handleClose();
                }}
              >
                <AiOutlineClose fontSize={"1.5rem"} />
              </IconButton>
            </Stack>

            <Grid
              container
              direction="column"
              gap={1}
              sx={{
                backgroundColor: theme.palette.gray.g75,
                borderRadius: theme.borderRadius.r15,
                paddingY: "1rem",
                paddingX: "1rem",
                minHeight: 100,
              }}
            >
              <Grid
                container
                direction={{ md: "row" }}
                justifyContent="space-between"
                spacing={1}
              >
                <Grid item justifyContent="flex-start" md={2}>
                  <Typography
                    color="secondary"
                    fontWeight={theme.typography.bold}
                  >
                    {t("wallet.transaction_id")}
                  </Typography>
                </Grid>
                <Grid item justifyContent="flex-start" md>
                  <Typography fontWeight={theme.typography.bold}>
                    {transaction_id}
                  </Typography>
                </Grid>
                <Grid item justifyContent="flex-start" md={2}>
                  <Typography
                    color="secondary"
                    fontWeight={theme.typography.bold}
                  >
                    {t("wallet.client_Name")}
                  </Typography>
                </Grid>
                <Grid item justifyContent="flex-start" md>
                  <Typography fontWeight={theme.typography.bold}>
                    {client_name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                direction={{ md: "row" }}
                justifyContent="flex-start"
                gap={1}
              >
                <Grid item justifyContent="flex-start" md={2}>
                  <Typography
                    color="secondary"
                    fontWeight={theme.typography.bold}
                  >
                    {t("wallet.totalAmount")}
                  </Typography>
                </Grid>
                <Grid item justifyContent="flex-start" md>
                  <Typography fontWeight={theme.typography.bold}>
                    {invoice_total_amount} {t("wallet.riyal")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {refundsList.length !== 0 && (
              <>
                <Divider textAlign="left">
                  <Typography
                    color="grey"
                    fontWeight={theme.typography.bold}
                    fontSize={theme.spacing(2.1)}
                  >
                    {t("wallet.refundedListTitle")}
                  </Typography>
                </Divider>
                <Grid
                  container
                  direction="column"
                  gap={1}
                  sx={{
                    backgroundColor: theme.palette.gray.g75,
                    borderRadius: theme.borderRadius.r15,
                    paddingY: "1rem",
                    paddingX: "1rem",
                    minHeight: 50,
                  }}
                >
                  {refundsList.map((refund) => (
                    <Grid
                      container
                      direction={{ md: "row" }}
                      justifyContent="space-between"
                      spacing={1}
                    >
                      <Grid container item justifyContent="flex-start" md={2}>
                        <NumericFormat
                          value={refund.amount}
                          thousandSeparator=","
                          displayType="text"
                          renderText={(value) => (
                            <Typography
                              color="secondary"
                              fontWeight={theme.typography.bold}
                            >
                              {` ${value} ${t("currency.SAR")}`}
                            </Typography>
                          )}
                        />
                      </Grid>
                      <Grid container item justifyContent="flex-start" md>
                        <Typography fontWeight={theme.typography.bold}>
                          {`${t("wallet.prevRefundedAmountOn")} ${moment(
                            refund.created_at
                          ).format("DD/MM/YYYY")}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}

            <form onSubmit={formik.handleSubmit}>
              <Stack gap={theme.spacing(2)}>
                <Grid
                  container
                  alignItems={{
                    xs: "flex-start",
                    sm: "center",
                  }}
                  flexDirection={{
                    xs: "column",
                    sm: "row",
                  }}
                >
                  <Grid
                    xs={12}
                    sm={4}
                    textAlign={i18n.dir() === "ltr" ? "left" : "right"}
                  >
                    <Typography fontWeight={theme.typography.bold}>
                      {`${t("wallet.totalPrice")} (VAT 15%)`}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={8}
                    container
                    // gap={theme.spacing(1)}
                    sx={{ width: "100%" }}
                  >
                    <TextField
                      color="secondary"
                      fullWidth
                      size="small"
                      name="amount"
                      value={formik.values.amount}
                      onChange={(e) => {
                        formik.handleChange("amount")(e);
                      }}
                      onBlur={formik.handleBlur}
                      placeholder={t("wallet.addRefundPlaceholder")}
                      error={
                        formik.touched.amount && Boolean(formik.errors.amount)
                      }
                      helperText={formik.touched.amount && formik.errors.amount}
                      InputProps={{
                        sx: {
                          borderRadius: "10px 10px 0px 0px",
                          overflow: "hidden",
                          height: "40px",
                          backgroundColor: theme.palette.white,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              color="secondary"
                              fontWeight={theme.typography.bold}
                            >
                              {t("wallet.riyal")}
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: i18n.dir() === "ltr" ? "left" : "right",
                        },
                      }}
                    />
                    <Stack
                      gap={theme.spacing(1)}
                      textAlign={i18n.languages[0] === "ar" ? "right" : "left"}
                      width={"100%"}
                    >
                      <Stack
                        bgcolor={theme.palette.secondary.light}
                        direction={{
                          xs: "column",
                          sm: "row",
                        }}
                        justifyContent={"space-between"}
                        alignItems={{
                          xs: "flex-start",
                          sm: "center",
                        }}
                        paddingX={theme.spacing(1)}
                        paddingY={theme.spacing(0.5)}
                        gap={{
                          xs: theme.spacing(2),
                        }}
                        borderRadius={"0px 0px 10px 10px"}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={theme.spacing(1)}
                        >
                          <AiFillExclamationCircle
                            fontSize={{
                              xs: "2rem",
                            }}
                          />

                          <NumericFormat
                            value={remainingBalance}
                            thousandSeparator=","
                            displayType="text"
                            renderText={(value) => (
                              <Typography
                                fontSize={{
                                  xs: "0.8rem",
                                  sm: "0.9rem",
                                }}
                              >
                                {`${t("wallet.maxRefundAmountIs")} ${value} ${t(
                                  "currency.SAR"
                                )}`}
                              </Typography>
                            )}
                          />
                          <Typography
                            fontSize={{
                              xs: "0.8rem",
                              sm: "0.9rem",
                            }}
                          >
                            {`${t("wallet.refundFeesPercentage")} ${ccyFormat(
                              refund_rate * 100
                            )}%`}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems={{
                    xs: "flex-start",
                    sm: "center",
                  }}
                  flexDirection={{
                    xs: "column",
                    sm: "row",
                  }}
                >
                  <Grid
                    xs={12}
                    sm={4}
                    textAlign={i18n.dir() === "ltr" ? "left" : "right"}
                  >
                    <Typography fontWeight={theme.typography.bold}>
                      {t("wallet.reason")}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={8}
                    container
                    gap={theme.spacing(1)}
                    sx={{ width: "100%" }}
                  >
                    <TextField
                      color="secondary"
                      fullWidth
                      size="small"
                      name="reason"
                      multiline
                      rows={4}
                      value={formik.values.reason}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={t("wallet.addReasonPlaceholder")}
                      error={
                        formik.touched.reason && Boolean(formik.errors.reason)
                      }
                      helperText={formik.touched.reason && formik.errors.reason}
                      InputProps={{
                        sx: {
                          borderRadius: "10px",
                          overflow: "hidden",
                          backgroundColor: theme.palette.white,
                        },
                      }}
                      FormHelperTextProps={{
                        sx: {
                          textAlign: i18n.dir() === "ltr" ? "left" : "right",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Box mt={theme.spacing(2)}>
                  <Button
                    color="secondary"
                    variant="contained"
                    fullWidth
                    disabled={
                      !(formik.isValid && formik.dirty) ||
                      createRefundState === "loading"
                    }
                    onClick={formik.handleSubmit}
                  >
                    {createRefundState === "loading" ? (
                      <CircularProgress size={25} color="white" />
                    ) : (
                      <Typography>{t("wallet.createRefund")}</Typography>
                    )}
                  </Button>
                </Box>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreateRefundModal;
