import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { BiCheckCircle } from "react-icons/bi";
import { useTheme } from "@emotion/react";
import ButtonComp from "../../components/common/ButtonComp";
import { useTranslation } from "react-i18next";
import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    flushItems,
    getCartItems,
    getSaleOrderId,
} from "../../app/features/cart/cart";
import IsEmpty from "../../utils/validation/IsEmpty";
import getTotalItemsQuantity from "../../utils/Helpers/getTotalItemsQuantity";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    textAlign: "center",
    width: { xs: "90%", sm: "700px" },
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "25px",
    pt: "2rem",
};

const SuccessModal = ({ open, handleClose }) => {
    //SECTION - GENERAL
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //SECTION - useSatate
    const [progress, setProgress] = useState(0);

    //SECTION - Selectors
    const saleOrderId = useSelector(getSaleOrderId);
    const orderItems = useSelector(getCartItems);

    //SECTION - useEffects
    useEffect(() => {
        let timer;

        if (open) {
            setProgress(0);
            timer = setInterval(() => {
                setProgress((oldProgress) => {
                    const diff = 5;
                    const newProgress = oldProgress + diff;
                    if (newProgress >= 110) {
                        clearInterval(timer);
                        dispatch(flushItems());
                        handleClose();
                        return 120;
                    }
                    return newProgress;
                });
            }, 300);
        } else {
            setProgress(0);
        }

        return () => {
            clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, handleClose]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <BiCheckCircle
                    fontSize={"8rem"}
                    color={theme.palette.success.main}
                />
                <Typography
                    fontWeight={theme.typography.bold}
                    fontSize={{ xs: "1.5rem", sm: "2rem" }}
                >
                    {t("cart.thanksForShopping")}
                </Typography>
                <Typography fontSize={"1.5rem"} pt={theme.spacing(4)}>
                    {t("cart.yourOrderNumber")}:
                    <Typography
                        fontWeight={theme.typography.bold}
                        fontSize={"1.5rem"}
                        variant="p"
                    >
                        {!IsEmpty(saleOrderId) && saleOrderId}
                    </Typography>
                </Typography>
                {/* <Typography fontSize={"1.5rem"}>
          {t("cart.numberOfItems")}: {orderItems.length} {t("cart.items")}
        </Typography> */}
                <Typography
                    color={theme.palette.secondary.dark}
                    fontWeight={theme.typography.bold}
                    fontSize={"1.5rem"}
                >
                    {t("cart.manyItems", { numOfItems: orderItems.length })}
                    {" • "}
                    {t("cart.manyQuantities", {
                        numOfQuantity: getTotalItemsQuantity(orderItems),
                    })}
                </Typography>
                <Box sx={{ width: "100%", mt: "2rem" }}>
                    <LinearProgress
                        variant="determinate"
                        color="success"
                        value={progress}
                    />
                </Box>
                <ButtonComp
                    content={t("cart.done")}
                    color="success"
                    onClick={() => {
                        dispatch(flushItems());
                        handleClose();
                    }}
                    fullWidth
                    sx={{
                        height: "3rem",
                        fontSize: { xs: "1rem", sm: "1.6rem" },
                        fontWeight: theme.typography.bold,
                        borderRadius: "0px 0px 15px 15px",
                    }}
                />
            </Box>
        </Modal>
    );
};

export default SuccessModal;
