import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { towerInstance } from "../../../utils/api/axiosConfig";
import { formulateTowerError } from "../../../utils/Helpers/formulateTowerError";
import { notifyWithError } from "../error/error";

const initialState = {
  orders: null,
  ordersRequiringConfirmation: null,
  regularOrders: null,
  selectedOrder: null,
  selectedOrderId: null,
  status: "idle",
  confirmOrderStatus: "idle",
  fetchSpecificOrderStatus: "idle",
  notificationDisplayStatus: true,
};

// export const customerConfirmOrder = createAsyncThunk(
//   "cart/customerConfirmOrder",
//   async (orderId) => {
//     try {
//       const response = await SingletonClient.confirmOrder(orderId);
//       return response.data;
//     } catch (error) {
//       throw Error("Failed to confirm order");
//     }
//   }
// );

export const customerConfirmOrder = createAsyncThunk(
  "order/customerConfirmOrder",
  async (orderId, thunkAPI) => {
    const response = await towerInstance.put(
      `sale-order/${orderId}/customer-approve`
    );
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(formulateTowerError(response.data));
    } else {
      return response.data.data;
    }
  }
);

// export const fetchSpecificOrder = createAsyncThunk(
//   "cart/fetchSpecificOrder",
//   async (orderId) => {
//     try {
//       const response = await SingletonClient.getSpecificOrder(orderId);
//       return response.data;
//     } catch (error) {
//       throw Error("Failed to fetch specific order from the API");
//     }
//   }
// );

export const fetchSpecificOrder = createAsyncThunk(
  "order/fetchSpecificOrder",
  async (orderId, thunkAPI) => {
    const response = await towerInstance.get(`sale-order/${orderId}`);
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(formulateTowerError(response.data));
    } else {
      return response.data.data;
    }
  }
);

// export const fetchOrders = createAsyncThunk("cart/fetchOrders", async () => {
//   try {
//     const response = await SingletonClient.getPreviousOrders();

//     return response.data;
//   } catch (error) {
//     throw Error("Failed to fetch orders from the API");
//   }
// });

export const fetchOrders = createAsyncThunk(
  "order/fetchOrders",
  async (thunkAPI) => {
    const response = await towerInstance.get(`sale-order?page=1`);
    if (response.status < 200 || response.status >= 300) {
      thunkAPI.dispatch(
        notifyWithError({
          message: formulateTowerError(response.data),
          type: "error",
        })
      );
      return thunkAPI.rejectWithValue(formulateTowerError(response.data));
    } else {
      return response.data.data;
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    selectOrder: (state, action) => {
      state.selectedOrderId = action.payload;
    },
    resetOrders: (state, action) => initialState,
    closeNotification: (state, action) => {
      state.notificationDisplayStatus = false;
    },
    updateUnconfirmedOrders: (state, action) => {
      state.ordersRequiringConfirmation.push(action.payload);
      state.regularOrders.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    //SECTION - fetchOrders
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.orders = action.payload.sort((a, b) => {
        if (
          a.status === "waiting_approvement" &&
          b.status !== "waiting_approvement"
        ) {
          return -1; // a comes before b
        } else if (
          a.status !== "waiting_approvement" &&
          b.status === "waiting_approvement"
        ) {
          return 1; // b comes before a
        } else {
          return 0; // maintain the original order
        }
      });
      state.ordersRequiringConfirmation = action.payload.filter(
        (order) => order.status === "waiting_approvement"
      );
      state.regularOrders = action.payload.filter(
        (order) => order.status !== "waiting_approvement"
      );
    });
    builder.addCase(fetchOrders.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchOrders.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    //SECTION - fetchSpecificOrder
    builder.addCase(fetchSpecificOrder.fulfilled, (state, action) => {
      state.fetchSpecificOrderStatus = "succeeded";
      state.selectedOrder = action.payload;
      state.selectedOrderId = action.payload.id;
    });
    builder.addCase(fetchSpecificOrder.pending, (state, action) => {
      state.fetchSpecificOrderStatus = "loading";
    });
    builder.addCase(fetchSpecificOrder.rejected, (state, action) => {
      state.fetchSpecificOrderStatus = "failed";
      state.error = action.error.message;
    });
    //SECTION - customerConfirmOrder
    builder.addCase(customerConfirmOrder.fulfilled, (state, action) => {
      state.confirmOrderStatus = "succeeded";
    });
    builder.addCase(customerConfirmOrder.pending, (state, action) => {
      state.confirmOrderStatus = "loading";
    });
    builder.addCase(customerConfirmOrder.rejected, (state, action) => {
      state.confirmOrderStatus = "failed";
      state.error = action.error.message;
    });
  },
});

export const {
  selectOrder,
  resetOrders,
  closeNotification,
  updateUnconfirmedOrders,
} = orderSlice.actions;

export default orderSlice.reducer;

export const selectOrders = (state) => state.orderReducer.orders;
export const currentlySelectedOrder = (state) =>
  state.orderReducer.selectedOrder;
export const currentlySelectedOrderId = (state) =>
  state.orderReducer.selectedOrderId;
export const orderStatus = (state) => state.orderReducer.status;
export const specificOrderStatus = (state) =>
  state.orderReducer.specificOrderStatus;
export const getConfirmOrderStatus = (state) =>
  state.orderReducer.confirmOrderStatus;
export const getFetchSpecificOrderStatus = (state) =>
  state.orderReducer.fetchSpecificOrderStatus;
export const getOrdersRequiringConfirmation = (state) =>
  state.orderReducer.ordersRequiringConfirmation;
export const getRegularOrders = (state) => state.orderReducer.regularOrders;
export const getNotificationDisplayStatus = (state) =>
  state.orderReducer.notificationDisplayStatus;
