import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IoArrowBackOutline,
  IoArrowForwardOutline,
  IoCreateOutline,
  IoTrashOutline,
} from "react-icons/io5";
import ButtonComp from "../../components/common/ButtonComp";
import { useFormik } from "formik";
import * as Yup from "yup";
import SuccessModal from "./SuccessModal";
import { useDispatch, useSelector } from "react-redux";
import {
  addAddress,
  getAddressStatus,
  getFetchAddressStatus,
  getIsNewUserAddress,
  getUserAddress,
  updateAddress,
} from "../../app/features/address/address";
import IsEmpty from "../../utils/validation/IsEmpty";
import CalculateCartPrice from "../../utils/data/CalculateCartPrice";
import {
  getCartId,
  submitCartOrder,
  applyCoupon,
  getCoupon,
  getConstantDiscount,
  isCouponApplied,
  removeCoupon,
  getPutCartStatus,
  getCouponCartStatus,
  getConfirmOrderCartStatus,
  getCartItems,
  fetchCartItems,
  getFetchCartStatus,
  getPricingConfig,
  getTotalOrders,
} from "../../app/features/cart/cart";
import { BiInfoCircle } from "react-icons/bi";
import { NumericFormat } from "react-number-format";
import ProgressBar from "./ProgressBar";
import IncrementalStepper from "./IncrementalStepper";
import { PiCurrencyDollarFill } from "react-icons/pi";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaShippingFast } from "react-icons/fa";
import { FaCircleQuestion } from "react-icons/fa6";
import WaitingPricingModal from "./WaitingPricingModal";
import {
  fetchAdministrativeAreas,
  fetchCitiesByAdministrativeArea,
} from "../../app/features/common/common";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import {
  checkClientValidity,
  payWithInstallment,
} from "../../app/features/wallet/wallet";
import { useNavigate } from "react-router-dom";
import { ccyFormat } from "../../utils/Helpers/general";
import useSetInstallmentWallets from "../Wallet/hooks/useSetInstallmentWallets";
import useCheckClientValidity from "../Wallet/hooks/useCheckClientValidity";
import { fetchSpecificOrder } from "../../app/features/order/order";
import CustomSwitch from "./components/CustomSwitch";

const CartCheckout = () => {
  //SECTION - General
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentLang = localStorage.getItem("lng");
  const { phone } = require("phone");
  const navigate = useNavigate();

  //SECTION - useStates
  const [shippingCompanyTooltipOpen, setShippingCompanyTooltipOpen] =
    useState(false);
  const [isShippingFormOpen, setIsShippingFormOpen] = useState(false);
  const [couponCode, setCouponCode] = useState(""); // State to store the coupon code value written by user
  const [openSuccessModal, setOpenSuccessModal] = useState(false); //Success Confirm order modal
  const [userAddress, setUserAddress] = useState({
    company_name: "",
    phone: "",
    country_id: 1,
    administrative_area_id: "",
    city_name: "",
    shipping_company: "",
    address: "",
  });
  const [cartTotals, setCartTotals] = useState({
    price: "0.00",
    shippingPrice: "0",
    discountValue: "-",
    VAT: "0",
    totalPrice: "0.00",
  });
  const [openWaitingPricingModal, setOpenWaitingPricingModal] = useState(false);
  const [installmentBalanceTooltipOpen, setInstallmentBalanceTooltipOpen] =
    useState(false);
  const [useWalletBalance, setUseWalletBalance] = useState(false);

  //SECTION - Selectors
  const fetchedAddress = useSelector(getUserAddress);
  const fetchAddressStatus = useSelector(getFetchAddressStatus);
  const createOrUpdateAddressStatus = useSelector(getAddressStatus);
  const putCartStatus = useSelector(getPutCartStatus);
  const fetchCartStatus = useSelector(getFetchCartStatus);
  const couponCartStatus = useSelector(getCouponCartStatus);
  const confirmOrderCartStatus = useSelector(getConfirmOrderCartStatus);
  const addressId = useSelector(getUserAddress).address_id;
  const cartId = useSelector(getCartId);
  const cartItems = useSelector(getCartItems);
  const couponObj = useSelector(getCoupon);
  const constantDiscount = useSelector(getConstantDiscount);
  const isCoupApplied = useSelector(isCouponApplied);
  const isNewUserAddress = useSelector(getIsNewUserAddress);
  const totalOrders = useSelector(getTotalOrders);
  const pricingConfig = useSelector(getPricingConfig);
  const clientValidityForReward = useSelector(
    (state) => state.rewardsReducer.clientValidForRewards
  );
  const cities = useSelector((state) => state.commonReducer.cities);
  const administrativeAreas = useSelector(
    (state) => state.commonReducer.administrativeAreas
  );
  const validClient = useSelector((state) => state.walletReducer.validClient);
  const validClientRequestStatus = useSelector(
    (state) => state.walletReducer.validClientStatus
  );
  const getWalletStatus = useSelector(
    (state) => state.walletReducer.getClientWalletStatus
  );
  const getAutobiaWalletStatus = useSelector(
    (state) => state.walletReducer.getClientAutobiaWalletStatus
  );
  const canViewWallet = useSelector(
    (state) => state.walletReducer.userCanViewWallet
  );

  //SECTION - Yup Validation Schema
  const FORM_VALIDATION = Yup.object({
    company_name: Yup.string()
      .min(3, t("cart.yupCompanyMinLength"))
      .max(150, t("cart.yupCompanyMaxLength"))
      .required(t("cart.yupCompanyRequired")),
    phone: Yup.string()
      .matches(/^[^[\u0600-\u06FF\s]+$/u, t("cart.yupPhoneNotInArabic"))
      .min(10, t("cart.yupPhoneLess10"))
      .test("phone", t("cart.yupPhoneInvalid"), (value) => {
        const phoneNumber = phone(value, { country: "SA" });
        return phoneNumber.isValid;
      })
      .required(t("cart.yupPhoneRequired")),
    administrative_area_id: Yup.string().required(
      t("cart.yupAdministrativeRequired")
    ),
    city_name: Yup.string()
      .min(3, t("cart.yupCityMinLength"))
      .max(50, t("cart.yupCityMaxLength"))
      .required(t("cart.yupCityRequired")),
    shipping_company: Yup.string()
      .min(3, t("cart.yupShippingCompanyMinLength"))
      .max(150, t("cart.yupShippingCompanyMaxLength"))
      .required(t("cart.yupShippingCompanyRequired")),
    address: Yup.string()
      .max(250, t("cart.yupAddressMaxLength"))
      .required(t("cart.yupAddressRequired")),
  });

  //SECTION - Formik
  const formik = useFormik({
    initialValues: { ...userAddress },
    validationSchema: FORM_VALIDATION,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (IsEmpty(addressId)) {
        createUserAddress(values);
      } else {
        updateUserAddress(values);
      }
    },
  });

  //SECTION - custom hooks
  useCheckClientValidity();
  const { balanceCardWalletObjects } = useSetInstallmentWallets();

  let installmentBalance = parseFloat(
    balanceCardWalletObjects.find((wallet) => wallet?.isBNPLwallet === true)
      ?.available_amount
  ).toFixed(2);

  //SECTION - useEffect

  // Update form values when address is fetched from the store
  useEffect(() => {
    if (fetchedAddress) {
      setUserAddress(() => ({
        company_name: fetchedAddress.company_name,
        phone: fetchedAddress.phone,
        country_id: fetchedAddress.country_id,
        administrative_area_id: fetchedAddress.administrative_area_id,
        city_name: fetchedAddress.city_name,
        shipping_company: fetchedAddress.shipping_company,
        address: fetchedAddress.address,
      }));
    }
  }, [fetchedAddress]);

  // Update pricing calculation whenever one of the dependencies changes
  useEffect(() => {
    if (!IsEmpty(cartItems)) {
      setCartTotals(
        CalculateCartPrice(
          totalOrders,
          pricingConfig === null ? {} : pricingConfig,
          cartItems,
          couponObj === null ? {} : couponObj,
          constantDiscount === null ? 0 : constantDiscount,
          isCoupApplied,
          currentLang
        )
      );
    }
  }, [
    couponCartStatus,
    putCartStatus,
    couponObj,
    currentLang,
    constantDiscount,
    cartItems,
    isCoupApplied,
    totalOrders,
    pricingConfig,
  ]);

  //If coupon exists and is applied, then set the couponCode value to it
  useEffect(() => {
    if (IsEmpty(couponCode) && isCoupApplied) {
      setCouponCode(couponObj.code);
    } else if (couponCartStatus === "failed") {
      setCouponCode("");
    }
  }, [couponObj, couponCartStatus, isCoupApplied, couponCode]);

  useEffect(() => {
    if (createOrUpdateAddressStatus === "succeeded") {
      setIsShippingFormOpen(false);
    }
  }, [createOrUpdateAddressStatus]);

  useEffect(() => {
    dispatch(fetchAdministrativeAreas());
    dispatch(fetchCitiesByAdministrativeArea());
  }, [dispatch]);

  useEffect(() => {
    dispatch(checkClientValidity());
  }, [dispatch]);

  //SECTION - Functions

  //Success Confirm order modal
  const handleOpenSuccessModal = () => setOpenSuccessModal(true);
  const handleCloseSuccessModal = () => {
    dispatch(fetchCartItems());
    setOpenSuccessModal(false);
    navigate("/orders");
  };

  const updateUserAddress = (values) => {
    isAddressFieldNotFilled();

    const data = {
      ...values,
      address_id: addressId,
      country_id: 1,
    };

    dispatch(updateAddress(data));
  };

  const createUserAddress = (values) => {
    const data = {
      ...values,
      country_id: 1,
    };
    dispatch(addAddress(data));
  };

  const submitCouponCode = () => {
    if (!IsEmpty(couponCode) && !IsEmpty(cartId)) {
      const data = {
        couponCode,
        cartId,
      };
      dispatch(applyCoupon(data));
    }
  };

  const removeCouponCode = () => {
    if (!IsEmpty(couponObj.id) && !IsEmpty(cartId)) {
      const data = {
        couponId: couponObj.id,
        cartId: cartId,
      };
      dispatch(removeCoupon(data)).then(() => {
        if (couponCartStatus === "succeeded") {
          setCouponCode("");
        }
      });
    }
  };

  const submitOrder = () => {
    const data = {
      address_id: addressId,
      app_id: 4,
      cartId,
    };
    if (!IsEmpty(addressId) && !IsEmpty(cartId)) {
      dispatch(submitCartOrder(data)).then((res) => {
        if (res.payload?.type === "success") {
          handleOpenSuccessModal();
          setCouponCode("");
          setCartTotals({
            price: 0,
            shippingPrice: 0,
            discountValue: "-",
            VAT: 0,
            totalPrice: 0,
          });
          if (useWalletBalance) {
            dispatch(
              payWithInstallment({
                order_id: cartId,
                order_amount: parseFloat(cartTotals.totalPrice),
              })
            );
          }
          setUseWalletBalance(false);
          dispatch(fetchSpecificOrder(cartId));
        }
      });
    }
  };

  //disable the confirm button when address fields are not filled, addressId not available, cartItems is empty
  const disableConfirmBtn = () => {
    return (
      isAddressFieldNotFilled() ||
      IsEmpty(addressId) ||
      IsEmpty(cartItems) ||
      cartItems.length === 0 ||
      confirmOrderCartStatus === "loading"
    );
  };

  //Checks if address fields are filled or not - used to warn new users to fill address + disable confirm btn
  const isAddressFieldNotFilled = () => {
    return (
      IsEmpty(userAddress.address) ||
      IsEmpty(userAddress.administrative_area_id) ||
      IsEmpty(userAddress.city_name) ||
      IsEmpty(userAddress.company_name) ||
      IsEmpty(userAddress.phone) ||
      IsEmpty(userAddress.shipping_company)
    );
  };

  useEffect(() => {
    if (isNewUserAddress) {
      setIsShippingFormOpen(true);
    }
  }, [isNewUserAddress]);
  return (
    <Stack
      gap={theme.spacing(2)}
      position={"sticky"}
      top={-190}
      pt={{ xs: "1rem", semiMd: "0px" }}
    >
      <WaitingPricingModal
        open={openWaitingPricingModal}
        onClose={() => setOpenWaitingPricingModal(false)}
      />

      {/* //SECTION - HEAD - {ORDER DETAILS} */}

      <Box
        bgcolor={theme.palette.gray.g100}
        textAlign={"center"}
        py={theme.spacing(1)}
        borderRadius={"25px 25px 0px 0px"}
      >
        <Typography variant="h6" fontWeight={theme.typography.bold}>
          {t("cart.orderDetails")}
        </Typography>
      </Box>

      {/* //SECTION - Address Form */}

      <Box
        border={
          (fetchAddressStatus === "failed" ||
            createOrUpdateAddressStatus === "failed") &&
          `1px solid ${theme.palette.error.main}`
        }
        bgcolor={theme.palette.gray.g100}
        p={theme.spacing(2)}
      >
        <form onSubmit={formik.handleSubmit}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            pb={theme.spacing(2)}
          >
            <Grid container alignItems={"center"} gap={theme.spacing(1)}>
              {/* //NOTE - user is new, no adrress? show info icon */}
              {(isNewUserAddress || isAddressFieldNotFilled()) && (
                <BiInfoCircle color={theme.palette.error.main} />
              )}
              <Typography
                fontWeight={theme.typography.bold}
                color={theme.palette.secondary.main}
                variant="subtitle1"
              >
                {t("cart.shippingDetails")}
              </Typography>
            </Grid>

            {isShippingFormOpen ? (
              <Stack direction={"row-reverse"} gap={theme.spacing(1)}>
                {!isNewUserAddress && (
                  <>
                    <ButtonComp
                      content={t("cart.submit")}
                      variant="contained"
                      type="submit"
                      size="small"
                      color={"secondary"}
                      disabled={
                        !formik.isValid ||
                        createOrUpdateAddressStatus === "loading"
                      }
                    />
                    <ButtonComp
                      content={t("cart.back")}
                      startIcon={
                        currentLang === "ar" ? (
                          <IoArrowForwardOutline />
                        ) : (
                          <IoArrowBackOutline />
                        )
                      }
                      onClick={() => {
                        setIsShippingFormOpen(false);
                        formik.handleReset();
                      }}
                      variant="outlined"
                      size="small"
                      color={"secondary"}
                    />
                  </>
                )}
              </Stack>
            ) : (
              <IoCreateOutline
                onClick={() => {
                  setIsShippingFormOpen(true);
                }}
                cursor={"pointer"}
                fontSize={"1.2rem"}
                color={theme.palette.secondary.main}
              />
            )}
          </Stack>
          <Grid
            container
            px={theme.spacing(1)}
            justifyContent={"space-between"}
          >
            <Grid item xs={12}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent={{ sm: "space-between" }}
              >
                {isShippingFormOpen ? (
                  <TextField
                    fullWidth
                    name="company_name"
                    label={t("cart.companyName")}
                    placeholder={userAddress.company_name}
                    size="small"
                    value={formik.values.company_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.company_name &&
                      Boolean(formik.errors.company_name)
                    }
                    helperText={
                      formik.touched.company_name && formik.errors.company_name
                    }
                  />
                ) : (
                  <>
                    <Typography
                      variant="subtitle2"
                      fontWeight={theme.typography.bold}
                    >
                      {t("cart.companyName")}:
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      textAlign={
                        currentLang === "ar"
                          ? {
                              xs: "right",
                              sm: "left",
                            }
                          : {
                              xs: "left",
                              sm: "right",
                            }
                      }
                    >
                      {userAddress.company_name}
                    </Typography>
                  </>
                )}
              </Stack>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent={{ sm: "space-between" }}
              >
                {isShippingFormOpen ? (
                  <TextField
                    fullWidth
                    sx={{ mt: theme.spacing(1) }}
                    name="phone"
                    label={t("cart.phoneNumber")}
                    placeholder={userAddress.phone}
                    size="small"
                    value={formik.values.phone}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                ) : (
                  <>
                    <Typography
                      variant="subtitle2"
                      fontWeight={theme.typography.bold}
                    >
                      {t("cart.phoneNumber")}:
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      textAlign={
                        currentLang === "ar"
                          ? {
                              xs: "right",
                              sm: "left",
                            }
                          : {
                              xs: "left",
                              sm: "right",
                            }
                      }
                    >
                      {userAddress.phone}
                    </Typography>
                  </>
                )}
              </Stack>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent={{ sm: "space-between" }}
              >
                {isShippingFormOpen ? (
                  <TextField
                    fullWidth
                    variant="outlined"
                    select
                    sx={{ mt: theme.spacing(1) }}
                    name="administrative_area_id"
                    label={t("cart.administrativeArea")}
                    placeholder={userAddress.administrative_area_id}
                    size="small"
                    value={formik.values.administrative_area_id}
                    onChange={(e) => {
                      formik.setFieldValue("city_name", "");
                      dispatch(
                        fetchCitiesByAdministrativeArea({
                          administrativeAreaID: e.target.value,
                        })
                      );
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.administrative_area_id &&
                      Boolean(formik.errors.administrative_area_id)
                    }
                    helperText={
                      formik.touched.administrative_area_id &&
                      formik.errors.administrative_area_id
                    }
                  >
                    {administrativeAreas?.map((area) => (
                      <MenuItem key={area.id} value={area.id}>
                        {i18n.language === "ar" ? area?.name_ar : area?.name_en}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <>
                    <Typography
                      variant="subtitle2"
                      fontWeight={theme.typography.bold}
                    >
                      {t("cart.administrativeArea")}:
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      textAlign={
                        currentLang === "ar"
                          ? {
                              xs: "right",
                              sm: "left",
                            }
                          : {
                              xs: "left",
                              sm: "right",
                            }
                      }
                    >
                      {
                        administrativeAreas.filter(
                          (area) =>
                            area &&
                            area.id &&
                            userAddress &&
                            area.id === userAddress.administrative_area_id
                        )[0]?.[i18n.language === "ar" ? "name_ar" : "name_en"]
                      }
                    </Typography>
                  </>
                )}
              </Stack>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent={{ sm: "space-between" }}
              >
                {isShippingFormOpen ? (
                  <>
                    <TextField
                      fullWidth
                      variant="outlined"
                      select
                      sx={{ mt: theme.spacing(1) }}
                      name="city_name"
                      label={t("cart.cityName")}
                      placeholder={userAddress.city_name}
                      size="small"
                      value={formik.values.city_name}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.city_name &&
                        Boolean(formik.errors.city_name)
                      }
                      helperText={
                        formik.touched.city_name && formik.errors.city_name
                      }
                    >
                      {cities.map((item, pos) => {
                        return (
                          <MenuItem
                            key={pos}
                            value={
                              i18n.language === "ar"
                                ? item?.name_ar
                                : item?.name_en
                            }
                          >
                            {i18n.language === "ar"
                              ? item?.name_ar
                              : item?.name_en}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </>
                ) : (
                  <>
                    <Typography
                      variant="subtitle2"
                      fontWeight={theme.typography.bold}
                    >
                      {t("cart.cityName")}:
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      textAlign={
                        currentLang === "ar"
                          ? {
                              xs: "right",
                              sm: "left",
                            }
                          : {
                              xs: "left",
                              sm: "right",
                            }
                      }
                    >
                      {userAddress.city_name}
                    </Typography>
                  </>
                )}
              </Stack>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent={{ sm: "space-between" }}
              >
                {isShippingFormOpen ? (
                  <TextField
                    fullWidth
                    sx={{ mt: theme.spacing(1) }}
                    name="shipping_company"
                    label={t("cart.shippingCompany")}
                    placeholder={userAddress.shipping_company}
                    size="small"
                    value={formik.values.shipping_company}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.shipping_company &&
                      Boolean(formik.errors.shipping_company)
                    }
                    helperText={
                      formik.touched.shipping_company &&
                      formik.errors.shipping_company
                    }
                  />
                ) : (
                  <>
                    <Tooltip
                      arrow
                      title={
                        <Typography>
                          {t("cart.shippingCompanyTooltip")}
                        </Typography>
                      }
                      open={shippingCompanyTooltipOpen}
                    >
                      <Typography
                        onClick={() => {
                          setShippingCompanyTooltipOpen(true);
                          setTimeout(() => {
                            setShippingCompanyTooltipOpen(false);
                          }, [2000]);
                        }}
                        variant="subtitle2"
                        fontWeight={theme.typography.bold}
                        onMouseEnter={() => {
                          setShippingCompanyTooltipOpen(true);
                        }}
                        onMouseOut={() => {
                          setShippingCompanyTooltipOpen(false);
                        }}
                      >
                        {t("cart.shippingCompany")}:
                      </Typography>
                    </Tooltip>

                    <Typography
                      variant="subtitle2"
                      textAlign={
                        currentLang === "ar"
                          ? {
                              xs: "right",
                              sm: "left",
                            }
                          : {
                              xs: "left",
                              sm: "right",
                            }
                      }
                    >
                      {userAddress.shipping_company}
                    </Typography>
                  </>
                )}
              </Stack>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent={{ sm: "space-between" }}
              >
                {isShippingFormOpen ? (
                  <TextField
                    fullWidth
                    sx={{ mt: theme.spacing(1) }}
                    name="address"
                    label={t("cart.address")}
                    placeholder={userAddress.address}
                    size="small"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  />
                ) : (
                  <>
                    <Typography
                      variant="subtitle2"
                      fontWeight={theme.typography.bold}
                    >
                      {t("cart.address")}:
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      textAlign={
                        currentLang === "ar"
                          ? {
                              xs: "right",
                              sm: "left",
                            }
                          : {
                              xs: "left",
                              sm: "right",
                            }
                      }
                    >
                      {userAddress.address}
                    </Typography>
                  </>
                )}
              </Stack>
            </Grid>
            {createOrUpdateAddressStatus === "loading" && (
              <Box width={"100%"}>
                <LinearProgress color="secondary" />
              </Box>
            )}
          </Grid>
          {isNewUserAddress && (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              textAlign={"center"}
              paddingTop="1rem"
            >
              <Typography
                color={theme.palette.error.dark}
                fontSize={{ xs: "0.8rem", md: "0.9rem", lg: "1rem" }}
              >
                {t("cart.addressRequiredToCheckout")}
              </Typography>
              <ButtonComp
                content={t("cart.submit")}
                variant="contained"
                type="submit"
                size="small"
                color={"secondary"}
                disabled={
                  !formik.isValid || createOrUpdateAddressStatus === "loading"
                }
              />
            </Stack>
          )}
        </form>
      </Box>

      {/* //SECTION - Coupon */}

      <Box bgcolor={theme.palette.gray.g100} p={theme.spacing(2)}>
        <Typography
          fontWeight={theme.typography.bold}
          color={theme.palette.secondary.main}
        >
          {t("cart.coupon")}
        </Typography>
        <Stack direction={"row"} gap={theme.spacing(2)} py={theme.spacing(2)}>
          <OutlinedInput
            color="primary"
            disabled={isCoupApplied}
            fullWidth
            sx={{
              boxShadow: theme.shadows[3],
              borderRadius: "10px",
              height: "40px",
              backgroundColor: theme.palette.white,
            }}
            placeholder={t("cart.enterCoupon")}
            value={couponCode}
            onKeyDown={(e) => {
              e.key === "Enter" &&
                fetchCartStatus === "succeeded" &&
                submitCouponCode();

              // if (e.key === "Enter") {
              //   if(fetchCartStatus === "succeeded"){
              //   submitCouponCode();
              //   }
              // }
            }}
            onChange={(e) => setCouponCode(e.target.value)} // Update the coupon code state
            endAdornment={
              couponCode &&
              !isCoupApplied && ( // Show the trash icon only when there is something typed
                <InputAdornment
                  position="end"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setCouponCode("");
                  }}
                >
                  <IoTrashOutline color={theme.palette.error.main} />
                </InputAdornment>
              )
            }
          />
          {isCoupApplied ? (
            <ButtonComp
              content={
                couponCartStatus === "loading" ? (
                  <CircularProgress size={"1rem"} />
                ) : (
                  t("cart.remove")
                )
              }
              color="error"
              onClick={() => {
                removeCouponCode();
              }}
              sx={{ borderRadius: "10px" }}
            />
          ) : (
            <ButtonComp
              content={
                couponCartStatus === "loading" ? (
                  <CircularProgress size={"1rem"} />
                ) : (
                  t("cart.apply")
                )
              }
              disabled={couponCode === "" || fetchCartStatus !== "succeeded"}
              color="secondary"
              onClick={() => {
                submitCouponCode();
              }}
              sx={{ borderRadius: "10px" }}
            />
          )}
        </Stack>

        {isCoupApplied &&
          (couponObj?.is_incremental === false ||
            !cartTotals.isWillBePriced) && (
            <Typography
              bgcolor={theme.palette.success.light}
              borderRadius={theme.borderRadius.r10}
              p={theme.spacing(0.5)}
              color={theme.palette.success.main}
            >
              {`${t("cart.youEarnedCoupon", {
                typeCoupon:
                  cartTotals.couponType === "cashback"
                    ? t("cart.cashback")
                    : t("cart.discount"),
              })}: ${
                cartTotals.couponType === "cashback"
                  ? cartTotals.cashback
                  : cartTotals.discountValue
              }`}
            </Typography>
          )}
        {couponObj?.is_incremental && (
          <Box sx={{ position: "relative" }}>
            {cartTotals.isWillBePriced && (
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  zIndex: 1000,
                  backdropFilter: "blur(2px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "black",
                  fontSize: "30px",
                  fontWeight: "bold",
                  textShadow: "2px 2px 0px white",
                }}
              >
                {t("cart.willBePriced")}
              </Box>
            )}
            <IncrementalStepper
              subtotalValue={cartTotals.price}
              isUserNew={cartTotals.isUserNew}
              discountAndThresholds={couponObj?.discount_thresholds}
            />
          </Box>
        )}
      </Box>
      {/* //SECTION - use installment balance */}
      {validClientRequestStatus === "succeeded" && (
        <Box
          bgcolor={theme.palette.gray.g100}
          p={theme.spacing(2)}
          sx={{ position: "relative" }}
        >
          {!validClient && (
            <Stack
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: 1000,
                backdropFilter: "blur(10px)",
                top: 0,
                left: 0,
              }}
              justifyContent={"center"}
              alignItems={"center"}
              direction={"column"}
              textAlign={"center"}
              gap={"0.5rem"}
            >
              <AiOutlineExclamationCircle
                fontSize={"3rem"}
                color={theme.palette.secondary.main}
              />
              <Typography variant="body1" fontWeight={theme.typography.bold}>
                {t("wallet.promotion.payWithInstallmentBalance")}
              </Typography>
              <Typography variant="body2">
                {t("wallet.promotion.notRegistered") + " "}
                <Typography
                  component="span"
                  variant="body2"
                  color={"secondary.main"}
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => navigate("/installment-services")}
                  fontWeight={theme.typography.bold}
                >
                  {t("wallet.promotion.knowMore")}
                </Typography>
              </Typography>
            </Stack>
          )}

          <Stack
            justifyContent={"space-between"}
            direction={"row"}
            alignItems={"center"}
            paddingBottom={theme.spacing(2)}
          >
            <Typography
              fontWeight={theme.typography.bold}
              color={theme.palette.secondary.main}
            >
              {t("wallet.promotion.payWithInstallmentBalance")}
            </Typography>
          </Stack>
          <Stack
            px={theme.spacing(1)}
            gap={theme.spacing(1)}
            direction={"column"}
          >
            {canViewWallet && (
              <Stack
                justifyContent={"space-between"}
                direction={"row"}
                alignItems={"center"}
              >
                <Typography>
                  {t("wallet.promotion.totalWalletsBalance")}
                </Typography>
                <Typography>
                  {getWalletStatus === "succeeded" &&
                  getAutobiaWalletStatus === "succeeded"
                    ? ccyFormat(installmentBalance) + " " + t("currency.SAR")
                    : "-"}
                </Typography>
              </Stack>
            )}

            <Stack direction={"row"} justifyContent={"space-between"}>
              <Stack direction={"row"} gap={"1rem"} alignItems={"center"}>
                <Typography>
                  {t("wallet.promotion.useInstallmentBalance")}
                </Typography>
                {validClient && (
                  <Tooltip
                    arrow
                    title={
                      <Typography variant="body2">
                        {t(
                          "wallet.promotion.useInstallmentBalanceIllustration"
                        )}
                      </Typography>
                    }
                    open={installmentBalanceTooltipOpen}
                    onClick={() => {
                      setInstallmentBalanceTooltipOpen(true);
                      setTimeout(() => {
                        setInstallmentBalanceTooltipOpen(false);
                      }, 2000); // Removed unnecessary array brackets around the delay
                    }}
                    onMouseEnter={() => {
                      setInstallmentBalanceTooltipOpen(true);
                    }}
                    onMouseLeave={() => {
                      setInstallmentBalanceTooltipOpen(false);
                    }}
                  >
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                        cursor: "pointer",
                        color: theme.palette.yellow.main,
                        "&:hover": {
                          color: theme.palette.yellow.dark,
                        },
                      }}
                    >
                      <FaCircleQuestion fontSize={"1.3rem"} />
                    </Stack>
                  </Tooltip>
                )}
              </Stack>
              <CustomSwitch
                useWalletBalance={useWalletBalance}
                setUseWalletBalance={setUseWalletBalance}
                installmentBalance={installmentBalance}
              />
            </Stack>
          </Stack>
        </Box>
      )}

      {/* //SECTION - Totals Calculations */}

      <Box bgcolor={theme.palette.gray.g100}>
        <ProgressBar
          subtotalValue={cartTotals.price}
          isUserNew={cartTotals.isUserNew}
        />
        {/* //NOTE - if there exists an item that doesn't have price, then show message to user */}
        {cartItems.some((item) => item.items_detail.selling_price === 0) && (
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"0.5rem"}
            sx={{
              backgroundColor: theme.palette.yellow.light,
              borderRadius: theme.borderRadius.r10,
              border: `2px solid ${theme.palette.yellow.main}`,
              padding: theme.spacing(0.5),
              margin: theme.spacing(1),
            }}
          >
            <Typography
              fontWeight={theme.typography.bold}
              color={theme.palette.yellow.dark}
              fontSize={{ xs: "0.8rem", md: "0.9rem", lg: "1rem" }}
            >
              {t("cart.waitPricing")}
            </Typography>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                cursor: "pointer",
                color: theme.palette.yellow.dark,
                "&:hover": {
                  color: theme.palette.yellow.main,
                },
              }}
              onClick={() => setOpenWaitingPricingModal(true)}
            >
              <FaCircleQuestion fontSize={"1.3rem"} />
            </Stack>
          </Stack>
        )}
        <Grid container p={theme.spacing(2)}>
          <Grid xs={12} paddingBottom={theme.spacing(2)}>
            <Typography
              fontWeight={theme.typography.bold}
              color={theme.palette.secondary.main}
            >
              {t("cart.paymentDetails")}
            </Typography>
          </Grid>
          <Grid
            xs={6}
            sm={5}
            container
            item
            direction={"column"}
            px={theme.spacing(1)}
            gap={theme.spacing(1)}
          >
            <Typography>{t("cart.subtotal")}</Typography>
            <Typography>{t("cart.discount")}</Typography>
            <Typography>{t("cart.shippingPrice")}</Typography>
            <Typography>{t("cart.VAT")} 15%</Typography>
            <Stack direction={"column"}>
              <Typography
                color={theme.palette.secondary.dark}
                fontWeight={theme.typography.bold}
                sx={{ whiteSpace: { lg: "nowrap" } }}
                variant="h6"
              >
                {t("cart.total")}
              </Typography>
              <Typography color={theme.palette.gray.g400} fontSize={"0.6rem"}>
                {t("cart.VATInclusive")}
              </Typography>
            </Stack>
          </Grid>

          <Grid
            xs={6}
            sm={7}
            textAlign={
              currentLang === "ar" || currentLang === null ? "left" : "right"
            }
            container
            item
            direction={"column"}
            px={theme.spacing(1)}
            gap={theme.spacing(1)}
          >
            <NumericFormat
              value={cartTotals.price}
              suffix={` ${t("currency.SAR")} `}
              thousandSeparator=","
              displayType="text"
              type="text"
              renderText={(value) => <Typography>{value}</Typography>}
            />
            <Typography>{`${cartTotals?.pureDiscountValue} `}</Typography>
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              suffix={` ${t("currency.SAR")} `}
              renderText={() => (
                <Typography>{cartTotals.shippingPrice}</Typography>
              )}
            />
            <NumericFormat
              value={cartTotals.VAT}
              thousandSeparator=","
              suffix={` ${t("currency.SAR")} `}
              displayType="text"
              renderText={(value) => <Typography>{value}</Typography>}
            />
            <NumericFormat
              value={cartTotals.totalPrice}
              thousandSeparator=","
              suffix={` ${t("currency.SAR")} `}
              displayType="text"
              renderText={(value) => (
                <Typography
                  color={theme.palette.secondary.dark}
                  fontWeight={theme.typography.bold}
                >
                  {value}
                </Typography>
              )}
            />
          </Grid>
          <SuccessModal
            handleClose={handleCloseSuccessModal}
            open={openSuccessModal}
          />
        </Grid>
      </Box>
      <Box>
        <Grid item xs={12} sm={12}>
          {clientValidityForReward && (
            <Stack
              backgroundColor="#fdf8f0"
              direction={"row"}
              padding={theme.spacing(1)}
              paddingX={theme.spacing(2)}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={theme.spacing(2)}
                width={cartTotals.isShippingFree ? "50%" : "100%"}
                justifyContent={"center"}
              >
                <Box
                  sx={{
                    width: "32px",
                    height: "32px",
                    position: "relative",
                  }}
                >
                  <PiCurrencyDollarFill fontSize={"2rem"} />
                  <Box
                    sx={{
                      width: "16px",
                      height: "16px",
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      backgroundColor: "white",
                      borderRadius: "50%",
                      lineHeight: 0,
                    }}
                  >
                    <BsCheckCircleFill color={theme.palette.success.main} />
                  </Box>
                </Box>
                <Typography>
                  {cartTotals.isWillBePriced
                    ? t("cart.willBePriced")
                    : t("cart.earnedRewardPoints", {
                        points: parseFloat(cartTotals.price) / 10,
                      })}
                </Typography>
              </Stack>

              {cartTotals.isShippingFree && (
                <>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ mx: theme.spacing(1) }}
                  />
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={theme.spacing(2)}
                    width={"50%"}
                    justifyContent={"center"}
                  >
                    <Box
                      sx={{
                        width: "32px",
                        height: "32px",
                        position: "relative",
                      }}
                    >
                      <FaShippingFast fontSize={"2rem"} />
                      <Box
                        sx={{
                          width: "16px",
                          height: "16px",
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          backgroundColor: "white",
                          borderRadius: "50%",
                          lineHeight: 0,
                        }}
                      >
                        <BsCheckCircleFill color={theme.palette.success.main} />
                      </Box>
                    </Box>
                    <Typography>{t("cart.freeShipping")}</Typography>
                  </Stack>
                </>
              )}
            </Stack>
          )}
          <ButtonComp
            content={
              confirmOrderCartStatus === "loading" ? (
                <CircularProgress size={"1.2rem"} />
              ) : (
                t("cart.confirmOrder")
              )
            }
            color="secondary"
            disabled={disableConfirmBtn()}
            onClick={() => {
              submitOrder();
            }}
            fullWidth
            sx={{
              height: "3rem",
              fontSize: { xs: "1rem", sm: "1.2rem" },
              fontWeight: theme.typography.bold,
              borderRadius: "0px 0px 15px 15px",
            }}
          />
        </Grid>
      </Box>
    </Stack>
  );
};

export default CartCheckout;
