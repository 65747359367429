import React from "react";
import {
  Typography,
  useTheme,
  Stack,
  Button,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { GoDotFill } from "react-icons/go";
import { FaFilePdf } from "react-icons/fa6";
import WebStepper from "./WebStepper";
import { useSelector } from "react-redux";
import moment from "moment";
import validation from "../../utils/Helpers/validation";

const FirstSection = ({ steps, currentStep, currentStepIndex }) => {
  //SECTION - general
  const { t } = useTranslation();
  const theme = useTheme();
  const isAboveMedium = useMediaQuery(theme.breakpoints.up("md"));
  const isAboveSmall = useMediaQuery(theme.breakpoints.up("sm"));

  //SECTION - useSelector
  const orderTrackingInformation = useSelector(
    (state) => state.orderTrackingReducer.orderTrackingInformation
  );

  //SECTION - stats
  const stats = [
    {
      label: t("orderTracking.totalItems"),
      quantity: orderTrackingInformation?.count_items,
    },
    {
      label: t("orderTracking.totalQuantity"),
      quantity: orderTrackingInformation?.total_items,
    },
    {
      label: t("orderTracking.available"),
      quantity: orderTrackingInformation?.total_available,
    },
    {
      label: t("orderTracking.unavailable"),
      quantity: orderTrackingInformation?.total_unavailable,
    },
  ];

  return (
    <Stack
      sx={{ width: "100%" }}
      maxWidth={"1600px"}
      paddingX={{ xs: "0px", sm: "50px" }}
    >
      <Stack
        sx={{
          backgroundColor: theme.palette.secondary.main,
          borderRadius: theme.borderRadius.r10,
          padding: "16px",
          width: "100%",
        }}
        direction={"column"}
        gap={"16px"}
      >
        <Stack direction={"column"} gap={"4px"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"column"} gap={"4px"}>
              <Typography
                fontWeight={theme.typography.bold}
                variant="h4"
                color={"white"}
                style={{
                  textShadow: "0 0 4px black",
                }}
              >
                {t("orderTracking.orderNo", {
                  orderNo: orderTrackingInformation?.id,
                })}
              </Typography>
            </Stack>
            <Button
              variant="contained"
              disabled={validation.isEmpty(
                orderTrackingInformation?.invoice_file_url
              )}
              color="secondary"
              sx={{
                paddingY: { xs: "4px", md: "8px" },
                paddingX: { xs: "8px", md: "16px" },
                backgroundColor: theme.palette.secondary.light,
                borderRadius: "8px",
                minHeight: "34px",
                minWidth: "100px",
                gap: "6px",
                ":hover": {
                  backgroundColor: theme.palette.secondary.light,
                },
                ":disabled": {
                  opacity: 0.5, // Adjust opacity to make the button less shiny
                  backgroundColor: theme.palette.secondary.light,
                },
                textTransform: "none",
              }}
              onClick={() =>
                window.open(orderTrackingInformation?.invoice_file_url)
              }
            >
              <Typography
                color={theme.palette.secondary.main}
                variant="body1"
                fontWeight={theme.typography.bold}
              >
                {t("orderTracking.printInvoice")}
              </Typography>
              <FaFilePdf
                color={theme.palette.secondary.main}
                fontSize={isAboveSmall ? "14px" : "12px"}
              />
            </Button>
          </Stack>
          <Stack direction={"row"} gap={"4px"} alignItems={"center"}>
            <Typography
              color={theme.palette.gray.g200}
              fontWeight={theme.typography.medium}
              variant="body1"
            >
              {moment(orderTrackingInformation?.approved_at).format(
                "DD/MM/YYYY"
              )}
            </Typography>
            <GoDotFill color={"white"} fontSize={"0.5rem"} />
            {orderTrackingInformation?.status && (
              <Stack
                sx={{
                  borderRadius: theme.borderRadius.r10,
                  backgroundColor: ["completed", "invoice"].includes(
                    orderTrackingInformation?.status.toLowerCase()
                  )
                    ? theme.palette.success.s50
                    : theme.palette.yellow.light,
                  color: ["completed", "invoice"].includes(
                    orderTrackingInformation?.status.toLowerCase()
                  )
                    ? theme.palette.success.s500
                    : theme.palette.yellow.main,
                  paddingX: "0.5rem",
                  fontSize: { xs: "10px", md: "14px", lg: "14px" },
                }}
              >
                <Typography variant="body2">
                  {["completed", "invoice"].includes(
                    orderTrackingInformation?.status.toLowerCase()
                  )
                    ? t("orderTracking.completed")
                    : t("orderTracking.notCompleted")}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>

        {isAboveMedium && (
          <Stack
            sx={{
              borderRadius: theme.borderRadius.r10,
            }}
          >
            <Stack
              padding={{ md: "8px", lg: "16px" }}
              sx={{
                backgroundColor: "white",
                borderTopLeftRadius: theme.borderRadius.r10,
                borderTopRightRadius: theme.borderRadius.r10,
                width: "100%",
              }}
            >
              <WebStepper currentStep={currentStep} steps={steps} />
            </Stack>
            {currentStep && (
              <Stack
                sx={{
                  height: "28px",
                  backgroundColor:
                    currentStep.status === "orderTracking.statuses.completed"
                      ? theme.palette.success.main
                      : currentStep.status ===
                        "orderTracking.statuses.waitingPayment"
                      ? theme.palette.error.main
                      : theme.palette.primary.main,
                  borderBottomLeftRadius: theme.borderRadius.r10,
                  borderBottomRightRadius: theme.borderRadius.r10,
                  padding: "4px",
                  color: "white",
                }}
                justifyContent={"space-between"}
                direction={"row"}
                textAlign={"center"}
              >
                <Typography fontWeight={theme.typography.bold} variant="body2">
                  <span>{t(currentStep.status) + " : "}</span>
                  <span style={{ fontWeight: theme.typography.regular }}>
                    {t(currentStep.description)}
                  </span>
                </Typography>
              </Stack>
            )}
          </Stack>
        )}
        <Stack
          gap={"8px"}
          direction={"row"}
          sx={{ flexWrap: "nowrap", overflowX: "auto" }}
        >
          {stats.map((stat) => {
            return (
              <Stack
                direction={"column"}
                gap={"8px"}
                sx={{
                  color: "white",
                  backgroundColor: "#7C8CF9",
                  paddingX: "16px",
                  paddingY: "4px",
                  borderRadius: theme.borderRadius.r10,
                  width: "100%",
                  minWidth: { xs: "150px", sm: "160px" },
                }}
              >
                <Typography variant="h6">{stat.label}</Typography>
                <Typography fontWeight={theme.typography.bold} variant="h5">
                  {validation.isNumber(stat.quantity) ? (
                    <>
                      <span>{stat.quantity}</span>
                      <span
                        style={{
                          fontWeight: theme.typography.regular,
                          fontSize: isAboveSmall ? "18px" : "14px",
                        }}
                      >
                        {" " + t("orderTracking.parts")}
                      </span>
                    </>
                  ) : (
                    <CircularProgress color="white" size={20} />
                  )}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FirstSection;
