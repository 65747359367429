import React from "react";
import { Typography, useTheme, Stepper, Step, StepLabel } from "@mui/material";
import { GoDotFill } from "react-icons/go";
import { styled } from "@mui/system";
import { IoIosCheckmark } from "react-icons/io";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import i18next from "i18next";
import { BiError } from "react-icons/bi";
import { MdAvTimer } from "react-icons/md";
import { useTranslation } from "react-i18next";

const WebStepper = ({ currentStep, steps }) => {
  //SECTION - general
  const theme = useTheme();
  const { t } = useTranslation();

  //SECTION - styles
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 15,
      left: i18next.dir() === "ltr" ? "calc(-50% + 16px)" : "calc(50% + 16px)",
      right: i18next.dir() === "ltr" ? "calc(50% + 16px)" : "calc(-50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: theme.palette.success.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: theme.palette.success.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 2,
      border: 0,
      backgroundColor: theme.palette.gray.g400,
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(
    ({ theme, ownerState, currentStep }) => ({
      border: `2px solid ${theme.palette.gray.g400}`,
      zIndex: 1,
      color: "#fff",
      width: 32,
      height: 32,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      ...(ownerState.active && {
        backgroundColor:
          currentStep?.status === "orderTracking.statuses.completed"
            ? theme.palette.success.main
            : currentStep?.status === "orderTracking.statuses.waitingPayment"
            ? theme.palette.error.main
            : theme.palette.primary.main,
        border:
          currentStep?.status === "orderTracking.statuses.completed"
            ? theme.palette.success.main
            : currentStep?.status === "orderTracking.statuses.waitingPayment"
            ? `2px solid ${theme.palette.error.main}`
            : `2px solid ${theme.palette.primary.main}`,
      }),
      ...(ownerState.completed && {
        backgroundColor:
          currentStep?.status === "orderTracking.statuses.waitingPayment"
            ? theme.palette.error.main
            : theme.palette.success.main,
        border:
          currentStep?.status === "orderTracking.statuses.waitingPayment"
            ? `2px solid ${theme.palette.error.main}`
            : `2px solid ${theme.palette.success.main}`,
      }),
    })
  );

  //SECTION - functions
  const ColorlibStepIcon = (props) => {
    const { active, completed, className, currentStep } = props;
    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
        currentStep={currentStep}
      >
        {(currentStep.status === "orderTracking.statuses.completed") &
        active ? (
          <IoIosCheckmark color={"white"} size={"2rem"} />
        ) : (currentStep.status === "orderTracking.statuses.waitingPayment") &
          active ? (
          <BiError color={"white"} size={"1.3rem"} />
        ) : completed ? (
          <IoIosCheckmark color={"white"} size={"2rem"} />
        ) : active ? (
          <MdAvTimer color={"white"} size={"1.3rem"} />
        ) : (
          <GoDotFill color={theme.palette.gray.g400} size={"1rem"} />
        )}
      </ColorlibStepIconRoot>
    );
  };

  return (
    <>
      <Stepper
        alternativeLabel
        activeStep={steps.findIndex(
          (step) => step?.status === currentStep?.status
        )}
        connector={<ColorlibConnector />}
      >
        {steps.map((step) => (
          <Step key={step.status}>
            <StepLabel
              StepIconComponent={(props) => (
                <ColorlibStepIcon {...props} currentStep={step} />
              )}
            >
              <Typography fontWeight={theme.typography.bold} variant="body2">
                {t(step.status)}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

export default WebStepper;
