import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack, useTheme } from "@mui/system";
import { IoClose } from "react-icons/io5";
import { GiSandsOfTime } from "react-icons/gi";
import { useTranslation } from "react-i18next";

const WaitingPricingModal = ({ open, onClose }) => {
  // SECTION - general
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          borderRadius: theme.borderRadius.r15,
          padding: "1rem",
          width: "90%",
          maxWidth: "600px",
          border: "0px",
        }}
      >
        <Stack gap={"2rem"} sx={{ textAlign: "center" }}>
          <Stack direction={"row"} justifyContent={"flex-end"}>
            <Stack sx={{ cursor: "pointer" }} onClick={onClose}>
              <IoClose fontSize={"2rem"} />
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <GiSandsOfTime
              fontSize={"7rem"}
              color={theme.palette.secondary.main}
            />
          </Stack>
          <Stack>
            <Typography color={theme.palette.gray.g400} variant="h6">
              {t("cart.waitPricingExplanation")}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default WaitingPricingModal;
