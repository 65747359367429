import {
  Grid,
  Typography,
  useTheme,
  SwipeableDrawer,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IoChevronDownOutline } from "react-icons/io5";
import CalculateOrderPrice from "../../utils/data/CalculateOrderPrice";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getConfirmOrderStatus,
  customerConfirmOrder,
} from "../../app/features/order/order";
import { fetchCartItems } from "../../app/features/cart/cart";
import { useEffect } from "react";
import OrderItem from "./OrderItem";
import ButtonComp from "../../components/common/ButtonComp";
import OrderSuccessModal from "./OrderSuccessModal";
import OrderErrorModal from "./OrderErrorModal";
import displayOrderStatus from "../../utils/data/DisplayOrderStatus";
import { NumericFormat } from "react-number-format";
import { BsQuestionCircleFill } from "react-icons/bs";
import { Stack } from "@mui/system";

const OrderBottomDrawer = ({
  open,
  handleClose,
  handleOpen,
  selectedOrder,
  brands,
  refetchOrder,
  updateUIOrderId,
}) => {
  //SECTION - general
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  //SECTION - useState
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [discountTooltip, setDiscountTooltip] = useState(false);
  const [cashbackTooltip, setCashbackTooltip] = useState(false);

  //SECTION - useSelector
  const confirmOrderCurrentStatus = useSelector(getConfirmOrderStatus);

  //SECTION - useEffect
  useEffect(() => {
    dispatch(fetchCartItems());
  }, [dispatch]);

  //SECTION - functions
  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
    refetchOrder(selectedOrder.id);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const handleConfirm = () => {
    dispatch(customerConfirmOrder(selectedOrder.id)).then((data) => {
      if (data.error) {
        setOpenErrorModal(true);
      } else {
        setOpenSuccessModal(true);
        updateUIOrderId(selectedOrder.id);
      }
    });
  };
  const watchTowerCouponData = {
    coupon_activated_at: selectedOrder?.coupon_activated_at,
    coupon_applied_at: selectedOrder?.coupon_applied_at,
    coupon_applied_by: selectedOrder?.coupon_applied_by,
    coupon_id: selectedOrder?.coupon_id,
    coupon_percentage: selectedOrder?.coupon_percentage,
    coupon_type: selectedOrder?.coupon_type,
    coupon_max_discount: selectedOrder?.coupon_max_price,
    coupon_details: selectedOrder?.coupon_details,
  };
  const {
    priceWithoutDiscount,
    discount,
    cashBack,
    shippingPrice,
    vat,
    totalPriceWithVat,
    totalPriceWithoutVat,
    discountMessage,
    cashBackMessage,
  } = CalculateOrderPrice(
    selectedOrder.saleOrderItems,
    selectedOrder.shipping_price,
    selectedOrder.constant_discount,
    watchTowerCouponData,
    selectedOrder.status
  );

  return (
    <>
      {selectedOrder && (
        <>
          <OrderSuccessModal
            open={openSuccessModal}
            handleClose={handleCloseSuccessModal}
            orderId={selectedOrder.id}
            orderItems={selectedOrder.saleOrderItems}
          />
          <OrderErrorModal
            open={openErrorModal}
            handleClose={handleCloseErrorModal}
            message={t("order.confirmOrderError")}
            buttonText={t("order.done")}
          />
          <SwipeableDrawer
            anchor={"bottom"}
            onClose={handleClose}
            open={open}
            onOpen={handleOpen}
          >
            <Grid
              conatiner
              borderRadius={theme.borderRadius.r15}
              bgcolor={theme.palette.gray.g100}
              gap={theme.spacing(1)}
              padding={theme.spacing(2)}
              sx={{
                maxHeight: "80vh",
                height: "100%",
                overflowY: "auto",
              }}
            >
              <Grid
                xs={12}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                gap={theme.spacing(1)}
              >
                <IoChevronDownOutline
                  onClick={handleClose}
                  fontSize={"2rem"}
                  color={theme.palette.gray.g400}
                />
                <Typography
                  fontWeight={theme.typography.bold}
                  color={theme.palette.secondary.dark}
                  variant={"h6"}
                >
                  {t("order.orderDetails")}
                </Typography>
                <Typography
                  fontWeight={theme.typography.bold}
                  color={theme.palette.secondary.dark}
                  variant={"h6"}
                >
                  {t("order.orderNo")} {selectedOrder.id}
                </Typography>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  wrap="true"
                  gap={theme.spacing(0.5)}
                >
                  <Typography color={theme.palette.gray.g400} variant={"h6"}>
                    {t("order.orderStatus")}
                  </Typography>
                  <Typography
                    color={
                      theme.palette[
                        displayOrderStatus(selectedOrder.status).color
                      ].main
                    }
                    variant={"h6"}
                    fontWeight={theme.typography.bold}
                  >
                    {displayOrderStatus(selectedOrder.status).status}
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={12}>
                <Grid
                  xs={12}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  paddingBottom={theme.spacing(1)}
                  sx={{
                    borderBottom: `1px solid ${theme.palette.gray.g400}`,
                  }}
                >
                  <Grid
                    xs={4}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography color={theme.palette.gray.g400}>
                      {t("order.items")}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={4}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography color={theme.palette.gray.g400}>
                      {t("order.quantity")}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={4}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography color={theme.palette.gray.g400}>
                      {t("order.priceTableHeader")}
                    </Typography>
                  </Grid>
                </Grid>
                {
                  <Grid
                    sx={{
                      height: "300px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {selectedOrder.saleOrderItems.map((item) => {
                      return (
                        <OrderItem key={item.id} item={item} brands={brands} />
                      );
                    })}
                  </Grid>
                }
              </Grid>
              {selectedOrder.status !== "pricing" ? (
                <Grid xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="true"
                    gap={0.5}
                    paddingTop={theme.spacing(1)}
                  >
                    <Typography color={theme.palette.gray.g400} variant={"h6"}>
                      {t("order.totalWithoutDiscount")}
                    </Typography>
                    <NumericFormat
                      value={priceWithoutDiscount}
                      thousandSeparator=","
                      displayType="text"
                      renderText={(value) => (
                        <Typography
                          color={theme.palette.secondary.dark}
                          variant={"h6"}
                        >{`${value} ${t("currency.SAR")}`}</Typography>
                      )}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="true"
                    gap={theme.spacing(0.5)}
                  >
                    <Grid
                      container
                      alignItems={"center"}
                      gap={theme.spacing(1)}
                    >
                      <Typography
                        color={theme.palette.gray.g400}
                        variant={"h6"}
                      >
                        {t("order.discount")}
                      </Typography>
                      <Tooltip
                        title={discountMessage}
                        open={discountTooltip}
                        onClick={() => setDiscountTooltip(true)}
                        onClose={() => setDiscountTooltip(false)}
                      >
                        <Stack sx={{ cursor: "pointer" }} alignItems={"center"}>
                          <BsQuestionCircleFill
                            color={theme.palette.primary.main}
                          />
                        </Stack>
                      </Tooltip>
                    </Grid>
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"flex-end"}
                    >
                      <Typography
                        color={theme.palette.secondary.dark}
                        variant={"h6"}
                      >
                        {discount + " " + t("currency.SAR")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="true"
                    gap={theme.spacing(0.5)}
                  >
                    <Grid
                      container
                      alignItems={"center"}
                      gap={theme.spacing(1)}
                    >
                      <Typography
                        color={theme.palette.gray.g400}
                        variant={"h6"}
                      >
                        {t("order.cashBack")}
                      </Typography>
                      <Tooltip
                        title={cashBackMessage}
                        open={cashbackTooltip}
                        onClick={() => setCashbackTooltip(true)}
                        onClose={() => setCashbackTooltip(false)}
                      >
                        <Stack sx={{ cursor: "pointer" }} alignItems={"center"}>
                          <BsQuestionCircleFill
                            color={theme.palette.primary.main}
                          />
                        </Stack>
                      </Tooltip>
                    </Grid>
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"flex-end"}
                    >
                      <Typography
                        color={theme.palette.secondary.dark}
                        variant={"h6"}
                      >
                        {cashBack + " " + t("currency.SAR")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="true"
                    gap={0.5}
                  >
                    <Typography color={theme.palette.gray.g400} variant={"h6"}>
                      {t("order.shippingPrice")}
                    </Typography>
                    {shippingPrice === "FREE" ? (
                      <Typography
                        color={theme.palette.secondary.dark}
                        variant={"h6"}
                      >
                        {t("order.free")}
                      </Typography>
                    ) : (
                      <NumericFormat
                        value={shippingPrice}
                        thousandSeparator=","
                        displayType="text"
                        renderText={(value) => (
                          <Typography
                            color={theme.palette.secondary.dark}
                            variant={"h6"}
                          >{`${value} ${t("currency.SAR")}`}</Typography>
                        )}
                      />
                    )}
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="true"
                    gap={theme.spacing(0.5)}
                  >
                    <Typography color={theme.palette.gray.g400} variant={"h6"}>
                      {t("order.priceWithoutVAT")}
                    </Typography>
                    <Typography
                      color={theme.palette.secondary.dark}
                      variant={"h6"}
                    >
                      <NumericFormat
                        value={totalPriceWithoutVat}
                        thousandSeparator=","
                        displayType="text"
                        renderText={(value) => (
                          <Typography
                            color={theme.palette.secondary.dark}
                            variant={"h6"}
                          >{`${value} ${t("currency.SAR")}`}</Typography>
                        )}
                      />
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="true"
                    gap={theme.spacing(0.5)}
                  >
                    <Typography color={theme.palette.gray.g400} variant={"h6"}>
                      {t("order.vat")} 15%
                    </Typography>
                    <NumericFormat
                      value={vat}
                      thousandSeparator=","
                      displayType="text"
                      renderText={(value) => (
                        <Typography
                          color={theme.palette.secondary.dark}
                          variant={"h6"}
                        >{`${value} ${t("currency.SAR")}`}</Typography>
                      )}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="true"
                    gap={theme.spacing(0.5)}
                  >
                    <Typography
                      color={theme.palette.secondary.main}
                      fontWeight={theme.typography.bold}
                      variant={"h6"}
                    >
                      {t("order.total")}
                    </Typography>
                    <NumericFormat
                      value={totalPriceWithVat}
                      thousandSeparator=","
                      displayType="text"
                      renderText={(value) => (
                        <Typography
                          color={theme.palette.secondary.main}
                          fontWeight={theme.typography.bold}
                          variant={"h6"}
                        >{`${value} ${t("currency.SAR")}`}</Typography>
                      )}
                    />
                  </Grid>
                  {selectedOrder.status === "waiting_approvement" && (
                    <Grid
                      container
                      height={"50px"}
                      paddingTop={theme.spacing(0.5)}
                    >
                      <ButtonComp
                        onClick={handleConfirm}
                        variant="contained"
                        color="secondary"
                        content={
                          confirmOrderCurrentStatus === "loading" ? (
                            <CircularProgress size={30} />
                          ) : (
                            t("order.confirm")
                          )
                        }
                        fullWidth
                        sx={{
                          borderRadius: `${theme.borderRadius.r10}`,
                          height: "100%",
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid
                  container
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  padding={"2rem"}
                >
                  <Typography
                    color={theme.palette.secondary.main}
                    variant={"h6"}
                    fontWeight={theme.typography.bold}
                    textAlign={"center"}
                  >
                    {t("order.waitPricing")}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </SwipeableDrawer>
        </>
      )}
    </>
  );
};

export default OrderBottomDrawer;
