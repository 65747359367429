import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "../utils/api/Authentication";

const LoginProtected = ({ children }) => {
    const redirectPath = "/home";
    if (isAuthenticated()) {
        return <Navigate to={redirectPath} replace />;
    }
    return children ? children : <Outlet />;
};
export default LoginProtected;
