import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  promotionBanner: {
    open: true,
  },
};

export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    togglePromotionBanner: (state) => {
      state.promotionBanner.open = !state.promotionBanner.open;
    },
    resetBanner: (state, action) => initialState,
  },

  extraReducers: (builder) => {},
});

export const { resetBanner, togglePromotionBanner } = bannerSlice.actions;
export default bannerSlice.reducer;
