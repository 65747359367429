import {
  Grid,
  Typography,
  useTheme,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getSpecificOfferStatus,
  currentlySelectedOfferId,
} from "../../app/features/offer/offer";
import Countdown from "react-countdown";
import { BiSolidGift } from "react-icons/bi";
import i18next from "i18next";

const OfferCard = ({ offer, handleSubmit }) => {
  //SECTION - general
  const { t } = useTranslation();
  const theme = useTheme();

  //SECTION - useState
  const [selected, setSelcted] = useState(null);

  //SECTION - useSelector
  const selectedOfferId = useSelector(currentlySelectedOfferId);
  const specificOfferStatus = useSelector(getSpecificOfferStatus);

  //SECTION - useEffect
  useEffect(() => {
    //update the selected order to highlight its div
    setSelcted(selectedOfferId);
  }, [selectedOfferId]);

  return (
    <Grid
      paddingY={theme.spacing(1)}
      key={offer.id}
      container
      borderRadius={theme.borderRadius.r20}
      sx={{
        border:
          selected === offer.id
            ? `solid ${theme.palette.primary.main} 3px`
            : `solid ${theme.palette.gray.g400} 1px`,
        backgroundColor: selected === offer.id ? `white` : `white`,
      }}
      gap={theme.spacing(1)}
    >
      {
        <>
          <Grid
            container
            onClick={(e) => {
              handleSubmit(offer.id);
            }}
            justifyContent={"flex-start"}
            height={"content-fit"}
            paddingX={theme.spacing(2)}
          >
            <Typography
              fontWeight={theme.typography.bold}
              color={theme.palette.secondary.main}
              fontSize={{
                semiMd: "0.9rem",
                md: "1.05rem",
                lg: "1.1rem",
              }}
            >
              {i18next.language === "ar" ? offer.message_ar : offer.message_en}
            </Typography>
          </Grid>
          <Grid container>
            <Grid
              onClick={(e) => {
                handleSubmit(offer.id);
              }}
              xs={3}
              semiSm={2.5}
              semiMd={3.3}
              lg={2}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  backgroundColor: theme.palette.primary.light,
                  padding: theme.spacing(1),
                  borderRadius: "50px",
                  width: {
                    xs: "60px",
                    semiMd: "50px",
                    md: "60px",
                  },
                  height: {
                    xs: "60px",
                    semiMd: "50px",
                    md: "60px",
                  },
                }}
              >
                {specificOfferStatus === "loading" &&
                offer.id === selectedOfferId ? (
                  <CircularProgress size={30} />
                ) : (
                  <BiSolidGift
                    fontSize={"2.5rem"}
                    color={theme.palette.primary.main}
                  />
                )}
              </Stack>
            </Grid>
            <Grid xs={9} semiSm={9.5} semiMd={8.7} lg={9}>
              <Grid
                onClick={(e) => {
                  handleSubmit(offer.id);
                }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                wrap="true"
                gap={theme.spacing(0.5)}
              >
                <Typography
                  color={theme.palette.gray.g400}
                  fontSize={{ semiMd: "0.9rem", lg: "1rem" }}
                >
                  {t("offers.startAt")}
                </Typography>
                <Typography
                  color={theme.palette.secondary.dark}
                  fontSize={{ semiMd: "0.9rem", lg: "1rem" }}
                >
                  {moment(offer.created_at).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
              <Grid
                onClick={(e) => {
                  handleSubmit(offer.id);
                }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                wrap="true"
                gap={theme.spacing(0.5)}
              >
                <Typography
                  color={theme.palette.gray.g400}
                  fontSize={{ semiMd: "0.9rem", lg: "1rem" }}
                >
                  {t("offers.endAt")}
                </Typography>
                <Typography
                  color={theme.palette.secondary.dark}
                  fontSize={{ semiMd: "0.9rem", lg: "1rem" }}
                >
                  {moment(offer.end_at).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
              <Grid
                onClick={(e) => {
                  handleSubmit(offer.id);
                }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                wrap="true"
                gap={theme.spacing(0.5)}
              >
                <Typography
                  color={theme.palette.gray.g400}
                  fontSize={{ semiMd: "0.9rem", lg: "1rem" }}
                >
                  {t("offers.endsIn")}
                </Typography>
                <Typography
                  fontSize={{ semiMd: "0.9rem", lg: "1rem" }}
                  color={theme.palette.primary.main}
                  fontWeight={theme.typography.bold}
                >
                  {moment(offer.end_at).diff(moment(offer.created_at)) >
                  86400000 ? (
                    moment(offer.end_at).diff(
                      moment(offer.created_at),
                      "days"
                    ) === "1" ? (
                      t("offers.oneDay")
                    ) : (
                      moment(offer.end_at).diff(
                        moment(offer.created_at),
                        "days"
                      ) +
                      " " +
                      t("offers.days")
                    )
                  ) : (
                    <Countdown
                      date={
                        Date.now() +
                        moment(offer.end_at).diff(moment(offer.created_at))
                      }
                    />
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    </Grid>
  );
};

export default OfferCard;
