import React from "react";
import Banner from "./Banner/Banner";
import { Container } from "@mui/material";

const HeroSection = () => {
    return (
        <Container maxWidth="lg">
            <Banner />
            {/* <SupplierBox /> */}
        </Container>
    );
};

export default HeroSection;
