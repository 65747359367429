import axios from "axios";
import i18n from "../../i18n";

// Create a function that generates an Axios instance with interceptors
const createTowerInstance = (baseURL) => {
  const instance = axios.create({
    baseURL: baseURL,
    timeout: 30000,
  });
  // Add a request interceptor
  instance.interceptors.request.use(
    (config) => {
      //add app version and platform and name to every call
      config.headers["X-App-Platform"] = "web";
      config.headers["X-App-Version"] = "1.0.0";
      config.headers["X-App-Name"] = "store-webapp";

      const accessToken = localStorage.getItem("accessToken");
      // Attach the access token to the request header
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      // You can add other request headers or perform modifications here

      // config.data = {
      //     ...config.data, // Preserve existing data properties
      //     // Add your default data here
      //     lang: i18n.language,
      // };

      return config;
    },
    (error) => {
      // Handle request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      // You can modify the response data here before it is passed to the calling code
      return response;
    },
    (error) => {
      switch (error.response.status) {
        case 401:
          for (let key in localStorage) {
            if (key !== "lng") {
              localStorage.removeItem(key);
            }
          }
          break;
        case 403:
          window.location.href = "/error/403";
          break;
        case 404:
          window.location.href = "/error/404";
          break;
        case 405:
          window.location.href = "/error/405";
          break;
        case 406:
          window.location.href = "/error/406";
          break;
        case 409:
          window.location.href = "/error/409";
          break;
        case 410:
          window.location.href = "/error/410";
          break;
        case 422:
          //NOTE - forms error handled at specific components
          break;
        case 429:
          window.location.href = "/error/429";
          break;
        case 500:
          window.location.href = "/error/500";
          break;
        default:
      }

      return error.response;
    }
  );

  return instance;
};

const createWatchTowerInstance = (baseURL) => {
  const instance = axios.create({
    baseURL: baseURL,
    timeout: 30000,
  });

  // Add a request interceptor
  instance.interceptors.request.use(
    (config) => {
      //add app version and platform and name to every call
      config.headers["X-App-Platform"] = "web";
      config.headers["X-App-Version"] = "1.0.0";
      config.headers["X-App-Name"] = "store-webapp";

      const accessToken = localStorage.getItem("watchTowerAccessToken");
      // Attach the access token to the request header
      if (!config.url.includes("sales/track/") && accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      config.headers["Accept-Language"] = i18n.language;

      // You can add other request headers or perform modifications here
      // config.data = {
      //     ...config.data, // Preserve existing data properties
      //     // Add your default data here
      //     lang: i18n.language,
      // };

      return config;
    },
    (error) => {
      // Handle request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      // You can modify the response data here before it is passed to the calling code
      return response;
    },
    (error) => {
      switch (error.response.status) {
        case 401:
          for (let key in localStorage) {
            if (key !== "lng") {
              localStorage.removeItem(key);
            }
          }
          if (
            error.response.data.errorCode === "usernameOrPasswordNotCorrect"
          ) {
            //NOTE -  handled at login page - flash message is displayed
          } else if (error.response.data.errorCode === "unauthorizedError") {
            //NOTE - token not valid
            window.location.href = "/error/401";
          } else {
            //NOTE - token not valid
            window.location.href = "/error/401";
          }
          break;
        case 403:
          window.location.href = "/error/403";
          break;
        case 404:
          if (error.request.responseURL.includes("/sales/track/")) {
            window.location.href = "/error/404-OT";
          } else {
            window.location.href = "/error/404";
          }
          break;
        case 405:
          window.location.href = "/error/405";
          break;
        case 406:
          window.location.href = "/error/406";
          break;
        case 409:
          window.location.href = "/error/409";
          break;
        case 410:
          window.location.href = "/error/410";
          break;
        case 422:
          //NOTE - forms error handled at specific components
          break;
        case 429:
          window.location.href = "/error/429";
          break;
        default:
      }
      return error.response;
    }
  );

  return instance;
};

// Create instances with different base URLs
const towerInstance = createTowerInstance(process.env.REACT_APP_URL);
const watchTowerInstance = createWatchTowerInstance(
  process.env.REACT_APP_WATCHTOWER_APP_URL
);

export { towerInstance, watchTowerInstance };
