import { Tooltip, Typography } from "@mui/material";
import { Stack, useMediaQuery, useTheme } from "@mui/system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const WillBePricedWrapper = ({ selling_price, Child, id }) => {
  //SECTION - general
  const theme = useTheme();
  const { t } = useTranslation();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  //SECTION - useState
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <>
      {selling_price === t("home.willBePriced") ? (
        <Tooltip
          arrow
          onClick={() => {
            setSelectedItem(id);
            setTimeout(() => {
              setSelectedItem(null);
            }, [3000]);
          }}
          onMouseEnter={() => {
            setSelectedItem(id);
          }}
          onMouseOut={() => {
            setSelectedItem(null);
          }}
          open={selectedItem === id}
          title={
            <Typography variant={isLargeScreen ? "body1" : "body2"}>
              {t("currency.willBePricedExplanation")}
            </Typography>
          }
        >
          <Stack>
            <Child />
          </Stack>
        </Tooltip>
      ) : (
        <Stack>
          <Child />
        </Stack>
      )}
    </>
  );
};

export default WillBePricedWrapper;
