import React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
    linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Trans, useTranslation } from "react-i18next";
import IsEmpty from "../../utils/validation/IsEmpty";
import { useSelector } from "react-redux";
import { getPricingConfig } from "../../app/features/cart/cart";

const BorderLinearProgress = styled(LinearProgress)(({ currentLang }) => ({
    height: 5,
    borderRadius: 5,
    transform: currentLang === "ar" && "scaleX(-1)",
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#d0f5e4",
        boxShadow: "0 0px 4px rgba(129, 247, 192, 0.5)",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        transform: currentLang === "ar" && "scaleX(-1)",
        backgroundColor: "#17e887",
    },
}));

const ProgressBar = ({ subtotalValue, isUserNew }) => {
    //SECTION - GENERAL
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    //SECTION - useSelector
    const pricingConfig = useSelector(getPricingConfig);
    const maxValue = parseFloat(pricingConfig?.shipping_price_apply_below);

    //SECTION - FUNCTIONS
    // Function to extract numbers from the subtotalValue string and convert to float
    const extractNumbers = (value) => {
        const numberRegex = /[\d.]+/; // Regex to match numbers

        const matches = value.toString().match(numberRegex);
        if (matches) {
            return parseFloat(matches[0]);
        }
        return null; // Return null if no valid number is found
    };

    const currentValue = extractNumbers(subtotalValue);

    return (
        <>
            {IsEmpty(currentValue) || isUserNew === true || isNaN(maxValue) ? (
                ""
            ) : (
                <Stack
                    sx={{ padding: theme.spacing(1), gap: theme.spacing(1) }}
                >
                    <Box>
                        <Typography fontSize={"0.9rem"}>
                            {currentValue > maxValue ? (
                                <Trans>
                                    {t("cart.youHaveEarnedFreeShipping")}
                                </Trans>
                            ) : (
                                <Trans>
                                    {t("cart.addToGetFreeShipping", {
                                        remainingValue:
                                            maxValue - currentValue <=
                                                maxValue &&
                                            maxValue - currentValue > 0
                                                ? (
                                                      maxValue - currentValue
                                                  ).toFixed(2)
                                                : 0,
                                    })}
                                </Trans>
                            )}
                        </Typography>
                    </Box>

                    <Stack
                        alignItems={"center"}
                        direction={"row"}
                        sx={{ gap: theme.spacing(1) }}
                    >
                        <Box sx={{ width: "100%" }}>
                            <BorderLinearProgress
                                variant="determinate"
                                currentLang={i18n.language}
                                value={
                                    (100 / maxValue) * currentValue <= 100
                                        ? (100 / maxValue) * currentValue
                                        : 100
                                }
                            />
                        </Box>
                        <Box>
                            <Typography variant="body2" noWrap>
                                {`${maxValue} ${t("currency.SAR")}`}
                            </Typography>
                        </Box>
                    </Stack>
                    <Divider sx={{ marginBottom: theme.spacing(1) }} />
                </Stack>
            )}
        </>
    );
};

export default ProgressBar;
