import { useTheme } from "@emotion/react";
import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import CartCheckout from "./CartCheckout";
import CartTable from "./CartTable";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCartItems,
  getCartItems,
  fetchPricingConfig,
} from "../../app/features/cart/cart";
import {
  fetchAddress,
  getAddressStatus,
} from "../../app/features/address/address";
import { fetchBrands, getBrandStatus } from "../../app/features/brand/brand";

const Cart = () => {
  //SECTION - GENERAL
  const theme = useTheme();
  const dispatch = useDispatch();

  //SECTION - Selectors
  const fetchCartStatus = useSelector(getCartItems);
  const fetchAddressStatus = useSelector(getAddressStatus);
  const brandStatus = useSelector(getBrandStatus);

  //SECTION - useEffects
  //Once user lands on cart page, fetch cartItems & address
  useEffect(() => {
    if (
      fetchCartStatus === "idle" ||
      fetchAddressStatus === "idle" ||
      brandStatus === "idle"
    ) {
      dispatch(fetchCartItems());
      dispatch(fetchAddress());
      dispatch(fetchBrands());
      dispatch(fetchPricingConfig());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box mt={theme.spacing(2)} p={theme.spacing(1)}>
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={12} sm={12} semiMd={7} md={7} lg={7.8}>
          <CartTable />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          semiMd={4.9}
          md={4.8}
          lg={4}
          position={"relative"}
        >
          <CartCheckout />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Cart;
