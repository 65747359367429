import { useTheme } from "@emotion/react";
import { Button, Grid, Typography, LinearProgress } from "@mui/material";
import { Stack, useMediaQuery } from "@mui/system";
import { useTranslation } from "react-i18next";
import { HiOutlineExclamation } from "react-icons/hi";
import { PiPackageBold } from "react-icons/pi";
import { FaTruck } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { AiFillDollarCircle } from "react-icons/ai";
import { MdOutlineTimeline } from "react-icons/md";
import MobileTimeline from "./MobileTimeline";
import { useSelector } from "react-redux";
import { IoMdNotifications } from "react-icons/io";
import moment from "moment";
import validation from "../../utils/Helpers/validation";
import ItemsTable from "./ItemsTable";
import { TbPackages } from "react-icons/tb";
import { NumericFormat } from "react-number-format";

const SecondSection = ({ steps, currentStep, currentStepIndex }) => {
  //SECTION - general
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isAboveSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const isAboveMedium = useMediaQuery(theme.breakpoints.up("md"));
  const isAboveLarge = useMediaQuery(theme.breakpoints.up("lg"));

  //SECTION - useSelector
  const orderTrackingInformation = useSelector(
    (state) => state.orderTrackingReducer.orderTrackingInformation
  );
  const fetchOrderTrackingInformationStatus = useSelector(
    (state) => state.orderTrackingReducer.fetchOrderTrackingInformationStatus
  );

  //SECTION - order details section
  const orderDetailsTitles = [
    t("orderTracking.orderID"),
    t("orderTracking.orderDate"),
    t("orderTracking.orderStatus"),
    t("orderTracking.totalItems"),
    t("orderTracking.totalQuantity"),
    t("orderTracking.available"),
    t("orderTracking.unavailable"),
  ];

  let orderDetailsInfo = [
    orderTrackingInformation?.id || "-",
    moment(orderTrackingInformation?.approved_at).format("DD/MM/YYYY") || "-",
    currentStep?.status || "-",
    validation.isNumber(orderTrackingInformation?.count_items)
      ? orderTrackingInformation?.count_items
      : "-",
    validation.isNumber(orderTrackingInformation?.total_items)
      ? orderTrackingInformation?.total_items
      : "-",
    validation.isNumber(orderTrackingInformation?.total_available)
      ? orderTrackingInformation?.total_available
      : "-",
    validation.isNumber(orderTrackingInformation?.total_unavailable)
      ? orderTrackingInformation?.total_unavailable
      : "-",
  ];

  //SECTION - order shipping section
  const orderShippingTitles = [
    t("orderTracking.shippingCompany"),
    t("orderTracking.shippingCompanyPhone"),
    t("orderTracking.finalDestination"),
  ];

  let orderShippingInfo = [
    orderTrackingInformation?.shipping_company
      ? (i18n.language === "ar"
          ? orderTrackingInformation?.shipping_company?.name_ar
          : orderTrackingInformation?.shipping_company?.name_en) || "-"
      : "-",
    orderTrackingInformation?.shipping_company_phone || "-",
    orderTrackingInformation?.address || "-",
  ];

  //SECTION - order payment section
  const orderPaymentTitles = [
    t("orderTracking.totalPriceWithoutDiscount"),
    t("orderTracking.discount"),
    t("orderTracking.cashBack"),
    t("orderTracking.shippingPrice"),
    t("orderTracking.totalWithoutVAT"),
    t("orderTracking.VAT"),
    t("orderTracking.total"),
  ];
  let orderPaymentInfo = [
    orderTrackingInformation?.total_price_without_vat &&
    orderTrackingInformation?.shipping_price
      ? (
          parseFloat(orderTrackingInformation?.total_price_without_vat) -
          parseFloat(orderTrackingInformation?.shipping_price)
        ).toFixed(2)
      : "-",
    orderTrackingInformation?.discount
      ? parseFloat(orderTrackingInformation?.discount).toFixed(2)
      : "-",
    orderTrackingInformation?.cashback
      ? parseFloat(orderTrackingInformation?.cashback).toFixed(2)
      : "-",
    orderTrackingInformation?.shipping_price
      ? parseFloat(orderTrackingInformation?.shipping_price).toFixed(2)
      : "-",
    orderTrackingInformation?.total_price_without_vat
      ? (
          parseFloat(orderTrackingInformation?.total_price_without_vat) || 0
        ).toFixed(2)
      : "-",

    orderTrackingInformation?.total_price_without_vat &&
    orderTrackingInformation?.vat
      ? (
          parseFloat(orderTrackingInformation?.total_price_without_vat) *
            parseFloat(orderTrackingInformation?.vat) || 0
        ).toFixed(2)
      : "-",
    orderTrackingInformation?.price_final_total
      ? parseFloat(orderTrackingInformation?.price_final_total).toFixed(2)
      : "-",
  ];

  return (
    <Stack
      paddingX={{ xs: "0px", sm: "50px" }}
      maxWidth={"1600px"}
      sx={{ width: "100%" }}
      gap={"16px"}
    >
      {orderTrackingInformation?.is_need_payment && (
        <Stack
          sx={{ backgroundColor: theme.palette.error.e50, borderRadius: "8px" }}
          paddingY={"8px"}
          paddingX={"6px"}
          justifyContent={"start"}
          alignItems={"center"}
          gap={"8px"}
          textAlign={"center"}
          direction={"row"}
        >
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
            sx={{
              borderRadius: "50%",
              backgroundColor: theme.palette.error.e100,
              padding: "4px",
            }}
          >
            <HiOutlineExclamation
              color={theme.palette.error.e400}
              size={isAboveSmall ? "1.5rem" : "1.25rem"}
            />
          </Stack>
          <Typography variant="body2" color={theme.palette.error.e400}>
            {t("orderTracking.payBeforeCancellationNoDays")}
          </Typography>
        </Stack>
      )}
      {!["invoice", "completed"].includes(orderTrackingInformation?.status) &&
        orderTrackingInformation?.is_out_for_delivery && (
          <Stack
            sx={{
              backgroundColor: theme.palette.primary.light,
              borderRadius: "8px",
            }}
            paddingY={"8px"}
            paddingX={"6px"}
            justifyContent={"start"}
            alignItems={"center"}
            gap={"8px"}
            textAlign={"center"}
            direction={"row"}
          >
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
              sx={{
                borderRadius: "50%",
                backgroundColor: theme.palette.primary.light,
                padding: "4px",
              }}
            >
              <IoMdNotifications
                color={theme.palette.primary.main}
                size={isAboveSmall ? "1.5rem" : "1.25rem"}
              />
            </Stack>
            <Typography variant="body2" color={theme.palette.primary.main}>
              {t("orderTracking.yourOrderIsOutForDelivery")}
            </Typography>
          </Stack>
        )}
      <Grid container direction={{ xs: "column", md: "row" }}>
        {!isAboveMedium && (
          <Stack
            padding={{ xs: "8px", md: "16px" }}
            borderRadius={theme.borderRadius.r10}
            sx={{
              border: `2px solid ${theme.palette.gray.g200}`,
              backgroundColor: theme.palette.gray.g25second,
              width: { xs: "100%" },
              position: "relative",
            }}
          >
            {fetchOrderTrackingInformationStatus === "loading" && (
              <Stack
                sx={{
                  width: "100%",
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
              >
                <LinearProgress />
              </Stack>
            )}
            <Stack gap={"8px"} direction={"row"} alignItems={"center"}>
              <MdOutlineTimeline
                fontSize={isAboveMedium ? "1.5rem" : "1.25rem"}
                color={theme.palette.secondary.main}
              />
              <Typography
                variant="h6"
                fontWeight={theme.typography.bold}
                color={theme.palette.gray.g700}
              >
                {t("orderTracking.orderTimeline")}
              </Typography>
            </Stack>
            <Stack direction={"row"} justifyContent={"flex-start"}>
              <MobileTimeline
                currentStep={currentStep}
                steps={steps}
                currentStepIndex={currentStepIndex}
              />
            </Stack>
          </Stack>
        )}
        {/*Order Details*/}
        <Grid
          xs={12}
          lg={4}
          container
          paddingLeft={i18n.language === "ar" && isAboveLarge ? "16px" : "0px"}
          paddingRight={i18n.language === "en" && isAboveLarge ? "16px" : "0px"}
          paddingTop={!isAboveMedium ? "16px" : "0px"}
        >
          <Stack
            gap={"16px"}
            padding={{ xs: "8px", md: "16px" }}
            borderRadius={theme.borderRadius.r10}
            sx={{
              border: `2px solid ${theme.palette.gray.g200}`,
              backgroundColor: theme.palette.gray.g25second,
              width: "100%",
              position: "relative",
              ":hover": {
                border: `2px solid ${theme.palette.primary.main}`,
              },
            }}
          >
            <Stack gap={"8px"} direction={"row"} alignItems={"center"}>
              <PiPackageBold
                fontSize={isAboveMedium ? "1.5rem" : "1.25rem"}
                color={theme.palette.secondary.main}
              />
              <Typography
                variant="h6"
                fontWeight={theme.typography.bold}
                color={theme.palette.gray.g700}
              >
                {t("orderTracking.orderDetails")}
              </Typography>
            </Stack>

            {fetchOrderTrackingInformationStatus === "loading" && (
              <Stack
                sx={{
                  width: "100%",
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
              >
                <LinearProgress />
              </Stack>
            )}

            <Grid container item xs={12}>
              <Grid
                container
                item
                xs={6}
                gap={"4px"}
                direction={"column"}
                alignItems={"start"}
              >
                {orderDetailsTitles.map((title, index) => (
                  <Typography variant="body2">{title + ":"}</Typography>
                ))}
              </Grid>
              <Grid container item xs={6} gap={"4px"} direction={"column"}>
                {orderDetailsInfo.map((info, index) => (
                  <Typography
                    variant="body2"
                    fontWeight={theme.typography.bold}
                    color={
                      index !== 2
                        ? theme.palette.gray.g600
                        : currentStep?.status ===
                          "orderTracking.statuses.completed"
                        ? theme.palette.success.main
                        : currentStep?.status ===
                          "orderTracking.statuses.waitingPayment"
                        ? theme.palette.error.main
                        : theme.palette.secondary.main
                    }
                  >
                    {index > 2
                      ? info + " " + t("orderTracking.parts")
                      : index === 2
                      ? t(info)
                      : info}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        {/*Payment Details */}
        <Grid
          xs={12}
          lg={4}
          container
          paddingLeft={i18n.language === "ar" && isAboveLarge ? "16px" : "0px"}
          paddingRight={i18n.language === "en" && isAboveLarge ? "16px" : "0px"}
          paddingTop={!isAboveLarge ? "16px" : "0px"}
        >
          <Stack
            gap={"16px"}
            direction={"column"}
            padding={{ xs: "8px", md: "16px" }}
            borderRadius={theme.borderRadius.r10}
            sx={{
              border: `2px solid ${theme.palette.gray.g200}`,
              backgroundColor: theme.palette.gray.g25second,
              width: "100%",
              position: "relative",
              ":hover": {
                border: `2px solid ${theme.palette.primary.main}`,
              },
            }}
          >
            <Stack gap={"8px"} direction={"row"} alignItems={"center"}>
              <AiFillDollarCircle
                fontSize={isAboveMedium ? "1.5rem" : "1.25rem"}
                color={theme.palette.secondary.main}
              />
              <Typography
                variant="h6"
                fontWeight={theme.typography.bold}
                color={theme.palette.gray.g700}
              >
                {t("orderTracking.paymentDetails")}
              </Typography>
            </Stack>
            {fetchOrderTrackingInformationStatus === "loading" && (
              <Stack
                sx={{
                  width: "100%",
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
              >
                <LinearProgress />
              </Stack>
            )}
            <Stack gap={"4px"} direction={"column"} alignItems={"start"}>
              {orderPaymentTitles.map((title, index) => {
                return (
                  <Stack
                    justifyContent={"space-between"}
                    direction={"row"}
                    width={"100%"}
                  >
                    <Typography variant="body2" color={theme.palette.gray.g600}>
                      {title + ":"}
                    </Typography>
                    <NumericFormat
                      displayType="text"
                      value={orderPaymentInfo[index]}
                      thousandSeparator=","
                      renderText={(value) => (
                        <Typography
                          variant="body2"
                          color={
                            index === 6
                              ? theme.palette.secondary.main
                              : theme.palette.gray.g600
                          }
                          fontWeight={theme.typography.bold}
                        >
                          {parseFloat(orderPaymentInfo[index]) ===
                            parseFloat("0.000") && index === 3
                            ? t("currency.free")
                            : `${value} ${t("currency.SAR")}`}
                        </Typography>
                      )}
                    />
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Grid>
        {/*Shipping Details */}
        <Grid
          xs={12}
          lg={4}
          container
          paddingTop={!isAboveLarge ? "16px" : "0px"}
        >
          <Stack
            gap={"16px"}
            direction={"column"}
            padding={{ xs: "8px", md: "16px" }}
            borderRadius={theme.borderRadius.r10}
            sx={{
              border: `2px solid ${theme.palette.gray.g200}`,
              backgroundColor: theme.palette.gray.g25second,
              width: "100%",
              position: "relative",
              ":hover": {
                border: `2px solid ${theme.palette.primary.main}`,
              },
            }}
          >
            <Stack
              gap={"8px"}
              direction={"row"}
              alignItems={"center"}
              sx={{ width: "100%" }}
            >
              <FaTruck
                fontSize={isAboveMedium ? "1.5rem" : "1.25rem"}
                color={theme.palette.secondary.main}
              />
              <Typography
                variant="h6"
                fontWeight={theme.typography.bold}
                color={theme.palette.gray.g700}
              >
                {t("orderTracking.shippingDetails")}
              </Typography>
            </Stack>
            {fetchOrderTrackingInformationStatus === "loading" && (
              <Stack
                sx={{
                  width: "100%",
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
              >
                <LinearProgress />
              </Stack>
            )}
            <Stack
              justifyContent={"space-between"}
              direction={"column"}
              gap={"16px"}
              sx={{ width: "100%", height: "100%" }}
            >
              <Grid container item xs={12}>
                <Grid container item xs={6} gap={"4px"} direction={"column"}>
                  {orderShippingTitles.map((title) => (
                    <Typography variant="body2" color={theme.palette.gray.g600}>
                      {title + ":"}
                    </Typography>
                  ))}
                </Grid>
                <Grid container item xs={6} gap={"4px"} direction={"column"}>
                  {orderShippingInfo.map((info) => (
                    <Typography
                      variant="body2"
                      color={theme.palette.gray.g600}
                      fontWeight={theme.typography.bold}
                    >
                      {info}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
              <Stack direction={"column"} gap={"16px"}>
                {validation.isEmpty(
                  orderTrackingInformation?.shipping_file_url
                ) && (
                  <Typography
                    fontSize={{ xs: "12px" }}
                    color={theme.palette.error.e400}
                    fontWeight={theme.typography.bold}
                  >
                    {t("orderTracking.shippingBillEmptyWarning")}
                  </Typography>
                )}
                <Button
                  variant="contained"
                  fullWidth
                  color="secondary"
                  sx={{
                    backgroundColor: theme.palette.secondary.light,
                    borderRadius: "8px",
                    height: "34px",
                    gap: "6px",
                    ":hover": {
                      backgroundColor: theme.palette.secondary.light,
                    },
                    ":disabled": {
                      opacity: 0.7, // Adjust opacity to make the button less shiny
                    },
                    paddingY: { xs: "4px", md: "8px" },
                    paddingX: { xs: "8px", md: "16px" },
                  }}
                  disabled={validation.isEmpty(
                    orderTrackingInformation?.shipping_file_url
                  )}
                  onClick={() => {
                    window.open(orderTrackingInformation?.shipping_file_url);
                  }}
                >
                  <Typography
                    color={theme.palette.secondary.main}
                    variant="body1"
                    fontWeight={theme.typography.bold}
                    sx={{ textTransform: "none" }}
                  >
                    {t("orderTracking.shippingBill")}
                  </Typography>
                  <FaFileAlt
                    color={theme.palette.secondary.main}
                    fontSize={isAboveSmall ? "14px" : "12px"}
                  />
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      {orderTrackingInformation?.items.length && (
        <Stack gap={"16px"} direction={"column"}>
          {/*Items Details*/}

          <Stack
            gap={"16px"}
            padding={{ xs: "8px", md: "16px" }}
            borderRadius={theme.borderRadius.r10}
            sx={{
              border: `2px solid ${theme.palette.gray.g200}`,
              backgroundColor: theme.palette.gray.g25second,
              width: { xs: "100%" },
              position: "relative",
              ":hover": {
                border: `2px solid ${theme.palette.primary.main}`,
              },
            }}
          >
            <Stack gap={"8px"} direction={"row"} alignItems={"center"}>
              <TbPackages
                fontSize={isAboveMedium ? "1.5rem" : "1.25rem"}
                color={theme.palette.secondary.main}
              />
              <Typography
                variant="h6"
                fontWeight={theme.typography.bold}
                color={theme.palette.gray.g700}
              >
                {t("orderTracking.itemsDetails")}
              </Typography>
            </Stack>
            {fetchOrderTrackingInformationStatus === "loading" && (
              <Stack
                sx={{
                  width: "100%",
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
              >
                <LinearProgress />
              </Stack>
            )}
            <ItemsTable />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default SecondSection;
