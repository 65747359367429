import {
  Grid,
  Typography,
  useTheme,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { BsFillBoxSeamFill } from "react-icons/bs";
import moment from "moment";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  currentlySelectedOrderId,
  getFetchSpecificOrderStatus,
} from "../../app/features/order/order";
import displayOrderStatus from "../../utils/data/DisplayOrderStatus";
import { NumericFormat } from "react-number-format";

const OrderCard = ({ order, handleSubmit, UIUpdatedOrderId }) => {
  //SECTION - general
  const { t } = useTranslation();
  const theme = useTheme();

  //SECTION - useState
  const [selected, setSelcted] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [currentDisplayColor, setCurrentDisplayColor] = useState("primary");

  //SECTION - useSelector
  const selectedOrderId = useSelector(currentlySelectedOrderId);
  const fetchSpecificOrderStatus = useSelector(getFetchSpecificOrderStatus);

  //SECTION - useEffect
  useEffect(() => {
    //update the selected order to highlight its div
    setSelcted(selectedOrderId);
  }, [selectedOrderId]);
  useEffect(() => {
    const setNormalStatus = () => {
      setCurrentStatus(displayOrderStatus(order.status).status);
      setCurrentDisplayColor(displayOrderStatus(order.status).color);
    };
    const setUIUpdatedDisplayColor = () => {
      setCurrentStatus(t("order.prepare"));
      setCurrentDisplayColor("primary");
    };
    UIUpdatedOrderId === order.id
      ? setUIUpdatedDisplayColor()
      : setNormalStatus();
  }, [UIUpdatedOrderId, t, order.id, order.status]);

  return (
    <Grid
      paddingY={theme.spacing(1)}
      onClick={(e) => {
        handleSubmit(order.id);
      }}
      key={order.id}
      container
      borderRadius={theme.borderRadius.r20}
      sx={{
        border:
          selected === order.id
            ? `solid ${theme.palette.primary.main} 3px`
            : `solid ${theme.palette.gray.g400} 1px`,
        backgroundColor: selected === order.id ? `white` : `white`,
      }}
    >
      <>
        <Grid
          container
          justifyContent={"space-between"}
          height={"30px"}
          paddingX={theme.spacing(2)}
        >
          <Typography
            fontWeight={theme.typography.bold}
            color={theme.palette.secondary.dark}
            fontSize={{ semiMd: "1rem", lg: "1.1rem" }}
          >
            {t("order.orderNo")} {order.id}
          </Typography>

          <Typography
            color={theme.palette.gray.g400}
            fontSize={{ semiMd: "0.9rem", lg: "1rem" }}
          >
            {moment(order.created_at).format("DD/MM/YYYY")}
          </Typography>
        </Grid>
        <Grid container>
          <Grid
            xs={3}
            semiSm={2.5}
            semiMd={3.3}
            lg={2}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                backgroundColor: theme.palette.primary.light,
                padding: theme.spacing(1),
                borderRadius: "50px",
                width: {
                  xs: "60px",
                  semiMd: "50px",
                  md: "60px",
                },
                height: {
                  xs: "60px",
                  semiMd: "50px",
                  md: "60px",
                },
              }}
            >
              {fetchSpecificOrderStatus === "loading" &&
              order.id === selectedOrderId ? (
                <CircularProgress size={30} />
              ) : (
                <BsFillBoxSeamFill
                  fontSize={"2.5rem"}
                  color={theme.palette.primary.main}
                />
              )}
            </Stack>
          </Grid>
          <Grid xs={9} semiSm={9.5} semiMd={8.7} lg={9}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              wrap="true"
              gap={theme.spacing(0.5)}
            >
              <Typography
                color={theme.palette.gray.g400}
                fontSize={{ semiMd: "0.9rem", lg: "1rem" }}
              >
                {t("order.invoiceNo")}
              </Typography>
              <Typography
                color={theme.palette.secondary.dark}
                fontSize={{ semiMd: "0.9rem", lg: "1rem" }}
              >
                {order.invoice_id || "-"}
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              wrap="true"
              gap={theme.spacing(0.5)}
            >
              <Typography
                color={theme.palette.gray.g400}
                fontSize={{ semiMd: "0.9rem", lg: "1rem" }}
              >
                {t("order.orderStatus")}
              </Typography>
              <Typography
                fontSize={{ semiMd: "0.9rem", lg: "1rem" }}
                color={theme.palette[currentDisplayColor].main}
                fontWeight={theme.typography.bold}
              >
                {currentStatus}
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              wrap="true"
              gap={theme.spacing(0.5)}
            >
              <Typography
                color={theme.palette.secondary.main}
                fontSize={{ semiMd: "0.9rem", lg: "1rem" }}
              >
                {t("order.price")}
              </Typography>
              {order.status !== "pricing" ? (
                <NumericFormat
                  value={(
                    parseFloat(order.total_price) -
                    parseFloat(order.total_discount) +
                    parseFloat(order.shipping_price) +
                    (parseFloat(order.total_price) -
                      parseFloat(order.total_discount) +
                      parseFloat(order.shipping_price)) *
                      0.15
                  ).toFixed(2)}
                  thousandSeparator=","
                  displayType="text"
                  renderText={(value) => (
                    <Typography
                      color={theme.palette.secondary.main}
                      fontSize={{
                        semiMd: "0.9rem",
                        lg: "1rem",
                      }}
                    >{`${value} ${t("currency.SAR")}`}</Typography>
                  )}
                />
              ) : (
                <Typography
                  color={theme.palette.secondary.main}
                  fontSize={{ semiMd: "0.9rem", lg: "1rem" }}
                >
                  {t("order.willBePriced")}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    </Grid>
  );
};

export default OrderCard;
